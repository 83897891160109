import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonList,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";

import { RootStore } from "../stores/RootStore";

import { Header, TextField } from "../components";
import { action } from "mobx";
import { GarmentSubCategory } from "../stores/models";
import { closeCircleOutline, addCircleOutline } from "ionicons/icons";
import ActionGarmentSubCategoryListItem from "../components/ActionGarmentSubCategoryListItem/ActionGarmentSubCategoryListItem";

interface GarmentSubCategoryDetailsProps {
  store: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class GarmentSubCategoryDetails extends React.Component<
  GarmentSubCategoryDetailsProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    this.init();
  }

  init() {
    const id = parseInt(this.props.match.params.id);
    this.getGarment(id);
    const {
      garments,
      actionGarmentSubCategories,
      actions,
    } = this.props.store.stores;
    garments.getGarmentGategories();
    garments.getGarmentSubCategoryByID(id);
    actionGarmentSubCategories.getActionGarmentSubCategories();
    actions.getActions();
  }

  getGarment(id: number) {
    const { garments } = this.props.store.stores;
    const { garmentSubCategory } = garments;
    if (garmentSubCategory.ID === id) return;
    if (id === 0) {
      this.reset();
      return;
    }
    garments.getGarmentSubCategoryByID(id);
  }

  reset() {
    const { garments } = this.props.store.stores;
    garments.resetGarmentSubCategory();
  }

  componentWillUnmount() {
    this.reset();
  }

  createGarmentSubCategory = async () => {
    const { garments, ui } = this.props.store.stores;
    try {
      const gsc = await garments.createGarmentSubCategory();
      ui.showToast({ message: "Information Saved", color: "success" });
      garments.getGarmentCategoryById(gsc.ID);
      garments.setGarmentSubCategory(gsc);
      this.props.history.replace(`/garmentsSubCategory/${gsc.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  updateGarmentSubCategory = async () => {
    const { garments, ui } = this.props.store.stores;
    const { garmentSubCategory } = garments;
    try {
      await garments.saveGarmentSubCategory();
      garments.getGarmentSubCategory(garmentSubCategory.ID);
      ui.showToast({ message: "Information Saved", color: "success" });
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { garments } = this.props.store.stores;
    const { garmentSubCategory } = garments;
    if (garmentSubCategory.ID === 0) {
      this.createGarmentSubCategory();
    } else {
      this.updateGarmentSubCategory();
    }
    garments.pushGarmentSubCategory(garmentSubCategory);
  };

  // @action
  handleImageUpload = (e: any) => {
    const { garments } = this.props.store.stores;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64Image: string | undefined = reader.result?.toString();
      garments.setImageGarmentSubCategory(base64Image ? base64Image : "");
    };
    reader.onerror = function () {};
  };

  @action
  handleDelete = async (confirmed: boolean) => {
    const { garments, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    const { garmentSubCategory } = garments;
    if (garmentSubCategory.ID === 0) return;
    try {
      const s = await garments.deleteGarmentSubCategory(garmentSubCategory.ID);
      if (s) {
        ui.showToast({
          message: "Garment Sub Category Removed",
          color: "success",
        });
        this.reset();
      }
      this.props.history.replace(`/garments/${garmentSubCategory.ID}`);
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  private getPieces(garmentSubCategory: GarmentSubCategory): string[] {
    return garmentSubCategory.Pieces.split(",");
  }

  private generatePieces(pieces: string[]): string {
    let p: string = "";
    pieces.map((piece: string, index) => {
      //handle empty pieces
      if (piece === "") {
        return "";
      }
      if (index !== pieces.length - 1) {
        p = p + piece.trim() + ", ";
      } else {
        p = p + piece.trim();
      }
      return p;
    });
    return p;
  }

  private handleDeletePeice(piece: string) {
    const { garments } = this.props.store.stores;
    let garmentSubCategory = garments.garmentSubCategory;
    let pieces = this.getPieces(garmentSubCategory);
    const newPieces = pieces.filter((p: string) => {
      return p !== piece;
    });
    const piecesCSV = this.generatePieces(newPieces);
    garmentSubCategory.Pieces = piecesCSV;
    garments.setGarmentSubCategory(garmentSubCategory);
  }

  private handleUpdatePiece(piece: string, index: number) {
    const { garments } = this.props.store.stores;
    let garmentSubCategory = garments.garmentSubCategory;
    let pieces = this.getPieces(garmentSubCategory);
    pieces[index] = piece;
    const piecesCSV = this.generatePieces(pieces);
    garmentSubCategory.Pieces = piecesCSV;
    garments.setGarmentSubCategory(garmentSubCategory);
    console.log(garments.garmentSubCategory);
  }

  private handleAddPiece(piece: string) {
    const { garments } = this.props.store.stores;
    const garmentSubCategory = garments.garmentSubCategory;
    let pieces = this.getPieces(garmentSubCategory);
    pieces.push(piece);
    const piecesCSV = this.generatePieces(pieces);
    garmentSubCategory.Pieces = piecesCSV;
    garments.setGarmentSubCategory(garmentSubCategory);
  }

  private getPiecesList(): JSX.Element {
    let newPiece = "";
    const { garmentSubCategory } = this.props.store.stores.garments;
    let pieces = this.getPieces(garmentSubCategory);
    return (
      <IonList>
        {garmentSubCategory.Pieces !== "" ? (
          pieces.map((piece, index) => {
            return (
              <div key={piece}>
                <IonItem>
                  <TextField
                    type="text"
                    value={piece}
                    onChange={(p: string) => {
                      this.handleUpdatePiece(p, index);
                    }}
                  />

                  <IonButton
                    slot="end"
                    color="green"
                    expand="full"
                    size="small"
                    onClick={() => {
                      this.handleDeletePeice(piece);
                    }}
                  >
                    <IonIcon
                      color={"danger"}
                      slot="icon-only"
                      icon={closeCircleOutline}
                    />
                  </IonButton>
                </IonItem>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
        <IonItem>
          <TextField
            type="text"
            label="New piece"
            onChange={(v: any) => {
              newPiece = v;
            }}
          />
          <IonButton
            slot="end"
            color="green"
            expand="full"
            size="small"
            onClick={() => {
              this.handleAddPiece(newPiece);
              newPiece = "";
            }}
          >
            <IonIcon
              color={"success"}
              slot="icon-only"
              icon={addCircleOutline}
            />
          </IonButton>
        </IonItem>
      </IonList>
    );
  }

  private getGarmentSubCategoryImage(
    garmentSubCategory: GarmentSubCategory
  ): JSX.Element {
    return (
      <IonItem>
        <IonThumbnail slot="start">
          <label htmlFor={"imageUploaderSub"} style={{ cursor: `pointer` }}>
            <img
              src={garmentSubCategory.Image ? garmentSubCategory.Image : ""}
              alt={"image"}
            />
          </label>
          <input
            type="file"
            accept="image/*"
            capture="camera"
            id="imageUploaderSub"
            style={{ display: `none` }}
            onChange={this.handleImageUpload}
          />
        </IonThumbnail>
      </IonItem>
    );
  }

  body() {
    const { garmentSubCategory } = this.props.store.stores.garments;
    const {
      garments,
      actions,
      actionGarmentSubCategories,
    } = this.props.store.stores;
    return (
      <>
        <IonContent>
          <IonGrid>
            <IonRow>
              {this.getGarmentSubCategoryImage(garmentSubCategory)}
              <IonCol sizeLg="2" sizeXs="12">
                <TextField
                  type="text"
                  label="Name"
                  onChange={(v: any) => {
                    garmentSubCategory.Name = v;
                  }}
                  value={garmentSubCategory.Name}
                />
              </IonCol>
              <IonCol sizeLg="1" sizeXs="12">
                <IonItem>
                  <IonLabel>Visible</IonLabel>
                  <IonCheckbox
                    checked={garmentSubCategory.Visible}
                    onIonChange={(e) => {
                      garmentSubCategory.Visible = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Show Choose Pieces</IonLabel>
                  <IonCheckbox
                    checked={garmentSubCategory.ShowChoosePieces}
                    onIonChange={(e) => {
                      garmentSubCategory.ShowChoosePieces = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="1" sizeXs="12">
                <TextField
                  type="number"
                  label="Discount"
                  onChange={(v: any) => {
                    parseFloat(v)<=0?v=0:v=v
                    parseFloat(v)>=100?v=100:v=v;
                    garmentSubCategory.Discount ="PERCENTAGE "+ parseFloat(v);
                  }}
                  value={garmentSubCategory.Discount.replace("PERCENTAGE ","")}
                />
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Quantity Allowed</IonLabel>
                  <IonCheckbox
                    checked={garmentSubCategory.AllowQuantity}
                    onIonChange={(e) => {
                      garmentSubCategory.AllowQuantity = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Garment Category</IonLabel>
                  <IonSelect
                    key={garmentSubCategory.GarmentCategoryID}
                    value={garments.garmentSubCategory.GarmentCategoryID}
                    onIonChange={(e) => {
                      garmentSubCategory.GarmentCategoryID = e.detail.value;
                    }}
                  >
                    {garments.garmentCategories.map((gcs) => {
                      return (
                        <IonSelectOption value={gcs.ID} key={gcs.ID}>
                          {gcs.Name}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>{this.getPiecesList()}</IonItem>
              </IonCol>
              {garmentSubCategory.ID ? (
                <IonCol size="8">
                  <IonList>
                    {actions.actions.map((action) => {
                      const agsc = actionGarmentSubCategories.getActionGarmentSubCategoryByActionIDGarmentSubCategoryID(
                        action.ID,
                        garments.garmentSubCategory.ID
                      );
                      return (
                        <ActionGarmentSubCategoryListItem
                          store={this.props.store}
                          actionGarmentSubCategory={agsc}
                        ></ActionGarmentSubCategoryListItem>
                      );
                    })}
                  </IonList>
                </IonCol>
              ) : (
                <></>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {garmentSubCategory.ID !== 0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }

  render() {
    const { garmentSubCategory } = this.props.store.stores.garments;
    const title =
      garmentSubCategory.ID === 0
        ? "New Garment Sub Category"
        : "Garment Sub Details";
    return (
      <IonPage>
        <Header title={title} backURL="/garments" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(GarmentSubCategoryDetails);
