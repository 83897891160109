import { Action } from "../../stores/models";
import {
  IonItem,
  IonLabel,
  IonAvatar,
  IonIcon,
  IonText,
  IonBadge,
} from "@ionic/react";
import React from "react";
import "./ActionListItem.css";
import { buildOutline } from "ionicons/icons";

interface ActionListItemProps {
  action: Action;
}

const ActionListItem: React.FC<ActionListItemProps> = ({ action }) => (
  <IonItem routerLink={"/actions/" + action.ID} routerDirection="forward">
    <IonAvatar slot="start" color="dark">
      <IonIcon icon={buildOutline} size="large" color="medium" />
    </IonAvatar>
    <IonLabel>
      <h2 style={{ textTransform: "capitalize" }}>{action.Name}</h2>
      {!action.Visible ? <IonBadge color="warning">Hidden</IonBadge> : null}
    </IonLabel>
    <IonText>
      <h2 style={{ textAlign: "right" }}>€ {action.UpCharge}</h2>
    </IonText>
  </IonItem>
);

export default ActionListItem;
