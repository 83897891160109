import { BaseService, IResponse } from "./BaseService";
import { Customer } from "../stores/models";
import Message from "../stores/models/Message";
import SimpleCustomer from "../stores/models/SimpleCustomer";

export default class CustomerService extends BaseService {
  public async fetchAll(): Promise<Customer[]> {
    try {
      const resp = await this.http.get("/api/v1/customers/");
      return this.decodeArray<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }
  public async fetchLimit(limited:number, term:string): Promise<Customer[]> {
    try {
      const resp = await this.http.get(`/api/v1/customers/?limit=${limited}&term=${term}`);
      return this.decodeArray<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }

  public async byDate(date: string): Promise<Customer[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/customers/byDeliveryDate/${date}`
      );
      console.log(resp);
      return this.decodeArray<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }

  public async byId(id: number): Promise<Customer> {
    try {
      const resp = await this.http.get(`/api/v1/customers/${id}`);
      return this.decodeObject<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }

  public async fetchSimpleCustomerWithSimpleTickets(id: number): Promise<SimpleCustomer> {
    try {
      const resp = await this.http.get(`/api/v1/customers/${id}?simple=true`);
      return this.decodeObject<SimpleCustomer>(resp, SimpleCustomer);
    } catch (error) {
      throw error;
    }
  }

  public async removeById(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/customers/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async updateById(id: number, customer: Customer): Promise<Boolean> {
    const customerPayload = this.encodeObject(customer, Customer);
    delete customerPayload["address"];
    try {
      const resp = await this.http.put(
        `/api/v1/customers/${id}`,
        customerPayload
      );
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }

  public async updateSimpleCustomerById(id: number, customer: SimpleCustomer): Promise<Boolean> {
    const customerPayload = this.encodeObject(customer, SimpleCustomer);
    delete customerPayload["address"];
    delete customerPayload["simple_tickets"]
    try {
      const resp = await this.http.put(
        `/api/v1/customers/${id}`,
        customerPayload
      );
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }

  public async create(customer: Customer): Promise<Customer> {
    const customerPayload = this.encodeObject(customer, Customer);
    delete customerPayload["address"];
    try {
      const resp = await this.http.post(`/api/v1/customers/`, customerPayload);
      return this.decodeObject<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }

  public async createSimpleCustomer(customer: SimpleCustomer): Promise<SimpleCustomer> {
    const customerPayload = this.encodeObject(customer, SimpleCustomer);
    delete customerPayload["address"];
    try {
      const resp = await this.http.post(`/api/v1/customers/`, customerPayload);
      return this.decodeObject<SimpleCustomer>(resp, SimpleCustomer);
    } catch (error) {
      throw error;
    }
  }

  public async sendMessage(message:Message, allCustomers:boolean): Promise<Boolean> {
    const messagePayload = this.encodeObject(message, Message);
    try {
      const resp = await this.http.post(`/api/v1/customers/sendMessages?all=${allCustomers}`, messagePayload);
      return true
      // return this.decodeObject<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }
  
  public async byField(field: string, value: string): Promise<Customer> {
    try {
      const resp = await this.http.get(`/api/v1/customers?${field}=${value}`);
      return this.decodeObject<Customer>(resp, Customer);
    } catch (error) {
      throw error;
    }
  }
}
