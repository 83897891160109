import React from "react";
import { ITrackingItem } from "../../stores/TrackingStore";
import {
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonAvatar,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from "@ionic/react";
import { shirtOutline, bookmarkOutline, trash } from "ionicons/icons";
import { RootStore } from "../../stores/RootStore";

interface IProps {
  item: ITrackingItem;
  store: RootStore;
}

const TrackingItem: React.FC<IProps> = (props: IProps) => {
  console.log(props.item);
  let icon = shirtOutline;
  let iconColor = "warning";
  if (props.item.Type === "ticket") {
    icon = bookmarkOutline;
    iconColor = "primary";
  } else if (props.item.Type === "multipleItems") {
    icon = shirtOutline;
    iconColor = "danger";
  }
  return (
    <IonItemSliding>
      <IonItemOptions side="start">
        <IonItemOption
          color="danger"
          onClick={() => {
            const { tracking, tickets } = props.store.stores;
            tracking.removeItem(props.item);
            const ticket = tickets.allTickets.find(
              (t) => t.ID === props.item.TicketID
            );
            if (ticket !== undefined) {
              tracking.removeItemFromCache(ticket.getQRString);
            }
          }}
        >
          <IonIcon icon={trash} size="large" />
        </IonItemOption>
      </IonItemOptions>
      <IonItem
        routerDirection="forward"
        routerLink={"/tickets/" + props.item.TicketID}
      >
        <IonAvatar slot="start" color="dark">
          <IonIcon
            // icon={props.item.Type === "ticket" ? bookmarkOutline : shirtOutline}
            icon={icon}
            size="large"
            color={iconColor}
          />
        </IonAvatar>
        <IonLabel style={{ fontSize: 14 }}>
          <IonGrid>
            <IonRow style={{ padding: 0 }}>
              <IonCol sizeXs="2" sizeLg="2">
                <IonBadge color="dark">#{props.item.TicketID}</IonBadge>
              </IonCol>
              {props.item.Type === "ticket" || props.item.Type === 'multipleItems' ? (
                <>
                  <IonCol sizeXs="5" sizeLg="3">
                    {props.item.Agent}
                  </IonCol>
                  <IonCol sizeXs="5" sizeLg="3">
                    {props.item.Date}
                  </IonCol>
                  <IonCol className="hide-xs" sizeLg="3">
                    {props.item.Customer}
                  </IonCol>
                  {props.item.Price ? (
                    <IonCol>&euro;{props.item.Price.toFixed(2)}</IonCol>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <IonCol sizeXs="10" sizeLg="10">
                    {props.item.Desc} {props.item.Piece}
                  </IonCol>
                </>
              )}
            </IonRow>
          </IonGrid>
        </IonLabel>
      </IonItem>
    </IonItemSliding>
  );
};

export default TrackingItem;
