import { observable, action } from "mobx";
import { RootStore } from "./RootStore";

class LoadingStore {
  @observable public isLoading: boolean = true;

  private root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
  }
  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }
}

export default LoadingStore;
