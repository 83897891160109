import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Locality")
class Locality {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";
}

@JsonObject("Street")
class Street {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("locality_id", Number)
  LocalityID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";
}

@JsonObject("Address")
class Address {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("number", String)
  Number: string = "";

  @JsonProperty("locality_id", Number)
  LocalityID: number = 0;

  @JsonProperty("street_id", Number)
  StreetID: number = 0;

  @JsonProperty("locality", Locality, true)
  Locality: Locality = new Locality();

  @JsonProperty("street", Street, true)
  Street: Street = new Street();
}

export { Locality, Street, Address };
