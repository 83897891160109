import React from "react";
import { IonGrid, IonText, IonRow, IonCol } from "@ionic/react";
import { TextField } from "..";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router";

interface ICustomerOtherDetails {
  store?: RootStore;
}

const CustomerOtherDetails: React.FC<ICustomerOtherDetails> = ({ store }) => {
  const { customers } = store!.stores;
  const { simpleCustomer } = customers;
  const history = useHistory();
  return (
    <IonGrid>
      <IonText color="medium">
        <h6 style={{ paddingLeft: 15 }}>Other Details</h6>
      </IonText>
      <IonRow>
        <IonCol sizeLg="2" sizeXs="12">
          <TextField
            type="text"
            label="ID Card"
            onBlur={() => {
              customers.customerExists("id_card", simpleCustomer.IDCard, history);
            }}
            onChange={(v: any) => {
              simpleCustomer.IDCard = v;
            }}
            value={simpleCustomer.IDCard}
          />
        </IonCol>
        <IonCol sizeLg="4" sizeXs="12">
          <TextField
            type="email"
            label="Email"
            onBlur={() => {
              customers.customerExists(
                "email_address",
                simpleCustomer.EmailAddress,
                history
              );
            }}
            onChange={(v: any) => {
              simpleCustomer.EmailAddress = v;
            }}
            value={simpleCustomer.EmailAddress}
          />
        </IonCol>
        <IonCol sizeLg="3" sizeXs="12">
          <TextField
            type="text"
            label="Occupation"
            onChange={(v: any) => {
              simpleCustomer.Occupation = v;
            }}
            value={simpleCustomer.Occupation}
          />
        </IonCol>
        <IonCol sizeLg="3" sizeXs="12">
          <TextField
            type="date"
            label="DOB"
            onChange={(v: any) => {
              simpleCustomer.DateOfBirth = v;
            }}
            value={simpleCustomer.DateOfBirth!}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(CustomerOtherDetails));
