import { BaseService, IResponse } from "./BaseService";
import { GarmentCategory, GarmentSubCategory } from "../stores/models";

class GarmentService extends BaseService {
  public async fetchAllCategories(): Promise<GarmentCategory[]> {
    try {
      const resp = await this.http.get("/api/v1/garmentCategories/");
      return this.decodeArray<GarmentCategory>(resp, GarmentCategory);
    } catch (error) {
      throw error;
    }
  }

  public async fetchGarmentsPriceList(): Promise<any> {
    try {
      const url = `/api/v1/garmentSubCategories/priceList`;
      return await this.http.get(url).then((response) => response.data);
    } catch (error) {
      throw error;
    }
  }

  public async fetchAllSubCategories(): Promise<GarmentSubCategory[]> {
    try {
      const resp = await this.http.get("/api/v1/garmentSubCategories/");
      return this.decodeArray<GarmentSubCategory>(resp, GarmentSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOneCategory(id: number): Promise<GarmentCategory> {
    try {
      const resp = await this.http.get(`/api/v1/garmentCategories/${id}`);
      return this.decodeObject<GarmentCategory>(resp, GarmentCategory);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOneSubCategory(id: number): Promise<GarmentSubCategory> {
    try {
      console.log("FETCH ONE CATEGORY: ", id);
      const resp = await this.http.get(`/api/v1/garmentSubCategories/${id}`);
      return this.decodeObject<GarmentSubCategory>(resp, GarmentSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async createCategory(gc: GarmentCategory): Promise<GarmentCategory> {
    try {
      const payload = this.encodeObject<GarmentCategory>(gc, GarmentCategory);
      // TODO: postForm
      const resp = await this.http.post("/api/v1/garmentCategories/", payload);
      return this.decodeObject<GarmentCategory>(resp, GarmentCategory);
    } catch (error) {
      throw error;
    }
  }

  public async reOrderGarmentCategories(gcs: GarmentCategory[]): Promise<void> {
    try {
      const gcsOrder: number[] = gcs.map((gc) => {
        return gc.ID;
      });
      const payload = JSON.stringify(gcsOrder);
      await this.http.put("/api/v1/garmentCategories/reOrder", payload);
    } catch (error) {
      throw error;
    }
  }

  public async reOrderGarmentSubCategories(
    gcs: GarmentSubCategory[]
  ): Promise<void> {
    try {
      const gscsOrder: number[] = gcs.map((gc) => {
        return gc.ID;
      });
      const payload = JSON.stringify(gscsOrder);
      await this.http.put("/api/v1/garmentSubCategories/reOrder", payload);
    } catch (error) {
      throw error;
    }
  }

  public async createSubCategory(
    gsc: GarmentSubCategory
  ): Promise<GarmentSubCategory> {
    try {
      const payload = this.encodeObject<GarmentSubCategory>(
        gsc,
        GarmentSubCategory
      );
      // TODO: postForm
      const resp = await this.http.post(
        "/api/v1/garmentSubCategories/",
        payload
      );
      return this.decodeObject<GarmentSubCategory>(resp, GarmentSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async updateCategory(
    id: number,
    gc: GarmentCategory
  ): Promise<GarmentCategory> {
    try {
      const payload = this.encodeObject<GarmentCategory>(gc, GarmentCategory);
      // TODO: postForm
      const resp = await this.http.put(
        `/api/v1/garmentCategories/${id}`,
        payload
      );
      return this.decodeObject<GarmentCategory>(resp, GarmentCategory);
    } catch (error) {
      throw error;
    }
  }

  public async updateSubCategory(
    id: number,
    gsc: GarmentSubCategory
  ): Promise<GarmentSubCategory> {
    try {
      const payload = this.encodeObject<GarmentSubCategory>(
        gsc,
        GarmentSubCategory
      );
      // TODO: postForm
      const resp = await this.http.put(
        `/api/v1/garmentSubCategories/${id}`,
        payload
      );
      return this.decodeObject<GarmentSubCategory>(resp, GarmentSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async deleteCategory(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/garmentCategories/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async deleteSubCategory(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/garmentSubCategories/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default GarmentService;
