import { BaseService, IResponse } from "./BaseService";
import { Configuration, ScheduleStatistic } from "../stores/models";
import { ActionWorkLoadStatistics } from "../stores/models/WorkLoadStatistic";

class ConfigurationService extends BaseService {
  public async fetchAll(): Promise<Configuration[]> {
    try {
      const resp = await this.http.get("/api/v1/configurations/");
      return this.decodeArray<Configuration>(resp, Configuration);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOne(id: number): Promise<Configuration> {
    try {
      const resp = await this.http.get(`/api/v1/configurations/${id}`);
      return this.decodeObject<Configuration>(resp, Configuration);
    } catch (error) {
      throw error;
    }
  }

  public async create(a: Configuration): Promise<Configuration> {
    try {
      const payload = this.encodeObject<Configuration>(a, Configuration);
      const resp = await this.http.post("/api/v1/configurations/", payload);
      return this.decodeObject<Configuration>(resp, Configuration);
    } catch (error) {
      throw error;
    }
  }

  public async update(id: number, a: Configuration): Promise<Configuration> {
    try {
      const payload = this.encodeObject<Configuration>(a, Configuration);
      const resp = await this.http.put(`/api/v1/configurations/${id}`, payload);
      return this.decodeObject<Configuration>(resp, Configuration);
    } catch (error) {
      throw error;
    }
  }

  public async delete(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/configurations/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async fetchScheduleStatisticsByWeek(
    year: number,
    weekNumber: number
  ): Promise<ScheduleStatistic[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/statistics/schedule/week/${weekNumber}/year/${year}`
      );
      return this.decodeArray<ScheduleStatistic>(resp, ScheduleStatistic);
    } catch (error) {
      throw error;
    }
  }

  public async fetchActionWorkLoadStatisticsByWeek(
    year: number,
    weekNumber: number
  ): Promise<ActionWorkLoadStatistics[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/statistics/actionWorkLoad/week/${weekNumber}/year/${year}`
      );
      return this.decodeArray<ActionWorkLoadStatistics>(
        resp,
        ActionWorkLoadStatistics
      );
    } catch (error) {
      throw error;
    }
  }
}

export default ConfigurationService;
