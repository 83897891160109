import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonSelectOption,
} from "@ionic/react";
import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";

import { RootStore } from "../stores/RootStore";

import { Header, TextField } from "../components";
import { action, observable, runInAction } from "mobx";
import { checkbox } from "ionicons/icons";
import CustomSelect from "../components/CustomSelect/CustomSelect";

interface ActionDetailsProps {
  store: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class ActionDetails extends React.Component<
  ActionDetailsProps & RouteComponentProps<RouteProps>
> {
  @observable isPercentage: boolean = false;
  @action
  setIsPercentage(b: boolean) {
    this.isPercentage = b;
  }
  componentDidMount() {
    this.init();
  }

  init() {
    const id = parseInt(this.props.match.params.id);
    this.getAction(id);
  }

  async getAction(id: number) {
    const { actions } = this.props.store.stores;
    actions.getActions();
    const { action } = actions;
    actions.action.IsPercentageOf
      ? this.setIsPercentage(true)
      : this.setIsPercentage(false);
    if (action.ID === id) return;
    if (id === 0) {
      this.reset();
      return;
    }
    await actions.getActionByIdAsync(id);
    actions.action.IsPercentageOf
      ? this.setIsPercentage(true)
      : this.setIsPercentage(false);
  }

  reset() {
    const { actions } = this.props.store.stores;
    actions.resetAction();
  }

  componentWillUnmount() {
    this.reset();
  }

  createAction = async () => {
    const { actions, ui } = this.props.store.stores;
    try {
      const c = await actions.createAction();
      ui.showToast({ message: "Information Saved", color: "success" });
      actions.getActionById(c.ID);
      this.props.history.replace(`/actions/${c.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  updateAction = async () => {
    const { actions, ui } = this.props.store.stores;
    const { action } = actions;
    try {
      await actions.updateAction();
      actions.getActionById(action.ID);
      ui.showToast({ message: "Information Saved", color: "success" });
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { actions } = this.props.store.stores;
    const { action } = actions;
    if (this.isPercentage === false) {
      console.log(this);
      runInAction(() => {
        actions.action.IsPercentageOf = null;
      });
    }
    if (action.ID === 0) {
      this.createAction();
    } else {
      this.updateAction();
    }
  };

  @action
  handleDelete = async (confirmed: boolean) => {
    const { actions, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    const { action } = actions;
    if (action.ID === 0) return;
    try {
      const s = await actions.deleteAction(action.ID);
      if (s) {
        ui.showToast({ message: "Action Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/actions");
    } catch (error) {
      console.log(error);
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  body() {
    const { action } = this.props.store.stores.actions;
    const { actions } = this.props.store.stores;
    return (
      <>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="2" sizeXs="12">
                <TextField
                  type="text"
                  label="Name"
                  onChange={(v: any) => {
                    action.Name = v;
                  }}
                  value={action.Name}
                />
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <TextField
                  type="number"
                  label="Charge"
                  onChange={(v: any) => {
                    action.UpCharge = parseFloat(v);
                  }}
                  value={action.UpCharge}
                />
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Is Percentage</IonLabel>
                  <IonCheckbox
                    checked={this.isPercentage}
                    onIonChange={(e) => {
                      this.setIsPercentage(e.detail.checked);
                    }}
                  />
                </IonItem>
              </IonCol>
              {this.isPercentage ? (
                <IonCol>
                  <CustomSelect
                    interface="popover"
                    onSelect={(e: string) => {
                      runInAction(() => {
                        actions.action.IsPercentageOf = parseInt(e);
                      });
                    }}
                    title="Action"
                    elements={actions.actions.map((a) => {
                      return (
                        <IonSelectOption value={a.ID} key={a.ID}>
                          {a.Name}
                        </IonSelectOption>
                      );
                    })}
                    value={action.IsPercentageOf!}
                  ></CustomSelect>
                </IonCol>
              ) : null}
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Images Required</IonLabel>
                  <IonCheckbox
                    checked={action.ImagesRequired}
                    onIonChange={(e) => {
                      action.ImagesRequired = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Visible</IonLabel>
                  <IonCheckbox
                    checked={action.Visible}
                    onIonChange={(e) => {
                      action.Visible = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="1" sizeXs="12">
                <TextField
                  type="number"
                  label="Discount"
                  onChange={(v: any) => {
                    parseFloat(v)<=0?v=0:v=v
                    parseFloat(v)>=100?v=100:v=v;
                    action.Discount ="PERCENTAGE "+ parseFloat(v);
                  }}
                  value={action.Discount.replace("PERCENTAGE ","")}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {action.ID !== 0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }

  render() {
    const { action } = this.props.store.stores.actions;
    const title = action.ID === 0 ? "New Action" : "Action Details";
    return (
      <IonPage>
        <Header title={title} backURL="/actions" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(ActionDetails);
