import { BaseService, IResponse } from "./BaseService";
import ActionGarmentSubCategory from "../stores/models/ActionGarmentSubCategory";

class ActionGarmentSubCategoryService extends BaseService {
  public async fetchAll(): Promise<ActionGarmentSubCategory[]> {
    try {
      const resp = await this.http.get("/api/v1/actionGarmentSubCategories/");
      return this.decodeArray<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async fetchOne(id: number): Promise<ActionGarmentSubCategory> {
    try {
      const resp = await this.http.get(
        `/api/v1/actionGarmentSubCategories/${id}`
      );
      return this.decodeObject<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async create(
    a: ActionGarmentSubCategory
  ): Promise<ActionGarmentSubCategory> {
    try {
      const payload = this.encodeObject<ActionGarmentSubCategory>(
        a,
        ActionGarmentSubCategory
      );
      const resp = await this.http.post(
        "/api/v1/actionGarmentSubCategories/",
        payload
      );
      return this.decodeObject<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async update(
    id: number,
    a: ActionGarmentSubCategory
  ): Promise<ActionGarmentSubCategory> {
    try {
      const payload = this.encodeObject<ActionGarmentSubCategory>(
        a,
        ActionGarmentSubCategory
      );
      const resp = await this.http.put(
        `/api/v1/actionGarmentSubCategories/${id}`,
        payload
      );
      return this.decodeObject<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async updateImage() {
    // TODO: implement
  }

  public async delete(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(
        `/api/v1/actionGarmentSubCategories/${id}`
      );
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default ActionGarmentSubCategoryService;
