import { IonContent, IonPage, IonReorderGroup } from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";

import React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Header } from "../components";
import GarmentCategoryListItem from "../components/GarmentCategoryListItem/GarmentCategoryListItem";
import { GarmentCategory } from "../stores/models";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Garments extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    const { auth, garments } = this.props.store.stores;
    if (auth.role !== "admin") {
      return;
    }
    this.getGarmentList();
    const gcs = garments.sortGarmentCategories();
    garments.setGarmentCategories(gcs);
  }

  getGarmentList = () => {
    const { garments } = this.props.store.stores;
    garments.getGarmentGategories();
  };

  search = (q: string) => {
    const { garments } = this.props.store.stores;
    garments.filterGarmentCategories(q);
  };

  add = () => {
    this.props.history.push("/garments/0");
  };

  toggleReOrder = () => {
    const { garments } = this.props.store.stores;
    garments.toggleReOrderDisabled();
    if (garments.reOrderDisabled) {
      garments.reOrderGarmentCategoriesAsyc();
    }
  };

  Reorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { garments } = this.props.store.stores;
    const gcs = garments.reOrderGarmentCategories(
      event.detail.from,
      event.detail.to
    );
    garments.setGarmentCategories(gcs);
    event.detail.complete();
  };

  getReorderableGarmentCategories = (): JSX.Element => {
    const { garments } = this.props.store.stores;
    let gcs = garments.garmentCategories;
    return (
      <IonReorderGroup
        disabled={garments.reOrderDisabled}
        onIonItemReorder={this.Reorder}
      >
        {gcs
          // slice(0, 50).
          .map((gc: GarmentCategory) => {
            return (
              <GarmentCategoryListItem
                garmentCategory={gc}
                reOrder={"start"}
                key={gc.Name}
              ></GarmentCategoryListItem>
            );
          })}
      </IonReorderGroup>
    );
  };

  render() {
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      //TODO display message of un-authorization
      return <Redirect to="/home" exact />;
    }
    return (
      <IonPage>
        <Header
          backURL="/home"
          title="Garments"
          onSearch={this.search}
          onAdd={this.add}
          toggleReOrder={this.toggleReOrder}
        />
        <IonContent>{this.getReorderableGarmentCategories()}</IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Garments);
