import { BaseService } from "./BaseService";
import { Ticket } from "../stores/models";

class ReportingService extends BaseService {
  public async fetchSalesReport(from: string, to: string, actions:number[]): Promise<any> {
    try {
      const url =  `/api/v1/tickets/csv/salesReport?from=${from}&to=${to}&actions=${actions.join(",")}`
     return  await this.http.get(url)
     .then(response => response.data)
    } catch (error) {
      throw error;
    }
  }

  public async fetchClientReport(from: string, to: string): Promise<any> {
    try {
      const url = `/api/v1/tickets/csv/clientReport?from=${from}&to=${to}`
      return  await this.http.get(url)
      .then(response => response.data)
    } catch (error) {
      throw error;
    }
  }

  public async fetchItemReport(from: string, to: string, agents:number[], states:string[], actions:number[], commission:number): Promise<any> {
    try {
      const url = `/api/v1/tickets/csv/itemReport?from=${from}&to=${to}&agents=${agents.join(',')}&states=${states.join(",")}&actions=${actions.join(",")}&commission=${commission}`
      return  await this.http.get(url)
      .then(response => response.data)
    } catch (error) {
      throw error;
    }
  }

  public async fetchItemStateReport(from: string, to: string, states:string[], actions:number[]): Promise<any> {
    try {
      const url = `/api/v1/tickets/csv/itemStateReport?from=${from}&to=${to}&states=${states.join(",")}&actions=${actions.join(",")}`
      return  await this.http.get(url)
      .then(response => response.data)
    } catch (error) {
      throw error;
    }
  }
  
}

export default ReportingService;
