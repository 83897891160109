import { JsonObject, JsonProperty } from "json2typescript";
import Agent from "./Agent";

@JsonObject("EmployeeOwing")
class EmployeeOwing {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("ticket_id", Number)
  TicketID: number = 0;

  @JsonProperty("employee_id", Number)
  EmployeeID: number = 0;

  @JsonProperty("amount", Number)
  Amount: number = 0.0;

  @JsonProperty("ticket_sub_category_id", Number)
  TicketSubCategoryID: number = 0;

  @JsonProperty("percentage", Number)
  Percentage: number = 0;

  @JsonProperty("payment_date", String, true)
  PaymentDate: string | null = null;

  @JsonProperty("state", String, true)
  State: string | null = null;

  @JsonProperty("paid_in_full", Boolean, true)
  PaidInFull: boolean | null = null;

  @JsonProperty("employee_payment_date", String, true)
  EmployeePaymentDate: string | null = null;
}

export default EmployeeOwing;
