import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import "./TicketHistoryViewer.css";
import { IonCol, IonIcon, IonRow } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import { eyeSharp } from "ionicons/icons";
import { TicketLogStateHistory } from "../../stores/models";
import moment from "moment";

interface ITicketHistoryItem {
  item: TicketLogStateHistory;
}

const TicketHistoryItem: React.FC<ITicketHistoryItem> = (
  props: ITicketHistoryItem
) => {
  return (
    <>
      <IonRow>
        <IonCol size="1">
          {moment(props.item.LoggedAt).format("DD-MM-YYYY")}
        </IonCol>
        <IonCol size="4">{props.item.Log}</IonCol>
        <IonCol size="1">{props.item.Agent?.Name}</IonCol>
        <IonCol size="2">{props.item.Employee?.FullName}</IonCol>
        <IonCol size="4">
          <img src={props.item.Signature} />
        </IonCol>
      </IonRow>
      <hr></hr>
    </>
  );
};

interface ITicketHistoryViewer {
  store: RootStore;
  mobile?: boolean;
  ticketID: number;
}

const TicketHistoryViewer: React.FC<ITicketHistoryViewer> = (
  props: ITicketHistoryViewer
) => {
  const { ui, tickets } = props.store.stores;

  // tickets.ticketLogStates

  // useEffect(() => {
  //   tickets.getLogStates(props.ticketID)
  // }, [props.ticketID]);
  // useEffect(() => {
  //   console.log(tickets.ticketLogStates)
  // }, [tickets.ticketLogStates]);

  const TicketHistoryHeaders = () => {
    return (
      <IonCol size="12">
        <IonRow>
          <IonCol size="1">Logged At</IonCol>
          <IonCol size="4">Log</IonCol>
          <IonCol size="1">Agent</IonCol>
          <IonCol size="2">Employee</IonCol>
          <IonCol size="4">Signature</IonCol>
        </IonRow>
      </IonCol>
    );
  };
  const openModal = () => {
    ui.showDialog({
      classes: "fullWidth",
      title: "Ticket History",
      content: (
        <IonCol size="12">
          {TicketHistoryHeaders()}
          {tickets.ticketLogStates.map((item) => {
            console.log(item);
            return (
              <>
                <TicketHistoryItem item={item} />
              </>
            );
          })}
        </IonCol>
      ),
    });
  };
  return (
    <>
      <IonIcon
        style={{ textAlign: "right", fontSize: 14, cursor: "pointer" }}
        onClick={async (e) => {
          await tickets.getLogStates(props.ticketID);
          console.log(tickets.ticketLogStates)
          openModal();
        }}
        icon={eyeSharp}
        color="info"
        size="small"
      />
    </>
  );
};

export default inject("store")(observer(TicketHistoryViewer));
