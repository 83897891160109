import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import {
  IonPage,
  IonContent,
  withIonLifeCycle,
  IonItem,
  IonButton,
  IonCol,
  IonRow,
} from "@ionic/react";
import { Header, SelectField, TextField } from "../components";
import { DatePicker } from "../components/DatePicker/DatePicker";
import moment from "moment";
import { action, observable, runInAction } from "mobx";
import { IOption } from "../components/SelectField/SelectField";

interface IReporting {
  store?: RootStore;
}

@inject("store")
@observer
class Reporting extends React.Component<IReporting & RouteComponentProps> {
  componentDidMount() {
    this.init();
  }

  async init() {
    const { agents, actions } = this.props.store!.stores;
    agents.getAgents();
    actions.getActions();
  }

  @observable salesFrom: string = moment(new Date()).format("YYYY-MM-DD");
  @observable salesTo: string = moment(new Date()).format("YYYY-MM-DD");
  @observable clientFrom: string = moment(new Date()).format("YYYY-MM-DD");
  @observable clientTo: string = moment(new Date()).format("YYYY-MM-DD");
  @observable salesNameSuffix: string = "";
  @observable clientNameSuffix: string = "";
  @observable agentIDS: number[] = [];
  @observable actionIDS: number[] = [];
  @observable states: string[] = [];
  @observable commission:number = 0;

  ionViewWillEnter() {
    this.UpdateSalesNameSuffix();
    this.UpdateClientNameSuffix();
  }

  // Reference:
  // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
  private showFile(blob: any, title: string) {
    const newBlob: Blob = new Blob([blob], { type: "application/csv" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = title + ".csv";
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  @action
  private UpdateSalesNameSuffix() {
    const from = moment(this.salesFrom).format("DD-MM-YYYY");
    const to = moment(this.salesTo).format("DD-MM-YYYY");
    this.salesNameSuffix = from + " - " + to;
  }

  @action
  private UpdateClientNameSuffix() {
    const from = moment(this.clientFrom).format("DD-MM-YYYY");
    const to = moment(this.clientTo).format("DD-MM-YYYY");
    this.clientNameSuffix = from + " - " + to;
  }

  private salesReport() {
    return (
      <IonRow>
        <IonCol size="2">
          <DatePicker
            labelClass="labelDisplayContents"
            label="From: "
            date={this.salesFrom}
            select={(e: string) => {
              runInAction(() => {
                this.salesFrom = e;
                this.UpdateSalesNameSuffix();
              });
            }}
          />
        </IonCol>
        <IonCol size="2">
          <DatePicker
            labelClass={"labelDisplayContents"}
            label="To: "
            date={this.salesTo}
            select={(e: string) => {
              runInAction(() => {
                this.salesTo = e;
                this.UpdateSalesNameSuffix();
              });
            }}
          />
        </IonCol>
        {this.actionMultiSelect()}
        <IonCol size="2">
          <IonButton
            onClick={() => {
              const { reporting } = this.props.store!.stores;
              reporting
                .fetchSalesReport(this.salesFrom, this.salesTo, this.actionIDS)
                .then((data) => {
                  this.showFile(data, "SalesReport" + this.salesNameSuffix);
                })
                .catch((err) => {});
            }}
          >
            Download Sales Report
          </IonButton>
        </IonCol>
      </IonRow>
    );
  }

  private clientReport() {
    return (
      <IonRow>
        <IonCol size="2">
          <DatePicker
            labelClass="labelDisplayContents"
            label="From: "
            date={this.clientFrom}
            select={(e: string) => {
              runInAction(() => {
                this.clientFrom = e;
                this.UpdateClientNameSuffix();
              });
            }}
          />
        </IonCol>
        <IonCol size="2">
          <DatePicker
            labelClass={"labelDisplayContents"}
            label="To: "
            date={this.clientTo}
            select={(e: string) => {
              runInAction(() => {
                this.clientTo = e;
                this.UpdateClientNameSuffix();
              });
            }}
          />
        </IonCol>
        <IonCol size="2">
          <IonButton
            onClick={() => {
              const { reporting } = this.props.store!.stores;
              reporting
                .fetchClientReport(this.clientFrom, this.clientTo)
                .then((data) => {
                  this.showFile(data, "ClientReport" + this.salesNameSuffix);
                });
            }}
          >
            Download Client Report
          </IonButton>
        </IonCol>
      </IonRow>
    );
  }

  private agentMultiSelect() {
    const { agents } = this.props.store!.stores;
    const handleAgentSelect = (agentID: number) => {
      console.log("adding agent with id ", agentID);
      const newAgentIDS = updateAgentIDS(agentID);
      runInAction(() => {
        this.agentIDS = newAgentIDS;
      });
    };

    const updateAgentIDS = (agentID: number): number[] => {
      // //if agentID == -1 then all has been selected
      // if (agentID === -1){
      // const t = this.agentIDS.find((a)=>a===-1)
      // if (t === undefined){
      // //if selected items does not contain -1 then clear array of selected items and add all items to the array

      // }else{

      // //else just clear 
      // }
      // }
      const index = this.agentIDS.findIndex((lid) => lid === agentID);
      if (index >= 0) {
        runInAction(() => {
          this.agentIDS.splice(index, 1);
        });
        return this.agentIDS;
      } else {
        return this.agentIDS.concat(agentID);
      }
    };

    const options: IOption[] =  []
    // options.push({ value:-1, label: 'All' })
    agents.agents.forEach((a) => {
      const option = { value: a.ID, label: a.Name };
      options.push(option)
    })

    return (
      <IonCol size="1">
        <SelectField
          label="Agents"
          multi
          multiSearch
          onChange={handleAgentSelect}
          options={options}
          selected={this.agentIDS.map((id) => {
            const agent = agents.agents.find((a) => {
              return a.ID === id;
            });
            return { label: agent?.Name!, value: id };
          })}
        />
      </IonCol>
    );
  }

  private actionMultiSelect() {
    const { actions } = this.props.store!.stores;
    const handleActionSelect = (actionID: number) => {
      console.log("adding action with id ", actionID);
      const newActionIDS = updateActionIDS(actionID);
      runInAction(() => {
        this.actionIDS = newActionIDS;
      });
    };

    const updateActionIDS = (actionID: number): number[] => {
      const index = this.actionIDS.findIndex((lid) => lid === actionID);
      if (index >= 0) {
        runInAction(() => {
          this.actionIDS.splice(index, 1);
        });
        return this.actionIDS;
      } else {
        return this.actionIDS.concat(actionID);
      }
    };

    const options: IOption[] = actions.actions.map((a) => {
      return { value: a.ID, label: a.Name };
    });

    return (
      <IonCol size="1">
        <SelectField
          label="Actions"
          multi
          multiSearch
          onChange={handleActionSelect}
          options={options}
          selected={this.actionIDS.map((id) => {
            const action = actions.actions.find((a) => {
              return a.ID === id;
            });
            return { label: action?.Name!, value: id };
          })}
        />
      </IonCol>
    );
  }

  private statesMultiSelect() {
    const { tracking } = this.props.store!.stores;
    const handleStateSelect = (state: string) => {
      console.log(state);
      const newStates = updateStates(state);
      runInAction(() => {
        this.states = newStates;
      });
    };

    const updateStates = (state: string): string[] => {
      const index = this.states.findIndex((s) => s === state);
      if (index >= 0) {
        runInAction(() => {
          this.states.splice(index, 1);
        });
        return this.states;
      } else {
        return this.states.concat(state);
      }
    };

    const options: IOption[] = tracking.states.map((a) => {
      return { value: a, label: a };
    });

    return (
      <IonCol size="1">
        <SelectField
          label="States"
          multi
          multiSearch
          onChange={handleStateSelect}
          options={options}
          selected={this.states.map((s) => {
            const state = tracking.states.find((state) => {
              return state === s;
            });
            return { label: state!, value: state! };
          })}
        />
      </IonCol>
    );
  }

  private itemReport() {
    return (
      <IonRow>
        <IonCol size="2">
          <DatePicker
            labelClass="labelDisplayContents"
            label="From: "
            date={this.clientFrom}
            select={(e: string) => {
              runInAction(() => {
                this.clientFrom = e;
                this.UpdateClientNameSuffix();
              });
            }}
          />
        </IonCol>
        <IonCol size="2">
          <DatePicker
            labelClass={"labelDisplayContents"}
            label="To: "
            date={this.clientTo}
            select={(e: string) => {
              runInAction(() => {
                this.clientTo = e;
                this.UpdateClientNameSuffix();
              });
            }}
          />
        </IonCol>
        {this.agentMultiSelect()}
        {this.statesMultiSelect()}
        {this.actionMultiSelect()}
        <IonCol size='2'>
          <TextField
            label='Commission %'
            value={this.commission}
            type='number'
            onChange={(v: string) => {
              runInAction(()=>{
                this.commission = parseInt(v);
              })
            }}
          />
        </IonCol>
        <IonCol size="2">
          <IonButton
            onClick={() => {
              const { reporting } = this.props.store!.stores;
              reporting
                .fetchItemReport(this.clientFrom, this.clientTo, this.agentIDS, this.states, this.actionIDS, this.commission)
                .then((data) => {
                  this.showFile(data, "ClientReport" + this.salesNameSuffix);
                });
            }}
          >
            Download Item Report
          </IonButton>
        </IonCol>
      </IonRow>
    );
  }

  private itemStateReport() {
    return (
      <IonRow>
        <IonCol size="2">
          <DatePicker
            labelClass="labelDisplayContents"
            label="From: "
            date={this.clientFrom}
            select={(e: string) => {
              runInAction(() => {
                this.clientFrom = e;
                this.UpdateClientNameSuffix();
              });
            }}
          />
        </IonCol>
        <IonCol size="2">
          <DatePicker
            labelClass={"labelDisplayContents"}
            label="To: "
            date={this.clientTo}
            select={(e: string) => {
              runInAction(() => {
                this.clientTo = e;
                this.UpdateClientNameSuffix();
              });
            }}
          />
        </IonCol>
        {this.actionMultiSelect()}
        {this.statesMultiSelect()}
        <IonCol size="2">
          <IonButton
            onClick={() => {
              const { reporting } = this.props.store!.stores;
              reporting
                .fetchItemStateReport(this.clientFrom, this.clientTo, this.states, this.actionIDS)
                .then((data) => {
                  this.showFile(data, "itemStateReport" + this.salesNameSuffix);
                });
            }}
          >
            Download Item State Report
          </IonButton>
        </IonCol>
      </IonRow>
    );
  }

  render() {
    return (
      <IonPage>
        <Header title="Reporting" backURL="/" />
        <IonContent>
          {this.salesReport()}
          {this.clientReport()}
          {this.itemReport()}
          {this.itemStateReport()}
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Reporting));
