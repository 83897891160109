import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import "./PickupDeliveryComponent.css";
import { PickupDelivery } from "../../stores/models";
import {
  IonItem,
  IonLabel,
  IonRow,
  IonContent,
  IonGrid,
  IonCol,
  IonList,
} from "@ionic/react";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import { runInAction } from "mobx";
import TextField from "../TextField/TextField";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";

interface IWeekItemsProps {
  title: string;
  data: PickupDelivery[];
  localities: string[];
}

const WeekItems: React.FC<IWeekItemsProps> = (props: IWeekItemsProps) => {
  const daysOfTheWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const setFromDate = (date: string) => {
    date = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
    runInAction(() => {
      props.data.forEach((p) => (p.From = date));
    });
  };

  const setToDate = (date: string) => {
    date = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
    runInAction(() => {
      props.data.forEach((p) => (p.To = date));
    });
  };
  return (
    <IonItem>
      <IonGrid fixed={true}>
        <IonRow>
          <IonCol>
            <h2 style={{ textTransform: "capitalize" }}>{props.title}</h2>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="fixed">From: </IonLabel>
              {props.data[0] ? (
                <DatePicker
                  date={moment(props.data[0].From).format("YYYY-MM-DD")}
                  select={setFromDate}
                ></DatePicker>
              ) : null}
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="fixed">To: </IonLabel>
              {props.data[0] ? (
                <DatePicker
                  date={moment(props.data[0].To).format("YYYY-MM-DD")}
                  select={setToDate}
                ></DatePicker>
              ) : null}
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          {props.data.map((p: PickupDelivery | undefined) => {
            return <IonCol size="2">{daysOfTheWeek[p!.DayOfWeek]}</IonCol>;
          })}
        </IonRow>

        <IonRow>
          {props.data.map((p: PickupDelivery) => {
            return (
              <IonCol size="2">
                <TextField
                  onChange={(e: string) => {
                    const newValue = parseInt(e);
                    p.Quantity = newValue;
                  }}
                  type={"number"}
                  value={p.Quantity}
                ></TextField>
              </IonCol>
            );
          })}
        </IonRow>

        <IonRow>
          {props.data.map((p: PickupDelivery) => {
            return (
              <IonCol size="2">
                <IonRow>
                  <MultipleSelect
                    placeholder={"Select Localities"}
                    onSelect={(e: string[]) => {
                      runInAction(() => {
                        if (p != undefined) {
                          p.Localities = e.join(";");
                        }
                      });
                    }}
                    label={""}
                    options={props.localities}
                    // selected={[]}
                    selected={p.Localities.split(";").map((e) => e.trim())}
                  />
                </IonRow>
                <IonRow>
                  <IonList>
                    {p.Localities.split(";").map((l) => (
                      <IonItem>{l}</IonItem>
                    ))}
                  </IonList>
                </IonRow>
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

interface IPickupDeliveryComponent {
  // onSelect: Function;
  data: PickupDelivery[];
  localities: string[];
}

const PickupDeliveryComponent: React.FC<IPickupDeliveryComponent> = (
  props: IPickupDeliveryComponent
) => {
  const Peak = props.data.filter((p) => p.Season === "PEAK");
  const OffSeason = props.data.filter((p) => p.Season === "OFFSEASON");
  return (
    <>
      <WeekItems data={Peak} title="Peak" localities={props.localities} />
      <WeekItems
        data={OffSeason}
        title="Off Season"
        localities={props.localities}
      />
    </>
  );
};

export default inject("store")(observer(PickupDeliveryComponent));
