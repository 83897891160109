import { IonPage, withIonLifeCycle } from "@ionic/react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Customer } from "../stores/models";
import { CustomerListItem, Header } from "../components";
import { action, observable, runInAction } from "mobx";
import { List } from "../components/List/List";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Customers extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  @observable searchTerm = "";

  ionViewWillEnter() {
    runInAction(() => {});
    // this.search("");
  }

  ionViewWillLeave() {
    const { customers } = this.props.store.stores;
    runInAction(() => {
      customers.customers = customers._customers;
    });
    this.search("");
  }

  ionViewDidEnter() {
    const { customers } = this.props.store.stores;
    runInAction(() => {
      customers.customers = customers._customers;
    });
    // this.search("");
  }

  ionViewDidLeave() {
    runInAction(() => {});
  }

  componentDidMount() {
    this.getCustomerList();
  }
  @observable loadedItems: number = 50;

  getCustomerList = async () => {
    const { customers, ui } = this.props.store.stores;
    ui.setLoadingValue(true);
    // let ok = customers.getCustomersLimited(20, this.searchTerm);
    // let ok = customers.getCustomersLimited(20, "");
    // if ((await ok) === true) {
      ui.setLoadingValue(false);
    // }
  };

  search = async (q: string) => {
    const { customers } = this.props.store.stores;
    
    runInAction(() => {
      this.searchTerm = q;
      customers.search = q
    });
    let ok = customers.getCustomersLimited(20, q);
    // if ((await ok) === true) {
    // customers.filterCustomers(q);
    // }
  };

  add = () => {
    const { customers } = this.props.store.stores;
    runInAction(() => {
      customers.mobile = this.searchTerm;
    });
    this.props.history.push("/customers/0");
  };

  list = () => {
    const { customers } = this.props.store!.stores;
    // customers.getCustomersLimited(20, this.searchTerm);
    let items = customers.customers.map((c: Customer) => {
      return <CustomerListItem key={c.ID} customer={c} />;
    });
    if (this.searchTerm.trim()===""){
      return null
    }
    return (<List listElements={items} interval={50}></List>);
  };

  render() {
    return (
      <IonPage>
        <Header
          backURL="/home"
          title="Customers"
          onSearch={this.search}
          searchOpen
          // searchTerm={this.searchTerm}
          onAdd={this.add}
        />
        {/* only if you are an admin can you see the list of customers else show only if search  */}
        {/* {auth.role === 'admin' || this.searchTerm!=""?<List listElements={items} interval={50}></List>:null} */}
        {this.list()}
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Customers));
