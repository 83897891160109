import React from "react";
import {
  IonInput,
  IonLabel,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import "./TextField.css";
import { checkmarkCircleSharp } from "ionicons/icons";

type TextFieldTypes =
  | "date"
  | "email"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "text"
  | "url"
  | "time";

interface ITextField {
  label?: string;
  placeholder?: string;
  type: TextFieldTypes;
  value?: string | number;
  onBlur?: Function;
  onChange?: Function;
  done?: Function;
  disabled?: boolean;
  prefix?: JSX.Element;
  onKeyUp?: Function;
  minimum?: string;
  labelClassNames?: string;
  inputStyles?:any;
  itemStyles?:any;
}

const TextField: React.FC<ITextField> = (props: ITextField) => {
  return (
    <IonItem style={props.itemStyles}>
      {props.label !== undefined ? (
        <IonLabel
          color="medium"
          position="stacked"
          className={props.labelClassNames}
        >
          {props.label}
        </IonLabel>
      ) : null}
      {props.prefix !== undefined ? props.prefix : null}
      <IonInput
      style={props.inputStyles}
        onKeyUp={(e: any) => {
          if (props.onKeyUp !== undefined) {
            props.onKeyUp(e);
          }
        }}
        min={props.minimum ? props.minimum : undefined}
        disabled={props.disabled}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onIonBlur={(e) => {
          if (props.onBlur) {
            props.onBlur(e);
          }
        }}
        onIonChange={(e) => {
          if (props.onChange) {
            props.onChange(e.detail.value!);
          }
        }}
      />
      {props.done !== undefined ? (
        <IonButtons slot={"end"}>
          <IonButton
            onClick={() => {
              if (props.done !== undefined) {
                props.done();
              }
            }}
          >
            <IonIcon icon={checkmarkCircleSharp} size="large" />
          </IonButton>
        </IonButtons>
      ) : null}
    </IonItem>
  );
};

export default TextField;
