import { RootStore } from "../../stores/RootStore";
import React from "react";
import { inject, observer } from "mobx-react";
import { IonGrid, IonText, IonRow, IonCol } from "@ionic/react";
import { SelectField } from "..";

import "./EmployeeFeatures.css";
import { Locality } from "../../stores/models";
import { useEffect } from "react";

interface IEmployeeFeatures {
  store?: RootStore;

}

interface IOption {
  label: string;
  value: string | number;
}

const EmployeeFeatures: React.FC<IEmployeeFeatures> = (
  props: IEmployeeFeatures
) => {
  const { addresses, employees } = props.store!.stores;
  addresses.getLocalities();

  const [localityIDS, setLocalityIDS] = React.useState<number[]>(employees.employee.Localities.split(",").map(x=>+x));
  const [options, setOptions] = React.useState<IOption[]>([]);

  const setLocalities = (localities: Locality[]) => {
    const newOptions = localities.map((l: Locality) => {
      return { label: l.Name, value: l.ID };
    });
    setOptions(newOptions);
  };

  const addIDToLocalityIDS = (localityID: number) => {
    employees.updateEmployeeLocalities(localityID)
  };

  const handleLocalitySelect = (localityID: number) => {
    console.log('chose locality')
    addIDToLocalityIDS(localityID);
    console.log(localityIDS)
    // employees.updateEmployeeLocalities(localityIDS)
  };

  useEffect(() => {
    setLocalities(addresses.localities)
  }, [addresses.localities]);

  useEffect(() => {
    const localityIDS = employees.employee.Localities.split(",").map(x=>+x)
    setLocalityIDS(localityIDS)
  }, [employees.employee]);

  console.log(employees.employee)
  console.log(localityIDS)
  return (
    <IonGrid>
      <IonText color="medium">
        <h5 style={{ padding: 15 }}>Features</h5>
      </IonText>
      <IonRow>
        <IonCol size="4">
          <SelectField
            onSearch={async (q: string) => {
              await addresses.getLocalities();
            }}
            label="Localities"
            multi
            multiSearch
            onChange={handleLocalitySelect}
            options={options}
            selected={localityIDS.map((id) => {
              const locality = addresses.localities.find((l) => {
                return l.ID === id;
              });
              return { label: locality?.Name!, value: id };
            })}
            // value={address.StreetID}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(EmployeeFeatures));
