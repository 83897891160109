import { BaseService, IResponse } from "./BaseService";
import { Street, Locality, Address } from "../stores/models/Address";

class AddressService extends BaseService {
  public async getLocalities(): Promise<Locality[]> {
    try {
      const resp = await this.http.get(`/api/v1/localities/`);
      return this.decodeArray<Locality>(resp, Locality);
    } catch (error) {
      throw error;
    }
  }

  public async getStreets(localityID: number): Promise<Street[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/streets/locality/${localityID}`
      );
      return this.decodeArray<Street>(resp, Street);
    } catch (error) {
      throw error;
    }
  }

  public async fetchAddresses(): Promise<Address[]> {
    try {
      const resp = await this.http.get(`/api/v1/address/`);
      return this.decodeArray<Address>(resp, Address);
    } catch (error) {
      throw error;
    }
  }

  public async getAddressById(id: number): Promise<Address> {
    try {
      const resp = await this.http.get(`/api/v1/address/${id}`);
      return this.decodeObject<Address>(resp, Address);
    } catch (error) {
      throw error;
    }
  }

  public async updateAddressById(
    id: number,
    address: Address
  ): Promise<Boolean> {
    const addressPayload = this.encodeObject(address, Address);
    delete addressPayload["Locality"];
    delete addressPayload["street"];
    try {
      const resp = await this.http.put(`/api/v1/address/${id}`, addressPayload);
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }

  public async createAddress(address: Address): Promise<Address> {
    const addressPayload = this.encodeObject<Address>(address, Address);
    delete addressPayload["Locality"];
    delete addressPayload["street"];
    try {
      const resp = await this.http.post(`/api/v1/address/`, addressPayload);
      return this.decodeObject<Address>(resp, Address);
    } catch (error) {
      throw error;
    }
  }
}

export default AddressService;
