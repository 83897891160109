import { GarmentService } from "../services";
import { RootStore } from "./RootStore";
import { GarmentCategory, GarmentSubCategory } from "./models";
import { observable, action, runInAction, computed } from "mobx";
import Fuse from "fuse.js";

class GarmentStore {
  private root: RootStore;
  private api: GarmentService;

  @observable
  public garmentCategory: GarmentCategory = new GarmentCategory();
  @observable
  public garmentSubCategory: GarmentSubCategory = new GarmentSubCategory();
  @observable
  public garmentCategories: GarmentCategory[] = [];
  @observable
  public _garmentCategories: GarmentCategory[] = [];
  @observable
  public showMoreGarments: boolean = false;
  @observable
  public garmentSubCategories: GarmentSubCategory[] = [];
  @observable
  public _garmentSubCategories: GarmentSubCategory[] = [];
  @observable
  public filteredGarmentSubCategoriesByCategory: GarmentSubCategory[] = [];
  @observable
  public reOrderDisabled: boolean = true;
  @observable
  public subCategoryReOrderDisabled: boolean = true;

  private garmentSubCategoryCache: any = {};

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.garments;
  }

  @action
  public resetGarmentCategory() {
    this.garmentCategory = new GarmentCategory();
  }

  @action
  public resetGarmentCategories() {
    this.garmentCategories = [];
    this._garmentCategories = [];
    this.garmentSubCategories = [];
    this._garmentSubCategories = [];
    this.filteredGarmentSubCategoriesByCategory = [];
  }

  public async getGarmentGategories() {
    const { ui } = this.root.stores;
    if (
      this.garmentCategories.length > 0 &&
      this.garmentSubCategories.length > 0
    ) {
      return;
    }
    try {
      let garmentCategories = await this.api.fetchAllCategories();
      this._garmentCategories = garmentCategories;
      const garmentSubCategories = await this.api.fetchAllSubCategories();
      const gcs = this.sortGarmentCategoriesList(garmentCategories);
      const gscs = this.sortGarmentSubCategoriesList(garmentSubCategories);
      this.setGarments(gcs, gscs);
      runInAction(() => {
        this._garmentSubCategories = gscs;
      });
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public fetchGarmentsPriceList() {
    return this.api.fetchGarmentsPriceList();
  }

  public async getGarmentSubCategoriesAsync() {
    if (this.garmentSubCategories.length > 0) {
      return;
    }
    const garmentSubCategories = await this.api.fetchAllSubCategories();
    this.setGarmentSubCategories(garmentSubCategories);
  }

  public getFilteredGarmentSubCategories(catID: number): GarmentSubCategory[] {
    return this.garmentSubCategories.filter((gsc) => {
      return (gsc.GarmentCategoryID = catID);
    });
  }

  public async getGarmentSubCategoryByID(
    id: number
  ): Promise<GarmentSubCategory> {
    if (this.garmentSubCategoryCache[id]) {
      return this.garmentSubCategoryCache[id];
    }
    const garmentSubCategory = await this.api.fetchOneSubCategory(id);
    this.garmentSubCategoryCache[id] = garmentSubCategory;
    this.setGarmentSubCategory(garmentSubCategory);
    return garmentSubCategory;
  }

  public async getGarmentCategoryByIdAsync(id: number) {
    const { ui } = this.root.stores;
    this.setGarmentCategory(new GarmentCategory());
    ui.setLoading(true);
    try {
      const GARMENTCATEGORY = await this.api.fetchOneCategory(id);
      this.setGarmentCategory(GARMENTCATEGORY);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public getGarmentCategoryById(id: number): GarmentCategory | undefined {
    return this.garmentCategories.find((g) => g.ID === id);
  }

  // TODO: refactor to service ?
  @action("filter action")
  public filterGarmentCategories(q: string) {
    if (q.trim() === "") {
      this.garmentCategories = this._garmentCategories;
      return;
    }
    console.log(this._garmentCategories);
    const filterOptions: any = {
      threshold: 0,
      distance: 10,
      keys: ["Name", "SubCategories.Name"],
    };
    const fuse = new Fuse(this._garmentCategories, filterOptions);
    this.garmentCategories = fuse.search(q).map((a) => a.item);
  }

  @action("filter action")
  public filterGarmentSubCategories(q: string) {
    if (q.trim() === "") {
      this.garmentSubCategories = this._garmentSubCategories;
      return;
    }
    console.log(this._garmentSubCategories);
    const filterOptions: any = {
      threshold: 0,
      distance: 10,
      keys: ["Name", "SubCategories.Name"],
    };
    const fuse = new Fuse(this._garmentSubCategories, filterOptions);
    this.garmentSubCategories = fuse.search(q).map((a) => a.item);
  }

  @action
  public setGarmentCategory(garmentCategory: GarmentCategory) {
    this.garmentCategory = garmentCategory;
  }

  @action
  public toggleReOrderDisabled() {
    this.reOrderDisabled = !this.reOrderDisabled;
  }

  @action
  public toggleSubCategoryReOrderDisabled() {
    this.subCategoryReOrderDisabled = !this.subCategoryReOrderDisabled;
  }

  // https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
  @action
  public reOrderGarmentCategories(
    old_index: number,
    new_index: number
  ): GarmentCategory[] {
    this.garmentCategories.splice(
      new_index,
      0,
      this.garmentCategories.splice(old_index, 1)[0]
    );
    return this.garmentCategories;
  }

  @action
  public reOrderGarmentSubCategories(
    old_index: number,
    new_index: number,
    catID: number
  ): GarmentSubCategory[] {
    const filtered = this.garmentSubCategories.filter((gsc) => {
      return gsc.GarmentCategoryID === catID;
    });
    filtered.splice(new_index, 0, filtered.splice(old_index, 1)[0]);
    return filtered;
  }

  @action
  public Swap(from: number, to: number) {
    var tmp = this.garmentCategories[from];
    this.garmentCategories[from] = this.garmentCategories[to];
    this.garmentCategories[to] = tmp;
  }

  @action
  public setGarmentSubCategory(garmentSubCategory: GarmentSubCategory) {
    this.resetGarmentSubCategory();
    this.garmentSubCategory = garmentSubCategory;
  }

  @action
  public setFilteredGarmentSubCategories(
    filteredGarmentSubCategoriesByCategory: GarmentSubCategory[]
  ) {
    this.filteredGarmentSubCategoriesByCategory = filteredGarmentSubCategoriesByCategory;
  }

  @action
  public pushGarmentSubCategory(garmentSubCategory: GarmentSubCategory) {
    this.garmentSubCategories.forEach((gsc: GarmentSubCategory, index) => {
      if (gsc.ID === garmentSubCategory.ID) {
        this.garmentSubCategories[index] = garmentSubCategory;
      }
    });
    this.setGarmentSubCategories(this.garmentSubCategories);
  }

  @action
  public setGarmentSubCategories(garmentSubCategories: GarmentSubCategory[]) {
    this.garmentSubCategories = garmentSubCategories;
  }

  @action
  public setGarmentCategories(garmentCategories: GarmentCategory[]) {
    this.garmentCategories = garmentCategories;
  }

  @action
  public resetGarmentSubCategory() {
    this.garmentSubCategory = new GarmentSubCategory();
  }

  @action
  public setImageGarmentCategory(image: string) {
    // TODO make this function work a bit more simpler. not quite happy with the way its working
    this.garmentCategory.Image = image;
    const gc = this.garmentCategory;
    this.resetGarmentCategory();
    this.setGarmentCategory(gc);
  }

  @action
  public setImageGarmentSubCategory(image: string) {
    // TODO make this function work a bit more simpler. not quite happy with the way its working
    this.garmentSubCategory.Image = image;
    const gsc = this.garmentSubCategory;
    this.resetGarmentSubCategory();
    this.setGarmentSubCategory(gsc);
  }

  public async createGarment(): Promise<GarmentCategory> {
    const { ui } = this.root.stores;
    try {
      const garment = await this.api.createCategory(this.garmentCategory);
      runInAction(() => {
        this.garmentCategories.push(garment);
      });
      return garment;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async reOrderGarmentCategoriesAsyc(): Promise<void> {
    const { ui } = this.root.stores;
    try {
      await this.api.reOrderGarmentCategories(this.garmentCategories);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async reOrderGarmentSubCategoriesAsyc(): Promise<void> {
    const { ui } = this.root.stores;
    try {
      await this.api.reOrderGarmentSubCategories(this.garmentSubCategories);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async createGarmentSubCategory(): Promise<GarmentSubCategory> {
    const { ui } = this.root.stores;
    try {
      const garmentSubCategory = await this.api.createSubCategory(
        this.garmentSubCategory
      );
      runInAction(() => {
        this.garmentSubCategories.push(garmentSubCategory);
      });
      return garmentSubCategory;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public getGarmentSubCategories(catid: number): GarmentSubCategory[] {
    const filterdSubCategories = this.garmentSubCategories.filter(
      (s) => s.GarmentCategoryID === catid
    );
    return filterdSubCategories;
  }

  public getGarmentSubCategory(id: number): GarmentSubCategory | undefined {
    return this.garmentSubCategories.find((gs) => gs.ID === id);
  }

  public async saveGarmentCategory(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      const c = await this.api.updateCategory(
        this.garmentCategory.ID,
        this.garmentCategory
      );
      runInAction(() => {
        this.replaceGarmentCategory(c);
      });
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  @action
  private replaceGarmentCategory(garmentCategory: GarmentCategory) {
    this.garmentCategory = garmentCategory;
    if (this.garmentCategories.length > 0) {
      var index = this.garmentCategories.findIndex(
        (item) => item.ID === garmentCategory.ID
      );
      this.garmentCategories.splice(index, 1, garmentCategory);
      this._garmentCategories.splice(index, 1, garmentCategory);
    }
  }

  @action
  private replaceGarmentSubCategory(garmentSubCategory: GarmentSubCategory) {
    this.garmentSubCategory = garmentSubCategory;
    if (this.garmentSubCategories.length > 0) {
      var index = this.garmentSubCategories.findIndex(
        (item) => item.ID === garmentSubCategory.ID
      );
      this.garmentSubCategories.splice(index, 1, garmentSubCategory);
      // this._garmentSubCategories.splice(index, 1, garmentSubCategory);
    }
  }

  public async saveGarmentSubCategory(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      const gsc = await this.api.updateSubCategory(
        this.garmentSubCategory.ID,
        this.garmentSubCategory
      );
      runInAction(() => {
        this.replaceGarmentSubCategory(gsc);
      });
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async deleteGarmentCategory(id: number) {
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.deleteCategory(id);
      if (resp.success) {
        runInAction(() => {
          const catToFilter = this.garmentCategories;
          this.garmentCategories = catToFilter.filter((c) => c.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async deleteGarmentSubCategory(id: number): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.deleteSubCategory(id);
      if (resp.success) {
        runInAction(() => {
          const subCatToFilter = this.garmentSubCategories;
          this.garmentSubCategories = subCatToFilter.filter((c) => c.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  @computed
  public get types(): string[] {
    let types: string[] = [];
    this.garmentCategories.forEach((g) => {
      if (g.Type.trim() !== "" && types.indexOf(g.Type) === -1) {
        types.push(g.Type);
      }
    });
    return types;
  }

  @action
  public setGarments(
    categories: GarmentCategory[],
    subCategories: GarmentSubCategory[]
  ) {
    this.garmentCategories = categories;
    this.garmentSubCategories = subCategories;
  }

  @action
  public setShow() {
    this.showMoreGarments = !this.showMoreGarments;
  }

  public sortGarmentCategories(): GarmentCategory[] {
    return this.garmentCategories.slice().sort(function (a, b) {
      return a.Order - b.Order;
    });
  }

  public sortGarmentCategoriesList(
    garmentCategories: GarmentCategory[]
  ): GarmentCategory[] {
    return garmentCategories.slice().sort(function (a, b) {
      return a.Order - b.Order;
    });
  }

  public sortGarmentSubCategoriesList(
    garmentSubCategories: GarmentSubCategory[]
  ): GarmentSubCategory[] {
    return garmentSubCategories.slice().sort(function (a, b) {
      return a.Order - b.Order;
    });
  }
}
export default GarmentStore;
