import { BluetoothSerial } from "@ionic-native/bluetooth-serial";

const DEVICES = {
  ble: [
    {
      uuid: "000018f0-0000-1000-8000-00805f9b34fb",
      service: "000018f0-0000-1000-8000-00805f9b34fb",
      characteristic: "00002af1-0000-1000-8000-00805f9b34fb",
      maxChunk: 100,
      encoding: "ESC/POS",
    },
  ],
  usb: [
    /* MUNBYN */
    {
      productId: 20497,
      vendorId: 1046,
      configuration: 1,
      interface: 0,
      endpoint: 3,
      encoding: "ESC/POS",
    },
    /* Argox Labels */
    {
      productId: 3344,
      vendorId: 5732,
      configuration: 1,
      interface: 0,
      endpoint: 1,
      encoding: "PPLZ",
    },
    /* ARGOX OS-2130D */
    {
      productId: 378,
      vendorId: 5732,
      interface: 0,
      configuration: 1,
      endpoint: 1,
      encoding: "PPLA",
    },
    /* Epson TM-T10III */
    {
      productId: 3624,
      vendorId: 1208,
      configuration: 1,
      interface: 0,
      endpoint: 1,
      encoding: "ESC/POS",
    },
  ],
};

class PrintService {
  public btSerial: any;
  public usbSerial: any;

  private _rbtStash = "";
  private _rbtTimeout = null;

  constructor() {
    this.btSerial = BluetoothSerial;
  }

  public searchBluetoothPrinter() {
    return this.btSerial.list();
  }

  public deviceInfo() {
    return DEVICES;
  }

  public searchUSBPrinter(): Promise<USBDevice> {
    const filters = DEVICES.usb.map((u) => {
      return { productId: u.productId, vendorId: u.vendorId };
    });
    return navigator.usb.requestDevice({
      filters: filters,
    });
  }

  public searchBLEPrinter(): Promise<BluetoothDevice> {
    return navigator.bluetooth.requestDevice({
      filters: [{ services: DEVICES.ble.map((b) => b.uuid) }],
      optionalServices: ["device_information"],
    });
  }

  public searchForRBT() {
    return {
      id: "0xRBT",
      name: "Default RBT Printer",
    };
  }

  public sendToSerialPrinter(document: string) {
    this.usbSerial.requestPermission(
      { vid: 1664, pid: 3344 },
      () => {
        this.usbSerial.open({ baudRate: 9600 }, () => {
          this.btSerial.write(document);
        });
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  public async sendToRBT(data: string, enc?: boolean) {
    let url = "";
    if (enc === false) {
      url = "rawbt:base64," + data;
    } else {
      url = "rawbt:base64," + btoa(data);
    }
    // let S = "#Intent;scheme=rawbt;";
    // let P = "package=ru.a402d.rawbtprinter;end;";
    // let textEncoded = url;
    // window.location.href = "intent:" + textEncoded + S + P;
    window.open(url);
  }

  public connectToBluetoothPrinter(macAddress: any) {
    return this.btSerial.connect(macAddress);
  }
  disconnectBluetoothPrinter() {
    return this.btSerial.disconnect();
  }

  public sendToBluetoothPrinter(macAddress: any, data: any) {
    this.connectToBluetoothPrinter(macAddress).subscribe(
      (_: any) => {
        this.btSerial.write(data).then(
          (_: any) => {
            this.disconnectBluetoothPrinter();
          },
          (err: any) => {
            throw err;
          }
        );
      },
      (err: any) => {
        throw err;
      }
    );
  }

  public async getBLEDeviceInfo(device: BluetoothDevice) {
    const server = await device.gatt?.connect();
    if (!server) return;
    const info = await server.getPrimaryService("device_information");
    const uuid = info.uuid;
    return DEVICES.ble.find((b) => uuid === uuid);
  }

  public getUSBDeviceInfo(device: USBDevice) {
    return DEVICES.usb.find(
      (d) => d.vendorId === device.vendorId && d.productId === device.productId
    );
  }

  //   public DelayPromise(delay: number) {
  //     return new Promise(resolve => {
  //         setTimeout(resolve, delay);
  //     });
  // }

  public async sendToBLEPrinter(device: BluetoothDevice, data: Uint8Array) {
    function delay(delay: number) {
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    }
    async function writeStrToCharacteristic(
      characteristic: BluetoothRemoteGATTCharacteristic,
      c: any
    ): Promise<any> {
      console.log("accessing the device");
      characteristic.writeValue(c).catch(() => {
        // console.log("DOMException: GATT operation already in progress.");
        return Promise.resolve()
          .then(() => delay(500))
          .then(() => {
            writeStrToCharacteristic(characteristic, c);
          })
          .catch((error) => {
            console.log(error);
          });
      });
      //  return result
    }

    const server = await device.gatt?.connect();
    if (!server) return;
    const info = await server.getPrimaryService("device_information");
    const uuid = info.uuid;
    const serviceuid = DEVICES.ble.find((b) => uuid === uuid)?.service;
    const charuid = DEVICES.ble.find((b) => uuid === uuid)?.characteristic;
    const maxChunk = DEVICES.ble.find((b) => uuid === uuid)?.maxChunk || 30;
    const service = await server.getPrimaryService(serviceuid!);
    const characteristic = await service.getCharacteristic(charuid!);
    var j = 0;
    if (data.length > maxChunk) {
      for (var i = 0; i < data.length; i += maxChunk) {
        var chunk;
        if (i + maxChunk <= data.length) {
          chunk = data.slice(i, i + maxChunk);
        } else {
          chunk = data.slice(i, data.length);
        }
        setTimeout(
          await writeStrToCharacteristic,
          50 * j,
          characteristic,
          chunk
        );
        // this.printPart(chunk, writeStrToCharacteristic, characteristic)
        j++;
      }
    } else {
      writeStrToCharacteristic(characteristic, data);
      // this.printPart(chunk, writeStrToCharacteristic, characteristic)
    }
    // this.printPart(data.slice(0,500), writeStrToCharacteristic, characteristic)
  }

  public async printPart(
    data: any,
    writeStrToCharacteristic: any,
    characteristic: any
  ) {
    const a = await writeStrToCharacteristic(characteristic, data);
    console.log(a);
  }

  public async sendToUsbPrinter(
    device: USBDevice,
    data: string | Uint8Array
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const dInfo = DEVICES.usb.find(
        (u) =>
          u.productId === device.productId && u.vendorId === device.vendorId
      );

      // console.log("USB", data);

      function delay(delay: number) {
        return new Promise((resolve) => {
          setTimeout(resolve, delay);
        });
      }
      device
        .open()
        .then(() => device.selectConfiguration((dInfo as any).configuration))
        .then(() => device.claimInterface((dInfo as any).interface))
        .then(() => {
          var d: any;
          if (typeof data === "string") {
            var encoder = new TextEncoder();
            d = encoder.encode(data);
          } else {
            d = data;
          }
          device
            .transferOut(dInfo!.endpoint, d)
            .then((r) => {
              console.log(r);
              if (r.status === "ok") {
                device.close().then(() => {
                  resolve(0);
                });
              } else if (r.status === "stall") {
                alert("Printer returned error during print");
                console.log(r.status);
              } else {
                alert("Printer returned error(1) during print, cancelling");
                reject(0);
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}

export default PrintService;
