import React, { useState } from "react";

import { observer, inject } from "mobx-react";
import {
  IonContent,
  IonList,
  isPlatform,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButton,
  IonButtons,
  IonToolbar,
  IonIcon,
} from "@ionic/react";
import {
  chevronBackCircleSharp,
  chevronForwardCircleSharp,
} from "ionicons/icons";

interface IListProps {
  listElements: JSX.Element[];
  interval: number;
}

export const List: React.FC<IListProps> = (props: IListProps) => {
  let [loadedItems, setLoadedItems] = useState(props.interval);
  let [currentPage, setCurrentPage] = useState(1);

  const scrollMoreItems = ($event: CustomEvent<void>) => {
    setLoadedItems(loadedItems + props.interval);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  const handleNext = () => {
    setCurrentPage((currentPage += 1));
  };

  const handlePrevious = () => {
    if (currentPage - 1 <= 0) {
      return;
    }
    setCurrentPage((currentPage -= 1));
  };

  const renderPageButtons = (): JSX.Element => {
    const lengthOflist: number = props.listElements.length;
    const pages = Math.ceil(lengthOflist / props.interval);
    let buttons: JSX.Element[] = [];
    var i;
    for (i = 0; i < pages; i++) {
      const button = renderPageButton(i + 1, i === currentPage - 1);
      buttons.push(button);
    }
    let start = currentPage - 3;
    if (start < 0) {
      start = 0;
    }
    let end = start + 5;
    if (end > pages) {
      end = pages;
      start = end - 5;
    }
    return (
      <IonButtons slot="end">
        {start !== 0 ? (
          <IonButton
            onClick={() => {
              handlePrevious();
            }}
          >
            <IonIcon icon={chevronBackCircleSharp} />
          </IonButton>
        ) : null}
        {start > 0 ? renderPageButton(1, false) : null}
        {start > 0 ? `...` : null}
        {buttons.slice(start, end)}
        {end < pages ? `...` : null}
        {end < pages ? renderPageButton(pages, false) : null}
        {end !== pages ? (
          <IonButton
            onClick={() => {
              handleNext();
            }}
          >
            <IonIcon icon={chevronForwardCircleSharp} />
          </IonButton>
        ) : null}
      </IonButtons>
    );
  };

  const renderPageButton = (page: number, highlight: boolean): JSX.Element => {
    if (highlight === true) {
      return (
        <IonButton
          key={page}
          style={{ color: "#ffc409" }}
          onClick={() => {
            setCurrentPage(page);
          }}
        >
          {page}
        </IonButton>
      );
    } else {
      return (
        <IonButton
          key={page}
          onClick={() => {
            setCurrentPage(page);
          }}
        >
          {page}
        </IonButton>
      );
    }
  };

  return (
    <IonContent>
      {isPlatform("desktop") ? (
        <>
          <IonList>
            {props.listElements.slice(
              (currentPage - 1) * props.interval,
              (currentPage - 1) * props.interval + props.interval - 1
            )}
          </IonList>
          <IonToolbar>{renderPageButtons()}</IonToolbar>
        </>
      ) : (
        <>
          <IonList>{props.listElements.slice(0, loadedItems)}</IonList>
          {/* https://github.com/peterpeterparker/infiniteScroll/blob/master/src/pages/Tab1.tsx */}
          <IonInfiniteScroll
            threshold="1000px"
            onIonInfinite={($event: any) => {
              scrollMoreItems($event);
            }}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more customers..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      )}
    </IonContent>
  );
};

export default inject("store")(observer(List));
