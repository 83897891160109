import { JsonObject, JsonProperty } from "json2typescript";
import TicketSubCategoryAction from "./TicketSubCategoryAction";
import TicketSubCategoryModifier from "./TicketSubCategoryModifier";
import TicketSubCategoryLogState from "./TicketSubCategoryLogState";
import GarmentSubCategory from "./GarmentSubCategory";
import moment from "moment";

@JsonObject("TicketSubCategory")
class TicketSubCategory {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("garment_sub_category_id", Number)
  GarmentSubCategoryID: number = 0;

  @JsonProperty("description", String)
  Description: string = "";

  @JsonProperty("ticket_id", Number)
  TicketID: number = 0;

  @JsonProperty("ticket_sub_category_actions", [TicketSubCategoryAction], true)
  Actions: TicketSubCategoryAction[] = [];

  @JsonProperty(
    "ticket_sub_category_modifiers",
    [TicketSubCategoryModifier],
    true
  )
  Modifiers: TicketSubCategoryModifier[] = [];

  @JsonProperty(
    "ticket_sub_category_log_states",
    [TicketSubCategoryLogState],
    true
  )
  TicketSubCategoryLogStates: TicketSubCategoryLogState[] = [];

  @JsonProperty("discount", String)
  Discount: string = "";

  @JsonProperty("quantity", Number)
  Quantity: number = 0;

  @JsonProperty("state", String)
  State: string = "";

  @JsonProperty("comments", String)
  Comments: string = "";

  @JsonProperty("location", String)
  Location: string = "";

  @JsonProperty("pieces", String)
  Pieces: string = "";

  @JsonProperty("sorted", Boolean)
  Sorted: boolean = false;

  @JsonProperty("has_express", Boolean, true)
  HasExpress: boolean | null = false;

  @JsonProperty("sorted_items", String)
  SortedItems: string = "";

  @JsonProperty("detail", String, true)
  Detail: string | null = "";

  @JsonProperty("total", Number)
  Total: number = 0;

  @JsonProperty("pre_discount_total", Number)
  PreDiscountTotal: number = 0;

  @JsonProperty("total_override", Number, true)
  TotalOverride: number | null = null;

  @JsonProperty("total_paid", Number, true)
  TotalPaid: number | null = null;

  @JsonProperty("process_date", String, true)
  ProcessDate: string | null = null;

  getTotal(): number {
    if (this.TotalOverride !== null) {
      return this.TotalOverride;
    } else {
      return this.Total;
    }
  }

  public get getPiecesArray(): string[] | undefined {
    if (this.Pieces === "") {
      return;
    }
    return this.Pieces.split(",");
  }

  getPiecesQuantity(): number {
    return this.getPiecesArray?.length!;
  }

  public updateSelectedPieces(piece: string): void {
    let pieces = this.getPiecesArray!;
    if (pieces.includes(piece)) {
      pieces.splice(pieces.indexOf(piece), 1);
    } else {
      pieces.push(piece);
    }
    this.Pieces = pieces.join();
  }

  public get getQRString(): string {
    return `!I!T:${this.TicketID},I:${this.ID},D:${this.Description.substring(
      0,
      12
    )}`;
  }

  public getActionNames(): string {
    let actions = "";
    this.Actions.map((a) => {
      a.Name.split(" ").map((w) => (actions += w.charAt(0).toUpperCase()));
      actions += " ";
    });
    return actions;
  }

  public hasExpressItem(): boolean {
    //iterate over the modifiers
    //if modifier == Express then return yes
    let hasExpressItem = false
    this.Modifiers.forEach((m)=>{
      if (m.ModifierType==="EXPRESS"){
        hasExpressItem = true
      }
    })
    return hasExpressItem;
  }

  public backgroundColor():string{
    let color = ""
    //if item has comment then return green
    //if item has express item then return red
    if (this.Comments.trim() != ""){
      color =  "green"
    }
    if (this.hasExpressItem()){
      color = "red"
    }
    return color
  }
}

export default TicketSubCategory;
