import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonAvatar,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { ExpressItem, TicketSubCategory } from "../stores/models";
import { Header } from "../components";
import { business } from "ionicons/icons";
import moment from "moment";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Express extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    this.getExpressList();
  }

  getExpressList = () => {
    const { tickets } = this.props.store.stores;
    tickets.getTicketSubCategoriesExpress();
  };

  renderItem = (item: ExpressItem) => (
    <IonItem
      key={item.ID}
      routerLink={"/tickets/" + item.TicketID}
      routerDirection="forward"
    >
      <IonAvatar slot="start" color="dark">
        <IonIcon icon={business} size="large" color="medium" />
      </IonAvatar>
      <IonLabel>
        <h2 style={{ textTransform: "capitalize" }}>{item.TicketID} - {item.ID} - {item.Description}</h2>
        <IonGrid style={{ margin: 0, padding: 0 }}>
          <IonRow>
            <IonCol size="6">
              <IonRow>
                <IonCol size="auto">
                  <IonText>
                    <h2>
                      Pickup:{" "}
                      {moment(item.PickupDate).format("YYYY-MM-DD")}
                    </h2>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );

  render() {
    const { expressItems } = this.props.store.stores.tickets;
    return (
      <IonPage>
        <Header backURL="/home" title="Express" />
        <IonContent>
          <IonList>
            {expressItems
              // .slice(0, 50)
              .map((item: ExpressItem) => {
                return this.renderItem(item);
              })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Express);
