import { RootStore } from "./RootStore";
import { Address, Locality, Street } from "./models/Address";
import { observable, action, computed, runInAction } from "mobx";

class AddressStore {
  private root: RootStore;

  @observable public streets: Street[] = [];
  @observable public localities: Locality[] = [];
  @observable public addresses: Address[] = [];
  @observable public address: Address = new Address();

  constructor(root: RootStore) {
    this.root = root;
  }

  @action
  public async getLocalities() {
    const { addresses } = this.root.services;
    const { ui } = this.root.stores;
    if (this.localities.length > 0) {
      return;
    }
    ui.setLoading(true);
    try {
      const localities = await addresses.getLocalities();
      this.setLocalities(localities);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    } finally {
      ui.setLoading(false);
    }
  }

  @action
  public async getStreets(localityID: number) {
    const { addresses } = this.root.services;
    const { ui } = this.root.stores;
    try {
      const streets = await addresses.getStreets(localityID);
      this.setStreets(streets);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public isValid(): boolean {
    return this.address.LocalityID !== 0 && this.address.StreetID !== 0;
  }

  @action
  public async createAddress(): Promise<Address> {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    const { addresses } = this.root.services;
    try {
      return await addresses.createAddress(this.address);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  @action
  public async updateAddress(): Promise<boolean> {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    const { addresses } = this.root.services;
    try {
      await addresses.updateAddressById(this.address.ID, this.address);

      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async getAddresses() {
    if (this.addresses.length > 0) return;
    const { ui } = this.root.stores;
    const { addresses } = this.root.services;
    try {
      const newAddresses = await addresses.fetchAddresses();
      runInAction(() => {
        this.addresses = newAddresses;
      });
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  }

  @action
  public async getAddressById(id: number): Promise<Address> {
    if (id === 0) return new Address();
    const { addresses } = this.root.services;
    const { ui } = this.root.stores;
    ui.setLoading(true);
    this.setAddress(new Address());
    this.setStreets([]);
    try {
      const address = await addresses.getAddressById(id);
      const streets = await addresses.getStreets(address.LocalityID);
      this.setStreets(streets);
      this.setAddress(address);
      return address;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      return new Address();
    } finally {
      ui.setLoading(false);
      return new Address();
    }
  }

  @action
  public resetAddress() {
    this.address = new Address();
  }

  @action
  public resetAddresses() {
    this.addresses = [];
    this.localities = [];
    this.streets = [];
  }

  @action
  public setStreets(streets: Street[]) {
    this.streets = streets;
  }

  @action
  public setLocalities(localities: Locality[]) {
    this.localities = localities;
  }

  @action
  public setAddress(address: Address) {
    this.address = address;
  }

  public getLocalityById(id: number): Locality | undefined {
    return this.localities.find((l) => l.ID === id);
  }

  public getStreetById(id: number): Street | undefined {
    return this.streets.find((s) => s.ID === id);
  }

  @computed
  public get addressDisplay(): string {
    return (
      this.address.Name +
      " " +
      this.address.Number +
      " " +
      (this.getStreetById(this.address.StreetID)?.Name || "") +
      " " +
      (this.getLocalityById(this.address.LocalityID)?.Name || "")
    ).trim();
  }
}

export default AddressStore;
