import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import { Component } from "react";

interface IToast {
  open?: boolean;
  message: string;
  color?: string;
  position?: "top" | "bottom" | "middle" | undefined;
}

interface IAlert {
  open?: boolean;
  message?: string;
  title?: string;
  handler?: () => void;
}

interface IDialog {
  open?: boolean;
  freeze?: boolean;
  classes?: string;
  title: string;
  content: React.ReactFragment;
  OKText?: string;
  CancelText?: string;
  handler?: () => void;
}

class UIStore {
  private lTimer: any = null;
  private root: RootStore;

  private toastDefaults: IToast = {
    open: false,
    message: "",
    color: "success",
    position: "bottom",
  };

  private alertDefaults: IAlert = {
    open: false,
    title: "Confirm!",
    message: "Are you sure?",
  };

  private dialogDefaults: IDialog = {
    open: false,
    freeze: false,
    title: "Dialog",
    content: "",
    OKText: "OK",
    CancelText: "Cancel",
  };

  @observable public isLoading: boolean = false;

  @observable public toast: IToast = {
    ...this.toastDefaults,
  };

  @observable public alert: IAlert = {
    ...this.alertDefaults,
  };

  @observable public dialog: IDialog = {
    ...this.dialogDefaults,
  };

  @observable public screenWidth: number = window.innerWidth;

  constructor(root: RootStore) {
    this.root = root;
  }

  @action
  setScreenSize(width: number) {
    this.screenWidth = width;
  }

  handleScreenResize = () => {
    this.setScreenSize(window.innerWidth);
  };

  public setResizeListener() {
    window.addEventListener("resize", this.handleScreenResize);
  }

  @action
  public closeToast() {
    this.toast.open = false;
  }

  @action
  public showToast(opts: IToast) {
    this.toast = Object.assign({}, this.toastDefaults, { ...opts });
    this.toast.open = true;
  }

  @action
  public closeAlert() {
    this.alert.open = false;
  }

  @action
  public showAlert(opts: IAlert) {
    this.alert = Object.assign({}, this.alertDefaults, { ...opts });
    this.alert.open = true;
  }

  @action
  public showDialog(opts: IDialog) {
    this.dialog = Object.assign({}, this.dialogDefaults, { ...opts });
    this.dialog.open = true;
  }

  @action
  closeDialog = () => {
    if (this.dialog.freeze === false) {
      this.dialog.open = false;
    }
  };

  @action
  freezeDialog = () => {
    this.dialog.freeze = true;
    console.log("dialog frozen");
  };

  @action
  unFreezeDialog = () => {
    this.dialog.freeze = false;
    console.log("dialog un frozen");
  };

  @action
  public setLoading(loading: boolean) {
    clearTimeout(this.lTimer);
    if (!loading) {
      setTimeout(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      }, 500);
      return;
    } else {
      this.lTimer = setTimeout(() => {
        runInAction(() => {
          this.isLoading = true;
        });
      }, 250);
    }
  }

  public setLoadingValue(value:boolean){
    runInAction(()=>{
      this.isLoading = value
    })
  }
}

export default UIStore;
