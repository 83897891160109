import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { Header, TextField } from "../components";
import { Action } from "../stores/models";

interface IConfigurationDetails {
  store: RootStore;
}

interface IRoute {
  id: string;
}

@inject("store")
@observer
class ConfigurationDetails extends React.Component<
  IConfigurationDetails & RouteComponentProps<IRoute>
> {
  componentDidMount() {
    const { configuration, actions } = this.props.store.stores;
    const id = parseInt(this.props.match.params.id);

    actions.getActions();
    if (id === 0) {
      this.reset();
      return;
    }
    configuration.getConfigurationsByID(id);
  }

  componentWillUnmount() {
    this.reset();
  }

  reset() {
    const { configuration } = this.props.store.stores;
    configuration.resetConfig();
    configuration.setConfigAmounts("0,0,0,0,0,0,0");
  }

  handleSubmit = async () => {
    const { configuration, ui } = this.props.store.stores;
    const { config } = configuration;
    if (config.ID === 0) {
      const newConfig = configuration.createConfiguration(config);
      this.props.history.replace("/configuration/" + (await newConfig).ID);
      ui.showToast({ message: "Configuration Created!", color: "success" });
      return;
    } else {
      configuration.saveConfiguration(config);
      ui.showToast({ message: "Configuration Updated!", color: "success" });
      return;
    }
  };

  handleDelete = async (confirmed: boolean) => {
    const { configuration, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }

    const { config } = configuration;
    if (config.ID === 0) return;
    try {
      const s = await configuration.deleteConfiguration(config.ID);
      if (s) {
        ui.showToast({ message: "Configuration Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/configuration");
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  configDetailsv2 = () => {
    const { config } = this.props.store.stores.configuration;
    const { actions, configuration } = this.props.store.stores;
    let amountsByDay = configuration.getAmounts();
    const action: Action | undefined = actions.actions.find(
      (action) => action.ID === config.ActionID
    );
    return (
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeLg={"2"} sizeXs={"12"}>
              <IonItem>
                <IonLabel position={"floating"}>Month</IonLabel>
                <IonSelect
                  key={config.Month}
                  value={config.Month}
                  onIonChange={(e) => {
                    configuration.setConfigMonth(e.detail.value);
                  }}
                >
                  {configuration.months.map((month) => {
                    return (
                      <IonSelectOption value={month} key={month}>
                        {month}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </IonCol>

            <IonCol sizeLg={"2"} sizeXs={"12"}>
              <IonItem>
                <IonLabel position={"floating"}>Action</IonLabel>
                <IonSelect
                  key={action?.ID}
                  value={action?.ID}
                  onIonChange={(e) => {
                    configuration.setConfigAction(e.detail.value);
                  }}
                >
                  {actions.actions.map((action) => {
                    return (
                      <IonSelectOption value={action.ID} key={action.ID}>
                        {action.Name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </IonCol>
            {amountsByDay.map((amount, index) => {
              return (
                <IonCol sizeLg="1" sizeXs="12" key={index}>
                  <TextField
                    minimum="0"
                    type="number"
                    label={configuration.daysOfTheWeek.find(
                      (dow: string, dowIndex) => {
                        return dowIndex === index;
                      }
                    )}
                    onChange={(v: any) => {
                      if (v <= -1) {
                        amount = "0";
                        return;
                      }
                      configuration.updateAmountByIndex(v, index);
                    }}
                    value={amount}
                  />
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  };

  body = () => {
    const { config } = this.props.store.stores.configuration;
    return (
      <>
        <IonContent>{this.configDetailsv2()}</IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {config.ID !== 0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  };

  render() {
    const { config } = this.props.store.stores.configuration;
    const title =
      config.ID === 0 ? "New Configuration" : "Configuration Details";
    return (
      <IonPage>
        <Header title={title} backURL="/configuration" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(ConfigurationDetails);
