import { JsonObject, JsonProperty } from "json2typescript";
import { Address } from "./Address";

@JsonObject("CustomerStatistics")
class CustomerStatistics {
  @JsonProperty("number_of_visits", Number)
  NumberOfVisits: number = 0;

  @JsonProperty("total_spent", Number)
  TotalSpent: number = 0;

  @JsonProperty("last_visit", String, true)
  LastVisit: string | null = null;

  @JsonProperty("ticket_total", Number)
  TicketTotal: number = 0;

  @JsonProperty("outstanding_balance", Number)
  OutstandingBalance: number = 0.0;

  @JsonProperty("turnover_rank", Number)
  TurnoverRank: number = 0;

  @JsonProperty("visit_rank", Number)
  VisitRank: number = 0;
}
@JsonObject("Customer")
class Customer {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("title", String)
  Title: string = "";

  @JsonProperty("full_name", String)
  FullName: string = "";

  @JsonProperty("address_id", Number)
  AddressID: number = 0;

  @JsonProperty("address", Address, true)
  Address: Address = new Address();

  @JsonProperty("mobile", String)
  Mobile: string = "";

  @JsonProperty("landline", String)
  Landline: string = "";

  @JsonProperty("email_address", String)
  EmailAddress: string = "";

  @JsonProperty("date_of_birth", String, true)
  DateOfBirth: string | null = null;

  @JsonProperty("id_card", String)
  IDCard: string = "";

  @JsonProperty("discount", Number, true)
  Discount: number = 0;

  @JsonProperty("occupation", String)
  Occupation: string = "";

  @JsonProperty("comments", String)
  Comments: string = "";

  @JsonProperty("statistics", CustomerStatistics, true)
  CustomerStatistics: CustomerStatistics = new CustomerStatistics();

  get firstName(): string {
    try {
      return this.FullName.split(" ")[0];
    } catch (e) {
      return "";
    }
  }

  get lastName(): string {
    try {
      return this.FullName.split(" ")[1];
    } catch (e) {
      return "";
    }
  }
}

export default Customer;
