import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Header } from "../components";
import { Configuration } from "../stores/models";
import { createOutline, aperture, car, shirtOutline,sendOutline } from "ionicons/icons";
import PickupDeliveryComponent from "../components/PickupDeliveryComponent/PickupDeliveryComponent";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
export class ConfigurationWorkLoad extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    //check if auth.role in auth store is admin. if not then redirect user to home page
    const { auth, configuration, actions } = this.props.store.stores;
    if (auth.role !== "admin") {
      return;
    }
    configuration.getConfigurations();
    actions.getActions();
  }

  getAmounts(amounts: string): string[] {
    return amounts.split(",");
  }

  handleSaveConfig(config: Configuration) {
    const { configuration } = this.props.store.stores;
    configuration.saveConfiguration(config);
  }

  add = () => {
    this.props.history.push("/configuration/0");
  };
  render() {
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      //TODO display message of un-authorization
      return <Redirect to="/home" exact />;
    }
    const { configuration, actions } = this.props.store.stores;
    return (
      <IonPage>
        <Header
          backURL="/configuration"
          title="Work Load Settings"
          onAdd={this.add}
        />

        <IonContent>
          <IonList>
            {configuration.months.map((month, i) => {
              return (
                <IonItem key={i}>
                  <IonLabel>
                    <h2 style={{ textTransform: "capitalize" }}>{month}</h2>
                    <IonGrid>
                      <IonRow>
                        <IonCol size={"1"}>&nbsp;</IonCol>
                        <IonCol size={"1"}>Sunday</IonCol>
                        <IonCol size={"1"}>Monday</IonCol>
                        <IonCol size={"1"}>Tuesday</IonCol>
                        <IonCol size={"1"}>Wednesday</IonCol>
                        <IonCol size={"1"}>Thursday</IonCol>
                        <IonCol size={"1"}>Friday</IonCol>
                        <IonCol size={"1"}>Saturday</IonCol>
                      </IonRow>
                      {configuration.configs.map((config) => {
                        const amountsByDay = this.getAmounts(config.Amounts);
                        if (config.Month === month) {
                          return (
                            <IonRow>
                              <IonCol size={"1"}>
                                {
                                  actions.actions.find(
                                    (action) => action.ID === config.ActionID
                                  )?.Name
                                }
                              </IonCol>
                              {amountsByDay.map((amount) => {
                                return <IonCol size={"1"}>{amount}</IonCol>;
                              })}
                              <IonCol size={"1"}>
                                <IonItem
                                  key={config.ID}
                                  routerLink={"/configuration/" + config.ID}
                                  routerDirection="forward"
                                >
                                  <IonButton
                                    slot={"start"}
                                    color="none"
                                    size="small"
                                  >
                                    <IonIcon
                                      color={"primary"}
                                      slot="icon-only"
                                      icon={createOutline}
                                    />
                                  </IonButton>
                                </IonItem>
                              </IonCol>
                            </IonRow>
                          );
                        }
                      })}
                    </IonGrid>
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

interface PickupDeliveryPageProps {
  store: RootStore;
}

interface PickupDeliveryRouteProps {
  name: string;
}

@inject("store")
@observer
export class ConfigurationPickupDeliveries extends React.Component<
  PickupDeliveryPageProps & RouteComponentProps<PickupDeliveryRouteProps>
> {
  componentDidMount() {
    console.log("pickupDeliveryComponentMounted");
    //check if auth.role in auth store is admin. if not then redirect user to home page
    const { auth, pickupDelivery, addresses } = this.props.store.stores;
    if (auth.role !== "admin") {
      return <Redirect to="/home" exact />;
    }
    addresses.getLocalities();
    pickupDelivery.getPickupDeliveries();
  }

  getAmounts(amounts: string): string[] {
    return amounts.split(",");
  }

  handleSavePickupDelivery() {
    const { pickupDelivery } = this.props.store.stores;
    pickupDelivery.save();
  }

  render() {
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      //TODO display message of un-authorization
      return <Redirect to="/home" exact />;
    }
    const { pickupDelivery, addresses, statistics } = this.props.store.stores;
    return (
      <IonPage>
        <Header
          backURL="/configuration"
          title="Pickup / Delivery Settings"
          onConfirm={async () => {
            console.log("save the pickup and delivery settings");
            console.log(pickupDelivery.pickupDeliveries);
            await pickupDelivery.save();
            await statistics.getScheduleStatisticsByWeek(
              statistics.yearNumber,
              statistics.weekNumber
            );
          }}
        />

        <IonContent>
          <PickupDeliveryComponent
            data={pickupDelivery.pickupDeliveries}
            localities={addresses.localities.map((l) => l.Name)}
          />
        </IonContent>
      </IonPage>
    );
  }
}

interface ConfigurationPageProps {
  store: RootStore;
}

interface ConfigurationRouteProps {
  name: string;
}
export class Configurations extends React.Component<
  ConfigurationPageProps & RouteComponentProps<ConfigurationRouteProps>
> {

  private myButton(routerLink:string, icon:string, label:string):JSX.Element{
    return (
      <IonItem
        routerLink={routerLink}
        className="main-menu-item"
        style={{
          height: "120px",
          display: "flex",
          "font-size": "28px",
        }}
        // onClick={handleLogout}
        routerDirection="root"
      >
        <IonIcon
          slot="start"
          icon={icon}
          size="large"
          style={{ "font-size": "28px" }}
        />
        <IonLabel>{label}</IonLabel>
      </IonItem>)
  }
  render() {
    return (
      <IonPage>
        <Header backURL="/home" title="Configuration" />
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol key="workloads" sizeXs="12" sizeLg="3">
                {this.myButton('/configWorkLoad', aperture, 'Work Loads')}
              </IonCol>
              <IonCol key="pickupDeliveries" sizeXs="12" sizeLg="3">
                {this.myButton('/configPickupDeliveries', car, 'Pickup / Delivery')}
              </IonCol>
              <IonCol key="quickGarments" sizeXs="12" sizeLg="3">
                {this.myButton('/quickgarments', shirtOutline, 'Garments')}
              </IonCol>
              <IonCol key="quickGarments" sizeXs="12" sizeLg="3">
                {this.myButton('/messaging', sendOutline, 'Messages')}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

// export default withRouter(ConfigurationPickupDeliverys);
