import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import {
  IonPage,
  IonContent,
  IonList,
  withIonLifeCycle,
  IonButton,
} from "@ionic/react";
import { Header, TicketListItem } from "../components";
import { observable, runInAction } from "mobx";

interface ITickets {
  store?: RootStore;
}

@inject("store")
@observer
class Tickets extends React.Component<ITickets & RouteComponentProps> {
  @observable term = "";
  @observable limit = 10;
  @observable offset = 0;

  componentDidMount() {
    // this.init();
  }

  async ionViewWillEnter() {
    const {tickets } = this.props.store!.stores;
    runInAction(()=>{
      this.limit = 10;
      this.offset = 0;
      tickets.tickets = []
      tickets.allTickets = []
      tickets.allTicketsWithUnconfirmed = []
      this.init()
    })
  //   const { tickets } = this.props.store!.stores;
  //   await tickets.getAllTickets();
  }

  async init() {
    const { tickets } = this.props.store!.stores;
    await tickets.getTicketsLimited(
      this.limit,
      this.offset,
      // this.term,
      "",
      "true",
      true,
      true
    );
  }

  search = async (q: string) => {
    runInAction(() => {
      this.term = q;
    });
    const { tickets } = this.props.store!.stores;
    runInAction(()=>{
      tickets.search = q
    })
    let ok = tickets.getTicketsLimited(this.limit, 0, q, "true", false, false);
    if ((await ok) === true) {
      // runInAction(() => {
      //   this.term = q;
      // });
    }
  };

  render() {
    const { allTickets } = this.props.store!.stores.tickets;
    return (
      <IonPage>
        <Header
          title="Ticket"
          backURL="/"
          onSearch={this.search}
          // searchTerm={this.term}
          searchOpen
        />
        <IonContent>
          <IonList>
            {allTickets
              .slice()
              .filter((t) => t.State !== "CREATED")
              // .reverse()
              .map((t) => {
                if (t.ID !== 0) {
                  return (
                    <TicketListItem
                      store={this.props.store!}
                      key={t.ID}
                      ticket={t}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </IonList>
          <IonButton
            onClick={() => {
              runInAction(async () => {
                // this.limit += 20
                this.offset += this.limit;
                const { tickets } = this.props.store!.stores;
                await tickets.getTicketsLimited(
                  this.limit,
                  this.offset,
                  // this.term,
                  "",
                  "true",
                  true,
                  true
                );
              });
            }}
          >
            load more
          </IonButton>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Tickets));
