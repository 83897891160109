import React, { useState, useRef } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonButton,
  IonIcon,
  IonSearchbar,
  withIonLifeCycle,
  useIonViewDidLeave,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  search,
  close,
  addOutline,
  checkmarkCircleOutline,
  repeatOutline,
  print,
  closeCircleOutline,
  homeSharp,
  pricetagsSharp,
  documentTextOutline,
} from "ionicons/icons";
import { withRouter, RouteComponentProps } from "react-router";
import { RootStore } from "../../stores/RootStore";

interface IHeader {
  store?: RootStore;
  title: string;
  backURL?: string;
  onConfirm?: Function;
  onConfirm2?: Function;
  onSearch?: Function;
  onAdd?: Function;
  toggleReOrder?: Function;
  onPrint?: Function;
  onPrint2?: Function;
  onPrintScreen?: Function;
  showPrintScreen?: boolean;
  showPriceTag?: boolean;
  onPrintAll?: Function;
  onPrintAll1?: Function;
  onPrintAll2?: Function;
  onCancel?: Function;
  searchOpen?: boolean;
  searchTerm?: string;
}

const Header: React.FC<IHeader & RouteComponentProps> = ({
  title,
  backURL,
  onSearch,
  onAdd,
  onConfirm,
  onConfirm2,
  toggleReOrder,
  onPrint,
  onPrint2,
  showPrintScreen,
  onPrintAll,
  onPrintAll1,
  onPrintAll2,
  onPrintScreen,
  showPriceTag,
  onCancel,
  history,
  searchOpen,
  searchTerm,
  store,
}) => {
  const [showSearch, setShowSearch] = useState(searchOpen ? searchOpen : false);
  const [searchInput, setSearchInput] = useState("");

  useIonViewDidLeave(() => {
    // (searchInput as any).current.setFocus();
    // if (onSearch) onSearch("");
    toggleSearch();
    setShowSearch(searchOpen!);
    // toggleSearch()
    if (searchOpen !== true) {
      setShowSearch(false);
    }
  });

  useIonViewDidEnter(() => {
  });

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    if (!showSearch) {
      setTimeout(() => {
        if ((searchInput as any).current !== undefined) {
          (searchInput as any).current.setFocus();
        }
      }, 1000);
    } else {
      if (onSearch) onSearch("");
    }
  };

  const handleSearch = (e: any) => {
    if (onSearch) {
      onSearch(e.detail.value!);
    }
  };

  const handleAdd = () => {
    if (onAdd) {
      onAdd();
    }
  };

  const handleReOrder = () => {
    if (toggleReOrder) {
      toggleReOrder();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleConfirm2 = () => {
    if (onConfirm2) {
      onConfirm2();
    }
  };
  const handlePrint = () => {
    if (onPrint) {
      onPrint();
    }
  };

  const handlePrint2 = () => {
    if (onPrint2) {
      onPrint2();
    }
  };

  const handlePrintAll = () => {
    if (onPrintAll) {
      onPrintAll();
    }
  };

  const handlePrintScreen = () => {
    if (onPrintScreen) {
      onPrintScreen();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const showHeaderTitleInDialog = () => {
    const { ui } = store!.stores;
    ui.showDialog({
      title: "Ticket Details",
      content: <p>{title}</p>,
      handler: () => {
        ui.closeDialog();
      },
    });
  };

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          {backURL ? <IonBackButton defaultHref={backURL} /> : null}
          <IonButton
            onClick={() => {
              history.replace("/");
            }}
          >
            <IonIcon icon={homeSharp} />
          </IonButton>
        </IonButtons>
        {showSearch ? (
          <IonSearchbar
            // value={searchTerm}
            // value={searchTerm!==undefined?searchTerm:''}
            onIonChange={(e) => {
              handleSearch(e);
            }}
          />
        ) : (
          <IonTitle onClick={showHeaderTitleInDialog}>{title}</IonTitle>
        )}
        <IonButtons slot="end">
          {onSearch ? (
            <IonButton onClick={toggleSearch}>
              <IonIcon icon={showSearch ? close : search} />
            </IonButton>
          ) : null}
          {onAdd ? (
            <IonButton onClick={handleAdd}>
              <IonIcon icon={addOutline} size="large" />
            </IonButton>
          ) : null}
          {toggleReOrder ? (
            <IonButton onClick={handleReOrder}>
              <IonIcon icon={repeatOutline} size="large" />
            </IonButton>
          ) : null}
          {onPrint ? (
            <IonButton onClick={handlePrint}>
              <IonIcon icon={print} color="info" size="large" />
            </IonButton>
          ) : null}

          {showPrintScreen ? (
            <IonButton onClick={handlePrintScreen}>
              <IonIcon icon={documentTextOutline} color="info" size="large" />
            </IonButton>
          ) : null}
          {showPriceTag && onPrint ? (
            <IonButton onClick={handlePrintAll}>
              <IonIcon icon={pricetagsSharp} color="info" size="large" />
            </IonButton>
          ) : null}
          {onCancel ? (
            <IonButton onClick={handleCancel}>
              <IonIcon icon={closeCircleOutline} color="danger" size="large" />
            </IonButton>
          ) : null}
          {onConfirm ? (
            <IonButton onClick={handleConfirm}>
              <IonIcon
                icon={checkmarkCircleOutline}
                color="success"
                size="large"
              />
            </IonButton>
          ) : null}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default withRouter(withIonLifeCycle(Header));
