import { JsonObject, JsonProperty } from "json2typescript";
import Agent from "./Agent";
import EmployeeOwing from "./EmployeeOwing";

@JsonObject("Employee")
class Employee {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("agent_id", Number)
  AgentID: number = 0;

  @JsonProperty("full_name", String)
  FullName: string = "";

  @JsonProperty("mobile", String)
  Mobile: string = "";

  @JsonProperty("role", String)
  Role: string = "";

  @JsonProperty("pin", Number)
  Pin: number = 0;

  @JsonProperty("accumulated", Number)
  Accumulated: number = 0;

  @JsonProperty("pending", Number)
  Pending: number = 0;

  @JsonProperty("enable_login", Boolean)
  LoginEnabled: boolean = false;

  @JsonProperty("agent", Agent, true)
  Agent: Agent = new Agent();

  @JsonProperty("employee_owings", [EmployeeOwing], true)
  EmployeeOwings: EmployeeOwing[] = [];

  @JsonProperty("localities", String)
  Localities:string = "";

  get firstName(): string {
    try {
      return this.FullName.split(" ")[0];
    } catch (e) {
      return "";
    }
  }

  get lastName(): string {
    try {
      return this.FullName.split(" ")[1];
    } catch (e) {
      return "";
    }
  }
}

export default Employee;
