import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Preference")
class Preference {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";
}

@JsonObject("CustomerPreference")
class CustomerPreference {
  @JsonProperty("customer_id", Number)
  CustomerID: number = 0;

  @JsonProperty("preference_id", Number)
  PreferenceID: number = 0;
}

export { Preference, CustomerPreference };
