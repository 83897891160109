import { RootStore } from "../../stores/RootStore";
import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import {
  IonList,
  IonText,
  IonLabel,
  IonListHeader,
  IonButton,
} from "@ionic/react";
import TicketListItem from "../TicketListItem/TicketListItem";
import SimpleTicketListItem from "../SimpleTicketListItem copy/SimpleTicketListItem";
import SimpleCustomer from "../../stores/models/SimpleCustomer";

interface ICustomerTickets {
  store?: RootStore;
}

const CustomerTickets: React.FC<ICustomerTickets> = ({ store }) => {
  const { simpleCustomer } = store!.stores.customers;
  const [all, setAll] = useState(false);
  const items = !all
    ? simpleCustomer.SimpleTickets.slice().reverse().slice(0, 10)
    : simpleCustomer.SimpleTickets.slice().reverse();
  return (
    <>
      <IonText color="medium">
        <h6 style={{ paddingLeft: 15 }}>Tickets</h6>
      </IonText>
      <IonList>
        {simpleCustomer.SimpleTickets.length > 10 ? (
          <IonListHeader>
            <IonLabel>&nbsp;</IonLabel>
            <IonButton
              fill={all ? "outline" : "clear"}
              onClick={() => {
                setAll(!all);
              }}
            >
              See All
            </IonButton>
          </IonListHeader>
        ) : null}
        {items.map((t) => {
          return <SimpleTicketListItem store={store!} key={t.ID} ticket={t} />;
        })}
      </IonList>
    </>
  );
};

export default inject("store")(observer(CustomerTickets));
