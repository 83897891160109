import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonList,
} from "@ionic/react";
import {
  Header,
  EmployeeContactDetails,
  EmployeePermissions,
  TextField,
} from "../components";
import { Employee } from "../stores/models";
import EmployeeOwing from "../stores/models/EmployeeOwing";
import moment from "moment";
import StateDropDown from "../components/StateDropDown/StateDropDown";
import EmployeeFeatures from "../components/EmployeeFeatures/EmployeeFeatures";

interface IEmployeeDetails {
  store: RootStore;
}

interface IRoute {
  id: string;
}

@inject("store")
@observer
class EmployeeDetails extends React.Component<
  IEmployeeDetails & RouteComponentProps<IRoute>
> {
  componentDidMount() {
    const { agents, employees } = this.props.store.stores;
    const id = parseInt(this.props.match.params.id);
    agents.getAgents();

    if (id === 0) {
      this.reset();
      return;
    }
    // TODO: refactore to store?
    employees.getEmployeeById(id);
  }

  componentWillUnmount() {
    this.reset();
  }

  reset() {
    const { employees } = this.props.store.stores;
    employees.resetEmployee();
  }

  updateEmployee = async () => {
    const { employees, ui } = this.props.store.stores;
    const employeeSaved = await employees.saveEmployee();

    if (employeeSaved) {
      ui.showToast({ message: "Employee Updated", color: "success" });
    } else {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  createEmployee = async () => {
    const { employees, ui } = this.props.store.stores;
    try {
      const e = await employees.createEmployee();
      ui.showToast({ message: "Employee Created", color: "success" });
      employees.getEmployeeById(e.ID);
      this.props.history.replace(`/employees/${e.ID}`);
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { employees } = this.props.store.stores;
    const { employee } = employees;
    if (employee.ID === 0) {
      this.createEmployee();
    } else {
      this.updateEmployee();
    }
  };

  handleDelete = async (confirmed: boolean) => {
    const { employees, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }

    const { employee } = employees;
    if (employee.ID === 0) return;
    try {
      const s = await employees.deleteEmployee(employee.ID);
      if (s) {
        ui.showToast({ message: "Employee Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/employees");
    } catch (error) {
      const message = error.response.data.message
        ? error.response.data.message
        : "Something went wrong";
      ui.showToast({ message: message, color: "danger" });
    }
  };

  buttons = () => {
    const { employee } = this.props.store.stores.employees;
    return (
      <IonGrid>
        <IonRow>
          <IonCol sizeXs="6" sizeLg="2">
            <IonButton
              color="primary"
              expand="full"
              onClick={this.handleSubmit}
            >
              Save
            </IonButton>
          </IonCol>
          {employee.ID !== 0 ? (
            <IonCol sizeXs="6" sizeLg="2">
              <IonButton
                color="danger"
                expand="full"
                onClick={() => this.handleDelete(false)}
              >
                Delete
              </IonButton>
            </IonCol>
          ) : null}
        </IonRow>
      </IonGrid>
    );
  };

  owings = () => {
    const { employees } = this.props.store.stores;
    return (
      <IonGrid>
        <IonText color="medium">
          <h5 style={{ padding: 15 }}>Employee Owings</h5>
        </IonText>
        <IonRow>
          <IonCol sizeLg="2" sizeXs="12">
            <TextField
              disabled={true}
              label="Owings"
              type="text"
              value={employees.employee.Pending}
            />
          </IonCol>
          <IonCol sizeLg="2" sizeXs="12">
            <IonButton
              color="success"
              expand="full"
              onClick={async () => {
                await employees.clearOwingsAndClose(employees.employee.ID);
              }}
            >
              Clear & close
            </IonButton>
          </IonCol>
        </IonRow>
        {this.owingList(employees.employee)}
      </IonGrid>
    );
  };

  owingList = (employee: Employee) => {
    const elemements = employee.EmployeeOwings.map((eo) => {
      return this.owingItem(eo);
    });
    return (
      <IonList>
        {this.owingListHeader()}
        {elemements}
      </IonList>
    );
  };
  owingListHeader = (): JSX.Element => {
    return (
      <IonRow>
        <IonCol size="1">Ticket ID</IonCol>
        <IonCol size="1">Amount</IonCol>
        <IonCol size="1">Amount Received</IonCol>
        <IonCol size="1">Percentage</IonCol>
        <IonCol size="1">Item ID</IonCol>
        <IonCol size="2">Payment Received</IonCol>
        <IonCol size="2">State</IonCol>
      </IonRow>
    );
  };
  owingItem = (employeeOwing: EmployeeOwing): JSX.Element => {
    return (
      <IonRow>
        <IonCol size="1">{employeeOwing.TicketID}</IonCol>
        <IonCol
          size="1"
          style={{ color: employeeOwing.Amount < 0 ? "red" : "green" }}
        >
          {employeeOwing.Amount}
        </IonCol>
        <IonCol
          size="1"
          style={{ color: employeeOwing.Amount < 0 ? "red" : "green" }}
        >
          {(employeeOwing.Amount * (employeeOwing.Percentage / 100)).toFixed(2)}
        </IonCol>
        <IonCol size="1">{employeeOwing.Percentage} %</IonCol>
        <IonCol size="1">{employeeOwing.TicketSubCategoryID}</IonCol>
        <IonCol size="2">
          {moment(employeeOwing.PaymentDate).format("DD-MM-YYYY HH:mm")}
        </IonCol>
        <IonCol size="2">
          <StateDropDown
            store={this.props.store!}
            elements={this.props.store!.stores.tracking.states.map((s) => s)}
            selected={employeeOwing.State!}
            onSelect={async (s: string, sig: string) => {
              console.log(`item selected ${s}`);
              await this.props.store?.stores.tracking.setTicketSubCategoriesState(
                [employeeOwing.TicketSubCategoryID],
                s,
                sig
              );
              this.props.store?.stores.tickets.reloadTicket();
              this.props.store.stores.employees.getEmployeeById(
                employeeOwing.EmployeeID
              );
            }}
          />
        </IonCol>
        <IonCol
          size="2"
          style={{ color: employeeOwing.PaidInFull === true ? "green" : "red" }}
        >
          {employeeOwing.PaidInFull === true
            ? `Paid In Full`
            : `Not Paid In Full`}
        </IonCol>
      </IonRow>
    );
  };

  body = () => {
    const { employees, auth, addresses } = this.props.store.stores;
    addresses.getLocalities()
    const employeeRole =
      employees.employee.Role === auth.DriverRole ||
      employees.employee.Role === auth.EmployeeRole;
    console.log(auth.role);
    return (
      <IonContent>
        <EmployeeContactDetails />
        <hr />
        <EmployeePermissions />
        <hr />
        <EmployeeFeatures />
        <hr />
        {this.buttons()}
        <hr />
        {employeeRole && employees.employee.EmployeeOwings.length > 0 ? (
          this.owings()
        ) : (
          <>
          <hr /></>
        )}
      </IonContent>
    );
  };

  render() {
    const { employee } = this.props.store.stores.employees;
    const title = employee.ID === 0 ? "New Employee" : "Employee Details";
    return (
      <IonPage>
        <Header title={title} backURL="/agents" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(EmployeeDetails);


