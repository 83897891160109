const VERSION = "5";

export default class StorageService {
  storage: any;
  constructor() {
    this.storage = window.localStorage;
    if (this.get("_qv") && parseInt(this.get("_qv")) < parseInt(VERSION)) {
      this.storage.clear();
    }
    this.set("_qv", VERSION);
  }

  set(key: string, val: string): void {
    this.storage.setItem(key, val);
  }

  rem(key: string): void {
    this.storage.removeItem(key);
  }

  get(key: string): string {
    return this.storage.getItem(key);
  }
}
