import { CustomerPreference, Preference } from "./models/Preference";
import { observable, action, runInAction } from "mobx";
import { RootStore } from "./RootStore";

class PreferencesStore {
  private root: RootStore;

  @observable public customerPreferences: CustomerPreference[] = [];
  @observable public preferences: Preference[] = [];

  constructor(root: RootStore) {
    this.root = root;
  }

  @action("get all preferences")
  public async getPreferences() {
    const { ui } = this.root.stores;
    const { preferences } = this.root.services;
    if (this.preferences.length > 0) {
      return;
    }
    try {
      const p = await preferences.fetchAll();
      this.setPreferences(p);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      this.setPreferences([]);
      console.log(error);
    }
  }

  @action
  public resetPreferences() {
    this.preferences = [];
  }

  public async getPreferencesForCustomer(id: number) {
    const { preferences } = this.root.services;
    const { ui } = this.root.stores;
    try {
      const cp = await preferences.fetchByCustomerId(id);
      runInAction(() => {
        this.customerPreferences = cp;
      });
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      runInAction(() => {
        this.customerPreferences = [];
      });
      console.log(error);
    }
  }

  @action("save customer preferences")
  public async saveCustomerPreferences(customerID: number): Promise<Boolean> {
    const { ui } = this.root.stores;
    const { preferences } = this.root.services;
    if (this.customerPreferences.length === 0) {
      return true;
    }
    try {
      const prefs = this.customerPreferences.map((p) => p.PreferenceID);
      const id = customerID;
      return await preferences.saveForCustomer(id, prefs);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  @action
  public setCustomerPreferences(customerID: number, preferences: number[]) {
    const cp: CustomerPreference[] = preferences.map((p: number) => {
      return {
        CustomerID: customerID,
        PreferenceID: p,
      };
    });
    this.customerPreferences = cp;
  }

  @action.bound
  public setPreferences(preferences: Preference[]) {
    this.preferences = preferences;
  }

  @action
  public resetCustomerPreferences() {
    this.customerPreferences = [];
  }
}

export default PreferencesStore;
