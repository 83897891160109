import {
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonContent,
  IonPage,
} from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import logo from "./../../assets/logo.png"

import "./EmployeeLogin.css";
import { TextField } from "..";
import { useHistory } from "react-router-dom";

interface IEmployeeLogin {
  store?: RootStore;
}

const EmployeeLogin: React.FC<IEmployeeLogin> = ({ store }) => {
  const [pin, setPin] = useState("");
  const { auth, ui } = store!.stores;
  const history = useHistory();

  const handleLogin = async () => {
    setPin("");
    const succ = await auth.employeeLogin(pin);
    if (!succ) {
      ui.showToast({ message: "Incorrect Pin", color: "danger" });
      return;
    }
    if (succ) {
      history.replace("/");
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      console.log("handle login");
      handleLogin();
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonModal
          backdropDismiss={false}
          keyboardClose={false}
          swipeToClose={false}
          isOpen={!auth.isEmployeeLoggedIn && auth.isLoggedIn}
        >
        <img src={logo} className={"loginLogo"}/>
          <IonGrid id="employeeLogin">
            <IonRow>
              <IonCol sizeXs="12">
                <div style={{ height: 25 }} />
                <IonText color="dark">
                  <h1>Employee Login</h1>
                  <p>Enter your employee pin code to proceed</p>
                </IonText>
                <div style={{ height: 25 }} />
                <TextField
                  onKeyUp={(e: any) => {
                    handleKeyPress(e);
                  }}
                  placeholder="PIN"
                  type="password"
                  value={pin}
                  onChange={(value: string) => {
                    setPin(value);
                  }}
                />
                <div style={{ height: 25 }} />
                <IonButton onClick={handleLogin} size="large" expand="full">
                  Continue
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <div />
            </IonRow>
          </IonGrid>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default inject("store")(observer(EmployeeLogin));
