import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import {
  IonPage,
  IonContent,
  withIonLifeCycle,
  IonItem,
  IonButton,
  IonCol,
  IonRow,
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import { Header, SelectField } from "../components";
import { DatePicker } from "../components/DatePicker/DatePicker";
import moment from "moment";
import { action, observable, runInAction } from "mobx";
import { Customer } from "../stores/models";
import MultiSelect from "../components/MultiSelect/MultiSelect";
import TextArea from "../components/TextArea/TextArea";

interface IMessaging {
  store?: RootStore;
}

interface IOption {
  label: string;
  value: string | number;
}
type optionType = { label: string; value: string | number };

@inject("store")
@observer
class Messaging extends React.Component<IMessaging & RouteComponentProps> {
  componentDidMount() {
    this.init();
  }

  async init() {}

  @observable customerIDS: number[] = [];
  @observable allCustomers: boolean = false;
  @observable message: string = "";
  // @observable options: optionType[] = [];
  @observable options: IOption[] = [];
  @observable date: string = moment(new Date()).format("YYYY-MM-DD");
  @observable _customers: Customer[] = [];

  async ionViewWillEnter() {
    console.log("entering the component");
    const { customers } = this.props.store!.stores;
    await customers.getCustomersLimited(20, "");
    runInAction(() => {
      this._customers = customers.customers;
    });
    this.setOptions(customers.customers);
    // this.getOptions(customers.customers);
  }

  @action
  setAllCustomers() {
    this.allCustomers = !this.allCustomers;
    if (this.allCustomers === true) {
      //set the customerIDS to all the customers
      console.log("all customers true");
      const customerIDS = this._customers.slice().map((c) => c.ID);
      this.setCustomerIDS(customerIDS);
    } else {
      //clear the array of customerIDS
      // this.customerIDS = [];
    }
  }

  @action
  private setCustomerIDS(customerIDS: number[]) {
    this.customerIDS = customerIDS;
    console.log("updated customer ids ", this.customerIDS);
    //need to set the selected customers so that we can send them again to the multi select on update
  }

  @action
  private addIDToCustomerIDS(customerID: number) {
    console.log(this.customerIDS);
    const found = this.customerIDS.findIndex((e) => {
      return e == customerID;
    });
    if (found > 0) {
      runInAction(() => {
        this.customerIDS.splice(found, 1);
      });
    } else {
      this.customerIDS = this.customerIDS.concat(customerID);
    }
    //if the customerIDS already have this number then remove instead of add(find index and slice.)
    // console.log("updated customer ids ", this.customerIDS);
    //need to set the selected customers so that we can send them again to the multi select on update
  }

  @action
  private setMessage(message: string) {
    console.log("updating message");
    this.message = message;
  }

  @action
  private setOptions(customers: Customer[]) {
    this.options = customers.map((c: Customer) => {
      return { label: c.FullName, value: c.ID };
    });
  }
  // @action
  // private getOptions = (customers: Customer[]) => {
  // console.log("getting options");
  //   const result = customers.map((c) => {
  //     let optionType: optionType = { label: c.FullName, value: c.ID };
  //     return optionType;
  //   });
  //   this.options = result;
  // };

  render() {
    const handleUserSelect = (userID: number) => {
      this.addIDToCustomerIDS(userID);
    };
    const { customers } = this.props.store!.stores;
    return (
      <IonPage>
        <Header title="Messages" backURL="/configuration" />
        <IonContent>
          <IonRow>
            <IonCol size="4">
              <IonItem>
                <IonLabel>All Customers</IonLabel>
                <IonCheckbox
                  checked={this.allCustomers}
                  onIonChange={() => this.setAllCustomers()}
                />
              </IonItem>
            </IonCol>
            <IonCol size="4">
              <SelectField
                onSearch={async (q: string) => {
                  runInAction(() => {
                    customers.search = q;
                  });
                  await customers.getCustomersLimited(20, q);
                  this.setOptions(customers.customers);
                }}
                label="Users"
                multi
                multiSearch
                onChange={handleUserSelect}
                options={this.options}
                selected={this.customerIDS.map((id) => {
                  const customer = customers.customers.find((c) => {
                    return c.ID === id;
                  });
                  console.log(customer);
                  return { label: customer?.FullName!, value: id };
                })}
                // value={address.StreetID}
              />
              {/* <MultiSelect
                label={"users"}
                options={this.options}
                select={(customerIDS: number[]) => {
                  this.setCustomerIDS(customerIDS);
                }}
              /> */}
            </IonCol>
            <IonCol size="4">
              <DatePicker
                date={this.date}
                select={async (date: string) => {
                  await customers.getCustomersByDeliveryDate(date);
                  //need to go fetch all customers that have a delivery on that date
                  runInAction(() => {
                    //since we  have all the users that have a delivery on that date we can update the _customers
                    this.date = date;
                    this._customers = customers.customersByDeliveryDate;
                    this.setOptions(this._customers);
                  });
                }}
              />
              <IonButton
                color="danger"
                onClick={() => {
                  runInAction(() => {
                    this.setOptions(customers.customers);
                  });
                }}
              >
                Clear
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <TextArea
                rows={5}
                label="Message"
                placeholder="Input message here"
                value={""}
                onChange={(message: string) => {
                  runInAction(() => {
                    this.message = message;
                  });
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonButton
              // disabled={
              //   customers.message === "" || this.customerIDS.slice().length <= 0
              // }
              onClick={() => {
                if (this.allCustomers === true) {
                  if (window.confirm("Are you sure!")) {
                    customers.sendMessage(
                      this.customerIDS,
                      this.allCustomers,
                      this.message
                    );
                  }
                }else{
                  customers.sendMessage(
                    this.customerIDS,
                    this.allCustomers,
                    this.message
                  );
                }
              }}
            >
              Send
            </IonButton>
          </IonRow>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Messaging));
