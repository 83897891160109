import { RootStore } from "./RootStore";
import ConfigurationService from "../services/ConfigurationService";
import Configuration from "./models/Configuration";
import { observable, action, runInAction } from "mobx";

class ConfigurationStore {
  private root: RootStore;
  private api: ConfigurationService;

  public months: string[] = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  public daysOfTheWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  @observable public configs: Configuration[] = [];
  @observable public _configurations: Configuration[] = [];
  @observable public config: Configuration = new Configuration();

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.configuration;
  }

  public async getConfigurations() {
    const { ui } = this.root.stores;
    try {
      const Configurations = await this.api.fetchAll();
      this.setConfigurations(Configurations, true);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async saveConfiguration(config: Configuration) {
    const { ui } = this.root.stores;
    try {
      const Configurations = await this.api.update(config.ID, config);
      runInAction(() => {
        this.pushConfiguration(Configurations);
      });
      ui.showToast({ message: `Configuration saved.`, color: "success" });
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async createConfiguration(
    config: Configuration
  ): Promise<Configuration> {
    const { ui } = this.root.stores;
    try {
      const configuration = await this.api.create(config);
      this.pushConfiguration(configuration);
      return configuration;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getConfigurationsByID(id: number) {
    const { ui } = this.root.stores;
    try {
      const configuration = await this.api.fetchOne(id);
      this.setConfiguration(configuration);
      return configuration;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async deleteConfiguration(id: number): Promise<boolean> {
    if (this.config.ID === 0) {
      return false;
    }
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const resp = await this.api.delete(id);
      if (resp.success) {
        runInAction(() => {
          const employeesToFilter = this.configs;
          this.configs = employeesToFilter.filter((c) => c.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public getConfigsByMonth(month: string): Configuration[] {
    return this.configs.filter((config) => config.Month === month);
  }

  getAmounts(): string[] {
    return this.config.Amounts.split(",");
  }

  @action
  public setConfigurations(
    configurations: Configuration[],
    reload: boolean = false
  ) {
    if (reload) {
      this._configurations = configurations;
    }
    this.configs = configurations;
  }

  @action
  public setConfiguration(configuration: Configuration) {
    this.config = configuration;
  }

  @action
  public pushConfiguration(configuration: Configuration) {
    //if the configuration list already contains the configuration being passed then first remove it
    this.popConfiguration(configuration.ID);
    this.configs.push(configuration);
  }

  // https://stackoverflow.com/questions/36326612/delete-item-from-state-array-in-react
  @action
  public popConfiguration(id: number) {
    var array = [...this.configs]; // make a separate copy of the array
    var config = array.find((c) => c.ID === id);
    var index = array.indexOf(config!);
    if (index !== -1) {
      array.splice(index, 1);
      this.setConfigurations(array);
    }
  }

  @action
  public resetConfig() {
    this.config = new Configuration();
  }

  @action
  public resetConfigs() {
    this.configs = [];
    this._configurations = [];
  }

  @action
  public setConfigAmounts(amounts: string) {
    this.config.Amounts = amounts;
  }

  @action
  public setConfigMonth(month: string) {
    this.config.Month = month;
  }

  @action
  public setConfigAction(actionID: number) {
    this.config.ActionID = actionID;
  }

  @action
  public setConfig(config: Configuration) {
    this.config = config;
  }

  public updateAmountByIndex(v: number, index: number) {
    if (v < 0) {
      v = 0;
    }
    console.log(v);
    const value: string = v.toString();
    let values: string[] = this.getAmounts();
    values[index] = value;
    const newValues = this.createValuesCSV(values);
    this.setConfigAmounts(newValues);
  }

  public createValuesCSV(values: string[]): string {
    return values.join(",");
  }
}

export default ConfigurationStore;
