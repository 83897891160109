import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("GarmentSubCategory")
class GarmentSubCategory {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("visible", Boolean)
  Visible: boolean = true;

  @JsonProperty("image", String, true)
  Image: string | null = null;

  @JsonProperty("discount", String)
  Discount:string = "Percentage 0"

  //TODO: refactor to string,csv
  @JsonProperty("pieces", String)
  Pieces: string = "";

  @JsonProperty("images_required", Boolean)
  ImagesRequired: boolean = false;

  @JsonProperty("show_choose_pieces", Boolean)
  ShowChoosePieces: boolean = false;

  @JsonProperty("garment_category_id", Number)
  GarmentCategoryID: number = 0;

  @JsonProperty("order", Number)
  Order: number = 0;

  @JsonProperty("allow_quantity", Boolean)
  AllowQuantity: boolean = false;

  public get getPiecesArray(): string[] | undefined {
    if (this.Pieces === "") {
      return;
    }
    return this.Pieces.split(",");
  }
}

export default GarmentSubCategory;
