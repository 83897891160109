import { IonContent, IonPage, IonList } from "@ionic/react";
import React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Action } from "../stores/models";
import { Header } from "../components";
import ActionListItem from "../components/ActionListItem/ActionListItem";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Actions extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    //check if auth.role in auth store is admin. if not then redirect user to home page
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      return;
    }
    this.getCustomerList();
  }

  getCustomerList = () => {
    const { actions } = this.props.store.stores;
    actions.getActions();
  };

  search = (q: string) => {
    const { actions } = this.props.store.stores;
    actions.filterActions(q);
  };

  add = () => {
    this.props.history.push("/actions/0");
  };

  render() {
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      //TODO display message of un-authorization
      return <Redirect to="/home" exact />;
    }
    const { actions } = this.props.store.stores.actions;
    return (
      <IonPage>
        <Header
          backURL="/home"
          title="Actions"
          onSearch={this.search}
          onAdd={this.add}
        />
        <IonContent>
          <IonList>
            {actions.slice(0, 50).map((a: Action) => {
              return <ActionListItem key={a.ID} action={a} />;
            })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Actions);
