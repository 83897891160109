import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonSelect,
  IonSelectOption,
  IonReorderGroup,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";
import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";

import { RootStore } from "../stores/RootStore";

import { Header, TextField } from "../components";
import { GarmentSubCategory } from "../stores/models";
import GarmentSubCategoryListItem from "../components/GarmentSubCategoryListItem/GarmentSubCategoryListItem";

interface GarmentDetailsProps {
  store: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class GarmentDetails extends React.Component<
  GarmentDetailsProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    this.init();
  }

  init() {
    const id = parseInt(this.props.match.params.id);
    this.getGarment(id);
    const { garments, actions } = this.props.store.stores;
    garments.getGarmentGategories();
    actions.getActions();
  }

  getGarment(id: number) {
    const { garments } = this.props.store.stores;
    const { garmentCategory } = garments;
    if (garmentCategory.ID === id) return;
    if (id === 0) {
      this.reset();
      return;
    }
    garments.getGarmentCategoryByIdAsync(id);
  }

  reset() {
    const { garments } = this.props.store.stores;
    garments.resetGarmentCategory();
  }

  componentWillUnmount() {
    this.reset();
  }

  createGarment = async () => {
    const { garments, ui } = this.props.store.stores;
    try {
      const g = await garments.createGarment();
      ui.showToast({ message: "Information Saved", color: "success" });
      garments.getGarmentCategoryById(g.ID);
      garments.setGarmentCategory(g);
      this.props.history.replace(`/garments/${g.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  updateGarment = async () => {
    const { garments, ui } = this.props.store.stores;
    const { garmentCategory } = garments;
    try {
      await garments.saveGarmentCategory();
      garments.getGarmentCategoryById(garmentCategory.ID);
      ui.showToast({ message: "Information Saved", color: "success" });
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { garments } = this.props.store.stores;
    const { garmentCategory } = garments;
    if (garmentCategory.ID === 0) {
      this.createGarment();
    } else {
      this.updateGarment();
    }
  };

  handleImageUpload = (e: any) => {
    const { garments } = this.props.store.stores;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64Image: string | undefined = reader.result?.toString();
      garments.setImageGarmentCategory(base64Image ? base64Image : "");
    };
    reader.onerror = function (error) {};
  };

  // @action
  handleDelete = async (confirmed: boolean) => {
    const { garments, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    const { garmentCategory } = garments;
    if (garmentCategory.ID === 0) return;
    try {
      const s = await garments.deleteGarmentCategory(garmentCategory.ID);
      if (s) {
        ui.showToast({ message: "Garment Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/garments");
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleDeleteSubCategory = async (confirmed: boolean, id: number) => {
    const { garments, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDeleteSubCategory(true, id);
        },
      });
      return;
    }
    const categoryID = parseInt(this.props.match.params.id);
    this.getGarment(categoryID);
    if (id === 0) return;
    try {
      const s = await garments.deleteGarmentSubCategory(id);
      if (s) {
        ui.showToast({
          message: "Garment Sub Category Removed",
          color: "success",
        });
      }
      this.props.history.replace(`/garments/${categoryID}`);
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  Reorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { garments } = this.props.store.stores;
    const id = parseInt(this.props.match.params.id);

    const gscs = garments.reOrderGarmentSubCategories(
      event.detail.from,
      event.detail.to,
      id
    );
    garments.setGarmentSubCategories(gscs);
    event.detail.complete();
  };

  listOfSubCategories() {
    const { garments } = this.props.store.stores;
    let gscs = garments.garmentSubCategories;
    const id = parseInt(this.props.match.params.id);
    return id ? (
      <IonReorderGroup
        disabled={garments.subCategoryReOrderDisabled}
        onIonItemReorder={this.Reorder}
      >
        {gscs
          // .slice(0, 50)
          .map((gsc: GarmentSubCategory) => {
            if (gsc.GarmentCategoryID === id) {
              return (
                <GarmentSubCategoryListItem
                  key={gsc.ID}
                  store={this.props.store}
                  garmentSubCategory={gsc}
                  reOrder={"start"}
                  deleteFunc={this.handleDeleteSubCategory}
                />
              );
            }else{
              return
            }
          })}

        <GarmentSubCategoryListItem
          key={0}
          store={this.props.store}
          garmentSubCategory={new GarmentSubCategory()}
          reOrder={"start"}
        ></GarmentSubCategoryListItem>
      </IonReorderGroup>
    ) : (
      <></>
    );
  }

  body() {
    const { actions } = this.props.store.stores;
    const { garmentCategory } = this.props.store.stores.garments;
    return (
      <>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonItem>
                <IonThumbnail slot="start">
                  <label
                    htmlFor={"imageUploader"}
                    style={{ cursor: `pointer` }}
                  >
                    <img
                      alt={"image"}
                      src={garmentCategory.Image ? garmentCategory.Image : ""}
                    />
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    capture="camera"
                    id="imageUploader"
                    style={{ display: `none` }}
                    onChange={this.handleImageUpload}
                  />
                </IonThumbnail>
              </IonItem>
              <IonCol sizeLg="2" sizeXs="12">
                <TextField
                  type="text"
                  label="Name"
                  onChange={(v: any) => {
                    garmentCategory.Name = v;
                  }}
                  value={garmentCategory.Name}
                />
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Visible</IonLabel>
                  <IonCheckbox
                    checked={garmentCategory.Visible}
                    onIonChange={(e) => {
                      garmentCategory.Visible = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Type</IonLabel>
                  <IonSelect
                    value={garmentCategory.Type}
                    onIonChange={(e) => {
                      garmentCategory.Type = e.detail.value;
                    }}
                  >
                    <IonSelectOption value="Dry Clean">
                      Dry Clean
                    </IonSelectOption>
                    <IonSelectOption value="Laundry">Laundry</IonSelectOption>
                    <IonSelectOption value="TECNOWASH">TECNOWASH</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Default Action</IonLabel>
                  <IonSelect
                    key={garmentCategory.Name}
                    value={garmentCategory.DefaultActionID}
                    onIonChange={(e) => {
                      garmentCategory.DefaultActionID = e.detail.value;
                    }}
                  >
                    {actions.actions.map((a) => {
                      console.log(actions.actions);
                      return (
                        <IonSelectOption value={a.ID} key={a.ID}>
                          {a.Name}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>{this.listOfSubCategories()}</IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {garmentCategory.ID !== 0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }

  toggleReOrder = () => {
    const { garments } = this.props.store.stores;
    garments.toggleSubCategoryReOrderDisabled();
    if (garments.subCategoryReOrderDisabled) {
      garments.reOrderGarmentSubCategoriesAsyc();
    }
  };

  render() {
    const { garmentCategory } = this.props.store.stores.garments;
    const title = garmentCategory.ID === 0 ? "New Garment" : "Garment Details";
    return (
      <IonPage>
        <Header
          title={title}
          backURL="/garments"
          toggleReOrder={this.toggleReOrder}
        />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(GarmentDetails);
