import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/RootStore";
import {
  IonModal,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonButton,
  IonButtons,
  IonTitle,
  IonSpinner,
} from "@ionic/react";

interface IDialog {
  store?: RootStore;
}

const Dialog: React.FC<IDialog> = ({ store }) => {
  const { ui } = store!.stores;
  return (
    <IonModal isOpen={ui.dialog.open!} onDidDismiss={ui.closeDialog} cssClass={ui.dialog.classes}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{ui.dialog.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{ui.dialog.content}</IonContent>
      <IonFooter>
        <IonToolbar>
          {ui.dialog.freeze ? <IonSpinner slot={"end"} name="circles" /> : null}
          <IonButtons slot="end">
            {ui.dialog.OKText && ui.dialog.handler ? (
              <IonButton
                disabled={ui.dialog.freeze}
                fill="outline"
                color="primary"
                onClick={ui.dialog.handler}
              >
                {ui.dialog.OKText}
              </IonButton>
            ) : null}
            <IonButton
              disabled={ui.dialog.freeze}
              onClick={ui.closeDialog}
              fill="clear"
              color="default"
            >
              {ui.dialog.CancelText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default inject("store")(observer(Dialog));
