import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ExpressItem")
class ExpressItem {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("ticket_id", Number)
  TicketID: number = 0;

  @JsonProperty("description", String)
  Description: string = "";

  @JsonProperty("pickup_date", String, true)
  PickupDate: string | null = null;
}

export default ExpressItem;
