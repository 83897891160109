import { BaseService, IResponse } from "./BaseService";
import { Action } from "../stores/models";
import ActionGarmentSubCategory from "../stores/models/ActionGarmentSubCategory";

class ActionService extends BaseService {
  public async fetchAll(): Promise<Action[]> {
    try {
      const resp = await this.http.get("/api/v1/actions/");
      return this.decodeArray<Action>(resp, Action);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOne(id: number): Promise<Action> {
    try {
      const resp = await this.http.get(`/api/v1/actions/${id}`);
      return this.decodeObject<Action>(resp, Action);
    } catch (error) {
      throw error;
    }
  }

  public async create(a: Action): Promise<Action> {
    try {
      const payload = this.encodeObject<Action>(a, Action);
      const resp = await this.http.post("/api/v1/actions/", payload);
      return this.decodeObject<Action>(resp, Action);
    } catch (error) {
      throw error;
    }
  }

  public async update(id: number, a: Action): Promise<Action> {
    try {
      const payload = this.encodeObject<Action>(a, Action);
      const resp = await this.http.put(`/api/v1/actions/${id}`, payload);
      return this.decodeObject<Action>(resp, Action);
    } catch (error) {
      throw error;
    }
  }

  public async updateImage() {
    // TODO: implement
  }

  public async delete(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/actions/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async createLink(
    link: ActionGarmentSubCategory
  ): Promise<ActionGarmentSubCategory> {
    try {
      const payload = this.encodeObject<ActionGarmentSubCategory>(
        link,
        ActionGarmentSubCategory
      );
      const resp = await this.http.post(
        "/api/v1/actionGarmentSubCategories",
        payload
      );
      return this.decodeObject<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async updateLink(
    id: number,
    link: ActionGarmentSubCategory
  ): Promise<ActionGarmentSubCategory> {
    try {
      const payload = this.encodeObject<ActionGarmentSubCategory>(
        link,
        ActionGarmentSubCategory
      );
      const resp = await this.http.put(
        `/api/v1/actionGarmentSubCategories/${id}`,
        payload
      );
      return this.decodeObject<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async getAllLinks(): Promise<ActionGarmentSubCategory[]> {
    try {
      const resp = await this.http.get("/api/v1/actionGarmentSubCategories/");
      return this.decodeArray<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async getLink(id: number): Promise<ActionGarmentSubCategory> {
    try {
      const resp = await this.http.get(
        `/api/v1/actionGarmentSubCategories/${id}`
      );
      return this.decodeObject<ActionGarmentSubCategory>(
        resp,
        ActionGarmentSubCategory
      );
    } catch (error) {
      throw error;
    }
  }

  public async deleteLink(lid: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(
        `/api/vi/actionGarmentSubCategories/${lid}`
      );
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default ActionService;
