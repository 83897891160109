import { RootStore } from "./RootStore";
import { Customer, Message } from "./models";
import { observable, action, runInAction } from "mobx";
import Fuse from "fuse.js";
import SimpleCustomer from "./models/SimpleCustomer";

class CustomersStore {
  private root: RootStore;
  @observable public customers: Customer[] = [];
  @observable public customer: Customer = new Customer();
  @observable public customersByDeliveryDate: Customer[] = [];
  @observable public message: string = "";
  @observable public mobile: string = "";
  @observable public search: string = "";
  @observable public simpleCustomer:SimpleCustomer=new SimpleCustomer();

  public _customers: Customer[] = [];

  constructor(root: RootStore) {
    this.root = root;
  }


  @action
  public async sendMessage(customerIDS:number[],allCustomers:boolean, text:string): Promise<void> {
    console.log('instore')
    const { ui } = this.root.stores;
    ui.setLoading(true);
    const { customers } = this.root.services;
    try {
      const message:Message = {CustomerIDS:customerIDS, Text:text}
      const ok = await customers.sendMessage(message, allCustomers)
      if (ok === true){
        ui.showToast({ message: "message/s on their way", color: "success" });
      }
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  @action("get customer by id")
  public async getCustomerById(id: number): Promise<Customer> {
    const { ui } = this.root.stores;
    const { customers, addresses } = this.root.services;
    this.setCustomer(new Customer());
    ui.setLoading(true);
    try {
      if (id > 0) {
        const c = await customers.byId(id);
        if (c.AddressID !== 0) {
          await addresses.getAddressById(c.AddressID);
        }
        this.setCustomer(c);
      }
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    } finally {
      ui.setLoading(false);
      return this.customer;
    }
  }


  // TODO: refector our history requirement, history should be in uiStore.
  @action("check if customer already exists")
  public async customerExists(field: string, value: string, history?: any) {
    if (this.customer.ID > 0) return;
    if (field.trim() === "" || value.trim() === "") {
      return;
    }
    const { ui } = this.root.stores;
    const { customers } = this.root.services;
    try {
      const c = await customers.byField(field, value);
      ui.showAlert({
        title: "Customer Exists",
        message: `A Customer with matching "${field}" field already exists, Load Customer?`,
        handler: () => {
          history.replace(`/customers/${c.ID}`);
          this.getSimpleCustomerWithSimpleTicketsDetails(c.ID)
          this.getCustomerWithDetails(c.ID);
        },
      });
    } catch (error) {
      if (error.response.status !== 404) {
        ui.showToast({ message: "Something is wrong", color: "danger" });
      }
    }
  }

  @action("get customer with details")
  public getCustomerWithDetails(id: number) {
    const { customers, addresses, preferences } = this.root.stores;
    customers.getCustomerById(id).then((c) => {
      preferences.getPreferencesForCustomer(id);
      if (c.AddressID !== 0) {
        addresses.setAddress(c.Address);
        addresses.getStreets(c.Address.LocalityID);
        //addresses.getAddressById(c.AddressID);
      }
    });
  }

  // @action("get simple customer with simple tickets by id")
  // public async getSimpleCustomerWithSimpleTicketsById(id: number): Promise<Customer> {
  //   const { ui } = this.root.stores;
  //   const { customers, addresses } = this.root.services;
  //   // this.setCustomer(new Customer());
  //   ui.setLoading(true);
  //   try {
  //     if (id > 0) {
  //       const c = await customers.fetchSimpleCustomerWithSimpleTickets(id);
  //       if (c.AddressID !== 0) {
  //         await addresses.getAddressById(c.AddressID);
  //       }
  //       runInAction(()=>{
  //         this.simpleCustomer = c
  //       })
  //     }
  //   } catch (error) {
  //     ui.showToast({ message: error.response.data.message, color: "danger" });
  //   } finally {
  //     ui.setLoading(false);
  //     return this.customer;
  //   }
  // }
  @action("get simple customer with details and simple tickets array")
  public async getSimpleCustomerWithSimpleTicketsDetails(id: number) {
    const { customers } = this.root.services;
    const {addresses, preferences} = this.root.stores;
    const c = await customers.fetchSimpleCustomerWithSimpleTickets(id);
    preferences.getPreferencesForCustomer(c.ID);
    runInAction(()=>{
      this.simpleCustomer = c
      // addresses.address = c.Address
      if (c.AddressLocalityID !== 0){
        addresses.getStreets(c.AddressLocalityID!);
      }
      console.log(this.simpleCustomer)
    })
    console.log(c)
    // customers.getSimpleCustomerWithSimpleTicketsById(id).then((c) => {
    //   if (c.AddressID !== 0) {
    //     addresses.setAddress(c.Address);
    //     addresses.getStreets(c.Address.LocalityID);
    //     //addresses.getAddressById(c.AddressID);
    //   }
    // });
  }

  @action("get customers")
  public async getCustomers():Promise<boolean> {
    if (this._customers.length > 0) {
      return true;
    }
    const { customers } = this.root.services;
    try {
      const c = await customers.fetchAll();
      this.setCustomers(c);
    } catch (error) {
      console.log(error);
      return false
      // ui.showToast({ message: error.response.data.message, color: "danger" });
    } finally {
      return true
    }
  }

  @action("get customers limited")
  public async getCustomersLimited(limited:number, term:string):Promise<boolean> {
    // const { ui } = this.root.stores;
    const { customers } = this.root.services;
    // ui.setLoading(true);
    try {
      const c = await customers.fetchLimit(limited, term);
      if (this.search===term){
        this.setCustomers(c);
      }
    } catch (error) {
      console.log(error);
      return false
      // ui.showToast({ message: error.response.data.message, color: "danger" });
    } finally {
      // ui.setLoading(false);
      return true
    }
  }

  @action("get customers given delivery date for their tickets")
  public async getCustomersByDeliveryDate(date: string) {
    const { ui } = this.root.stores;
    const { customers } = this.root.services;
    try {
      const c = await customers.byDate(date);
      this.setCustomersByDeliveryDate(c);
    } catch (error) {
      console.log(error);
    } finally {
      ui.setLoading(false);
    }
    console.log(this.customersByDeliveryDate);
  }

  // TODO: refactor to service ?
  @action("filter customers")
  public filterCustomers(q: string) {
    if (q.trim() === "") {
      this.customers = this._customers;
      return;
    }

    const filterOptions: any = {
      threshold: 0,
      distance: 10,
      keys: [
        "firstName",
        "lastName",
        "Mobile",
        "Landline",
        "IDCard",
        "EmailAddress",
      ],
    };
    const fuse = new Fuse(this._customers, filterOptions);
    this.customers = fuse.search(q).map((c) => c.item);
  }

  @action
  public async createCustomer(): Promise<SimpleCustomer> {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    const { customers } = this.root.services;
    try {
      const customer = await customers.createSimpleCustomer(this.simpleCustomer);
      // runInAction(() => {
      //   this.customers.push(customer);
      //   this._customers.push(customer);
      // });
      return customer;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  @action
  public async updateCustomer(): Promise<boolean> {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    const { customers } = this.root.services;
    try {
      await customers.updateSimpleCustomerById(this.simpleCustomer.ID, this.simpleCustomer);
      // this.replaceCustomer(this.customer);
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  @action
  private replaceCustomer(customer: Customer) {
    for (var i = 0; i < this.customers.length; i++) {
      if (this.customers[i].ID === customer.ID) {
        this.customers[i] = customer;
        break;
      }
    }
    for (i = 0; i < this._customers.length; i++) {
      if (this._customers[i].ID === customer.ID) {
        this._customers[i] = customer;
        break;
      }
    }
  }

  @action
  public resetCustomer() {
    this.customer = new Customer();
  }
  @action
  public resetSimpleCustomer() {
    this.simpleCustomer = new SimpleCustomer();
  }

  @action
  public resetCustomerKeepMobile() {
    this.customer = new Customer();
    this.customer.Mobile = this.mobile
  }

  public async reloadCustomer() {
    if (this.customer.ID) {
      await this.getCustomerById(this.customer.ID);
    }
  }

  @action
  public resetCustomers() {
    this.customers = [];
    this._customers = [];
  }

  @action
  public setCustomers(customers: Customer[]) {
    this._customers = customers;
    this.customers = customers;
  }
  @action
  public setCustomersByDeliveryDate(customers: Customer[]) {
    this.customersByDeliveryDate = customers;
  }

  @action public setCustomer(customer: Customer) {
    this.customer = customer;
  }

  public async deleteCustomer(id: number): Promise<boolean> {
    const { ui } = this.root.stores;
    const { customers } = this.root.services;
    ui.setLoading(true);
    if (this.customer.ID === 0) {
      return false;
    }
    const resp = await customers.removeById(id);
    if (resp.success) {
      runInAction(() => {
        const customersToFilter = this.customers;
        this.customers = customersToFilter.filter((c) => c.ID !== id);
      });
    }
    ui.setLoading(false);
    return resp.success;
  }
}

export default CustomersStore;
