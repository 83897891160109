import React from "react";
import { inject, observer } from "mobx-react";
import { IonItem, IonCol, IonRow, IonIcon } from "@ionic/react";
import { map } from "ionicons/icons";

type sizes = "small";

interface IGoogleLocation {
  name: string;
  street: string;
  locality: string;
  iconOnly?: boolean;
  size?: sizes;
  mobile?: string;
}

const GoogleLocation: React.FC<IGoogleLocation> = (props: IGoogleLocation) => {
  return props.iconOnly ? (
    <IonItem
      style={{ cursor: "pointer" }}
      href={encodeURIComponent(`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${props.street}, ${props.locality}`)}
      // href={`https://maps.google.com/maps/dir//${props.street}, ${props.locality}`}
      target="_blank"
    >
      <IonIcon icon={map} />
    </IonItem>
  ) : (
    <IonItem
      style={{ cursor: "pointer" }}
      href={encodeURIComponent(`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${props.street}, ${props.locality}`)}
      // href={`https://maps.google.com/maps/dir//${props.street}, ${props.locality}`}
      target="_blank"
    >
      <IonCol>
        <IonRow>{props.name!=="" ? props.name : "N/A"}</IonRow>
        <IonRow>{props.street ? props.street : "N/A"}</IonRow>
        <IonRow>{props.locality ? props.locality : "N/A"}</IonRow>
        <IonRow>
          {props.mobile ? (
            <a href={"tel:" + props.mobile}>{props.mobile}</a>
          ) : (
            <></>
          )}
        </IonRow>
      </IonCol>
      <IonIcon icon={map} slot="end" />
    </IonItem>
  );
};

export default inject("store")(observer(GoogleLocation));
