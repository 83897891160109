import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import { ActionGarmentSubCategoryService } from "../services";
import Fuse from "fuse.js";
import ActionGarmentSubCategory from "./models/ActionGarmentSubCategory";

class ActionGarmentSubCategoryStore {
  private root: RootStore;
  private api: ActionGarmentSubCategoryService;

  @observable
  public actionGarmentSubCategories: ActionGarmentSubCategory[] = [];
  @observable
  public actionGarmentSubCategory: ActionGarmentSubCategory = new ActionGarmentSubCategory();

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.actionGarmentSubCategoryService;
  }

  public async getActionGarmentSubCategories() {
    const { ui } = this.root.stores;
    try {
      const actionGarmentSubCategories = await this.api.fetchAll();
      this.setActionGarmentSubCategories(actionGarmentSubCategories, true);
    } catch (error) {
      console.log(error);
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async createActionGarmentSubCategory(): Promise<
    ActionGarmentSubCategory
  > {
    const { ui } = this.root.stores;
    try {
      const actionGarmentSubCategory = await this.api.create(
        this.actionGarmentSubCategory
      );
      runInAction(() => {
        this.actionGarmentSubCategories.push(actionGarmentSubCategory);
        // this._actionGarmentSubCategories.push(action);
      });
      return actionGarmentSubCategory;
    } catch (error) {
      console.log(error)
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async updateActionGarmentSubCategory() {
    try {
      await this.api.update(
        this.actionGarmentSubCategory.ID,
        this.actionGarmentSubCategory
      );
      return true;
    } catch (error) {
      throw error;
    }
  }

  public async saveActionGarmentSubCategory(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      // TODO: parse response or action ?
      await this.api.update(
        this.actionGarmentSubCategory.ID,
        this.actionGarmentSubCategory
      );
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getActionGarmentSubCategoryByIdAsync(id: number) {
    const { ui } = this.root.stores;
    this.setActionGarmentSubCategory(new ActionGarmentSubCategory());
    ui.setLoading(true);
    try {
      const ACTION = await this.api.fetchOne(id);
      this.setActionGarmentSubCategory(ACTION);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public getActionGarmentSubCategoryByActionIDGarmentSubCategoryID(
    actionID: number,
    garmetSubCategoryID: number
  ): ActionGarmentSubCategory {
    let agsc:
      | ActionGarmentSubCategory
      | undefined = this.actionGarmentSubCategories.find(
      (agsc) =>
        agsc.ActionID === actionID &&
        agsc.GarmentSubCategoryID === garmetSubCategoryID
    );
    if (!agsc) {
      return <ActionGarmentSubCategory>{
        ID: 0,
        ActionID: actionID,
        GarmentSubCategoryID: garmetSubCategoryID,
      };
    } else {
      return agsc;
    }
  }

  public getActionGarmentSubCategoryById(
    id: number
  ): ActionGarmentSubCategory | undefined {
    return this.actionGarmentSubCategories.find((a) => a.ID === id);
  }

  public async deleteActionGarmentSubCategory(id: number): Promise<boolean> {
    if (this.actionGarmentSubCategory.ID === 0) {
      return false;
    }
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.delete(id);
      if (resp.success) {
        runInAction(() => {
          const actionGarmentSubCategoriesToFilter = this
            .actionGarmentSubCategories;
          this.actionGarmentSubCategories = actionGarmentSubCategoriesToFilter.filter(
            (a) => a.ID !== id
          );
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  // TODO: refactor to service ?
  // @action("filter action")
  // public filterActions(q: string) {
  //   if (q.trim() === "") {
  //     this.actionGarmentSubCategories = this._actionGarmentSubCategories;
  //     return;
  //   }

  //   const filterOptions: any = {
  //     threshold: 0,
  //     distance: 10,
  //     keys: ["Name"],
  //   };
  //   const fuse = new Fuse(this._actionGarmentSubCategories, filterOptions);
  //   this.actionGarmentSubCategories = fuse.search(q).map((a) => a.item);
  // }

  @action
  public resetActionGarmentSubCategory() {
    this.actionGarmentSubCategory = new ActionGarmentSubCategory();
  }

  @action
  public resetActionGarmentSubCategories() {
    this.actionGarmentSubCategories = [];
  }

  @action
  public setActionGarmentSubCategory(
    actionGarmentSubCategory: ActionGarmentSubCategory
  ) {
    this.actionGarmentSubCategory = actionGarmentSubCategory;
  }

  @action
  public setActionGarmentSubCategoryActionID(actionID: number) {
    this.actionGarmentSubCategory.ActionID = actionID;
  }

  @action
  public setActionGarmentSubCategoryGarmentSubCategoryID(
    garmentSubCategoryID: number
  ) {
    this.actionGarmentSubCategory.GarmentSubCategoryID = garmentSubCategoryID;
  }

  @action
  public setActionGarmentSubCategories(
    actionGarmentSubCategories: ActionGarmentSubCategory[],
    reload: boolean = false
  ) {
    // if (reload) {
    //   this._actionGarmentSubCategories = actionGarmentSubCategories;
    // }
    this.actionGarmentSubCategories = actionGarmentSubCategories;
  }
}

export default ActionGarmentSubCategoryStore;
