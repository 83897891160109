import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("BulkLogState")
class BulkLogState {
  @JsonProperty("state", String)
  State: string = "";

  @JsonProperty("ids", [Number])
  Ids: Number[] = [];

  @JsonProperty("signature", String, true)
  Signature: string | null = null;
}

export default BulkLogState;
