import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonAvatar,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Agent } from "../stores/models";
import { Header } from "../components";
import { business } from "ionicons/icons";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Agents extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    this.getAgentList();
  }

  getAgentList = () => {
    const { agents } = this.props.store.stores;
    agents.getAgents();
  };

  search = (q: string) => {
    const { agents } = this.props.store.stores;
    agents.filterAgents(q);
  };

  add = () => {
    this.props.history.push("/agents/0");
  };

  renderItem = (agent: Agent) => (
    <IonItem
      key={agent.ID}
      routerLink={"/agents/" + agent.ID}
      routerDirection="forward"
    >
      <IonAvatar slot="start" color="dark">
        <IonIcon icon={business} size="large" color="medium" />
      </IonAvatar>
      <IonLabel>
        <h2 style={{ textTransform: "capitalize" }}>{agent.Name}</h2>
        <IonGrid style={{ margin: 0, padding: 0 }}>
          <IonRow>
            <IonCol size="6">
              <IonRow>
                <IonCol size="auto">
                  <IonText>
                    <h2>{agent.UserName}</h2>
                  </IonText>
                </IonCol>
                <IonCol size="auto">
                  <IonText color="medium">
                    <h2>{agent.Landline}</h2>
                  </IonText>
                </IonCol>
                <IonCol size="auto">
                  <IonText color="medium">
                    <h2>{agent.Mobile}</h2>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="auto">
              <IonText color="medium">
                <h2>{agent.Address.Locality.Name}</h2>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );

  render() {
    const { agents } = this.props.store.stores.agents;
    return (
      <IonPage>
        <Header
          backURL="/home"
          title="Agents"
          onSearch={this.search}
          onAdd={this.add}
        />
        <IonContent>
          <IonList>
            {agents.slice(0, 50).map((agent: Agent) => {
              return this.renderItem(agent);
            })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Agents);
