import { RootStore } from "./RootStore";
import { PrintService } from "../services";
import { observable, action, runInAction } from "mobx";

interface PrintItem {
  id: string;
  description: string;
  data: any;
  hasError: boolean;
}

class PrintStore {
  private root: RootStore;
  private api: PrintService;

  // TODO: clean up this mess

  @observable
  public selectedPrinter: any;
  @observable
  public blDeviceList: any[] = [];
  @observable
  public usbDeviceList: any[] = [];
  @observable
  public rbtDeviceList: any[] = [];
  @observable
  public bleDeviceList: BluetoothDevice[] = [];
  @observable
  public defaultPort: string = "";
  @observable
  public defaultEncoding: string = "";

  private spool: PrintItem[] = [];

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.printer;
  }

  public requestUSBDevices() {
    const { ui } = this.root.stores;
    if (this.usbDeviceList.length > 0) {
      return;
    }
    try {
      navigator.usb.requestDevice({
        filters: [{}],
      });
    } catch (error) {
      ui.showToast({ color: "danger", message: error });
    }
  }

  @action
  public setDefaultPort(port: string) {
    this.defaultPort = port;
  }

  @action
  public setDefaultEncoding(encoding: string) {
    this.defaultEncoding = encoding;
  }

  @action
  public selectPrinter(macAddres: any) {
    this.selectedPrinter = macAddres;
  }

  @action
  public selectUSBPrinter(device: USBDevice) {
    this.selectedPrinter = device;
  }

  @action
  public selectSerialPrinter(device: any) {
    this.selectPrinter = device;
  }

  @action
  public resetList() {
    this.usbDeviceList = [];
    this.defaultEncoding = "";
    this.selectedPrinter = null;
  }

  public async searchForUSBPrinters() {
    this.resetList();
    const device = await this.api.searchUSBPrinter();
    runInAction(() => {
      this.usbDeviceList = [device];
      this.selectedPrinter = device as any;
      this.defaultEncoding = this.api.getUSBDeviceInfo(device)!.encoding;
    });
  }

  public async searchForBLEPrinters() {
    this.resetList();
    const device = await this.api.searchBLEPrinter();
    const info = await this.api.getBLEDeviceInfo(device);
    runInAction(() => {
      this.bleDeviceList = [device];
      this.selectedPrinter = device as any;
      this.defaultEncoding = info!.encoding;
    });
  }

  public searchForBlueToothPrinters() {
    runInAction(() => {
      this.blDeviceList = [];
    });
    this.api.searchBluetoothPrinter().then((resp: any) => {
      runInAction(() => {
        this.blDeviceList = resp;
      });
    });
  }

  public searchForRBT() {
    const printer = this.api.searchForRBT();
    runInAction(() => {
      this.selectedPrinter = printer.id;
      this.rbtDeviceList = [];
      this.rbtDeviceList.push(printer);
    });
  }

  private addToSpool() {}

  public async print(document: any): Promise<any> {
    const { ui } = this.root.stores;
    try {
      if (this.defaultPort === "u") {
        return this.api.sendToUsbPrinter(
          this.selectedPrinter as USBDevice,
          document
        );
      } else if (this.defaultPort === "b") {
        return this.api.sendToBluetoothPrinter(
          this.selectedPrinter as any,
          document
        );
      } else if (this.defaultPort === "ble") {
        return this.api.sendToBLEPrinter(
          this.selectedPrinter as BluetoothDevice,
          document
        );
      } else if (this.defaultPort === "rbt") {
        console.log("SENDING TO RBT");
        console.log(this.defaultEncoding);
        if (this.defaultEncoding === "ESC/POS") {
          var string = Buffer.from(document).toString("base64");
          this.api.sendToRBT(string, false);
        } else if (this.defaultEncoding === "PPLZ") {
          this.api.sendToRBT(document);
        }
      } else {
        throw new Error("Select Port");
      }
    } catch (err) {
      ui.showToast({ color: "danger", message: err });
    }
  }
}

export default PrintStore;
