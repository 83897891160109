import { JsonObject, JsonProperty } from "json2typescript";
import TicketSubCategory from "./TicketSubCategory";
import TicketLogStateHistory from "./TicketLogStateHistory";
import Agent from "./Agent";
import { Employee, Customer } from ".";
import moment from "moment";
import { Address } from "./Address";

@JsonObject("Ticket")
class Ticket {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("employee_id", Number)
  EmployeeID: number = 0;

  @JsonProperty("customer_id", Number)
  CustomerID: number = 0;

  @JsonProperty("agent", Agent, true)
  Agent: Agent = new Agent();

  @JsonProperty("employee", Employee, true)
  Employee: Employee = new Employee();

  @JsonProperty("customer", Customer, true)
  Customer: Customer = new Customer();

  @JsonProperty("pickup_date", String, true)
  PickupDate: string = "";

  @JsonProperty("delivery_date", String, true)
  DeliveryDate: string = "";

  @JsonProperty("pickup_comment", String, true)
  PickupComment: string = "";

  @JsonProperty("delivery_comment", String, true)
  DeliveryComment: string = "";

  @JsonProperty("delivery_to_client", Boolean)
  DeliveryToClient: boolean = false;

  @JsonProperty("pickup_from_client", Boolean)
  PickupFromClient: boolean = false;

  @JsonProperty("has_express_item", Boolean)
  HasExpressItem: boolean = false;

  @JsonProperty("state", String)
  State: string = "";

  @JsonProperty("total", Number)
  Total: number = 0.0;

  @JsonProperty("total_paid", Number)
  TotalPaid: number = 0.0;

  @JsonProperty("delivery_charge", Number)
  DeliveryCharge: number = 0.0;

  @JsonProperty("pickup_charge", Number)
  PickupCharge: number = 0.0;

  @JsonProperty("delivery_address_id", Number)
  DeliveryAddressID: number = 0;

  @JsonProperty("pickup_address_id", Number)
  PickupAddressID: number = 0;

  @JsonProperty("confirmed_at", String, true)
  ConfirmedAt: string | null = null;

  @JsonProperty("ticket_log_states", [TicketLogStateHistory], true)
  TicketLogStates: TicketLogStateHistory[] = [];

  @JsonProperty("ticket_sub_categories", [TicketSubCategory], true)
  SubCategories: TicketSubCategory[] = [];

  @JsonProperty("pickup_address", Address, true)
  PickupAddress: Address | null = null;

  @JsonProperty("delivery_address", Address, true)
  DeliveryAddress: Address | null = null;

  @JsonProperty("actions", [String])
  Actions: string[]|null = null;
  
  @JsonProperty("discount", String)
  Discount: string = "";

  isPrinted: boolean = false;

  public get getQRString(): string {
    const date = moment().format("YYYY-MM-DD");
    return `!T!T:${this.ID},IA:${this.Agent.Name},DI:${date},CN:${this.Customer.FullName}`;
  }
}

export default Ticket;
