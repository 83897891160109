import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { IonCol, IonRow, IonItem, IonLabel, IonCheckbox } from "@ionic/react";
import { runInAction } from "mobx";
import moment from "moment";
import DatePicker from "../DatePicker/DatePicker";
import { Ticket } from "../../stores/models";
import { RootStore } from "../../stores/RootStore";
import TextArea from "../TextArea/TextArea";

interface IPickupDeliveryOptions {
  ticket: Ticket;
  store: RootStore;
  saveAll: (b: boolean) => {};
}

const PickupDeliveryOptions: React.FC<IPickupDeliveryOptions> = (
  props: IPickupDeliveryOptions
) => {
  const [pickupTimer, setPickupTimer] = useState<any>();
  const [deliveryTimer, setDeliveryTimer] = useState<any>();

  return (
    <>
      <IonCol>
        <IonRow>
          <IonCol size="12">
            <IonItem>
              <IonLabel position="stacked">Pickup From Client</IonLabel>
              <IonCheckbox
                checked={props.ticket.PickupFromClient}
                onIonChange={(e) => {
                  runInAction(() => {
                    props.store!.stores.tickets.ticket.PickupFromClient =
                      e.detail.checked;
                    props.saveAll(false);
                  });
                }}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <TextArea
              rows={2}
              spellCheck={true}
              autoGrow={false}
              autoFocus={true}
              label="Pickup Comments"
              onChange={(v: string) => {
                props.ticket.PickupComment = v
                runInAction(()=>{
                  props.store!.stores.tickets.ticket.PickupComment = v
                })
                // clearTimeout(pickupTimer);
                // const t = setTimeout(function () {
                //   console.log("save pickup comments");
                // }, 1000);

                // runInAction(() => {
                //   setPickupTimer(t);
                // });
              }}
              value={props.ticket.PickupComment}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonItem>
              <IonLabel position="stacked">Deliver To Client</IonLabel>
              <IonCheckbox
                checked={props.ticket.DeliveryToClient}
                onIonChange={(e) => {
                  runInAction(() => {
                    props.store!.stores.tickets.ticket.DeliveryToClient =
                      e.detail.checked;
                    props.saveAll(false);
                  });
                }}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <TextArea
              rows={2}
              spellCheck={true}
              autoGrow={false}
              autoFocus={true}
              label="Delivery Comments"
              onChange={(v: string) => {
                props.ticket.DeliveryComment = v
                runInAction(()=>{
                  props.store!.stores.tickets.ticket.DeliveryComment = v
                })
                // clearTimeout(pickupTimer);
                // const t = setTimeout(function () {
                //   console.log("save delivery comments");
                // }, 1000);

                // runInAction(() => {
                //   setDeliveryTimer(t);
                // });
              }}
              value={props.ticket.DeliveryComment}
            />
          </IonCol>
        </IonRow>
      </IonCol>
    </>
  );
};

export default inject("store")(observer(PickupDeliveryOptions));
