import { RootStore } from "../../stores/RootStore";
import { inject } from "mobx-react";

import "./AgentPasswordReset.css";
import { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { key, checkbox } from "ionicons/icons";
import { TextField } from "..";

interface IAgentPasswordReset {
  store?: RootStore;
}

const AgentPasswordReset: React.FC<IAgentPasswordReset> = ({ store }) => {
  const [password, setPassword] = useState("");

  const generatePassword = () => {
    var length = 12,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setPassword(retVal);
  };

  const handleSubmit = async () => {
    const { agents, ui } = store!.stores;
    const { agent } = agents;
    const saved = await agents.setAgentPassword(agent.UserName, password);
    if (saved) {
      ui.showToast({ message: "Password set", color: "success" });
      setPassword("");
    } else {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  return (
    <IonGrid>
      <IonText color='medium'>
        <h5 style={{ padding: 15 }}>Security</h5>
      </IonText>
      <IonRow>
        <IonCol sizeXl='4' sizeLg='6' sizeXs='12'>
          <TextField
            type='text'
            placeholder='Password'
            value={password}
            onChange={(v: string) => {
              setPassword(v);
            }}
          />
        </IonCol>
        <IonCol sizeXl='2' sizeXs='6' sizeLg='3'>
          <IonButton color='tertiary' expand='full' onClick={generatePassword}>
            <IonIcon icon={key}></IonIcon>&nbsp;Generate
          </IonButton>
        </IonCol>
        <IonCol sizeXl='2' sizeXs='6' sizeLg='3'>
          <IonButton color='success' expand='full' onClick={handleSubmit}>
            <IonIcon icon={checkbox}></IonIcon>&nbsp;Set Password
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(AgentPasswordReset);
