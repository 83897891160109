import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Route, Redirect } from "react-router";

import Home from "../../pages/Home";
import Agents from "../../pages/Agents";
import AgentDetails from "../../pages/AgentDetails";
import Employees from "../../pages/Employees";
import EmployeeDetails from "../../pages/EmployeeDetails";
import Customers from "../../pages/Customers";
import CustomerDetails from "../../pages/CustomerDetails";
import Actions from "../../pages/Actions";
import ActionDetails from "../../pages/ActionDetails";
import TicketDetail from "../../pages/TicketDetail";
import Garments from "../../pages/Garments";
import GarmentDetails from "../../pages/GarmentDetails";
import GarmentSubCategoryDetails from "../../pages/GarmentSubCategoryDetails";
import TicketViewer from "../../pages/TicketViewer";
import Modifiers from "../../pages/Modifiers";
import ModifierDetails from "../../pages/ModifierDetails";
import ConfigurationDetails from "../../pages/ConfigurationDetails";
import ActionGarmentSubCategoryDetails from "../../pages/ActionGarmentSubCategoryDetails";
import Tracking from "../../pages/Tracking";
import Tickets from "../../pages/Tickets";
import {
  Configurations,
  ConfigurationPickupDeliveries,
  ConfigurationWorkLoad,
} from "../../pages/Configurations";
import Scheduler from "../../pages/Scheduler";
import GarmentSubCategories from "../../pages/GarmentSubCategories";
import Reporting from "../../pages/Reporting";
import Messaging from "../../pages/Messaging";
import Express from "../../pages/Express";
import StateLogs from "../../pages/StateLogs";

const Routes: React.FC = () => (
  <IonRouterOutlet id="main">
    {/* Home */}
    <Route path="/home" component={Home} exact />
    {/* Agents */}
    <Route path="/agents" component={Agents} exact />
    <Route path="/agents/:id" component={AgentDetails} exact />
    {/* Express */}
    <Route path="/express" component={Express} exact />
    {/* StateLogs */}
    <Route path="/state-logs" component={StateLogs} exact />
    {/* Employees */}
    <Route path="/employees" component={Employees} exact />
    <Route path="/employees/:id" component={EmployeeDetails} exact />
    {/* Customers */}
    <Route path="/customers" component={Customers} exact />
    <Route path="/customers/:id" component={CustomerDetails} exact />
    {/* Actions */}
    <Route path="/actions" component={Actions} exact />
    <Route path="/actions/:id" component={ActionDetails} exact />
    {/* Garments */}
    <Route path="/garments" component={Garments} exact />
    <Route path="/garments/:id" component={GarmentDetails} exact />
    <Route
      path="/garmentsSubCategory/:id"
      component={GarmentSubCategoryDetails}
      exact
    />
    {/* ActionGarmentsSubCategory */}
    <Route
      path="/actionGarmentSubCategory/:id"
      component={ActionGarmentSubCategoryDetails}
    />
    {/* Modifiers */}
    <Route path="/modifiers" component={Modifiers} exact />
    <Route path="/modifiers/:id" component={ModifierDetails} exact />

    <Route path="/actions" component={Actions} exact />
    <Route path="/actions/:id" component={ActionDetails} exact />
    {/* Tickets */}
    <Route
      path="/customers/:id/new-ticket/:ticketID"
      component={TicketDetail}
      exact
    />
    <Route path="/tickets" component={Tickets} exact />
    <Route path="/tickets/:id" component={TicketViewer} exact />

    {/*Tracking*/}
    <Route path="/tracking" component={Tracking} exact />
    <Route path="/tracking/:id" component={Tracking} exact />

    {/* Configuration */}
    <Route path="/configuration" component={Configurations} exact />
    <Route path="/configWorkLoad" component={ConfigurationWorkLoad} exact />
    <Route
      path="/configPickupDeliveries"
      component={ConfigurationPickupDeliveries}
      exact
    />
    <Route path="/configuration/:id" component={ConfigurationDetails} exact />
    <Route path="/quickgarments" component={GarmentSubCategories} exact />

    {/*Scheduling*/}
    <Route path="/scheduling" component={Scheduler} exact />

    {/*Reporting*/}
    <Route path="/reporting" component={Reporting} exact />

    {/*Messaging*/}
    <Route path="/messaging" component={Messaging} exact />

    {/*Home*/}
    <Redirect from="/" to="/home" exact />
  </IonRouterOutlet>
);

export default Routes;
