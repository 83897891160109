import {
  IonPage,
  IonItem,
  IonInput,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonContent,
} from "@ionic/react";

import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { observable, runInAction } from "mobx";
import logo from "./../assets/logo.png"

interface PageProps {
  store: RootStore;
}

@inject("store")
@observer
class Login extends React.Component<PageProps> {
  @observable username: string = "";
  @observable password: string = "";

  constructor(props: PageProps) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.loginForm = this.loginForm.bind(this);
  }

  componentDidMount() {
    this.setKeyPressListener();
  }
  setKeyPressListener() {
    document
      .getElementById("loginButton")
      ?.addEventListener("keypress", this.handleKeyPress, false);
  }

  handleKeyPress = (e: any) => {
    if (e.code === "Enter") {
      console.log("handle login");
      this.handleLogin();
    }
  };

  async handleLogin() {
    const { ui } = this.props.store.stores;
    if (this.username.trim() === "" || this.password.trim() === "") {
      ui.showToast({
        message: "Username & Password required",
        color: "danger",
      });
      return;
    }
    const { auth } = this.props.store.stores;
    const success = await auth.agentLogin(this.username, this.password);
    if (!success) {
      ui.showToast({
        message: "Wrong username/password",
        color: "danger",
      });
      return;
    }
  }

  loginForm() {
    return (
      <>
        <IonGrid id={"loginButton"}>
          <IonRow>
            <IonCol sizeXl="4" offsetXl="4">
              <img src={logo} style={{ padding: 15 }}/>
              <IonCard style={{ padding: 15 }}>
                <hr />
                <IonItem>
                  <IonInput
                    type="email"
                    required
                    value={this.username}
                    placeholder="Enter Username"
                    onIonChange={(e) =>
                      runInAction(() => {
                        this.username = e.detail.value!;
                      })
                    }
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonInput
                    type="password"
                    required
                    placeholder="Enter password"
                    value={this.password}
                    onIonChange={(e) =>
                      runInAction(() => {
                        this.password = e.detail.value!;
                      })
                    }
                  ></IonInput>
                </IonItem>
                <br />
                <br />

                <IonButton
                  size="large"
                  color="primary"
                  expand="full"
                  onClick={this.handleLogin}
                >
                  Login
                </IonButton>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <IonGrid>
            <IonRow
              className="ion-align-items-center"
              style={{ height: "100vh" }}
            >
              <IonCol>{this.loginForm()}</IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

export default Login;
