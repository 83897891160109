import { BaseService, IResponse } from "./BaseService";
import { Employee } from "../stores/models";

class EmployeeService extends BaseService {
  public async fetchAll(): Promise<Employee[]> {
    try {
      const resp = await this.http.get("/api/v1/employees/");
      return this.decodeArray<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async fetchById(id: number): Promise<Employee> {
    try {
      const resp = await this.http.get(`/api/v1/employees/${id}`);
      return this.decodeObject<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async create(employee: Employee): Promise<Employee> {
    try {
      const payload = this.encodeObject<Employee>(employee, Employee);
      delete payload["agent"];
      const resp = await this.http.post("/api/v1/employees/", payload);
      return this.decodeObject<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async update(id: number, employee: Employee): Promise<Employee> {
    try {
      const payload = this.encodeObject<Employee>(employee, Employee);
      delete payload["agent"];
      const resp = await this.http.put(`/api/v1/employees/${id}`, payload);
      return this.decodeObject<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async updateLocalityIDS(localityIDS: number[], employeeID:number): Promise<Employee> {
    try {
      // const payload = this.encodeArray<number[]>(localityIDS,);
      // delete payload["agent"];
      const resp = await this.http.put(`/api/v1/employees/localities/${employeeID}`, localityIDS);
      return this.decodeObject<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async clearOwings(employeeID: number): Promise<Employee> {
    try {
      const resp = await this.http.put(
        `/api/v1/employees/clearOwings/${employeeID}`
      );
      return this.decodeObject<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async clearOwingsAndClose(employeeID: number): Promise<Employee> {
    try {
      const resp = await this.http.put(
        `/api/v1/employees/clearOwings/${employeeID}?close=true`
      );
      return this.decodeObject<Employee>(resp, Employee);
    } catch (error) {
      throw error;
    }
  }

  public async delete(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/employees/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default EmployeeService;
