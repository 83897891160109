import React from "react";
import { IonLabel, IonItem, IonTextarea } from "@ionic/react";

interface ITextArea {
  label?: string;
  placeholder?: string;
  value?: string | number;
  onBlur?: Function;
  onChange?: Function;
  rows?: number;
  autoGrow?: boolean;
  autoFocus?: boolean;
  spellCheck?: boolean;
}

const TextArea: React.FC<ITextArea> = (props: ITextArea) => (
  <IonItem>
    {props.label !== undefined ? (
      <IonLabel color='medium' position='stacked'>
        {props.label}
      </IonLabel>
    ) : null}
    <IonTextarea
      rows={props.rows}
      autoGrow={props.autoGrow}
      autofocus={props.autoFocus}
      spellCheck={props.spellCheck}
      placeholder={props.placeholder}
      value={props.value?.toString()}
      onIonBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      onIonChange={(e) => {
        if (props.onChange) {
          props.onChange(e.detail.value!);
        }
      }}
    />
  </IonItem>
);

export default TextArea;
