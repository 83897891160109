import { JsonObject, JsonProperty } from "json2typescript";
import GarmentSubCategory from "./GarmentSubCategory";

@JsonObject("GarmentCategory")
class GarmentCategory {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("type", String)
  Type: string = "";

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("visible", Boolean)
  Visible: boolean = true;

  @JsonProperty("image", String, true)
  Image: string | null = null;

  @JsonProperty("order", Number)
  Order: number = 0;

  @JsonProperty("default_action_id", Number, true)
  DefaultActionID: number | null = null;

  @JsonProperty("sub_categories", [GarmentSubCategory], true)
  SubCategories: GarmentSubCategory[] = [];
}

export default GarmentCategory;
