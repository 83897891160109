import { RootStore } from "./RootStore";
import Statistics from "./models/Statistics";
import { observable, action, runInAction } from "mobx";
import { StatisticsService, ConfigurationService } from "../services";
import { ScheduleStatistic } from "./models";
import { ActionWorkLoadStatistics } from "./models/WorkLoadStatistic";

class StatisticsStore {
  private root: RootStore;
  private api: StatisticsService;
  private configurationService: ConfigurationService;

  @observable public statsThisWeek: Statistics[] = [];
  @observable public scheduleStatistics: ScheduleStatistic[] = [];
  @observable public actionWorkLoadStatistics: ActionWorkLoadStatistics[] = [];
  @observable public weekNumber: number = this.getNumberOfWeek(new Date());
  @observable public yearNumber: number = this.getNumberOfYear(new Date());
  @observable public scheduleDate: string | null = null;
  @observable public processFeedbackDate: string | null = null;
  @observable public processFeedbackAction: string | null = null;

  constructor(root: RootStore) {
    this.root = root;
    this.api = root.services.statistics;
    this.configurationService = root.services.configuration;
  }

  @action
  resetStatistics() {
    this.statsThisWeek = [];
    this.scheduleStatistics = [];
    this.actionWorkLoadStatistics = [];
  }

  public async getConfigurations() {
    const configurations = await this.configurationService.fetchAll();
    this.root.stores.configuration.setConfigurations(configurations);
  }

  public async getActions() {
    // Ask the store to populate its self, don't call it's service then populate it.
    // Same thing applies to the method above.
    /*
    const actions = await this.actionService.fetchAll();
    this.root.stores.actions.setActions(actions, false);
    */
    const { actions } = this.root.stores;
    await actions.getActions();
  }

  public async fetchStatsThisWeek() {
    try {
      const statsThisWeek = await this.api.fetchStatsThisWeek();
      this.setStatsThisWeek(statsThisWeek);
    } catch (e) {}
  }

  public getThisMonth(): string {
    const { configuration } = this.root.stores;
    const d = new Date();
    return configuration.months[d.getMonth()];
  }
  @action
  public setStatsThisWeek(statsThisWeek: Statistics[]) {
    this.statsThisWeek = statsThisWeek;
  }

  //ScheduleStats
  getNumberOfWeek(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear =
      (date.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    const dayOfWeek =  Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    console.log(dayOfWeek)
    return dayOfWeek - 1
  }

  getNumberOfYear(date: Date): number {
    return date.getFullYear();
  }

  public async getScheduleStatisticsByWeek(year: number, weekNumber: number) {
    const { ui } = this.root.stores;
    try {
      const scheduleStatistics = await this.configurationService.fetchScheduleStatisticsByWeek(
        year,
        weekNumber
      );
      runInAction(() => {
        this.scheduleStatistics = scheduleStatistics;
      });
    } catch (error) {
      console.log(error);
      // ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  }

  //ProcessStats

  public async getActionWorkLoadStatisticsByWeek(
    year: number,
    weekNumber: number
  ) {
    const { ui } = this.root.stores;
    try {
      const actionWorkLoadStatistics = await this.configurationService.fetchActionWorkLoadStatisticsByWeek(
        year,
        weekNumber
      );
      runInAction(() => {
        this.actionWorkLoadStatistics = actionWorkLoadStatistics;
      });
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  }
}

export default StatisticsStore;
