import React from "react";

import { IonGrid, IonText, IonRow, IonCol } from "@ionic/react";
import { TextField, SelectField } from "..";

import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import { IOption } from "../SelectField/SelectField";

interface ICustomerPreferences {
  store?: RootStore;
}

const CustomerPreferences: React.FC<ICustomerPreferences> = ({ store }) => {
  const { simpleCustomer } = store!.stores.customers;
  const { preferences, customerPreferences } = store!.stores.preferences;

  const setCustomerPrefs = (prefs: number[]) => {
    const { preferences } = store!.stores;
    preferences.setCustomerPreferences(simpleCustomer.ID, prefs);
  };

  const prefs: IOption[] = preferences.map((p) => {
    return { value: p.ID, label: p.Name };
  });
  const customerPrefs: number[] = customerPreferences.map(
    (cp) => cp.PreferenceID
  );

  return (
    <IonGrid>
      <IonText color="medium">
        <h6 style={{ paddingLeft: 15 }}>Preferences</h6>
      </IonText>
      <IonRow>
        <IonCol sizeLg="5" sizeXs="12">
          <TextField
            label="Comments"
            value={simpleCustomer.Comments}
            type="text"
            onChange={(v: string) => {
              simpleCustomer.Comments = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg="5" sizeXs="12">
          <SelectField
            multi={true}
            label="Preferences"
            value={customerPrefs}
            options={prefs}
            onChange={setCustomerPrefs}
          />
        </IonCol>
        <IonCol sizeLg="2" sizeXs="12">
          <TextField
            value={simpleCustomer.Discount}
            type="number"
            label="Discount"
            onChange={(v: string) => {
              simpleCustomer.Discount = parseFloat(v);
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(CustomerPreferences));
