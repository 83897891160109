import React, { useState } from "react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonIcon,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { checkmarkCircle, refresh } from "ionicons/icons";
import { runInAction } from "mobx";

interface IPrintSelection {
  store?: RootStore;
}

const PrinterSelect: React.FC<IPrintSelection> = ({ store }) => {
  const { printer } = store!.stores;
  const [port, setPort] = useState(printer.defaultPort);

  const selectPort = async (port: any) => {
    if (port === "u") {
      //printer.requestUSBDevices();
      printer.searchForUSBPrinters();
    }
    if (port === "b") {
      printer.searchForBlueToothPrinters();
    }
    if (port === "ble") {
      printer.searchForBLEPrinters();
    }
    if (port === "rbt") {
      printer.searchForRBT();
    }
    if (port === "pdf") {
      console.log("pdf selected");
      runInAction(() => {
        printer.selectedPrinter = undefined;
      });
    }
    //refresh();
    printer.setDefaultPort(port);
    setPort(port);
  };

  const refreshList = () => {
    if (port === "u") {
      //printer.requestDevices();
      printer.searchForUSBPrinters();
    }
    if (port === "b") {
      printer.searchForBlueToothPrinters();
    }
    if (port === "rbt") {
      printer.searchForRBT();
    }
    if (port === "ble") {
      printer.searchForBLEPrinters();
    }
    if (port === "pdf") {
      console.log("refresh pdf list");
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="primary"
              fill={port === "u" ? "outline" : undefined}
              onClick={() => selectPort("u")}
            >
              USB
            </IonButton>
            <IonButton
              color="primary"
              fill={port === "b" ? "outline" : undefined}
              onClick={() => selectPort("b")}
            >
              Bluetooth
            </IonButton>
            <IonButton
              color="primary"
              fill={port === "ble" ? "outline" : undefined}
              onClick={() => selectPort("ble")}
            >
              BLE
            </IonButton>
            <IonButton
              color="primary"
              fill={port === "rbt" ? "outline" : undefined}
              onClick={() => selectPort("rbt")}
            >
              RAWBT
            </IonButton>
            <IonButton
              color="primary"
              fill={port === "pdf" ? "outline" : undefined}
              onClick={() => selectPort("pdf")}
            >
              PDF
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={refreshList}>
              <IonIcon icon={refresh} color="primary" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {port === "ble" ? (
            <>
              {printer.bleDeviceList.map((d: any) => {
                return (
                  <IonItem
                    key={d.id}
                    onClick={() => {
                      printer.selectPrinter(d);
                    }}
                  >
                    <IonAvatar slot="start">
                      {d === printer.selectedPrinter ? (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="success"
                        />
                      ) : (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="light"
                        />
                      )}
                    </IonAvatar>
                    <IonLabel>
                      <h2>{d.name}</h2>
                      <p>{d.id}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          ) : null}
          {port === "b" ? (
            <>
              {printer.blDeviceList.map((d: any) => {
                return (
                  <IonItem
                    key={d.id}
                    onClick={() => {
                      printer.selectPrinter(d.id);
                    }}
                  >
                    <IonAvatar slot="start">
                      {d.id === printer.selectedPrinter ? (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="success"
                        />
                      ) : (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="light"
                        />
                      )}
                    </IonAvatar>
                    <IonLabel>
                      <h2>{d.name}</h2>
                      <p>{d.id}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          ) : null}
          {port === "rbt" ? (
            <>
              {printer.rbtDeviceList.map((d: any) => {
                return (
                  <IonItem
                    key={d.id}
                    onClick={() => {
                      printer.selectPrinter(d.id);
                    }}
                  >
                    <IonAvatar slot="start">
                      {d.id === printer.selectedPrinter ? (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="success"
                        />
                      ) : (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="light"
                        />
                      )}
                    </IonAvatar>
                    <IonLabel>
                      <h2>{d.name}</h2>
                      <p>{d.id}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          ) : null}
          {/* TODO: Refactor into single item */}
          {port === "u" ? (
            <>
              {printer.usbDeviceList.map((device: USBDevice) => {
                return (
                  <IonItem
                    key={device.serialNumber}
                    onClick={() => {
                      printer.selectUSBPrinter(device);
                    }}
                  >
                    <IonAvatar slot="start">
                      {device === printer.selectedPrinter ? (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="success"
                        />
                      ) : (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="light"
                        />
                      )}
                    </IonAvatar>
                    <IonLabel>
                      <h2>{device.productName}</h2>
                      <p>{device.manufacturerName}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          ) : null}
          {port === "pdf" ? (
            <IonRadioGroup
              value={printer.defaultEncoding}
              onIonChange={(e) => {
                printer.setDefaultEncoding(e.detail.value);
              }}
            >
              <IonListHeader>
                <IonLabel>Select option</IonLabel>
              </IonListHeader>

              <IonItem>
                <IonLabel>Download</IonLabel>
                <IonRadio slot="start" value="Download" />
              </IonItem>

              <IonItem>
                <IonLabel>Preview</IonLabel>
                <IonRadio slot="start" value="Preview" />
              </IonItem>
            </IonRadioGroup>
          ) : null}
          {printer.selectedPrinter ? (
            <IonRadioGroup
              value={printer.defaultEncoding}
              onIonChange={(e) => {
                printer.setDefaultEncoding(e.detail.value);
              }}
            >
              <IonListHeader>
                <IonLabel>Select encoding</IonLabel>
              </IonListHeader>

              <IonItem>
                <IonLabel>PPLZ</IonLabel>
                <IonRadio slot="start" value="PPLZ" />
              </IonItem>

              <IonItem>
                <IonLabel>PPLA</IonLabel>
                <IonRadio slot="start" value="PPLA" />
              </IonItem>

              <IonItem>
                <IonLabel>ESC/POS</IonLabel>
                <IonRadio slot="start" value="ESC/POS" />
              </IonItem>
            </IonRadioGroup>
          ) : null}
        </IonList>
      </IonContent>
    </>
  );
};

export default inject("store")(observer(PrinterSelect));
