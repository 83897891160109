import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("PickupDelivery")
class PickupDelivery {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("season", String)
  Season: string = "";

  @JsonProperty("from", String, true)
  From: string | null = null;

  @JsonProperty("to", String, true)
  To: string | null = null;

  @JsonProperty("day_of_week", Number)
  DayOfWeek: number = 0;

  @JsonProperty("quantity", Number)
  Quantity: number = 0;

  @JsonProperty("localities", String)
  Localities: string = "";
}

export default PickupDelivery;
