import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TicketSubCategoryAction")
class TicketSubCategoryAction {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("ticket_sub_category_id", Number)
  TicketSubCategoryID: number = 0;

  @JsonProperty("actionID", Number)
  ActionID: number = 0;

  @JsonProperty("quantity", Number)
  Quantity: number = 0;

  @JsonProperty("price", Number)
  Price: number = 0;

  @JsonProperty("images", String)
  Images: string = "";

  @JsonProperty("name", String)
  Name: string = "";
}

export default TicketSubCategoryAction;
