import { BaseService, IResponse } from "./BaseService";

export default class AuthService extends BaseService {
  public setAuthToken(name: "agent" | "employee", token: string): void {
    this.http.defaults.headers.common[name] = "token " + token;
  }

  public async agentLogin(user: string, pass: string): Promise<IResponse> {
    try {
      const resp = await this.http.post<IResponse>("/api/v1/login/agent", {
        user_name: user,
        password: pass,
      });
      const data: IResponse = resp.data;
      if (data.success && data.data) {
        this.setAuthToken("agent", data.data);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async agentLogout(): Promise<IResponse> {
    return this.http.get<IResponse>("/api/v1/logout/agent");
  }

  public async employeeLogin(pin: string) {
    try {
      const resp = await this.http.post<IResponse>("/api/v1/login/employee", {
        pin: parseInt(pin),
      });
      const data: IResponse = resp.data;
      if (data.success && data.data) {
        this.setAuthToken("employee", data.data);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
}
