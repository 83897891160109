import { BaseService, IResponse } from "./BaseService";
import { Modifier } from "../stores/models";

class ModifierService extends BaseService {
  public async fetchAll(): Promise<Modifier[]> {
    try {
      const resp = await this.http.get("/api/v1/modifiers/");
      return this.decodeArray<Modifier>(resp, Modifier);
    } catch (error) {
      throw error;
    }
  }
  public async fetchOne(id: number): Promise<Modifier> {
    try {
      const resp = await this.http.get(`/api/v1/modifiers/${id}`);
      return this.decodeObject<Modifier>(resp, Modifier);
    } catch (error) {
      throw error;
    }
  }

  public async reOrderModifiers(modifiers: Modifier[]): Promise<Modifier[]> {
    try {
      const mOrder: number[] = modifiers.map((m) => {
        return m.ID;
      });
      const payload = JSON.stringify(mOrder);
      return await this.http.put("/api/v1/modifiers/reOrder", payload);
    } catch (error) {
      throw error;
    }
  }

  public async create(m: Modifier): Promise<Modifier> {
    try {
      const payload = this.encodeObject<Modifier>(m, Modifier);
      const resp = await this.http.post("/api/v1/modifiers/", payload);
      return this.decodeObject<Modifier>(resp, Modifier);
    } catch (error) {
      throw error;
    }
  }

  public async update(id: number, m: Modifier): Promise<Modifier> {
    try {
      const payload = this.encodeObject<Modifier>(m, Modifier);
      const resp = await this.http.put(`/api/v1/modifiers/${id}`, payload);
      return this.decodeObject<Modifier>(resp, Modifier);
    } catch (error) {
      throw error;
    }
  }

  public async updateImage(id: number, image: string) {
    // TODO: implement
  }

  public async delete(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/modifiers/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default ModifierService;
