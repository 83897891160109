import { JsonObject, JsonProperty } from "json2typescript";
import Agent from "./Agent";
import Employee from "./Employee";

@JsonObject("TicketLogStateHistory")
class TicketLogStateHistory {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("ticket_id", Number)
  TicketID: number = 0;

  @JsonProperty("state", String)
  State: string = "";

  @JsonProperty("logged_at", String)
  LoggedAt: string = "";

  @JsonProperty("log", String, true)
  Log: string|undefined = "";

  @JsonProperty("employee_id", Number)
  EmployeeID: number = 0;

  @JsonProperty("signature", String, true)
  Signature: string|undefined = "";

  @JsonProperty("agent", Agent, true)
  Agent: Agent | null = null;

  @JsonProperty("employee", Employee, true)
  Employee: Employee | null = null;
}

export default TicketLogStateHistory;
