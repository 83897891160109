import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import "./MessageDropDown.css";
import { IonBadge, IonCol, IonRow } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import SignatureCanvas from "react-signature-canvas";
import { useRef } from "react";

interface IMessageDropDown {
  store: RootStore;
  mobile?: boolean;
  ticketID:number;
}

interface stringFunction {
  value: string;
  e: ()=>void;
}
const MessageDropDown: React.FC<IMessageDropDown> = (
  props: IMessageDropDown
) => {
  const elements: stringFunction[] = [
    {
      value: "Send Message Status",
      e: () => {
        props.store?.stores.messagingStore.sendMessageStatus(props.ticketID)
        console.log("send message status to the current user in question");
      },
    },
    // {
    //   value: "Ask Customer To Pickup",
    //   e: () => {
    //     console.log("send a message to the customer to come and pickup their items.");
    //   },
    // },
  ];
  const { ui } = props.store.stores;

  const openModal = () => {
    ui.showDialog({
      title: "Select a message",
      content: (
        <IonCol size="12">
          {elements.map((s) => {
            return (
              <IonRow>
                <IonBadge
                  className="messageItem"
                  color="primary"
                  onClick={s.e}
                >
                  {s.value}
                </IonBadge>
              </IonRow>
            );
          })}
        </IonCol>
      ),
    });
  };

  return (
    <>
      <IonBadge
        className="messageItem"
        onClick={() => {
          openModal();
        }}
        color="primary"
      >
        {props.mobile ? "Message Status" : "Message Status"}
      </IonBadge>
    </>
  );
};

export default inject("store")(observer(MessageDropDown));
