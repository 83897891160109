import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonThumbnail,
} from "@ionic/react";

import React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Header, TextField } from "../components";
import { Action, GarmentSubCategory } from "../stores/models";
import { runInAction } from "mobx";
import ActionGarmentSubCategory from "../stores/models/ActionGarmentSubCategory";
import config from "../services/api.config"

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class GarmentSubCategories extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    const { auth, garments } = this.props.store.stores;
    if (auth.role !== "admin") {
      this.props.history.push("/home");
      return;
    }
    this.getGarmentList();
    const gcs = garments.sortGarmentCategories();
    garments.setGarmentCategories(gcs);
    this.getDataFromAPI();
  }

  private async getDataFromAPI() {
    const { actions, actionGarmentSubCategories } = this.props.store.stores;
    await actions.getActions();
    await actionGarmentSubCategories.getActionGarmentSubCategories();
  }

  getGarmentList = () => {
    const { garments } = this.props.store.stores;
    garments.getGarmentGategories();
  };

  search = (q: string) => {
    const { garments } = this.props.store.stores;
    garments.filterGarmentSubCategories(q);
  };

  // Reference:
  // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
  private showFile(blob:any, title:string) {
    const newBlob: Blob = new Blob([ blob ], { type: 'application/pdf' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = title;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  private getGarmentHeaders(): JSX.Element {
    const { actions } = this.props.store.stores;
    return (
      <IonRow>
        <IonCol size="1" class="header1">
          <strong>Image</strong>
        </IonCol>
        <IonCol size="2" class="header1">
          {" "}
          <strong>Category</strong>
        </IonCol>
        <IonCol size="2" class="header1">
          {" "}
          <strong>Garment</strong>
        </IonCol>
        {actions.actions.map((a: Action) => {
          return (
            <IonCol
              style={{
                maxWidth: "calc(calc(1 / var(--ion-grid-columns, 12)) * 82%)",
              }}
              class="header"
            >
              <strong>
                {
                  a.Name
                  // .match(/\b(\w)/g)?.join("")
                }
              </strong>
            </IonCol>
          );
        })}
      </IonRow>
    );
  }

  private getGarmentSubCategories(): JSX.Element {
    const { garments } = this.props.store.stores;
    return (
      <IonContent>
        <IonGrid>
          {garments.garmentSubCategories
            .sort(this.byGarmentCategory(true))
            .slice()
            .map((gsc) => {
              return this.getGarmentSubCategory(gsc);
            })}
        </IonGrid>
      </IonContent>
    );
  }

  private getGarmentSubCategory(
    garmentSubCategory: GarmentSubCategory
  ): JSX.Element {
    const {
      actions,
      actionGarmentSubCategories,
      garments,
    } = this.props.store.stores;
    return (
      <IonRow>
        <IonCol size="1">
          {this.getGarmentSubCategoryImage(garmentSubCategory)}
        </IonCol>
        <IonCol size="2">
          <TextField type="text" value={
            garments.garmentCategories.find(
              (gc) => gc.ID === garmentSubCategory.GarmentCategoryID
            )?.Name
          }
          disabled
           />
          
        </IonCol>
        <IonCol size="2">
          <TextField
            type="text"
            value={garmentSubCategory.Name}
            onChange={(v: string) => {
              runInAction(() => {
                garments.garmentSubCategory = garmentSubCategory;
                if (v !== garments.garmentSubCategory.Name) {
                  garments.garmentSubCategory.Name = v;
                  garments.saveGarmentSubCategory();
                }
              });
            }}
          />
        </IonCol>
        {actions.actions.map((a: Action) => {
          // find the action garment  sub  category
          let value = 0;
          let prefix = <>&euro;</>;
          const actionGarmentSubCategory = actionGarmentSubCategories.actionGarmentSubCategories.find(
            (agsc) => {
              return (
                agsc.ActionID === a.ID &&
                agsc.GarmentSubCategoryID === garmentSubCategory.ID
              );
            }
          );
          if (actionGarmentSubCategory) {
            value = actionGarmentSubCategory.Price;
            prefix = actionGarmentSubCategory.IsPercentageOf
              ? (prefix = <>&perc;</>)
              : (prefix = prefix);
          }
          return (
            <IonCol
              // size="1"
              style={{
                maxWidth: "calc(calc(1 / var(--ion-grid-columns, 12)) * 82%)",
              }}
            >
              {" "}
              <TextField
                type="number"
                value={value.toFixed(2)}
                prefix={prefix}
                onChange={(v: string) => {
                  // runInAction(() => {
                  const price = parseFloat(parseFloat(v).toFixed(2));
                  if (value === price) {
                    return;
                  }
                  if (actionGarmentSubCategory) {
                    this.handleSaveActionGarmentSubCategory(
                      actionGarmentSubCategory,
                      price
                    );
                  } else {
                    let actionGarmentSubCategory = new ActionGarmentSubCategory();
                    actionGarmentSubCategory.ActionID = a.ID;
                    actionGarmentSubCategory.Price = price;
                    actionGarmentSubCategory.GarmentSubCategoryID =
                      garmentSubCategory.ID;
                    this.handleSaveActionGarmentSubCategory(
                      actionGarmentSubCategory,
                      price
                    );
                  }
                  // });
                }}
              />
            </IonCol>
          );
        })}
      </IonRow>
    );
  }

  byGarmentCategory(ascending: boolean) {
    return function (a: GarmentSubCategory, b: GarmentSubCategory) {
      // equal items sort equally
      if (a.GarmentCategoryID === b.GarmentCategoryID) {
        return 0;
      }
      // if we're ascending, lowest sorts first
      else if (ascending) {
        return a.GarmentCategoryID! < b.GarmentCategoryID! ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return a.GarmentCategoryID! < b.GarmentCategoryID! ? 1 : -1;
      }
    };
  }

  private handleSaveActionGarmentSubCategory(
    actionGarmentSubCategory: ActionGarmentSubCategory,
    price: number
  ) {
    const { actionGarmentSubCategories } = this.props.store.stores;
    actionGarmentSubCategory.Price = price;
    if (actionGarmentSubCategory.ID === 0) {
      //create
      runInAction(() => {
        actionGarmentSubCategories.actionGarmentSubCategory = actionGarmentSubCategory;
        actionGarmentSubCategories.createActionGarmentSubCategory();
      });
    } else {
      // update
      runInAction(() => {
        actionGarmentSubCategories.actionGarmentSubCategory = actionGarmentSubCategory;
        actionGarmentSubCategories.updateActionGarmentSubCategory();
      });
    }
  }

  private getGarmentSubCategoryImage(
    garmentSubCategory: GarmentSubCategory
  ): JSX.Element {
    const htmlFor = "imageUploader" + garmentSubCategory.Name;
    return (
      <IonThumbnail>
        <label htmlFor={htmlFor} style={{ cursor: `pointer` }}>
          <img
            style={{ height: "100%" }}
            src={garmentSubCategory.Image ? garmentSubCategory.Image : ""}
            alt={"image"}
          />
        </label>
        <input
          type="file"
          accept="image/*"
          capture="camera"
          id={htmlFor}
          style={{ display: `none` }}
          onChange={(e) => {
            const { garments } = this.props.store.stores;
            runInAction(() => {
              garments.garmentSubCategory = garmentSubCategory;
            });
            this.handleImageUpload(e);
            // garments.saveGarmentSubCategory();
          }}
        />
      </IonThumbnail>
    );
  }

  handleImageUpload = (e: any) => {
    const { garments } = this.props.store.stores;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64Image: string | undefined = reader.result?.toString();
      runInAction(() => {
        garments.garmentSubCategory.Image = base64Image ? base64Image : "";
        garments.saveGarmentSubCategory();
      });
      // garments.setImageGarmentSubCategory(base64Image ? base64Image : "");
    };
    reader.onerror = function () {};
  };

  render() {
    const { auth, garments, ui } = this.props.store.stores;
    if (auth.role !== "admin") {
      //TODO display message of un-authorization
      return <Redirect to="/home" exact />;
    }
    return (
      <IonPage>
        <Header
        showPrintScreen
        onPrintScreen={()=>{
          window.open(`${config.baseURL}/api/v1/garmentSubCategories/priceList?Employee=${auth.eToken}&Agent=${auth.aToken}&preview=true`)
          // garments.fetchGarmentsPriceList()
          // .then((data) => {
          //   this.showFile(data, 'garmentsPriceList.pdf');
          // })
          // .catch((err) => {
          // });
        }}
          backURL="/configuration"
          title="Garments"
          onSearch={this.search}
        />
        <IonHeader>{this.getGarmentHeaders()}</IonHeader>
        {this.getGarmentSubCategories()}
      </IonPage>
    );
  }
}

export default withRouter(GarmentSubCategories);
