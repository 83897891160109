import React from "react";
import { inject, observer } from "mobx-react";
import "./SchedulerComponent.css";
import {
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonLabel,
  IonButton,
  IonIcon,
  isPlatform,
} from "@ionic/react";
import { ScheduleStatistic, Statistics } from "../../stores/models";
import moment from "moment";
import { caretBack, caretForward } from "ionicons/icons";

interface ISchedulerComponentProps {
  title: string;
  data: ScheduleStatistic[];
  forwardWeekFunction: Function;
  backWeekFunction: Function;
  dateFunction: Function;
  weekFunction: Function;
}

const SchedulerComponent: React.FC<ISchedulerComponentProps> = (
  props: ISchedulerComponentProps
) => {
  const daysOfTheWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const statistic = (statistic: ScheduleStatistic): JSX.Element => {
    const statisticItem = (
      <IonItem
        onClick={() => {
          props.dateFunction(statistic.Date);
        }}
      >
        <IonLabel position={"stacked"}>
          {moment(statistic.Date).format("DD-MMM-YY")}
        </IonLabel>
        <IonRow>{daysOfTheWeek[statistic.DayOfWeek]}</IonRow>
        <IonRow>
          {statistic.Capacity}(
          {statistic.AssignedDeliveries + statistic.AssignedPickups})
        </IonRow>
      </IonItem>
    );
    const statisticItemMobile = (
      <div
        onClick={() => {
          props.dateFunction(statistic.Date);
        }}
      >
        <IonLabel position={"stacked"} style={{ fontSize: "10px" }}>
          {moment(statistic.Date).format("MMM")}
        </IonLabel>
        <IonLabel position={"stacked"}>
          {moment(statistic.Date).format("DD")}
        </IonLabel>
        <IonRow>{daysOfTheWeek[statistic.DayOfWeek].substring(0, 1)}</IonRow>
        <IonRow>
          {statistic.AssignedDeliveries + statistic.AssignedPickups}
        </IonRow>
      </div>
    );
    return isPlatform("desktop") ? statisticItem : statisticItemMobile;
  };

  return (
    <IonItem>
      <IonGrid fixed={true}>
        <IonRow>
          <IonCol>
            <h2 style={{ textTransform: "capitalize" }}>{props.title}</h2>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol sizeXs="2">
            <IonButton
              onClick={() => {
                props.backWeekFunction();
              }}
            >
              <IonIcon icon={caretBack} />
            </IonButton>
          </IonCol>
          {props.data.map((scheduleStatistic: ScheduleStatistic) => {
            return (
              <IonCol key={scheduleStatistic.DayOfWeek}>
                {statistic(scheduleStatistic)}
              </IonCol>
            );
          })}
          <IonCol sizeXs="2">
            <IonButton
              onClick={() => {
                props.forwardWeekFunction();
              }}
            >
              <IonIcon icon={caretForward} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default inject("store")(observer(SchedulerComponent));
