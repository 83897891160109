import React, { useState, useEffect } from "react";

import "./Address.css";
import { Locality, Street } from "../../stores/models";
import { IonRow, IonText, IonCol, IonGrid } from "@ionic/react";
import { SelectField, TextField } from "..";
import { IOption } from "../SelectField/SelectField";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";

interface IAddress {
  title?: string;
  store?: RootStore;
}

const Address: React.FC<IAddress> = ({ store, title }) => {
  const [, setStreetID] = useState(0);

  const { addresses } = store!.stores;
  const { localities, streets, address } = addresses;
  const {simpleCustomer} = store!.stores.customers

  useEffect(() => {
    setStreetID(address.StreetID);
  });

  let _localities: IOption[] = [];
  let _streets: IOption[] = [];

  _localities = localities.map((l: Locality) => {
    return { label: l.Name, value: l.ID };
  });

  _streets = streets.map((s: Street) => {
    return { label: s.Name, value: s.ID };
  });

  const handleLocalityChange = (localityID: number) => {
    if (localityID !== address.LocalityID) {
      addresses.getStreets(localityID);
    }
    runInAction(()=>{
      simpleCustomer.AddressLocalityID = localityID
    })
    address.LocalityID = localityID;
  };

  const handleStreetChange = (streetID: number) => {
    address.StreetID = streetID;
    // TODO: remove the need to manually refresh state
    runInAction(()=>{
      simpleCustomer.AddressStreetID = streetID
    })
    setStreetID(streetID);
  };

  const handleNameChange = (name: string) => {
    address.Name = name;
    simpleCustomer.AddressName = name
  };

  const handleNumberChange = (number: string) => {
    address.Number = number;
    simpleCustomer.AddressNumber = number
  };

  return (
    <IonGrid>
      <IonText color="medium">
        <h6 style={{ paddingLeft: 15 }}>
          {title !== undefined ? title : "Address"}
        </h6>
      </IonText>
      <IonRow>
        <IonCol sizeLg="3" sizeXs="12">
          <SelectField
            label="Locality"
            searchable={true}
            options={_localities}
            onChange={handleLocalityChange}
            value={simpleCustomer.AddressLocalityID}
          />
        </IonCol>
        <IonCol sizeLg="4" sizeXs="12">
          <SelectField
            label="Street"
            searchable={true}
            onChange={handleStreetChange}
            options={_streets}
            value={simpleCustomer.AddressStreetID}
          />
        </IonCol>
        <IonCol sizeLg="2" sizeXs="12">
          <TextField
            type="text"
            label="Number"
            value={simpleCustomer.AddressNumber!}
            onChange={handleNumberChange}
          />
        </IonCol>
        <IonCol sizeLg="3" sizeXs="12">
          <TextField
            type="text"
            label="Name"
            value={simpleCustomer.AddressName!}
            onChange={handleNameChange}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export type { IAddress };
export default inject("store")(observer(Address));
