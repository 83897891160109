import { MessagingService } from "../services";
import { RootStore } from "./RootStore";

class GarmentStore {
  private root: RootStore;
  private api: MessagingService;

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.messagingService;
  }

  public async sendMessageStatus(ticketID:number):Promise<void>{
    console.log('instore')
    const { ui } = this.root.stores;
    ui.setLoading(true);
    const { customers } = this.root.services;
    try {
      const ok = await this.api.sendStatusMessage(ticketID)
      if (ok === true){
        ui.showToast({ message: "message on its way", color: "success" });
      }
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }
}
export default GarmentStore;
