import React, { useState } from "react";
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonList,
  IonText,
  IonSearchbar,
  IonIcon,
  IonInput,
  IonModal,
  IonContent,
  IonHeader,
  IonButtons,
  IonButton,
  IonToolbar,
} from "@ionic/react";

import Fuse from "fuse.js";

import "./SelectField.css";
import { checkmarkDoneOutline, close } from "ionicons/icons";
import _ from "lodash";

type Interfaces = "action-sheet" | "popover" | "alert" | undefined;

interface ISelectField {
  label?: string;
  options: IOption[];
  searchable?: boolean;
  multi?: boolean;
  multiSearch?: boolean;
  value?: any;
  interface?: Interfaces;
  onChange: Function;
  selected?: IOption[];
  onSearch?: Function;
  selectAll?: Function;
}

interface IOption {
  label: string;
  value: string | number;
}

const SearchableSelectField: React.FC<ISelectField> = (props: ISelectField) => {
  const [showPopover, setShowPopover] = useState(false);
  const [preSelected, setPreSelected] = useState(0);
  const [filter, setFilter] = useState("");
  const filterOptions: any = {
    threshold: 0.3,
    keys: ["label"],
  };

  const fuse = new Fuse(props.options, filterOptions);
  let options: IOption[] = props.options;
  if (filter.trim() !== "") {
    options = fuse.search(filter).map((r) => r.item);
  }

  const handleSelect = (selectedOption: IOption) => {
    setShowPopover(false);
    props.onChange(selectedOption.value);
  };

  const selected = (
    value: number | string | undefined
  ): IOption | undefined => {
    if (value === undefined) {
      return undefined;
    }
    return props.options.find((o: IOption) => o.value === value);
  };

  const quickSelect = (e: any) => {
    if (e.key === "Enter" && options.length > 0 && filter.trim() !== "") {
      handleSelect(options[preSelected]);
    } else if (
      e.key === "ArrowDown" &&
      filter.trim() !== "" &&
      options.length > 0 &&
      preSelected < options.length - 1
    ) {
      setPreSelected(preSelected + 1);
    } else if (
      e.key === "ArrowUp" &&
      options.length > 0 &&
      preSelected > 0 &&
      filter.trim() !== ""
    ) {
      setPreSelected(preSelected - 1);
    }
  };

  const handleSearch = (e: any) => {
    setPreSelected(0);
    setFilter(e.detail.value!);
  };

  const expand = () => {
    setShowPopover(true);
    setFilter("");
    setPreSelected(0);
  };

  const modalHeader = () => (
    <IonHeader>
      <IonToolbar>
        <IonSearchbar onKeyDown={quickSelect} onIonChange={handleSearch} />
        <IonButtons slot="end">
          <IonButton onClick={() => setShowPopover(false)}>
            <IonIcon icon={close} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );

  const item = (o: IOption, i: number) => (
    <IonItem
      color={preSelected === i ? "primary" : ""}
      className="search-select-item"
      key={o.value}
      onClick={() => {
        handleSelect(o);
      }}
    >
      <IonText className="search-select-item-label">{o.label}</IonText>
    </IonItem>
  );

  const searchModal = () => (
    <IonModal isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
      {modalHeader()}
      <IonContent>
        <IonList>
          {options.map((o: IOption, i: number) => {
            return item(o, i);
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );

  return (
    <IonItem
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (showPopover) {
          return;
        }
        expand();
      }}
    >
      {props.label !== undefined ? (
        <IonLabel position="stacked" color="medium">
          {props.label}
        </IonLabel>
      ) : null}
      <IonInput
        value={selected(props.value)?.label}
        readonly
        onFocus={() => expand()}
      />
      {searchModal()}
    </IonItem>
  );
};

class SimpleSelectField extends React.Component<ISelectField> {
  handleChange = (e: any) => {
    this.props.onChange(e.detail.value);
  };

  shouldComponentUpdate(nextProps: ISelectField, nextState: any) {
    // for some reason onIonChange was being called on render when value changes
    // resultin in a observable loop, shouldComponentUpdate isChecking that values
    // actually change before re-rendering

    return (
      !_.isEqual(this.props.value, nextProps.value) ||
      !_.isEqual(this.props.options, nextProps.options)
    );
  }

  options(): React.ReactFragment[] {
    return this.props.options.map((o: IOption, i: number) => {
      return (
        <IonSelectOption key={i} value={o.value}>
          {o.label}
        </IonSelectOption>
      );
    });
  }

  render() {
    return (
      <IonItem>
        {this.props.label !== undefined ? (
          <IonLabel color="medium" position="stacked">
            {this.props.label}
          </IonLabel>
        ) : null}
        <IonSelect
          interface={this.props.interface}
          value={this.props.value}
          multiple={this.props.multi}
          onIonChange={this.handleChange}
        >
          {this.options()}
        </IonSelect>
      </IonItem>
    );
  }
}

const SearchableMultiSelectField: React.FC<ISelectField> = (
  props: ISelectField
) => {
  const [showPopover, setShowPopover] = useState(false);
  const [preSelected, setPreSelected] = useState(0);
  const [filter, setFilter] = useState("");
  const filterOptions: any = {
    threshold: 0.3,
    keys: ["label"],
  };

  const fuse = new Fuse(props.options, filterOptions);
  let options: IOption[] = props.options;
  if (filter.trim() !== "") {
    options = fuse.search(filter).map((r) => r.item);
  }

  const handleSelect = (selectedOption: IOption) => {
    // setShowPopover(false);
    props.onChange(selectedOption.value);
  };

  const selectAllOptions = () => {
    if (props.selectAll) {
      props.selectAll();
    }
  };

  const selected = (
    value: number | string | undefined
  ): IOption | undefined => {
    if (value === undefined) {
      return undefined;
    }
    return props.options.find((o: IOption) => o.value === value);
  };

  const quickSelect = (e: any) => {
    if (e.key === "Enter" && options.length > 0 && filter.trim() !== "") {
      handleSelect(options[preSelected]);
    } else if (
      e.key === "ArrowDown" &&
      filter.trim() !== "" &&
      options.length > 0 &&
      preSelected < options.length - 1
    ) {
      setPreSelected(preSelected + 1);
    } else if (
      e.key === "ArrowUp" &&
      options.length > 0 &&
      preSelected > 0 &&
      filter.trim() !== ""
    ) {
      setPreSelected(preSelected - 1);
    }
  };

  const handleSearch = (e: any) => {
    if (props.onSearch !== undefined) {
      props.onSearch(e.detail.value);
    }
    setPreSelected(0);
    setFilter(e.detail.value!);
  };

  const expand = () => {
    setShowPopover(true);
    setFilter("");
    setPreSelected(0);
  };

  const modalHeader = () => (
    <IonHeader>
      <IonToolbar>
        <IonSearchbar onKeyDown={quickSelect} onIonChange={handleSearch} />
        <IonButtons slot="end">
          <IonButton onClick={() => setShowPopover(false)}>
            <IonIcon icon={close} />
          </IonButton>
          {props.selectAll ? (
            <IonButton onClick={selectAllOptions}>
              <IonIcon icon={checkmarkDoneOutline} />
            </IonButton>
          ) : (
            <></>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );

  const item = (o: IOption, i: number, preselected: boolean) => (
    <IonItem
      color={preselected === true ? "primary" : ""}
      className="search-select-item"
      key={o.value}
      onClick={() => {
        handleSelect(o);
      }}
    >
      <IonText className="search-select-item-label">{o.label}</IonText>
    </IonItem>
  );

  const searchModal = () => (
    <IonModal isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
      {modalHeader()}
      <IonContent>
        <IonList>
          {options.map((o: IOption, i: number) => {
            let found = false;
            props.selected?.forEach((option) => {
              if (option.value === o.value) {
                found = true;
              }
            });
            return item(o, i, found);
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
  console.log(props.selected);
  return (
    <IonItem
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (showPopover) {
          return;
        }
        expand();
      }}
    >
      {props.label !== undefined ? (
        <IonLabel position="stacked" color="medium">
          {props.label}
        </IonLabel>
      ) : null}
      <IonInput
        value={props.selected
          ?.map((s) => {
            return s.label;
          })
          .join(", ")}
        // value={selected(props.value)?.label}
        readonly
        onFocus={() => expand()}
      />
      {searchModal()}
    </IonItem>
  );
};

const SelectField: React.FC<ISelectField> = (props: ISelectField) => {
  if (props.multiSearch) {
    return <SearchableMultiSelectField {...props} />;
  }
  if (props.searchable) {
    return <SearchableSelectField {...props} />;
  }
  return <SimpleSelectField {...props} />;
};

export default SelectField;
export type { IOption, ISelectField };
