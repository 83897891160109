import { BaseService, IResponse } from "./BaseService";
import { Preference, CustomerPreference } from "../stores/models";

class PreferencesService extends BaseService {
  public async fetchAll(): Promise<Preference[]> {
    try {
      const resp = await this.http.get("/api/v1/preferences/");
      return this.decodeArray<Preference>(resp, Preference);
    } catch (error) {
      throw error;
    }
  }

  public async fetchByCustomerId(id: number): Promise<CustomerPreference[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/customerPreferences/customers/${id}`
      );
      return this.decodeArray<CustomerPreference>(resp, CustomerPreference);
    } catch (error) {
      throw error;
    }
  }

  public async saveForCustomer(id: number, prefs: number[]): Promise<Boolean> {
    try {
      const resp = await this.http.post("/api/v1/customerPreferences/bulk", {
        customer_id: id,
        preferences: prefs,
      });
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }
}

export default PreferencesService;
