import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";

import { RootStore } from "../stores/RootStore";

import { Header, TextField } from "../components";

interface ActionGarmentSubCategoryDetailsProps {
  store: RootStore;
}

interface RouteProps {
  id: string;
  action: string;
}

@inject("store")
@observer
class ActionGarmentSubCategoryDetails extends React.Component<
  ActionGarmentSubCategoryDetailsProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    this.init();
  }

  init() {
    const {
      actions,
      garments,
      actionGarmentSubCategories,
    } = this.props.store.stores;
    const id = parseInt(this.props.match.params.id);
    this.getActionGarmentSubCategory(id);
    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const actionID = parseInt(params.get("action")!);
    const subGarmentID = parseInt(params.get("subGarment")!);
    actions.getActionByIdAsync(actionID);
    garments.getGarmentSubCategoryByID(subGarmentID);
    actionGarmentSubCategories.setActionGarmentSubCategoryActionID(actionID);
    actionGarmentSubCategories.setActionGarmentSubCategoryGarmentSubCategoryID(
      subGarmentID
    );
    console.log(actionGarmentSubCategories);
    actions.getActions();
  }

  getActionGarmentSubCategory(id: number) {
    const { actionGarmentSubCategories } = this.props.store.stores;
    if (actionGarmentSubCategories.actionGarmentSubCategory.ID === id) return;
    if (id === 0) {
      this.reset();
      return;
    }
    actionGarmentSubCategories.getActionGarmentSubCategoryByIdAsync(id);
  }

  reset() {
    const { actionGarmentSubCategories } = this.props.store.stores;
    actionGarmentSubCategories.resetActionGarmentSubCategory();
  }

  componentWillUnmount() {
    this.reset();
  }

  createActionGarmentSubCategory = async () => {
    const { actionGarmentSubCategories, ui } = this.props.store.stores;
    try {
      const g = await actionGarmentSubCategories.createActionGarmentSubCategory();
      ui.showToast({ message: "Information Saved", color: "success" });
      actionGarmentSubCategories.getActionGarmentSubCategoryById(g.ID);
      this.props.history.replace(`/actionGarmentSubCategories/${g.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  updateActionGarmentSubCategory = async () => {
    const { actionGarmentSubCategories, ui } = this.props.store.stores;
    try {
      await actionGarmentSubCategories.saveActionGarmentSubCategory();
      actionGarmentSubCategories.getActionGarmentSubCategoryById(
        actionGarmentSubCategories.actionGarmentSubCategory.ID
      );
      ui.showToast({ message: "Information Saved", color: "success" });
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { actionGarmentSubCategories } = this.props.store.stores;
    if (actionGarmentSubCategories.actionGarmentSubCategory.ID === 0) {
      this.createActionGarmentSubCategory();
    } else {
      this.updateActionGarmentSubCategory();
    }
  };

  handleDelete = async (confirmed: boolean) => {
    const { actionGarmentSubCategories, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    if (actionGarmentSubCategories.actionGarmentSubCategory.ID === 0) return;
    try {
      const subCategory =
        actionGarmentSubCategories.actionGarmentSubCategory
          .GarmentSubCategoryID;
      const s = await actionGarmentSubCategories.deleteActionGarmentSubCategory(
        actionGarmentSubCategories.actionGarmentSubCategory.ID
      );
      if (s) {
        ui.showToast({
          message: "ActionGarmentSubCategory Removed",
          color: "success",
        });
        this.reset();
      }
      this.props.history.replace("/garmentsSubCategory/" + subCategory);
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleDeleteSubCategory = async (confirmed: boolean, id: number) => {
    const { actionGarmentSubCategories, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDeleteSubCategory(true, id);
        },
      });
      return;
    }
    const categoryID = parseInt(this.props.match.params.id);
    this.getActionGarmentSubCategory(categoryID);
    if (id === 0) return;
    try {
      const s = await actionGarmentSubCategories.deleteActionGarmentSubCategory(
        id
      );
      if (s) {
        ui.showToast({
          message: "ActionGarmentSubCategory Sub Category Removed",
          color: "success",
        });
      }
      this.props.history.replace(`/actionGarmentSubCategories/${categoryID}`);
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  body() {
    const {
      actionGarmentSubCategories,
      actions,
      garments,
    } = this.props.store.stores;
    const actionGarmentSubCategory =
      actionGarmentSubCategories.actionGarmentSubCategory;
    const action = actions.action;
    const garmentSubCategory = garments.garmentSubCategory;
    return (
      <>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol size={"auto"}>
                <TextField
                  type="text"
                  label="Action"
                  value={action.Name}
                  disabled={true}
                />
              </IonCol>
              <IonCol size={"auto"}>
                <TextField
                  type="text"
                  label="Garment"
                  value={garmentSubCategory.Name}
                  disabled={true}
                />
              </IonCol>
              <IonCol size={"1"}>
                <TextField
                  type="number"
                  label="Price"
                  onChange={(v: any) => {
                    actionGarmentSubCategory.Price = parseFloat(v);
                    console.log(
                      this.props.store.stores.actionGarmentSubCategories
                        .actionGarmentSubCategory
                    );
                  }}
                  value={actionGarmentSubCategory.Price}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {actionGarmentSubCategories.actionGarmentSubCategory.ID !== 0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }

  render() {
    const { actionGarmentSubCategories } = this.props.store.stores;
    const title =
      actionGarmentSubCategories.actionGarmentSubCategory.ID === 0
        ? "New Action Garment SubCategory"
        : "Action Garment Sub Category Details";
    return (
      <IonPage>
        <Header title={title} backURL="/actionGarmentSubCategories" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(ActionGarmentSubCategoryDetails);
