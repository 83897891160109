import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonAvatar,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Employee } from "../stores/models";
import { Header } from "../components";
import { personCircleOutline } from "ionicons/icons";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Employees extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  UNSAFE_componentWillReceiveProps() {
    this.getEmployeeList();
  }

  getEmployeeList = () => {
    const { employees } = this.props.store.stores;
    employees.getEmployees();
  };

  search = (q: string) => {
    const { employees } = this.props.store.stores;
    employees.filterEmployees(q);
  };

  add = () => {
    this.props.history.push("/employees/0");
  };

  renderItem = (employee: Employee) => (
    <IonItem
      key={employee.ID}
      routerLink={"/employees/" + employee.ID}
      routerDirection="forward"
    >
      <IonAvatar slot="start" color="dark">
        <IonIcon icon={personCircleOutline} size="large" color="medium" />
      </IonAvatar>
      <IonLabel>
        <h2 style={{ textTransform: "capitalize" }}>{employee.FullName}</h2>
        <IonGrid style={{ margin: 0, padding: 0 }}>
          <IonRow>
            <IonCol sizeLg="4" sizeXs="6">
              <IonText>
                <h2>{employee.Mobile}</h2>
              </IonText>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="6">
              <IonText>
                <h2 style={{ textAlign: "center" }}>{employee.Agent.Name}</h2>
              </IonText>
            </IonCol>
            <IonCol sizeLg="4" sizeXs="6">
              <IonText color="medium">
                <h2 style={{ textAlign: "right" }}>{employee.Role}</h2>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );

  render() {
    const { employees } = this.props.store.stores.employees;
    return (
      <IonPage>
        <Header
          backURL="/home"
          title="Employees"
          onSearch={this.search}
          onAdd={this.add}
        />
        <IonContent>
          <IonList>
            {employees.slice(0, 50).map((employee: Employee) => {
              return this.renderItem(employee);
            })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Employees);
