import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TicketSubCategoryModifier")
class TicketSubCategoryModifier {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("ticket_sub_category_id", Number)
  TicketSubCategoryID: number = 0;

  @JsonProperty("modifier_id", Number)
  ModifierID: number = 0;

  @JsonProperty("modifier_type", String)
  ModifierType: string = "";
}

export default TicketSubCategoryModifier;
