import { RootStore } from "../../stores/RootStore";
import { IonLoading } from "@ionic/react";
import React from "react";
import { inject, observer } from "mobx-react";

interface ILoader {
  store?: RootStore;
}

const Loader: React.FC<ILoader> = ({ store }) => {
  const { ui } = store!.stores;
  return (
    <IonLoading
      isOpen={ui.isLoading}
      message={"Please wait..."}
      duration={5000}
    />
  );
};

export default inject("store")(observer(Loader));
