import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonLabel,
  IonIcon,
  IonButton,
} from "@ionic/react";
import moment from "moment";
import { Ticket, TicketLogStateHistory } from "../../stores/models";
import { pencilSharp } from "ionicons/icons";
import StateDropDown from "../StateDropDown/StateDropDown";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { runInAction } from "mobx";
import TicketHistoryViewer from "../TicketHistoryViewer/TicketHistoryViewer";
import SimpleTicket from "../../stores/models/SimpleTicket";

interface ISimpleTicketListItem {
  ticket: SimpleTicket;
  store: RootStore;
}

const SimpleTicketListItem: React.FC<
  ISimpleTicketListItem & RouteComponentProps
> = ({ ticket, history, store }) => {
  const [state, setState] = React.useState(ticket.State);
  const { tickets, auth } = store.stores;
  const [balance, setbalance] = useState(ticket.Total - ticket.TotalPaid);
  // let balance = ticket.Total - ticket.TotalPaid
  return (
    <IonItem key={ticket.ID}>
      <IonGrid>
        <IonRow>
          <IonCol
            sizeLg="1"
            sizeXs="4"
            onClick={(e) => {
              history.push("/tickets/" + ticket.ID);
            }}
          >
            <IonBadge color="warning">#{ticket.ID}</IonBadge>
          </IonCol>
          <IonCol
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            sizeLg="2"
            sizeXs="4"
          >
            <StateDropDown
              store={store!}
              elements={store!.stores.tracking.states.map((s) => s)}
              selected={state}
              onSelect={async (s: string, sig: string) => {
                console.log(`item selected ${s}`);
                const customerID = store.stores.customers.customer.ID;
                const stateChanged =
                  await store?.stores.tracking.setTicketsState(
                    [ticket.ID],
                    s,
                    sig
                  );
                runInAction(() => {
                  setState(s);
                });
                console.log(stateChanged);
                if (stateChanged === true) {
                  console.log(customerID);
                  await store?.stores.tickets.getAllForCustomer(customerID);
                }
              }}
            />
          </IonCol>
          <IonCol sizeLg="2" sizeXs="4" className="show-xs">
            <IonLabel style={{ textAlign: "right" }}>
              &euro; {ticket.Total}
            </IonLabel>
          </IonCol>
          <IonCol sizeLg="2" className="hide-xs">
            <IonLabel style={{ fontSize: 14 }}>{ticket.AgentName}</IonLabel>
          </IonCol>
          <IonCol sizeLg="2" sizeXs="4" className="hide-xs">
            <IonLabel style={{ fontSize: 14 }}>
              Pickup: {moment(ticket.PickupDate).format("DD/MM/YYYY")}
            </IonLabel>
          </IonCol>
          <IonCol sizeLg="2" sizeXs="4" className="hide-xs">
            <IonLabel style={{ fontSize: 14 }}>
              Delivery:{" "}
              {moment(ticket.DeliveryDate).format("DD/MM/YYYY") ===
              "Invalid date"
                ? "N/A"
                : moment(ticket.DeliveryDate).format("DD/MM/YYYY")}
            </IonLabel>
          </IonCol>
          <IonCol sizeLg="2" sizeXs="4" className="hide-xs">
            <IonLabel style={{ textAlign: "right", fontSize: 14 }}>
              &euro; {ticket.Total.toFixed(2)}
            </IonLabel>
          </IonCol>
          <IonCol sizeLg="auto" sizeXs="3">
            <IonRow>
              <IonLabel
                className={"edit"}
                style={{ textAlign: "right", fontSize: 14, cursor: "pointer" }}
              >
                <IonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push(
                      `/customers/${ticket.CustomerID}/new-ticket/${ticket.ID}`
                    );
                  }}
                  icon={pencilSharp}
                  color="info"
                  size="small"
                />
              </IonLabel>
            </IonRow>
            <IonRow>
              <TicketHistoryViewer store={store} ticketID={ticket.ID} />
            </IonRow>
          </IonCol>
          <IonCol>
            <IonButton
              style={{ float: "right" }}
              color={balance === 0 ? "success" : "danger"}
              onClick={async () => {
                if (window.confirm("Are you sure!")) {
                  await ticket.SubCategoryIDS.split(",").forEach((tscid) => {
                    tickets.updatePaymentInFull(parseInt(tscid));
                  });
                  setbalance(0);
                }
              }}
            >
              {balance === 0 ? "PAID" : balance.toFixed(2)}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default inject("store")(withRouter(observer(SimpleTicketListItem)));
