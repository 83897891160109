import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import "./CustomSelect.css";
import { IonLabel, IonItem, IonSelect } from "@ionic/react";

type Interface = "action-sheet" | "popover" | "alert" | undefined;

interface ISelect {
  elements: (JSX.Element | undefined)[];
  title: string;
  value: string | number;
  onSelect: Function;
  interface?: Interface;
}

const CustomSelect: React.FC<ISelect> = (props: ISelect) => {
  return (
    <IonItem style={{ height: "56px" }}>
      <IonLabel>{props.title}</IonLabel>
      <IonSelect
        interface={props.interface}
        value={props.value}
        onIonChange={(e) => {
          // modifiers.setModifierType(e.detail.value);
          props.onSelect(e.detail.value);
        }}
      >
        {props.elements}
      </IonSelect>
    </IonItem>
  );
};

export default inject("store")(observer(CustomSelect));
