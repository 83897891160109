import { GarmentCategory } from "../../stores/models";
import {
  IonItem,
  IonLabel,
  IonImg,
  IonThumbnail,
  IonReorder,
} from "@ionic/react";
import React from "react";
import "./GarmentCategoryListItem.css";

interface GarmentCategoryListItemProps {
  garmentCategory: GarmentCategory;
  reOrder?: string;
}

const GarmentCategoryListItem: React.FC<GarmentCategoryListItemProps> = ({
  garmentCategory,
  reOrder,
}) => (
  <IonItem
    routerLink={"/garments/" + garmentCategory.ID}
    routerDirection="forward"
  >
    {reOrder ? <IonReorder key={garmentCategory.ID} slot={reOrder} /> : null}
    <IonThumbnail slot="start">
      <IonImg src={garmentCategory.Image ? garmentCategory.Image : ""} />
    </IonThumbnail>
    <IonLabel>
      <h2 style={{ textTransform: "capitalize" }}>{garmentCategory.Name}</h2>
    </IonLabel>
  </IonItem>
);

export default GarmentCategoryListItem;
