import { RootStore } from "../../stores/RootStore";
import React from "react";
import { inject, observer } from "mobx-react";
import { IonGrid, IonCol, IonText, IonRow, IonSelectOption, IonSelect } from "@ionic/react";
import TextField from "../TextField/TextField";
import Select from "react-select/src/Select";
import { MultiSelect } from "../MultiSelect/MultiSelect";

interface IAgentDetails {
  store?: RootStore;
}

const AgentContactDetails: React.FC<IAgentDetails> = ({ store }) => {
  const { agent } = store!.stores.agents;
  const { actions } = store!.stores;
  return (
    <IonGrid>
      <IonText color='medium'>
        <h5 style={{ padding: 15 }}>Business Details</h5>
      </IonText>
      <IonRow>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Business Name'
            value={agent.Name}
            type='text'
            onChange={(v: string) => {
              agent.Name = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Business Type'
            value={agent.Type}
            type='text'
            onChange={(v: string) => {
              agent.Type = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Username'
            value={agent.UserName}
            type='text'
            onChange={(v: string) => {
              agent.UserName = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Landline'
            value={agent.Landline}
            type='number'
            onChange={(v: string) => {
              agent.Landline = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
              {/* <MultiSelect
                label={"Action Can View"}
                options={actions.actions.map(a=>{return { label: a.Name, value: a.Name }})}
                select={(actions: string[]) => {
                 agent.CanSeeAction = actions.join(",")
                }}
              /> */}

            <IonSelect value={agent.CanSeeAction} placeholder="Select Action" onIonChange={e => agent.CanSeeAction = e.detail.value}>
              {actions.actions.map(a =>{return <IonSelectOption value={a.Name}>{a.Name}</IonSelectOption>})}
            </IonSelect>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(AgentContactDetails));
