import React from "react";
import { Action } from "../../stores/models";
import { IonButton, IonButtons, IonContent, IonItem, IonLabel } from "@ionic/react";
import { inject } from "mobx-react";
import { RootStore } from "../../stores/RootStore";
import "./Actions.css";

interface IActions {
  onSelect: Function;
  imageUpload?: Function;
  actions: Action[];
  store: RootStore;
  showImageUploader: number[];
}

const Actions: React.FC<IActions> = ({
  actions,
  onSelect,
  imageUpload,
  store,
  showImageUploader,
}) => {
  const handleClick = (id: number) => {
    onSelect(id);
  };
  const { ui } = store!.stores;

  const openModelForImageUpload = (a: Action) => {
    ui.showDialog({
      title: "Need to select image!",
      content: (
        <div>
          Adding this action requires an image. <br />
          Please upload image to add action.
          <br />
          <IonButton>
            <label htmlFor={"uploadDialog"} className={"pointer"}>
              UPLOAD
            </label>
          </IonButton>
          <br />
          <IonItem>
            <br />
            <IonLabel>Click to override image upload!</IonLabel>
            <IonButton
              onClick={() => {
                handleAddActionNoImage(a.ID);
              }}
            >
              <label className={"pointer"}>Override</label>
            </IonButton>
          </IonItem>
          <input
            className={"hide"}
            id={"uploadDialog"}
            type="file"
            accept="image/*"
            capture="camera"
            onChange={(e) => {
              uploadImage(e, a.ID);
            }}
          />
        </div>
      ),
      OKText: "Upload",
      CancelText: "CLOSE",
    });
  };

  const uploadImage = (e: any, actionID: number) => {
    ui.freezeDialog();
    if (e !== undefined) {
      if (e.target.files != null) {
        let file = e.target.files[0];
        if (file !== undefined) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            const base64Image: string | undefined = reader.result?.toString();
            if (imageUpload !== undefined) {
              imageUpload(base64Image, actionID);
            }
          };
          reader.onerror = function () {
            ui.closeDialog();
          };
        }
      }
    }
  };

  const handleAddActionNoImage = (id: number) => {
    onSelect(id);
    console.log("add action with dummy image");
    ui.closeDialog();
  };

  return (
    <IonButtons slot="end" style={{overflowX:"scroll"}} >
      {actions.map((a) => {
        if (!a.Visible) return null;
        let show = false;
        showImageUploader.forEach((s) => {
          if (s === a.ID) {
            show = true;
          }
        });
        return a.ImagesRequired && imageUpload && !show ? (
          <>
            <IonButton
              key={a.ID}
              color="default"
              size="large"
              onClick={() => openModelForImageUpload(a)}
            >
              {a.Name}
            </IonButton>
          </>
        ) : (
          <IonButton
            key={a.ID}
            color="default"
            size="large"
            onClick={() => {
              handleClick(a.ID);
            }}
          >
            {a.Name}
          </IonButton>
        );
      })}
    </IonButtons>
  );
};

export default inject("store")(Actions);
