import { GarmentSubCategory } from "../../stores/models";
import {
  IonItem,
  IonLabel,
  IonImg,
  IonThumbnail,
  IonReorder,
  IonButton,
  IonIcon,
} from "@ionic/react";
import React from "react";
import "./GarmentSubCategoryListItem.css";
import { closeCircleOutline } from "ionicons/icons";
import { RootStore } from "../../stores/RootStore";
import { runInAction } from "mobx";

interface GarmentSubCategoryListItemProps {
  garmentSubCategory: GarmentSubCategory;
  reOrder?: string;
  deleteFunc?: Function;
  store?: RootStore;
}

const GarmentSubCategoryListItem: React.FC<GarmentSubCategoryListItemProps> = ({
  garmentSubCategory,
  reOrder,
  deleteFunc,
  store,
}) => {
  return (
    <IonItem>
      <IonItem
        routerLink={"/garmentsSubCategory/" + garmentSubCategory.ID}
        routerDirection="forward"
        onClick={() => {
          const { garments } = store!.stores;
          runInAction(() => {
            garments.garmentSubCategory.GarmentCategoryID =
              garments.garmentCategory.ID;
          });
          console.log("something needs to happen here");
        }}
      >
        {reOrder ? (
          <IonReorder key={garmentSubCategory.ID} slot={reOrder} />
        ) : null}
        <IonItem>
          <IonThumbnail slot="start">
            <IonImg
              src={garmentSubCategory.Image ? garmentSubCategory.Image : ""}
            />
          </IonThumbnail>
        </IonItem>
        <IonLabel>
          <h2 style={{ textTransform: "capitalize" }}>
            {garmentSubCategory.ID == 0
              ? "Add new sub category"
              : garmentSubCategory.Name}
          </h2>
        </IonLabel>
      </IonItem>
      {deleteFunc ? (
        <IonButton
          slot={"end"}
          color="none"
          expand="full"
          size="small"
          onClick={() => deleteFunc(false, garmentSubCategory.ID)}
        >
          <IonIcon
            color={"danger"}
            slot="icon-only"
            icon={closeCircleOutline}
          />
        </IonButton>
      ) : null}
    </IonItem>
  );
};

export default GarmentSubCategoryListItem;
