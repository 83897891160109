import React, { useState } from "react";
import { GarmentCategory, GarmentSubCategory } from "../../stores/models";
import { Tiles, Tile } from "..";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

interface IGarments {
  store?: RootStore;
  onSelect: Function;
  garments: GarmentCategory[];
}

const Garments: React.FC<IGarments> = ({ garments, store, onSelect }) => {
  const [selected, setSelected] = useState(0);
  const [children, setChildren] = useState<GarmentSubCategory[]>([]);

  const handleClick = (i: GarmentCategory | GarmentSubCategory) => {
    const tickets = store!.stores.tickets;
    if (selected !== i.ID && i instanceof GarmentCategory) {
      if (!getChildren(i.ID)) {
        // onSelect(i.ID);
      } else {
        setSelected(i.ID);
        tickets.selectGarment(i.ID);
      }
    } else {
      setChildren([]);
      setSelected(0);
      tickets.selectGarment(0);
      if (i instanceof GarmentSubCategory) {
        onSelect(i.ID);
      }
    }
  };

  const getChildren = (id: number): boolean => {
    const { garments } = store!.stores;
    const children = garments.getGarmentSubCategories(id);
    setChildren(children);
    return children.length > 0;
  };

  const hasChildren = (id: number): boolean => {
    const { garments } = store!.stores;
    const children = garments.getGarmentSubCategories(id);
    return children.length > 0;
  };

  return (
    <Tiles>
      {garments.map((g) => {
        const garmentHasChildren = hasChildren(g.ID);
        return (selected === 0 || selected === g.ID) &&
          garmentHasChildren === true &&
          g.Visible === true ? (
          <Tile
            onClick={() => handleClick(g)}
            key={g.ID}
            image={g.Image}
            title={g.Name}
            selected={selected === g.ID}
            className={"t-grid-item-text "}
          />
        ) : null;
      })}
      <>
        {children.map((c) => {
          return c.Visible === true ? (
            <Tile
              key={c.ID}
              title={c.Name}
              image={c.Image}
              selected={false}
              onClick={() => handleClick(c)}
              className={"t-grid-item-text "}
            />
          ) : null;
        })}
      </>
    </Tiles>
  );
};

export default inject("store")(observer(Garments));
