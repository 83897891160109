import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import "./StateDropDown.css";
import { IonBadge, IonCol, IonRow } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import SignatureCanvas from 'react-signature-canvas'
import { useRef } from "react";

interface IStateDropDown {
  elements: string[];
  onSelect: Function;
  selected: string;
  store: RootStore;
  mobile?: boolean;
}

const StateDropDown: React.FC<IStateDropDown> = (props: IStateDropDown) => {
  const { ui } = props.store.stores;
  let sigCanvas:any = useRef({})
  const [isSigning, setIsSigning] = React.useState(true)
  const [signature, setSignature] = React.useState("true")
  let signatureData: any[] = [];
  let signatureString = "";

const   showSignature = () => {
    if (isSigning) {
      signatureData = sigCanvas.toData();
      signatureString = sigCanvas
        .getTrimmedCanvas()
        .toDataURL("image/png");
    } else {
      setTimeout(() => {
        sigCanvas.fromData(signatureData);
      }, 100);
    }
    setIsSigning(!isSigning);
    setSignature(signatureString)
  };

  const openModal = () => {
    ui.showDialog({
      title: "Select a state",
      content: (
        <IonCol size="12">
          {props.elements.map((s) => {
            return (
              <IonRow
                onClick={() => {
                  props.onSelect(s, sigCanvas
                    .getTrimmedCanvas()
                    .toDataURL("image/png"));
                  ui.closeDialog();
                }}
              >
                <IonBadge className="stateItem" color="primary">
                  {s}
                </IonBadge>
              </IonRow>
            );
          })}
          
          <IonRow>
            <div onClick={()=>{
            }}>
            Sign Here</div>
            <SignatureCanvas penColor='green'
        ref={(ref: any) => {
          sigCanvas = ref;
        }}
    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
    />
      </IonRow>
        </IonCol>
      ),
    });
  };
  return (
    <>
      <IonBadge
        className="stateItem"
        onClick={() => {
          openModal();
        }}
        color="primary"
      >
        {props.mobile
          ? props.selected.match(/\b(\w)/g)?.join("")
          : props.selected}
      </IonBadge>
    </>
  );
};

export default inject("store")(observer(StateDropDown));
