import { RootStore } from "../../stores/RootStore";
import React from "react";
import { IonToast } from "@ionic/react";
import { inject, observer } from "mobx-react";

interface IToast {
  store?: RootStore;
}

const Toast: React.FC<IToast> = ({ store }) => {
  const { ui } = store!.stores;

  return (
    <IonToast
      isOpen={ui.toast.open!}
      onDidDismiss={() => {
        ui.closeToast();
      }}
      message={ui.toast.message}
      color={ui.toast.color}
      position={ui.toast.position}
      duration={1500}
    />
  );
};

export default inject("store")(observer(Toast));
