import { RootStore } from "../../stores/RootStore";
import React from "react";
import { inject, observer } from "mobx-react";
import { IonGrid, IonCol, IonText, IonRow } from "@ionic/react";
import TextField from "../TextField/TextField";

interface IAgentDetails {
  store?: RootStore;
}

const ContactPersonDetails: React.FC<IAgentDetails> = ({ store }) => {
  const { agent } = store!.stores.agents;
  return (
    <IonGrid>
      <IonText color='medium'>
        <h5 style={{ padding: 15 }}>Contact Person Details</h5>
      </IonText>
      <IonRow>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Contact Person'
            value={agent.ContactPerson}
            type='text'
            onChange={(v: string) => {
              agent.ContactPerson = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Mobile Number'
            value={agent.Mobile}
            type='text'
            onChange={(v: string) => {
              agent.Mobile = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Email Address'
            value={agent.EmailAddress}
            type='text'
            onChange={(v: string) => {
              agent.EmailAddress = v;
            }}
          />
        </IonCol>
        <IonCol sizeLg='3' sizeXs='12'>
          <TextField
            label='Commission %'
            value={agent.Commission}
            type='number'
            onChange={(v: string) => {
              agent.Commission = parseInt(v);
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(ContactPersonDetails));
