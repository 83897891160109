import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import {
  IonPage,
  IonContent,
  IonCol,
  IonBadge,
  IonList,
  IonHeader,
  IonRow,
  IonButton,
  isPlatform,
  IonButtons,
  withIonLifeCycle,
} from "@ionic/react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Ticket } from "../stores/models";
import { Header } from "../components";
import SchedulerComponent from "../components/SchdulerComponent/SchedulerComponent";
import { action, observable, runInAction } from "mobx";
import { DatePicker } from "../components/DatePicker/DatePicker";
import moment from "moment";
import GoogleLocation from "../components/GoogleLocation/GoogleLocation";
import StateDropDown from "../components/StateDropDown/StateDropDown";
import SimpleTicket from "../stores/models/SimpleTicket";

interface IScheduler {
  store?: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class Scheduler extends React.Component<
  IScheduler & RouteComponentProps<RouteProps>
> {
  @observable sortByPickupAscending: boolean | undefined = undefined;
  @observable sortByDeliveryAscending: boolean | undefined = undefined;
  @observable limit = 10;
  @observable offset = 0;
  @observable Deliveries = false;
  @observable View: "Driver" | "Pickups" | "Deliveries" | "Week" = "Week";
  @observable Pickups = false;
  @action
  setPickupAscending(b: boolean) {
    this.sortByDeliveryAscending = undefined;
    if (this.sortByPickupAscending === false) {
      this.sortByPickupAscending = undefined;
      return;
    }
    this.sortByPickupAscending = b;
  }
  @action
  setDeliveryAscending(b: boolean) {
    this.sortByPickupAscending = undefined;
    if (this.sortByDeliveryAscending === false) {
      this.sortByDeliveryAscending = undefined;
      return;
    }
    this.sortByDeliveryAscending = b;
  }
  componentDidMount() {
    this.getDataFromApi();
  }
  ionViewDidLeave() {
    // console.log("left the scheduler")
    const { statistics } = this.props.store!.stores;
    runInAction(()=>{
      statistics.scheduleDate = null
    })
  }

  async getDataFromApi() {
    const {
      tickets,
      statistics,
      addresses,
      customers,
      agents,
      pickupDelivery,
    } = this.props.store!.stores;
    // await customers.getCustomers();
    // await addresses.getAddresses();
    await tickets.getSimpleTickets(
      this.limit,
      this.limit - 10,
      false,
      false,
      this.View
    );
    await statistics.getScheduleStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
    await pickupDelivery.getPickupDeliveries();
  }

  // https://gist.github.com/ecarter/1423674
  mapOrderPickup(ascending: boolean | undefined) {
    const { pickupDelivery, addresses } = this.props.store!.stores;
    let order = pickupDelivery.getPickupDeliveryLocalitiesThisSeason();
    if (ascending) {
      order.reverse();
    }
    return function (a: Ticket, b: Ticket) {
      const aLocality = a.DeliveryAddress?.Locality.Name;
      const bLocality = b.DeliveryAddress?.Locality.Name;

      if (order.indexOf(aLocality!) > order.indexOf(bLocality!)) {
        return 1;
      } else {
        return -1;
      }
    };
  }

  mapOrderDelivery(ascending: boolean | undefined) {
    const { pickupDelivery, addresses } = this.props.store!.stores;
    let order = pickupDelivery.getPickupDeliveryLocalitiesThisSeason();
    if (ascending) {
      order.reverse();
    }

    return function (a: Ticket, b: Ticket) {
      const aLocality = a.DeliveryAddress?.Locality.Name;
      const bLocality = b.DeliveryAddress?.Locality.Name;
      if (order.indexOf(aLocality!) > order.indexOf(bLocality!)) {
        return 1;
      } else {
        return -1;
      }
    };
  }

  alphabeticallyPickupAddress(ascending: boolean) {
    const { addresses } = this.props.store!.stores;
    return function (a: Ticket, b: Ticket) {
      const addressA = addresses.addresses.find((adress) => {
        return adress.ID === a.PickupAddressID;
      });
      const addressB = addresses.addresses.find((adress) => {
        return adress.ID === b.PickupAddressID;
      });
      // equal items sort equally
      if (addressA?.Locality.Name === addressB?.Locality.Name) {
        return 0;
      }
      // if we're ascending, lowest sorts first
      else if (ascending) {
        return addressA?.Locality.Name! < addressB?.Locality.Name! ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return addressA?.Locality.Name! < addressB?.Locality.Name! ? 1 : -1;
      }
    };
  }

  alphabeticallyDeliveryAddress(ascending: boolean) {
    const { addresses } = this.props.store!.stores;
    return function (a: Ticket, b: Ticket) {
      const addressA = addresses.addresses.find((adress) => {
        return adress.ID === a.DeliveryAddressID;
      });
      const addressB = addresses.addresses.find((adress) => {
        return adress.ID === b.DeliveryAddressID;
      });
      // equal items sort equally
      if (addressA?.Locality.Name === addressB?.Locality.Name) {
        return 0;
      }
      // if we're ascending, lowest sorts first
      else if (ascending) {
        return addressA?.Locality.Name! < addressB?.Locality.Name! ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return addressA?.Locality.Name! < addressB?.Locality.Name! ? 1 : -1;
      }
    };
  }

  sortDeliveredLast() {
    return function (a: Ticket, b: Ticket) {
      if (a.State === "DELIVERED") {
        return 1;
      } else if (b.State === "DELIVERED") {
        return -1;
      } else {
        return 0;
      }
    };
  }

  renderScheduler = () => {
    const { statistics } = this.props.store!.stores;
    console.log(statistics.scheduleDate)
    return statistics.scheduleDate === null ? (
      <SchedulerComponent
        forwardWeekFunction={() => {
          const { statistics } = this.props.store!.stores;
          runInAction(() => {
            statistics.weekNumber++;
            if (statistics.weekNumber === 53) {
              statistics.weekNumber = 1;
              statistics.yearNumber++;
            }
            statistics.getScheduleStatisticsByWeek(
              statistics.yearNumber,
              statistics.weekNumber
            );
          });
        }}
        backWeekFunction={() => {
          runInAction(() => {
            const { statistics } = this.props.store!.stores;
            statistics.weekNumber--;
            if (statistics.weekNumber === -1) {
              statistics.weekNumber = 52;
              statistics.yearNumber--;
            }
            statistics.getScheduleStatisticsByWeek(
              statistics.yearNumber,
              statistics.weekNumber
            );
          });
        }}
        dateFunction={(date: string) => {
          const { tickets } = this.props.store!.stores;
          runInAction(() => {
            this.View = "Driver";
            statistics.scheduleDate! = date;
            // this.limit = 9999999999;
            //need to get tickets for this date since they need to be displayed
            tickets.getSimpleTicketsByDate(
              9999999999,
              0,
              date,
              false,
              false,
              this.View
            );
            //set limit to big number
          });
        }}
        weekFunction={() => {
          runInAction(() => {
            statistics.scheduleDate = null;
          });
        }}
        title=""
        data={statistics.scheduleStatistics}
      ></SchedulerComponent>
    ) : (
      <>
        <IonRow>
          <IonCol>
            {" "}
            <h2>{moment(statistics.scheduleDate).format("DD-MM-YYYY")}</h2>
          </IonCol>
        </IonRow>
        <IonButtons slot="end" style={{ overflowX: "scroll" }}>
          <IonButton
            color="default"
            onClick={() => {
              const { tickets, statistics } = this.props.store?.stores!;
              runInAction(() => {
                this.View = "Week";
                tickets.getSimpleTickets(10, 0, false, false, this.View);
                statistics.scheduleDate = null;
                this.Deliveries = false;
                this.Pickups = false;
                this.limit = 20;
              });
            }}
          >
            Week
          </IonButton>
          <IonButton
            color={this.View === "Driver" ? "danger" : ""}
            onClick={() => {
              const { tickets, statistics } = this.props.store!.stores;
              runInAction(() => {
                this.View = "Driver";
                tickets.getSimpleTicketsByDate(
                  9999999999,
                  0,
                  statistics.scheduleDate!,
                  false,
                  false,
                  this.View
                );
                this.Deliveries = false;
                this.Pickups = false;
              });
            }}
          >
            Driver
          </IonButton>
          <IonButton
            color={this.View === "Pickups" ? "danger" : ""}
            onClick={() => {
              const { tickets, statistics } = this.props.store!.stores;
              runInAction(() => {
                this.View = "Pickups";
                tickets.getSimpleTicketsByDate(
                  9999999999,
                  0,
                  statistics.scheduleDate!,
                  false,
                  false,
                  this.View
                );
                this.Pickups = true;
                this.Deliveries = false;
              });
            }}
          >
            Pickups
          </IonButton>
          <IonButton
            color={this.View === "Deliveries" ? "danger" : ""}
            onClick={() => {
              const { tickets, statistics } = this.props.store!.stores;
              runInAction(() => {
                this.View = "Deliveries";
                tickets.getSimpleTicketsByDate(
                  9999999999,
                  0,
                  statistics.scheduleDate!,
                  false,
                  false,
                  this.View
                );
                this.Pickups = false;
                this.Deliveries = true;
              });
            }}
          >
            Deliveries
          </IonButton>
        </IonButtons>
      </>
    );
  };

  reschedulePickupDate = async (e: string, ticketID: number) => {
    const { tickets, statistics } = this.props.store!.stores;
    await tickets.reschedulePickupDate(ticketID, e);
    await statistics.getScheduleStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
  };

  rescheduleDeliveryDate = async (e: string, ticketID: number) => {
    const { tickets, statistics } = this.props.store!.stores;
    await tickets.rescheduleDeliveryDate(ticketID, e);
    await statistics.getScheduleStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
  };

  private getMobileSchedulerHeader(): JSX.Element {
    return (
      <IonRow>
        <IonCol size="2">id</IonCol>
        <IonCol size="2">state</IonCol>
        <IonCol
          size="6"
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.setPickupAscending(!this.sortByPickupAscending);
          }}
        >
          {`location`}
        </IonCol>
      </IonRow>
    );
  }
  private returnMobileItem(ticket: SimpleTicket): JSX.Element {
    let pickupAddress = ""
    if (ticket.PickupAddressNumber.trim() !== ""){
      pickupAddress+= ticket.PickupAddressNumber + ", "
    }
    if (ticket.PickupAddressName.trim() !== ""){
      pickupAddress+= ticket.PickupAddressName
    }
    let deliveryAddress = ""
    if (ticket.DeliveryAddressNumber.trim() !== ""){
      deliveryAddress+= ticket.DeliveryAddressNumber + ", "
    }
    if (ticket.DeliveryAddressName.trim() !== ""){
      deliveryAddress+= ticket.DeliveryAddressName
    }
    return (
      <IonRow>
        <IonCol size="2">
          <IonRow>
            <IonBadge
              style={{ cursor: "pointer", width: "100%" }}
              color="warning"
              onClick={() => {
                this.props.history.push(
                  `/customers/${ticket.CustomerID}/new-ticket/${ticket.ID}`
                );
              }}
            >
              #{ticket.ID}
            </IonBadge>
          </IonRow>
          <IonRow>
            <IonBadge
              style={{ cursor: "pointer", width: "100%", marginTop: "10%" }}
              color="primary"
              onClick={() => {
                // runInAction(() => {
                //   this.props.store!.stores.tickets.ticket = ticket;
                // });
                this.props.history.push(`/tickets/${ticket.ID}`);
              }}
            >
              #{ticket.ID}{ticket.Detail}
            </IonBadge>
          </IonRow>
          {ticket.PickupComment !== "" ? (
            <IonRow>
              <IonBadge
                style={{ cursor: "pointer", width: "100%", marginTop: "10%" }}
                color="danger"
                onClick={() => {
                  const { ui } = this.props.store!.stores;
                  ui.showDialog({
                    title: `Pickup Comment`,
                    content: (
                      <>
                        <span>{ticket.PickupComment}</span>
                      </>
                    ),
                    CancelText: "CLOSE",
                  });
                }}
              >
                PC
              </IonBadge>
            </IonRow>
          ) : null}
          {ticket.DeliveryComment !== "" ? (
            <IonRow>
              <IonBadge
                style={{ cursor: "pointer", width: "100%", marginTop: "10%" }}
                color="danger"
                onClick={() => {
                  const { ui } = this.props.store!.stores;
                  ui.showDialog({
                    title: `Delivery Comment`,
                    content: (
                      <>
                        <span>{ticket.DeliveryComment}</span>
                      </>
                    ),
                    CancelText: "CLOSE",
                  });
                }}
              >
                DC
              </IonBadge>
            </IonRow>
          ) : null}
        </IonCol>
        <IonCol size="2">
          <StateDropDown
            mobile
            store={this.props.store!}
            elements={this.props.store!.stores.tracking.states.map((s) => s)}
            selected={ticket.State}
            onSelect={async (s: string, sig: string) => {
              await this.props.store?.stores.tracking.setTicketsState(
                [ticket.ID],
                s,
                sig
              );
            }}
          />
        </IonCol>
        <IonCol size="6">
          {ticket.State === "AWAITING PICKUP" ? (
            <GoogleLocation
              locality={ticket.PickupAddressLocality}
              street={ticket.PickupAddressStreet}
              name={pickupAddress}
              mobile={ticket.CustomerContact}
              // iconOnly
            />
          ) : (
            <GoogleLocation
              locality={ticket.DeliveryAddressLocality}
              street={ticket.DeliveryAddressStreet}
              name={deliveryAddress}
              mobile={ticket.CustomerContact}
              // iconOnly
            />
          )}
        </IonCol>
      </IonRow>
    );
  }

  private returnItem(ticket: SimpleTicket): JSX.Element {
    let pickupAddress = ""
    if (ticket.PickupAddressNumber.trim() !== ""){
      pickupAddress+= ticket.PickupAddressNumber + ", "
    }
    if (ticket.PickupAddressName.trim() !== ""){
      pickupAddress+= ticket.PickupAddressName
    }
    let deliveryAddress = ""
    if (ticket.DeliveryAddressNumber.trim() !== ""){
      deliveryAddress+= ticket.DeliveryAddressNumber + ", "
    }
    if (ticket.DeliveryAddressName.trim() !== ""){
      deliveryAddress+= ticket.DeliveryAddressName
    }
    return (
      // <IonRow key={ticket.ID} color={ticket.HasExpressItem===true?'danger':""}>
      <IonRow
        key={ticket.ID}
        // style={ticket.HasExpressItem === true ? { backgroundColor: "red" } : {}}
      >
        <IonCol sizeXl="1" sizeXs="2" sizeSm="1">
          <IonBadge
            style={{ cursor: "pointer" }}
            color="warning"
            onClick={() => {
              this.props.history.push(
                `/customers/${ticket.CustomerID}/new-ticket/${ticket.ID}`
              );
            }}
          >
            #{ticket.ID}
          </IonBadge>
          <IonBadge
            style={{ float: "right", marginRight: "30px", cursor: "pointer" }}
            color="primary"
            onClick={() => {
              // runInAction(() => {
              //   this.props.store!.stores.tickets.ticket = ticket;
              // });
              this.props.history.push(`/tickets/${ticket.ID}`);
            }}
          >
            #{ticket.ID}{ticket.Detail}
            {/* {ticket.Agent.Detail} */}
          </IonBadge>
        </IonCol>
        <IonCol sizeLg="2" sizeXs="3" sizeSm="2">
          <StateDropDown
            store={this.props.store!}
            elements={this.props.store!.stores.tracking.states.map((s) => s)}
            selected={ticket.State}
            onSelect={async (s: string, sig: string) => {
              await this.props.store?.stores.tracking.setTicketsState(
                [ticket.ID],
                s,
                sig
              );
              await this.getDataFromApi()
            }}
          />
        </IonCol>
        <>
          <IonCol sizeLg="2">
            <DatePicker
              date={moment(ticket.PickupDate).format("YYYY-MM-DD")}
              select={(e: string) => {
                this.reschedulePickupDate(e, ticket.ID);
              }}
            />
          </IonCol>
          <IonCol sizeLg="2">
            <DatePicker
              date={moment(ticket.DeliveryDate).format("YYYY-MM-DD")}
              select={(e: string) => {
                this.rescheduleDeliveryDate(e, ticket.ID);
              }}
            />
          </IonCol>
          <IonCol sizeLg="2">
            <GoogleLocation
              locality={ticket.PickupAddressLocality}
              street={ticket.PickupAddressStreet}
              name={pickupAddress}
              mobile={ticket.CustomerContact}
            />
          </IonCol>
          <IonCol sizeLg="2">
            <GoogleLocation
              locality={ticket.DeliveryAddressLocality}
              street={ticket.DeliveryAddressStreet}
              name={deliveryAddress}
              mobile={ticket.CustomerContact}
            />
          </IonCol>
        </>

        <IonCol sizeLg="1" sizeXs="3" sizeSm="1">
          <IonRow>
            {ticket.CustomerFullName ? ticket.CustomerFullName : "N/A"}
          </IonRow>
        </IonCol>
      </IonRow>
    );
  }

  // showTickethenDateSelectedForDeliveries = (ticket: Ticket): boolean => {
  //   //if the ticket.delivery date = selected date then return true
  //   const { statistics } = this.props.store!.stores;
  //   if (
  //     moment(ticket.DeliveryDate).format("YYYY-MM-DD") ===
  //     moment(statistics.scheduleDate).format("YYYY-MM-DD")
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  // showTickethenDateSelectedForPickups = (ticket: Ticket): boolean => {
  //   //if the ticket.delivery date = selected date then return true
  //   const { statistics } = this.props.store!.stores;
  //   if (
  //     moment(ticket.PickupDate).format("YYYY-MM-DD") ===
  //     moment(statistics.scheduleDate).format("YYYY-MM-DD")
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  // showTicketWhenDateSelected = (ticket: Ticket): boolean => {
  //   const ticketID = ticket.ID;
  //   if (ticketID === 2288) {
  //   }
  //   const ticketState = ticket.State.trim();
  //   //ticket.pickup needs to be true also
  //   if (ticketState === "AWAITING PICKUP") {
  //     return true;
  //   }
  //   //ticket.delivery needs to be true also
  //   if (ticketState === "OUT FOR DELIVERY") {
  //     return true;
  //   }
  //   if (ticketState === "DELIVERED NOT PAID") {
  //     return true;
  //   }
  //   // if (ticket.DeliveryToClient === true) {
  //   let atLeastOneItemOutForDelivery: boolean = false;
  //   //this means that ticket is not out for delivery but there could be items in the ticket hat have that state
  //   ticket.SubCategories.forEach((sc) => {
  //     const scState = sc.State.trim();
  //     if (ticketID === 2288) {
  //     }
  //     if (scState === "OUT FOR DELIVERY" || scState === "DELIVERED NOT PAID") {
  //       atLeastOneItemOutForDelivery = true;
  //     }
  //   });
  //   return atLeastOneItemOutForDelivery;
  //   // }
  //   // return false;
  // };

  renderItem = (ticket: SimpleTicket) => {
    //insert pickup and delivery address with ticket so as not to request all addresses.
    // const customer = ticket.Customer;
    // // const { statistics } = this.props.store!.stores;
    // const pickupAddress = ticket.PickupAddress;
    // const deliveryAddress = ticket.DeliveryAddress;
    // if (this.Pickups === true) {
    //   if (this.showTickethenDateSelectedForPickups(ticket) == true) {
    //     if (isPlatform("mobile")) {
    //       return this.returnMobileItem(
    //         ticket,
    //         customer!,
    //         pickupAddress!,
    //         deliveryAddress!
    //       );
    //     }
    //     return this.returnItem(
    //       ticket,
    //       customer!,
    //       pickupAddress!,
    //       deliveryAddress!
    //     );
    //   } else {
    //     return;
    //   }
    // }
    // if (this.Deliveries === true) {
    //   if (this.showTickethenDateSelectedForDeliveries(ticket) == true) {
    //     if (isPlatform("mobile")) {
    //       return this.returnMobileItem(
    //         ticket,
    //         customer!,
    //         pickupAddress!,
    //         deliveryAddress!
    //       );
    //     }
    //     return this.returnItem(
    //       ticket,
    //       customer!,
    //       pickupAddress!,
    //       deliveryAddress!
    //     );
    //   } else {
    //     return;
    //   }
    // }
    // if (statistics.scheduleDate !== null) {
    //   //this means that the date has been selected
    //   if (this.showTicketWhenDateSelected(ticket) == true) {
    //     if (isPlatform("mobile")) {
    //       return this.returnMobileItem(
    //         ticket,
    //         customer!,
    //         pickupAddress!,
    //         deliveryAddress!
    //       );
    //     }
    //     return this.returnItem(
    //       ticket,
    //       customer!,
    //       pickupAddress!,
    //       deliveryAddress!
    //     );
    //   } else {
    //     return;
    //   }
    // }
    if (isPlatform("mobile")) {
      return this.returnMobileItem(ticket);
    } else {
      return this.returnItem(ticket);
    }
  };

  renderItems = (): JSX.Element => {
    const { tickets } = this.props.store!.stores;
    return (
      <IonList>
        {tickets.simpleTickets
          // .slice(0, this.limit)
          // .sort(this.mapOrderPickup(this.sortByPickupAscending))
          // .sort(
          //   this.sortByDeliveryAscending !== undefined
          //     ? this.mapOrderDelivery(this.sortByDeliveryAscending)
          //     : () => {
          //         return 0;
          //       }
          // )
          // .sort(this.sortDeliveredLast())

          // )
          .map((t) => {
            // return statistics.scheduleDate === null ? (
            //   this.renderItem(t)
            // ) : (moment(t.PickupDate).format("DD-MM-YYYY") ===
            //     moment(statistics.scheduleDate).format("DD-MM-YYYY") ||
            //     moment(t.DeliveryDate).format("DD-MM-YYYY") ===
            //       moment(statistics.scheduleDate).format("DD-MM-YYYY")) &&
            //   t.State != "CLOSED" ? (
            return this.renderItem(t);
            // ) : (
            //   <div></div>
            // );
          })}
      </IonList>
    );
  };

  private getSchedulerHeader(): JSX.Element {
    return (
      <IonRow>
        <IonCol size="1">id</IonCol>
        <IonCol size="2">state</IonCol>
        <IonCol size="2">pickup date</IonCol>
        <IonCol size="2">delivery date</IonCol>
        <IonCol
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.setPickupAscending(!this.sortByPickupAscending);
          }}
          size="2"
        >
          {`pickup address${
            this.sortByPickupAscending !== undefined
              ? this.sortByPickupAscending === true
                ? " ↑"
                : " ↓"
              : ""
          }`}
        </IonCol>
        <IonCol
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.setDeliveryAscending(!this.sortByDeliveryAscending);
          }}
          size="2"
        >
          {`delivery address${
            this.sortByDeliveryAscending !== undefined
              ? this.sortByDeliveryAscending === true
                ? " ↑"
                : " ↓"
              : ""
          }`}
        </IonCol>
        <IonCol size="1">customer</IonCol>
      </IonRow>
    );
  }

  render() {
    const { statistics, tickets } = this.props.store!.stores;
    // tickets.simpleTickets.map(t=>console.log(t.ID))
    return (
      <IonPage>
        <Header
          title={statistics.scheduleDate === null ? "Scheduler" : "Schedule"}
          backURL="/home"
        />
        <IonHeader>
          {this.renderScheduler()}
          {isPlatform("mobile")
            ? this.getMobileSchedulerHeader()
            : this.getSchedulerHeader()}
        </IonHeader>
        <IonContent>
          {this.renderItems()}
          {/* <IonButton
            onClick={() => {
              runInAction(() => {
                this.offset = this.offset + 10;
                tickets.getSimpleTickets(
                  this.limit,
                  this.offset,
                  true, false,
                  this.View
                );
              });
            }}
          >
            Show More
          </IonButton> */}
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Scheduler));
