import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ActionGarmentSubCategory")
class ActionGarmentSubCategory {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("garment_sub_category_id", Number,)
  GarmentSubCategoryID: number = 0;

  @JsonProperty("action_id", Number)
  ActionID: number = 0;

  @JsonProperty("price", Number)
  Price: number = 0;

  @JsonProperty("discount", String, true)
  Discount:string|undefined = "Percentage 0"

  @JsonProperty("is_percentage_of", Number, true)
  IsPercentageOf: number | null = null;

  findPrice(actionGarmentSubCategories: ActionGarmentSubCategory[]): number {
    try {
      const percentage = this.Price / 100;
      const newActionGarmentSubCategory = actionGarmentSubCategories.find(
        (agsc) => agsc.ID === this.IsPercentageOf
      );
      console.log(this);
      if (
        this?.IsPercentageOf !== null &&
        newActionGarmentSubCategory !== undefined
      ) {
        const price = newActionGarmentSubCategory.findPrice(
          actionGarmentSubCategories
        );
        const percentagePrice = percentage * price;
        console.log(percentagePrice);
        return percentagePrice;
      } else {
        console.log(this!.Price);
        return this!.Price;
      }
    } catch (e) {
      return 0;
    }
  }
}

export default ActionGarmentSubCategory;
