import React from "react";
import { inject, observer } from "mobx-react";
import "./ProcessFeedbackBar.css";
import {
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
} from "@ionic/react";

import moment from "moment";
import {
  caretBack,
  caretForward,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";
import {
  ActionWorkLoadStatistics,
  WorkLoadStatistic,
} from "../../stores/models/WorkLoadStatistic";
import { RootStore } from "../../stores/RootStore";
import { RouteComponentProps } from "react-router";

interface IProcessFeedbackBarProps {
  title: string;
  data: ActionWorkLoadStatistics[];
  forwardWeekFunction: Function;
  backWeekFunction: Function;
  // dateFunction: Function;
  // weekFunction: Function;
  store: RootStore;
}

const ProcessFeedbackBar: React.FC<IProcessFeedbackBarProps> = (props) => {
  const [show, setShow] = React.useState(false);

  const modalItems = (workLoadStatistic: WorkLoadStatistic) => {
    const { ui } = props.store!.stores;
    console.log("item clicked");
    ui.showDialog({
      title: `${moment(workLoadStatistic.Date).format("DD-MM-YYYY")}`,
      content: (
        <IonContent>
          <IonList>
            {workLoadStatistic.TicketSubCategories.map((i) => {
              return (
                <IonItem
                  // routerLink={"#/tickets/" + i.TicketID}
                  // routerDirection="root"
                >
                  <IonRow onClick={()=>{
                    window.location.href = "#/tickets/" + i.TicketID
                  }}>
                    <IonCol size="1">{i.Detail}</IonCol>
                    <IonCol size="2">{i.TicketID}</IonCol>
                    <IonCol size="21">{i.Description}</IonCol>
                  </IonRow>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      ),
      OKText: "Ok",
      CancelText: "CLOSE",
    });
  };

  function getWorkColor(capacity: number, amountToBeWorked: number): string {
    const danger: string = "red";
    const medium: string = "orange";
    const low: string = "green";
    //devide capacity by 3
    const part = capacity / 2;
    if (amountToBeWorked <= part) {
      return low;
    }
    if (amountToBeWorked >= capacity) {
      return danger;
    }
    return medium;
  }
  if (show === true) {
    return (
      <IonItem>
        <IonGrid fixed={true}>
          <IonRow
            onClick={() => {
              setShow(false);
            }}
          >
            <IonCol>
              <h2 style={{ textTransform: "capitalize" }}>{props.title}</h2>
            </IonCol>
            <IonCol>
              <IonIcon
                onClick={() => {
                  setShow(false);
                }}
                icon={chevronDownOutline}
                size="large"
                style={{ float: "right" }}
                // slot="end"
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="1">
              <IonButton
                onClick={() => {
                  props.backWeekFunction();
                }}
              >
                <IonIcon icon={caretBack} />
              </IonButton>
            </IonCol>
            {props.data[0]
              ? props.data[0].WorkLoadStatistics.map((wls) => {
                  return (
                    <IonCol size="1">
                      <IonRow>{moment(wls.Date).format("DD-MM-YYYY")}</IonRow>
                      <IonRow>{moment(wls.Date).format("dddd")}</IonRow>
                    </IonCol>
                  );
                })
              : "N/A"}
            <IonCol size="1">
              <IonButton
                onClick={() => {
                  props.forwardWeekFunction();
                }}
              >
                <IonIcon icon={caretForward} />
              </IonButton>
            </IonCol>
          </IonRow>
          {props.data.map(
            (actionWorkLoadStatistic: ActionWorkLoadStatistics) => {
              return (
                <IonRow>
                  <IonCol size="1">{actionWorkLoadStatistic.Action}</IonCol>
                  {actionWorkLoadStatistic.WorkLoadStatistics.map(
                    (workLoadStatistic: WorkLoadStatistic) => {
                      return (
                        <IonCol size="1">
                          <IonItem
                            onClick={() => {
                              modalItems(workLoadStatistic);
                            }}
                          >
                            <p>{`${workLoadStatistic.Capacity}(`}</p>
                            <p
                              style={{
                                color: getWorkColor(
                                  workLoadStatistic.Capacity,
                                  workLoadStatistic.Assigned
                                ),
                              }}
                            >{`${workLoadStatistic.Assigned}`}</p>
                            <p>{`)`}</p>
                          </IonItem>
                        </IonCol>
                      );
                    }
                  )}
                </IonRow>
              );
            }
          )}
        </IonGrid>
      </IonItem>
    );
  } else {
    return (
      <IonItem
        onClick={() => {
          setShow(true);
        }}
      >
        <IonCol size="10">
          <h2 style={{ textTransform: "capitalize" }}>{props.title}</h2>
        </IonCol>
        <IonCol size="2">
          <IonIcon
            style={{ float: "right" }}
            icon={chevronUpOutline}
            size="large"
          />
        </IonCol>
      </IonItem>
    );
  }
};

export default inject("store")(observer(ProcessFeedbackBar));
