import React from "react";

import { observer, inject } from "mobx-react";

interface IColorPickerProps {
  color: string;
  select: Function;
}

export const ColorPicker: React.FC<IColorPickerProps> = ({ color, select }) => {
  return (
    <>
      <input
        type="color"
        value={color}
        onChange={(e) => {
          select(e.target.value);
        }}
        style={{ height: "100%" }}
      ></input>
    </>
  );
};

export default inject("store")(observer(ColorPicker));
