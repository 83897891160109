import { BaseService } from "./BaseService";
import { Statistics } from "../stores/models";

class StatisticsService extends BaseService {
  public async fetchStatsThisWeek(): Promise<Statistics[]> {
    try {
      const resp = await this.http.get("/api/v1/weeklyStats/thisWeek");
      return this.decodeArray<Statistics>(resp, Statistics);
    } catch (error) {
      throw error;
    }
  }

  public async getLogStateJourney(
    tid: Number, eid: Number, df: string, dt: string, state: string
  ): Promise<any> {
    try {
      return await this.http.get(`/api/v1/ticketLogStates/logStateJourney/query?tid=${tid}&eid=${eid}&dt=${dt}&df=${df}&s=${state}`)
    } catch (error) {
      throw error;
    }
  }
}

export default StatisticsService;
