import { Modifier } from "../../stores/models";
import {
  IonItem,
  IonLabel,
  IonImg,
  IonThumbnail,
  IonReorder,
  IonButton,
  IonIcon,
  IonCol,
  IonRow,
} from "@ionic/react";
import React from "react";
import "./ModifierListItem.css";
import { closeCircleOutline } from "ionicons/icons";

interface ModifierListItemProps {
  modifier: Modifier;
  reOrder?: string;
  deleteFunc?: Function;
}

const getImage = (modifier: Modifier): JSX.Element => {
  if (modifier.Image !== "") {
    if (modifier.Type === "COLOR") {
      //SHOW THE COLOR
      const imageColor = modifier.Image?.replace(/^color:/, "");
      return (
        <IonThumbnail slot="start">
          <div
            id={modifier.Name}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: `${imageColor}`,
            }}
          ></div>
        </IonThumbnail>
      );
    } else {
      //SHOW THE IMAGE
      return (
        <IonThumbnail slot="start">
          <IonImg src={modifier.Image ? modifier.Image : ""} />
        </IonThumbnail>
      );
    }
  } else
    return (
      <IonThumbnail slot="start">
        <IonImg src={modifier.Image ? modifier.Image : ""} />
      </IonThumbnail>
    );
};

const ModifierListItem: React.FC<ModifierListItemProps> = ({
  modifier,
  reOrder,
  deleteFunc,
}) => (
  <>
    <IonRow>
      <IonCol>
        <IonItem
          routerLink={"/modifiers/" + modifier.ID}
          routerDirection="forward"
        >
          {reOrder ? <IonReorder key={modifier.ID} slot={reOrder} /> : null}
          {getImage(modifier)}
          <IonLabel>
            <h2 style={{ textTransform: "capitalize" }}>{modifier.Name}</h2>
          </IonLabel>
          &nbsp; &nbsp; &nbsp;
          {`${modifier.IsPercentage ? "%" : "€"} ${modifier.UpCharge}`}
        </IonItem>
      </IonCol>
    </IonRow>
  </>
);

export default ModifierListItem;
