import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import { RootStore } from "../stores/RootStore";

import {
  CustomerAddress,
  CustomerContactDetails,
  CustomerPreferences,
  CustomerOtherDetails,
  Header,
  CustomerTickets,
} from "../components";
import { action, observable, runInAction } from "mobx";
import CustomerStatistics from "../components/CustomerStatistics/CustomerStatistics";
import SimpleCustomer from "../stores/models/SimpleCustomer";

interface CustomerDetailsProps {
  store: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class CustomerDetails extends React.Component<
  CustomerDetailsProps & RouteComponentProps<RouteProps>
> {

  ionViewDidEnter() {
    const {addresses} = this.props.store!.stores
    addresses.getLocalities()
    const id = parseInt(this.props.match.params.id);
    if (id === 0){
    this.props.store!.stores.customers.resetSimpleCustomer();
    } 
    this.getCustomer(id)
    console.log("did enter")
    if (id ===0){
      this.props.store!.stores.customers.simpleCustomer.Mobile = this.props.store!.stores.customers.mobile
    }
    if (id != this.props.store.stores.customers.customer.ID){
    }
  }

  @observable isLoading: boolean = true;
  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  componentDidMount() {
    const {addresses} = this.props.store!.stores
    addresses.getLocalities()
    const id = parseInt(this.props.match.params.id);
    if (id === 0){
      runInAction(()=>{
        this.props.store!.stores.customers.simpleCustomer = new SimpleCustomer();
      })
      this.setLoading(false)
      return
    }
    this.getDataFromApi().then(() => {
      console.log("no errors found customer and setting loading to false")
      this.setLoading(false);
    }).catch(error=>{
      console.log("errors have been  found customer and setting loading to false")
      this.setLoading(false)
    });
  }

  async getDataFromApi() {
    this.setLoading(true);
    const id = parseInt(this.props.match.params.id);
    console.log(id)
    await this.getCustomer(id).then(() => {
      this.setLoading(false);
    }).catch(()=>{
      this.setLoading(false)
    })
  }

  async getCustomer(id: number) {
    const {
      customers,
      addresses,
      preferences,
      tickets,
    } = this.props.store.stores;
    preferences.getPreferences();
    addresses.getLocalities();
    customers.getSimpleCustomerWithSimpleTicketsDetails(id);
  }

  reset() {
    const {
      customers,
      addresses,
      preferences,
      tickets,
    } = this.props.store.stores;
    customers.resetCustomerKeepMobile();
    addresses.resetAddress();
    preferences.resetCustomerPreferences();
    tickets.resetTickets();
  }

  componentWillUnmount() {
    this.reset();
  }

  createCustomer = async () => {
    const { customers, preferences, ui, addresses } = this.props.store.stores;
    try {
      // await this.createOrUpdateAddress();
      const c = await customers.createCustomer();
      runInAction(() => {
        customers.simpleCustomer = c;
        // addresses.address = c.Address;
      });
      await preferences.saveCustomerPreferences(c.ID);
      ui.showToast({ message: "Information Saved", color: "success" });
      // customers.getCustomerById(c.ID);
      this.props.history.replace(`/customers/${c.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  createOrUpdateAddress = async () => {
    const { addresses, customers } = this.props.store.stores;
    const { customer } = customers;
    if (addresses.isValid()) {
      if (addresses.address.ID === 0) {
        const a = await addresses.createAddress();
        customer.AddressID = a.ID;
      } else {
        addresses.updateAddress();
      }
    }
  };

  updateCustomer = async () => {
    const { customers, preferences, ui } = this.props.store.stores;
    const { customer } = customers;
    try {
      // await this.createOrUpdateAddress();
      await preferences.saveCustomerPreferences(customer.ID);
      await customers.updateCustomer();
      ui.showToast({ message: "Information Saved", color: "success" });
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { customers } = this.props.store.stores;
    const { customer } = customers;
    if (customers.simpleCustomer.ID === 0) {
      this.createCustomer();
    } else {
      this.updateCustomer();
    }
  };

  @action
  handleDelete = async (confirmed: boolean) => {
    const { customers, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    const { customer } = customers;
    if (customer.ID === 0) return;
    try {
      const s = await customers.deleteCustomer(customer.ID);
      if (s) {
        ui.showToast({ message: "Customer Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/customers");
    } catch (error) {
      const message = error.response.data.message
        ? error.response.data.message
        : "Something went wrong";
      ui.showToast({ message: message, color: "danger" });
    }
  };

  handleNewTicket = () => {
    const { customers, tickets } = this.props.store.stores;
    const { customer } = this.props.store.stores.customers;
    runInAction(() => {});
    const id = parseInt(this.props.match.params.id);
    this.props.history.push(`/customers/${id}/new-ticket/0`);
  };

  body() {
    const { simpleCustomer } = this.props.store.stores.customers;
    const { auth } = this.props.store.stores;
    return (
      <>
        <IonContent>
          <CustomerContactDetails handleSubmit={this.handleSubmit} />
          <CustomerAddress />
          <CustomerOtherDetails />
          <CustomerPreferences />
          {auth.isAdmin() ? <CustomerStatistics /> : <></>}

          <hr />
          <CustomerTickets />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start" style={{ paddingLeft: 5 }}>
              {simpleCustomer.ID !== 0 ? (
                <IonButton
                  color="warning"
                  expand="full"
                  fill="solid"
                  size="large"
                  onClick={this.handleNewTicket}
                >
                  New Ticket
                </IonButton>
              ) : null}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {simpleCustomer.ID !== 0 && simpleCustomer.TicketTotal<=0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }

  render() {
    const { simpleCustomer } = this.props.store.stores.customers;
    const title = simpleCustomer.ID === 0 ? "New Customer" : "Customer Details";
    if (this.isLoading) {
      return <IonSpinner />;
    }
    return (
      <IonPage>
        <Header title={title} backURL="/customers" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(CustomerDetails));
