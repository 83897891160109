import React from "react";
import {
  isPlatform,
  IonButton,
  IonBadge,
  IonLabel,
  IonCheckbox,
  IonSelectOption,
} from "@ionic/react";

import "./TicketItem.css";
import {
  TicketSubCategory,
  TicketSubCategoryAction,
  TicketSubCategoryModifier,
} from "../../stores/models";
import {
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonContent,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from "@ionic/react";
import {
  trashOutline,
  create,
  closeCircleOutline,
  pricetag,
  image,
  checkmarkCircle,
} from "ionicons/icons";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/RootStore";
import TextArea from "../TextArea/TextArea";
import PrinterSelect from "../PrinterSelect/PrinterSelect";
import TextField from "../TextField/TextField";
import { DatePicker } from "../DatePicker/DatePicker";
import { runInAction } from "mobx";
import moment from "moment";
import Tracking from "../../pages/Tracking";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import CustomSelect from "../CustomSelect/CustomSelect";
import { types } from "util";
import StateDropDown from "../StateDropDown/StateDropDown";
import { Console } from "console";

interface ITicketItem {
  store?: RootStore;
  item: TicketSubCategory;
  view?: boolean;
}

interface ITicketItems {
  items: TicketSubCategory[];
  store?: RootStore;
  showCurrentOnly?: boolean;
  className?: string;
  view?: boolean;
}

interface ITicketActions {
  actions: TicketSubCategoryAction[];
  store?: RootStore;
  ticketSubCategory: TicketSubCategory;
}

interface ITicketModifiers {
  modifiers: TicketSubCategoryModifier[];
  store?: RootStore;
}

const TicketItems: React.FC<ITicketItems> = inject("store")(
  observer(({ items, className, view }) => {
    if (items.length === 0) return null;
    return (
      <IonContent className={className!}>
        <IonList>
          {items.map((i) => {
            return <TicketItem key={i.ID} item={i} view={view} />;
          })}
        </IonList>
      </IonContent>
    );
  })
);

const TicketActions: React.FC<ITicketActions> = inject("store")(
  ({ actions, store, ticketSubCategory: item }) => {
    const renderLabel = () => {
      let label = "";
      actions.forEach((a) => {
        if (a.Name[0] === undefined) {
          return "N/A";
        }
        label += a!.Name[0].toUpperCase() + "(€ " + a.Price + ")";
      });
      return label;
    };
    return <div className="ticket-action-label">{renderLabel()}</div>;
  }
);

const TicketModifiers: React.FC<ITicketModifiers> = inject("store")(
  ({ modifiers, store }) => {
    const getLabel = (id: number) => {
      const modifier = store!.stores.modifiers.getModifierById(id);
      if (modifier === undefined) return;
      let modifierParent = store!.stores.modifiers.getModifierById(
        modifier.ParentID!
      );
      if (modifierParent === undefined) {
        return (
          modifier.Name +
          (modifier.IsPercentage === false ? "(€ " : "(") +
          modifier.UpCharge +
          (modifier.IsPercentage === true ? " %)" : ")")
        );
      }
      return (
        modifierParent.Name +
        ": " +
        modifier.Name +
        "(€ " +
        modifier.UpCharge +
        ")"
      );
    };

    const deleteSubCategoryModifier = (id: number) => {
      const ticketStore = store?.stores.tickets;
      ticketStore?.deleteSubCategoryModifier(id);
    };

    return (
      <div>
        {modifiers.map((m) => {
          return (
            <span key={m.ID} className="ticket-modifier-label">
              {getLabel(m.ModifierID)}&nbsp;
              <IonIcon
                color={"danger"}
                slot="icon-only"
                icon={closeCircleOutline}
                onClick={() => {
                  deleteSubCategoryModifier(m.ID);
                }}
              />
            </span>
          );
        })}
      </div>
    );
  }
);

const TicketItem: React.FC<ITicketItem> = inject("store")(
  observer(({ item, store, view }) => {
    let saveTimer: any;
    let timer: any;
    const { garments, tickets, ui, statistics, customers, tracking } =
      store!.stores;
    const isSelected = tickets.selectedGarment === item.ID;
    const garmentSubCategory = garments.getGarmentSubCategory(
      item.GarmentSubCategoryID
    );

    const handleClick = () => {
      tickets.selectGarment(item.ID);
    };
    const handleDoubleTap = () => {
      // if (!view) {
      handleItemDoubleTap(item.ID);
      // }
    };

    function handleItemDoubleTap(itemID: number) {
      if (tickets.selectedGarment === itemID) {
        handleAddNote();
      }
    }

    const handleDeletePiece = async (
      ticketSubCategory: TicketSubCategory,
      piece: string
    ) => {
      const { tickets, ui } = store!.stores;
      // if its the only piece left then show error
      if (ticketSubCategory.Pieces.trim() === piece.trim()) {
        ui.showToast({
          message: "Must have at least 1 piece",
          color: "danger",
        });
        return;
      }
      ticketSubCategory.updateSelectedPieces(piece);
      const ok = await tickets.saveTicketSubCategory(
        ticketSubCategory.ID,
        ticketSubCategory
      );
    };

    const handleDelete = async () => {
      const { tickets } = store!.stores;
      await tickets.deleteSubCategory(item.ID);
    };

    const handleSave = async () => {
      const { tickets } = store!.stores;
      await tickets.saveTicketSubCategory(item.ID, item);
      ui.closeDialog();
    };

    const handlePrintLabel = async () => {
      // store!.stores.printer.reset();
      ui.showDialog({
        title: "Select Printer",
        content: <PrinterSelect />,
        OKText: "PRINT",
        CancelText: "CLOSE",
        handler: async () => {
          tickets.printItemLabels(tickets.ticket, item);
          await tracking.setTicketSubCategoriesState([item.ID], "MARKED", "");
          let tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          selectProcessDate(moment(tomorrow).format("YYYY-MM-DD"));
        },
      });
    };

    const getImagesForTicketItem = (itemID: number): JSX.Element[] => {
      const images: JSX.Element[] = [];
      if (itemID === 0) {
        return images;
      }
      tickets.ticket.SubCategories.forEach((tsc) => {
        if (tsc.ID === itemID) {
          tsc.Actions.forEach((tsca) => {
            if (tsca.Images !== "") {
              images.push(
                <img
                  src={tsca.Images}
                  className={"images"}
                  alt="Ticket Item Image"
                />
              );
            }
          });
        }
      });
      return images;
    };

    const handleShowImages = async () => {
      const images = getImagesForTicketItem(item.ID);
      ui.showDialog({
        title: "Select Printer",
        content: <div className={"centerContent"}>{images}</div>,
        CancelText: "CLOSE",
      });
    };

    const updateQuantity = async (v: string) => {
      if (
        isSelected === true &&
        item.Quantity !== parseFloat(v) &&
        parseFloat(v) > 0
      ) {
        console.log("value changed for override prices");
        item.Quantity = parseFloat(v);
        console.log(item.Quantity);
        clearTimeout(saveTimer);
        saveTimer = setTimeout(function () {
          handleSave();
        }, 1000);
      }
    };

    // TODO: refactor elsewere
    const handleAddNote = () => {
      console.log(view);
      tickets.selectGarment(item.ID);
      ui.showDialog({
        title: item.Description,
        content: (
          <>
            {/* {view === false || view === undefined ? ( */}
              <TextArea
                rows={20}
                spellCheck={true}
                autoGrow={false}
                autoFocus={true}
                label="Comments"
                onChange={(v: string) => {
                  item.Comments = v;
                }}
                value={item!.Comments}
              />
            {/*  ) : null} */}
          </>
        ),
        handler: handleSave,
      });
    };

    if (garmentSubCategory === undefined) return null;

    const renderMenu = () => (
      <IonItemOptions side="end">
        <IonItemOption color="primary" onClick={handleAddNote}>
          &nbsp;&nbsp;
          <IonIcon icon={create} size="medium" />
          &nbsp;&nbsp;
        </IonItemOption>
        <IonItemOption color="danger" onClick={handleDelete}>
          &nbsp;&nbsp;
          <IonIcon icon={trashOutline} size="medium" />
        </IonItemOption>
        <IonItemOption color="danger" onClick={handleDelete}>
          &nbsp;&nbsp;
          <IonIcon icon={trashOutline} size="medium" />
          &nbsp;&nbsp;
        </IonItemOption>
      </IonItemOptions>
    );

    const renderViewMenu = () => (
      <IonItemOptions side="end">
        <IonItemOption color="primary" onClick={handlePrintLabel}>
          &nbsp;&nbsp;
          <IonIcon icon={pricetag} size="medium" />
          &nbsp;&nbsp;
        </IonItemOption>
      </IonItemOptions>
    );

    const updateValue = async (v: string) => {
      // if (
      //   isSelected === true &&
      //   item.TotalOverride !== parseFloat(v) &&
      //   parseFloat(v) !== item.Total
      // ) {
      //   item.TotalOverride = parseFloat(v);
      //   clearTimeout(saveTimer);
      //   saveTimer = setTimeout(function () {
      //     handleSave();
      //   }, 1000);
      // }
      if (
        isSelected === true &&
        item.TotalOverride !== parseFloat(v) &&
        parseFloat(v) !== item.Total
      ) {
        item.TotalOverride = parseFloat(v);
        handleSave();
      }
    };

    const selectProcessDate = (e: string) => {
      runInAction(async () => {
        await tickets.updateTicketSubCategoryPorcessDate(item.ID, e);
        // await tracking.setTicketSubCategoriesState([item.ID], 'MARKED', '')
        await statistics.getActionWorkLoadStatisticsByWeek(
          statistics.yearNumber,
          statistics.weekNumber
        );
      });
    };

    const setSorted = async (piece: string) => {
      await tickets.toggleTicketSubCategoryPieceSorted(
        item.ID,
        encodeURIComponent(piece)
      );
    };

    const renderItem = () => {
      const pieces = item.Pieces.split(",");
      return (
        <IonItem
          onClick={() => {
            handleClick();
          }}
          className={"ticket-item" + (isSelected ? " selected" : "")}
        >
          <IonGrid style={{ paddingLeft: 0 }}>
            <IonRow
              style={
                view === true ? { backgroundColor: item.backgroundColor() } : {}
              }
              // style={
              //   item.Comments.trim() != "" && view
              //     ? { backgroundColor: "green" }
              //     : {}
              // }
            >
              <IonCol
                onClick={handleDoubleTap}
                sizeXs="3"
                sizeLg={view ? "1" : "3"}
                style={{ paddingLeft: 0 }}
              >
                <TicketActions
                  actions={item.Actions}
                  ticketSubCategory={item}
                />
              </IonCol>
              <IonCol
                sizeXs="8"
                sizeLg={
                  view
                    ? "2"
                    : garmentSubCategory.AllowQuantity === true
                    ? "2"
                    : "6"
                }
              >
                <h4
                  style={{ paddingTop: 0, marginTop: 0 }}
                  className="ticket-item-description"
                >
                  {garmentSubCategory.Name}
                </h4>
                {item.Pieces !== "" ? (
                  <IonRow>
                    <IonCol style={{ paddingLeft: 0 }}>
                      pieces:&nbsp;
                      {pieces.map((p, index) => {
                        return (
                          <span
                            key={index}
                            className="ticket-modifier-label"
                            style={{ fontSize: "16px", fontWeight: "normal" }}
                          >
                            <IonCol
                              className={
                                item.SortedItems.split(",").find((i) => i == p)
                                  ? view === true
                                    ? "sorted"
                                    : ""
                                  : ""
                              }
                              onClick={() => {
                                if (view) {
                                  setSorted(p);
                                }
                              }}
                            >
                              {p}
                            </IonCol>
                            {view === true ? (
                              <></>
                            ) : (
                              <>
                                &nbsp;
                                <IonIcon
                                  color={"danger"}
                                  slot="icon-only"
                                  icon={closeCircleOutline}
                                  onClick={() => {
                                    handleDeletePiece(item, p);
                                  }}
                                />
                              </>
                            )}
                            {/*  */}
                          </span>
                        );
                      })}
                    </IonCol>
                  </IonRow>
                ) : null}

                {item.Modifiers.length > 0 ? (
                  <IonRow>
                    <IonCol style={{ paddingLeft: 0 }}>
                      <TicketModifiers modifiers={item.Modifiers} />
                    </IonCol>
                  </IonRow>
                ) : null}

                {item.Comments.trim() !== "" ? (
                  <IonRow>
                    <IonCol sizeXs="12" style={{ paddingLeft: 0 }}>
                      <small>{item.Comments}</small>
                    </IonCol>
                  </IonRow>
                ) : null}
              </IonCol>

              {view ? (
                <IonCol>
                  <IonRow>
                    <DatePicker
                      date={moment(item.ProcessDate!).format("YYYY-MM-DD")}
                      select={selectProcessDate}
                    ></DatePicker>
                    <TextArea
                      rows={1}
                      spellCheck={true}
                      autoGrow={false}
                      autoFocus={true}
                      label="Location"
                      onChange={(v: string) => {
                        item.Location = v;
                      }}
                      onBlur={() => {
                        handleSave();
                      }}
                      value={item!.Location}
                    />
                  </IonRow>
                </IonCol>
              ) : null}

              {view ? (
                <IonCol>
                  <IonItem>
                    <IonLabel position="stacked">Sorted</IonLabel>
                    <IonCheckbox disabled={true} checked={item.Sorted} />
                  </IonItem>
                </IonCol>
              ) : null}

              {view ? (
                <>
                  <IonCol className="hide-xs">
                    <StateDropDown
                      store={store!}
                      elements={store!.stores.tracking.states.map((s) => s)}
                      selected={item.State}
                      onSelect={async (s: string, sig: string) => {
                        console.log(`item selected ${s}`);
                        await store?.stores.tracking.setTicketSubCategoriesState(
                          [item.ID],
                          s,
                          sig
                        );
                        store?.stores.tickets.reloadTicket();
                      }}
                    />
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="stacked">Print</IonLabel>
                      <IonCheckbox
                        checked={
                          tickets.ticketSubCategoriesToPrint.find(
                            (ttp) => ttp === item.ID
                          )
                            ? true
                            : false
                        }
                        onIonChange={(e) => {
                          e.detail.checked === true
                            ? runInAction(() => {
                                tickets.ticketSubCategoriesToPrint.push(
                                  item.ID
                                );
                              })
                            : runInAction(() => {
                                tickets.ticketSubCategoriesToPrint =
                                  tickets.ticketSubCategoriesToPrint.filter(
                                    (ttp: number) => {
                                      return ttp !== item.ID;
                                    }
                                  );
                              });
                          console.log(e.detail.checked);
                        }}
                      />
                    </IonItem>
                  </IonCol>
                </>
              ) : null}

              {garmentSubCategory.AllowQuantity === true ? (
                <IonCol sizeLg={view ? "1" : "2"}>
                  <TextField
                    itemStyles={{ width: "98px" }}
                    inputStyles={{ width: "68px" }}
                    // disabled={view ? true : false}
                    label="Q"
                    type="number"
                    value={item.Quantity}
                    onChange={(v: string) => {
                      updateQuantity(v);
                    }}
                  />
                </IonCol>
              ) : (
                <></>
              )}

              <IonCol
                style={{ width: "20px;" }}
                sizeLg={
                  view
                    ? garmentSubCategory.AllowQuantity === true
                      ? "1"
                      : "1"
                    : "3"
                }
                offsetLg={
                  view
                    ? garmentSubCategory.AllowQuantity === true
                      ? "0"
                      : "1"
                    : "0"
                }
              >
                <TextField
                  // disabled={view ? true : false}
                  label="&euro;&nbsp;"
                  // prefix={<>&euro;&nbsp;</>}
                  type={"number"}
                  value={
                    item.TotalOverride
                      ? item.TotalOverride.toFixed(2)
                      : item.Total.toFixed(2)
                  }
                  onBlur={(v: any) => {
                    console.log(v.target.value);
                    updateValue(v.target.value);
                  }}
                  // onChange={(v: string) => {
                  //   updateValue(v);
                  // }}
                />
              </IonCol>

              {view ? (
                <IonCol className="hide-xs">
                  <IonButton
                    style={{ float: "right" }}
                    fill="default"
                    onClick={handlePrintLabel}
                  >
                    <IonIcon icon={pricetag} size="medium"></IonIcon>
                  </IonButton>
                  <div style={{ clear: "both" }}></div>
                </IonCol>
              ) : null}

              {view === true ? (
                <>
                  {/* <IonCol size="1"> */}
                  <IonCol style={{ width: "20px;" }}>
                    <TextField

                  label="&euro;&nbsp;"
                      // prefix={<>&euro;&nbsp;</>}
                      value={item.TotalPaid ? item.TotalPaid : 0.00}
                      type={"number"}
                      onBlur={(v: any) => {
                        runInAction(async () => {
                          item.TotalPaid = v.target.value;
                          await tickets.updatePayment(item.ID, item.TotalPaid!);
                          await tickets.getAllForCustomer(
                            customers.customer.ID
                          );
                          await customers.reloadCustomer();
                        });
                      }}
                    />
                  </IonCol>
                </>
              ) : null}

              {/* only if item has images */}
              <IonCol className="hide-xs">
                {getImagesForTicketItem(item.ID).length > 0 && view ? (
                  <IonButton fill="default" onClick={handleShowImages}>
                    <IonIcon icon={image} size="medium"></IonIcon>
                  </IonButton>
                ) : null}
                <div style={{ clear: "both" }}></div>
              </IonCol>

              {view ? (
                <>
                  <IonCol>
                    <IonButton
                      style={{ float: "right" }}
                      // color="danger"
                      color={
                        item.getTotal() === item.TotalPaid!
                          ? "success"
                          : "danger"
                      }
                      onClick={async () => {
                        await tickets.updatePayment(item.ID, item.getTotal());
                      }}
                    >
                      {item.getTotal() - item.TotalPaid! === 0
                        ? "PAID"
                        : (item.getTotal() - item.TotalPaid!).toFixed(2)}
                    </IonButton>
                  </IonCol>
                </>
              ) : null}
            </IonRow>
          </IonGrid>
        </IonItem>
      );
    };

    const mobileView = () => (
      <IonItemSliding>
        {view === true ? renderViewMenu() : renderMenu()}
        {renderItem()}
      </IonItemSliding>
    );

    const desktopView = () => renderItem();

    return isPlatform("desktop") ? desktopView() : mobileView();
  })
);

export default TicketItems;
