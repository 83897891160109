import { BaseService, IResponse } from "./BaseService";
import { GarmentCategory, GarmentSubCategory } from "../stores/models";

class GarmentService extends BaseService {
  
  public async sendStatusMessage(ticketID:number): Promise<boolean> {
    try {
      const resp = await this.http.post(`/api/v1/messages/${ticketID}/status`);
      return true
    } catch (error) {
      throw error;
    }
  }

}

export default GarmentService;
