import { JsonObject, JsonProperty } from "json2typescript";
import { Address } from "./Address";

@JsonObject("Agent")
class Agent {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("address_id", Number)
  AddressID: number = 0;

  @JsonProperty("commission", Number)
  Commission: number = 0;

  @JsonProperty("user_name", String)
  UserName: string = "";

  @JsonProperty("landline", String)
  Landline: string = "";

  @JsonProperty("mobile", String)
  Mobile: string = "";

  @JsonProperty("type", String)
  Type: string = "";

  @JsonProperty("contact_person", String)
  ContactPerson: string = "";

  @JsonProperty("email_address", String)
  EmailAddress: string = "";

  @JsonProperty("queens", String)
  Queens: string = "";

  @JsonProperty("detail", String, true)
  Detail: string = "";

  @JsonProperty("can_see_action", String, true)
  CanSeeAction: string = "";

  @JsonProperty("address", Address, true)
  Address: Address = new Address();
}

export default Agent;
