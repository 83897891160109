import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import "./Accordion.css";
import {
  IonReorderGroup,
  IonItemDivider,
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { repeatOutline, addSharp, removeSharp } from "ionicons/icons";

interface IAccordions {
  // onSelect: Function;
  elements: (JSX.Element | undefined)[];
  title: string;
  reOrderEvent: Function;
  reOrderItems: Function;
}

const Accordions: React.FC<IAccordions> = ({
  elements,
  title,
  reOrderEvent,
  reOrderItems,
}) => {
  const [reOrder, setReorder] = useState(true);
  const [show, setShow] = useState(false);
  return (
    <>
      <IonItemDivider>
        <IonLabel
          style={{
            width: "100%",
            cursor: "pointer",
            margin: "0px",
            height: "100%",
            lineHeight: "230%",
          }}
          onClick={() => {
            setShow(!show);
          }}
        >
          {title}
        </IonLabel>
        <IonButtons slot={"end"}>
          <IonButton
            onClick={() => {
              if (reOrder) {
                setReorder(!reOrder);
              } else {
                setReorder(!reOrder);
                reOrderItems();
              }
            }}
          >
            <IonIcon icon={repeatOutline} size="large" />
          </IonButton>
          {show === false ? (
            <IonButton
              onClick={() => {
                setShow(!show);
              }}
            >
              <IonIcon icon={addSharp} size="large" slot="end" />
            </IonButton>
          ) : (
            <IonButton
              onClick={() => {
                setShow(!show);
              }}
            >
              <IonIcon icon={removeSharp} size="large" slot="end" />
            </IonButton>
          )}
        </IonButtons>
      </IonItemDivider>
      <IonReorderGroup
        disabled={reOrder}
        onIonItemReorder={(e) => {
          reOrderEvent(e, title);
        }}
      >
        {show === true ? elements : <></>}
      </IonReorderGroup>
    </>
  );
};

export default inject("store")(observer(Accordions));
