import { BaseService, IResponse } from "./BaseService";
import {
  Ticket,
  TicketSubCategory,
  TicketSubCategoryModifier,
  TicketSubCategoryAction,
  TicketSubCategoryLogState,
  TicketLogStateHistory,
  ExpressItem,
} from "../stores/models";
import moment from "moment";
import SimpleTicket from "../stores/models/SimpleTicket";

class TicketService extends BaseService {
  public async fetchAll(): Promise<Ticket[]> {
    try {
      const resp = await this.http.get(
        "/api/v1/tickets/?confirmed_at=true"
      );
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }
  public async fetchAllLogsByTicket(ticketID:number): Promise<TicketLogStateHistory[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/ticketLogStates/${ticketID}`
      );
      return this.decodeArray<TicketLogStateHistory>(resp, TicketLogStateHistory);
    } catch (error) {
      throw error;
    }
  }
  public async fetchAllNotClosed(): Promise<Ticket[]> {
    try {
      const resp = await this.http.get(
        "/api/v1/tickets/?confirmed_at=true&notState=CLOSED"
      );
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }
  public async fetchAllNotClosedLimitOffset(limit:number, offset:number, withChildren:boolean, tab:string): Promise<Ticket[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/?confirmed_at=true&notState=CLOSED&limit=${limit}&offset=${offset}&withChildren=${withChildren}&tab=${tab}`
      );
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }
  public async fetchSimpleTickets(limit:number, offset:number, withChildren:boolean, tab:string): Promise<SimpleTicket[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/?confirmed_at=true&notState=CLOSED&limit=${limit}&offset=${offset}&withChildren=${withChildren}&tab=${tab}`
      );
      return this.decodeArray<SimpleTicket>(resp, SimpleTicket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchSimpleTicketsByDate(limit:number, offset:number, date:string, withChildren:boolean, tab:string): Promise<SimpleTicket[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/?confirmed_at=true&notState=CLOSED&limit=${limit}&offset=${offset}&date=${date}&withChildren=${withChildren}&tab=${tab}`
      );
      return this.decodeArray<SimpleTicket>(resp, SimpleTicket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchAllNotClosedLimitOffsetByDate(limit:number, offset:number, date:string, withChildren:boolean, tab:string): Promise<Ticket[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/?confirmed_at=true&notState=CLOSED&limit=${limit}&offset=${offset}&date=${date}&withChildren=${withChildren}&tab=${tab}`
      );
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchLimitOffsetTerm(limit:number, offset:number, term:string, confirmedAt:string): Promise<Ticket[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/?confirmed_at=${confirmedAt}&limit=${limit}&offset=${offset}&term=${term}`
      );
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchExpressItems(): Promise<ExpressItem[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/ticketSubCategory/express/`
      );
      return this.decodeArray<ExpressItem>(resp, ExpressItem);
    } catch (error) {
      throw error;
    }
  }

  public async fetchAllConfirmedAndUnconfirmed(): Promise<Ticket[]> {
    try {
      const resp = await this.http.get("/api/v1/tickets/");
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async download(ticketID:number, ticketSubCategoriesToBePrinted:number[]):Promise<any>{
    try {
      const url = `/api/v1/tickets/pdf/${ticketID}?ticketSubCategories=${ticketSubCategoriesToBePrinted}`
      return  await this.http.get(url, {
        responseType: 'blob', // had to add this one here
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ticket${ticketID} ${moment(new Date).format(`DD-MM-YYYY`)}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
      // return await this.http.get(`/api/v1/tickets/pdf/${ticketID}?ticketSubCategories=${ticketSubCategoriesToBePrinted}`);
    } catch (error) {
      throw error;
    }
  }

  public async preview(ticketID:number, ticketSubCategoriesToBePrinted:number[]):Promise<string>{
    try {
      const resp = await this.http.get(`/api/v1/tickets/pdf/${ticketID}?ticketSubCategories=${ticketSubCategoriesToBePrinted}&preview=true`);
      return resp.headers['filename']
    } catch (error) {
      throw error;
    }
  }

  public async fetchAllForCustomer(customerID: number): Promise<Ticket[]> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/customer/${customerID}`
      );
      return this.decodeArray<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOne(id: number): Promise<Ticket> {
    try {
      const resp = await this.http.get(`/api/v1/tickets/${id}`);
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOneSubCategory(id: number): Promise<TicketSubCategory> {
    try {
      const resp = await this.http.get(`/api/v1/ticketSubCategory/${id}`);
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async fetchLastOpenForCustomer(customerID: number): Promise<Ticket> {
    try {
      const resp = await this.http.get(
        `/api/v1/tickets/lastnonconfirmed/${customerID}`
      );
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async fetchOneWithChildren(id: number): Promise<Ticket> {
    try {
      const resp = await this.http.get(`/api/v1/tickets/children/${id}`);
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async create(ticket: Ticket): Promise<Ticket> {
    try {
      const payload = this.encodeObject<Ticket>(ticket, Ticket);
      delete payload["employee"];
      delete payload["agent"];
      delete payload["customer"];
      const resp = await this.http.post("/api/v1/tickets/", payload);
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async delete(id: number): Promise<Ticket> {
    try {
      const resp = await this.http.delete(`/api/v1/tickets/${id}`);
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async reschedule(id: number, date: string): Promise<Ticket> {
    try {
      const resp = await this.http.get(`/api/v1/tickets/${id}?date=${date}`);
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async addSubCategory(
    tsc: TicketSubCategory, copies:number
  ): Promise<TicketSubCategory> {
    try {
      const payload = this.encodeObject<TicketSubCategory>(
        tsc,
        TicketSubCategory
      );
      const resp = await this.http.post(`/api/v1/ticketSubCategory/?copies=${copies}`, payload);
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async duplicateGarmentSubCategory(id:number,quantity:number):Promise<TicketSubCategory[]>{
    try {
      const resp = await this.http.post(`/api/v1/ticketSubCategory/duplicate/${id}/quantity/${quantity}`);
      return this.decodeArray<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async updateSubCategory(
    id: number,
    tsc: TicketSubCategory
  ): Promise<TicketSubCategory> {
    try {
      const payload = this.encodeObject<TicketSubCategory>(
        tsc,
        TicketSubCategory
      );
      const resp = await this.http.put(
        `/api/v1/ticketSubCategory/${id}`,
        payload
      );
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async updatePayment(
    ticketSubCategoryID: number,
    amount: number
  ): Promise<TicketSubCategory> {
    try {
      if (!amount) {
        amount = 0;
      }
      const resp = await this.http.put(
        `/api/v1/ticketSubCategory/paidFor/${ticketSubCategoryID}/${amount}`
      );
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }
  public async updatePaymentInFull(
    ticketSubCategoryID: number,
  ): Promise<TicketSubCategory> {
    try {
      const resp = await this.http.put(
        `/api/v1/ticketSubCategory/paidFor/${ticketSubCategoryID}/0?full=true`
      );
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async updateTicketPaidInFull(
    ticketID: number,
  ): Promise<SimpleTicket> {
    try {
      const resp = await this.http.put(
        `/api/v1/tickets/paidFor/${ticketID}`
      );
      return this.decodeObject<SimpleTicket>(resp, SimpleTicket);
    } catch (error) {
      throw error;
    }
  }

  public async updateSubCategoryPieceSorted(
    id: number,
    piece: string
  ): Promise<TicketSubCategory> {
    try {
      const resp = await this.http.put(
        `/api/v1/ticketSubCategory/sorted/${id}?item=${piece}`
      );
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async updateSubCategoryProcessDate(
    id: number,
    date: string
  ): Promise<TicketSubCategory> {
    const formatedDate =
      moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm:ss");
    try {
      const resp = await this.http.put(
        `/api/v1/ticketSubCategory/processDate/${id}/${formatedDate}`
      );
      return this.decodeObject<TicketSubCategory>(resp, TicketSubCategory);
    } catch (error) {
      throw error;
    }
  }

  public async reschedulePickupDate(id: number, date: string): Promise<Ticket> {
    const formatedDate =
      moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm:ss");
    try {
      const resp = await this.http.put(
        `/api/v1/tickets/${id}/pickup?date=${formatedDate}`,
        null
      );
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async rescheduleDeliveryDate(
    id: number,
    date: string
  ): Promise<Ticket> {
    let formatedDate =
      moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm:ss");
    if (date === "") {
      formatedDate = "";
    }
    try {
      const resp = await this.http.put(
        `/api/v1/tickets/${id}/delivery?date=${formatedDate}`,
        null
      );
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async updateDiscount(
    id:number,
    discount: string
  ): Promise<Ticket> {
    try {
      const resp = await this.http.put(
        `/api/v1/tickets/${id}/discount?discount=${discount}`,
        null
      );
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  // public async reschedulePickupDateDeliveryOptions(
  //   id: number,
  //   date: string
  // ): Promise<Ticket> {
  //   const formatedDate =
  //     moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm:ss");
  //   try {
  //     const resp = await this.http.put(
  //       `/api/v1/tickets/${id}/deliveryPickupOptions?date=${formatedDate}`,
  //       null
  //     );
  //     return this.decodeObject<Ticket>(resp, Ticket);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  public async updateDeliveryPickupOptions(
    id: number,
    date: string,
    delivery: boolean,
    pickup: boolean,
    pickupComment:string,
    deliveryComment:string,
  ): Promise<Ticket> {
    const formatedDate =
      moment(date).format("YYYY-MM-DD") + " " + moment(date).format("hh:mm:ss");
    try {
      const resp = await this.http.put(
        `/api/v1/tickets/${id}/deliveryPickupOptions?date=${formatedDate}&delivery=${delivery}&pickup=${pickup}&pickupComment=${pickupComment}&deliveryComment=${deliveryComment}`,
        null
      );
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async setDelivery(id: number, value: boolean): Promise<Ticket> {
    try {
      const resp = await this.http.put(
        `/api/v1/tickets/${id}/delivery/${value}`,
        null
      );
      return this.decodeObject<Ticket>(resp, Ticket);
    } catch (error) {
      throw error;
    }
  }

  public async deleteSubCategory(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/ticketSubCategory/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async addSubCategoryModifier(
    tsm: TicketSubCategoryModifier
  ): Promise<TicketSubCategoryModifier> {
    try {
      const payload = this.encodeObject<TicketSubCategoryModifier>(
        tsm,
        TicketSubCategoryModifier
      );
      const resp = await this.http.post(
        `/api/v1/ticketSubCategoryModifier/`,
        payload
      );
      return this.decodeObject<TicketSubCategoryModifier>(
        resp,
        TicketSubCategoryModifier
      );
    } catch (error) {
      throw error;
    }
  }

  public async deleteSubCategoryModifier(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(
        `/api/v1/ticketSubCategoryModifier/${id}`
      );
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async addImagesToSubCategory() {
    // TODO: implement
  }

  public async addSubCategoryAction(
    tsa: TicketSubCategoryAction
  ): Promise<TicketSubCategoryAction> {
    try {
      const payload = this.encodeObject<TicketSubCategoryAction>(
        tsa,
        TicketSubCategoryAction
      );
      const resp = await this.http.post(
        "/api/v1/ticketSubCategoryActions/",
        payload
      );
      return this.decodeObject<TicketSubCategoryAction>(
        resp,
        TicketSubCategoryAction
      );
    } catch (error) {
      throw error;
    }
  }

  public async deleteSubCategoryAction(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(
        `/api/v1/ticketSubCategoryActions/${id}`
      );
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async confirmTicket(id: number): Promise<Ticket> {
    try {
      const resp = await this.http.put(`/api/v1/tickets/${id}/confirm`);
      // const data: IResponse = resp.data;
      const ticket = this.decodeObject<Ticket>(resp, Ticket);
      return ticket;
    } catch (error) {
      throw error;
    }
  }

  public async createSubCategoryState(tscs: TicketSubCategoryLogState) {
    // TODO: imeplement
  }

  public async addImagesToSubCategoryAction(images: string[]) {
    // TODO: implement
  }
}

export default TicketService;
