import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import { Employee } from "./models";
import { EmployeeService } from "../services";
import Fuse from "fuse.js";

class EmployeeStore {
  private root: RootStore;
  private api: EmployeeService;

  @observable public employees: Employee[] = [];
  @observable private _employees: Employee[] = [];
  @observable public employee: Employee = new Employee();

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.employees;
  }

  public async getEmployees() {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const employees = await this.api.fetchAll();
      this.setEmployees(employees, true);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async getEmployeeById(id: number) {
    const { ui } = this.root.stores;
    this.setEmployee(new Employee());
    ui.setLoading(true);
    try {
      const employee = await this.api.fetchById(id);
      this.setEmployee(employee);
      this.replaceEmployee(employee);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async filterEmployees(q: string) {
    if (q.trim() === "") {
      this.setEmployees(this._employees);
      return;
    }
    const options: any = {
      threshold: 0,
      distance: 10,
      keys: ["firstName", "lastName", "Landline", "Mobile", "Role"],
    };
    const fuse = new Fuse(this._employees, options);
    const employees = fuse.search(q).map((a) => a.item);
    this.setEmployees(employees);
  }

  public async createEmployee(): Promise<Employee> {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const employee = await this.api.create(this.employee);
      runInAction(() => {
        this.employees.push(employee);
        this._employees.push(employee);
      });
      return employee;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async updateEmployee(id: number, employee: Employee) {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      await this.api.update(id, employee);
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async updateEmployeeLocalities(localityID: number) {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const newLocalityIDS = this.updateEmployeeLocality(localityID)
      const employee = await this.api.updateLocalityIDS(
        newLocalityIDS,
        this.employee.ID
      );
      runInAction(() => {
        this.employee = employee;
      });
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public updateEmployeeLocality(localityID: number): number[] {
    const localityIDS = this.employee.Localities.split(",").map((x) => +x);
    const index = localityIDS.findIndex((lid) => lid === localityID);
    // let newLocalityIDS:number[]=[]
    if (index >= 0) {
      localityIDS.splice(index, 1);
      return localityIDS;
    } else {
      return localityIDS.concat(localityID);
    }
  }

  public async clearOwings(employeeID: number) {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const e = await this.api.clearOwings(employeeID);
      this.replaceEmployee(e);
      runInAction(() => {
        this.employee = e;
      });
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async clearOwingsAndClose(employeeID: number) {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const e = await this.api.clearOwingsAndClose(employeeID);
      this.replaceEmployee(e);
      runInAction(() => {
        this.employee = e;
      });
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }
  
  public async saveEmployee(): Promise<boolean> {
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      // TODO: parse response or employee ?
      await this.api.update(this.employee.ID, this.employee);
      this.replaceEmployee(this.employee);
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async deleteEmployee(id: number): Promise<boolean> {
    if (this.employee.ID === 0) {
      return false;
    }
    const { ui } = this.root.stores;
    ui.setLoading(true);
    try {
      const resp = await this.api.delete(id);
      if (resp.success) {
        runInAction(() => {
          const employeesToFilter = this.employees;
          this.employees = employeesToFilter.filter((c) => c.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  @action
  public replaceEmployee(employee: Employee) {
    for (var i = 0; i < this.employees.length; i++) {
      if (this.employees[i].ID === employee.ID) {
        this.employees[i] = employee;
        break;
      }
    }
    for (i = 0; i < this._employees.length; i++) {
      if (this._employees[i].ID === employee.ID) {
        this._employees[i] = employee;
        break;
      }
    }
  }

  @action
  public resetEmployee() {
    this.employee = new Employee();
  }

  @action
  public resetEmployees() {
    this.employees = [];
    this._employees = [];
  }

  @action
  public setEmployee(employee: Employee) {
    this.employee = employee;
  }

  @action
  public setEmployees(employees: Employee[], reload: boolean = false) {
    if (reload) {
      this._employees = employees;
    }
    this.employees = employees;
  }
}

export default EmployeeStore;
