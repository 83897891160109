import { BaseService, IResponse } from "./BaseService";
import BulkLogState from "../stores/models/BulkLogState";

class TrackingService extends BaseService {
  public async setTicketItemsStates(bls: BulkLogState): Promise<boolean> {
    try {
      const payload = this.encodeObject(bls, BulkLogState);
      const resp = await this.http.put(
        "/api/v1/ticketSubCategory/changeState",
        payload
      );
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }

  public async setTicketsStates(bls: BulkLogState): Promise<boolean> {
    try {
      const payload = this.encodeObject(bls, BulkLogState);
      const resp = await this.http.put("/api/v1/tickets/changeState", payload);
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }

  public async setTicketSubCategoryStates(bls: BulkLogState): Promise<boolean> {
    try {
      const payload = this.encodeObject(bls, BulkLogState);
      const resp = await this.http.put(
        "/api/v1/ticketSubCategory/changeState",
        payload
      );
      const data: IResponse = resp.data;
      return data.success;
    } catch (error) {
      throw error;
    }
  }
}

export default TrackingService;
