import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("SimpleTicket")
class SimpleTicket {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("customer_id", Number)
  CustomerID: number = 0;

  @JsonProperty("state", String)
  State: string = "";
  
  @JsonProperty("pickup_date", String, true)
  PickupDate: string = "";
  
  @JsonProperty("delivery_date", String, true)
  DeliveryDate: string = "";
  
  @JsonProperty("pickup_address_name", String, true)
  PickupAddressName: string = "";
  
  @JsonProperty("pickup_address_number", String, true)
  PickupAddressNumber: string = "";
  
  @JsonProperty("pickup_address_street", String, true)
  PickupAddressStreet: string = "";
  
  @JsonProperty("pickup_address_locality", String, true)
  PickupAddressLocality: string = "";
  
  @JsonProperty("delivery_address_name", String, true)
  DeliveryAddressName: string = "";
  
  @JsonProperty("delivery_address_number", String, true)
  DeliveryAddressNumber: string = "";
  
  @JsonProperty("delivery_address_street", String, true)
  DeliveryAddressStreet: string = "";
  
  @JsonProperty("sub_category_ids", String, true)
  SubCategoryIDS: string = "";
  
  @JsonProperty("delivery_address_locality", String, true)
  DeliveryAddressLocality: string = "";
  
  @JsonProperty("customer_full_name", String, true)
  CustomerFullName: string = "";
  
  @JsonProperty("customer_contact", String, true)
  CustomerContact: string = "";
  
  @JsonProperty("pickup_comment", String, true)
  PickupComment: string = "";
  
  @JsonProperty("agent_name", String, true)
  AgentName: string = "";
  
  @JsonProperty("delivery_comment", String, true)
  DeliveryComment: string = "";
  
  @JsonProperty("detail", String, true)
  Detail: string = "";

  @JsonProperty("total", Number, true)
  Total: number = 0.0;

  @JsonProperty("total_paid", Number, true)
  TotalPaid: number = 0.0;
}

export default SimpleTicket;
