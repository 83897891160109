import React from "react";
import { IonAlert } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

interface IAlert {
  store?: RootStore;
}

const Alert: React.FC<IAlert> = ({ store }) => {
  const { ui } = store!.stores;
  return (
    <IonAlert
      isOpen={ui.alert.open!}
      onDidDismiss={() => ui.closeAlert()}
      header={ui.alert.title}
      message={ui.alert.message}
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            return;
          },
        },
        {
          text: "OK",
          handler: ui.alert.handler!,
        },
      ]}
    />
  );
};

export default inject("store")(observer(Alert));
