import React, { useState } from "react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import {
  IonContent,
  IonList,
  IonHeader,
  IonButton,
  IonButtons,
  IonIcon,
  IonToolbar,
  IonCol,
  IonRow,
  IonAvatar,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { checkmarkCircle, refresh } from "ionicons/icons";
import { runInAction } from "mobx";
import { TextField } from "..";

interface MiniTicketPrinter {
  store?: RootStore;
}

const MiniTicketPrinter: React.FC<MiniTicketPrinter> = ({ store }) => {
  const { tickets, printer } = store?.stores!;
  printer.setDefaultEncoding("ESC/POS");
  const [port, setPort] = useState(printer.defaultPort);
  const selectPort = async (port: any) => {
    if (port === "u") {
      printer.searchForUSBPrinters();
    }
    if (port === "rbt") {
      printer.searchForRBT();
    }
    printer.setDefaultPort(port);
    setPort(port);
  };

  const refreshList = () => {
    if (port === "u") {
      printer.searchForUSBPrinters();
    }
    if (port === "rbt") {
      printer.searchForRBT();
    }
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="primary"
              fill={port === "u" ? "outline" : undefined}
              onClick={() => selectPort("u")}
            >
              USB
            </IonButton>
            <IonButton
              color="primary"
              fill={port === "rbt" ? "outline" : undefined}
              onClick={() => selectPort("rbt")}
            >
              RAWBT
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={refreshList}>
              <IonIcon icon={refresh} color="primary" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {port === "rbt" ? (
            <>
              {printer.rbtDeviceList.map((d: any) => {
                return (
                  <IonItem
                    key={d.id}
                    onClick={() => {
                      printer.selectPrinter(d.id);
                    }}
                  >
                    <IonAvatar slot="start">
                      {d.id === printer.selectedPrinter ? (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="success"
                        />
                      ) : (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="light"
                        />
                      )}
                    </IonAvatar>
                    <IonLabel>
                      <h2>{d.name}</h2>
                      <p>{d.id}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          ) : null}
          {port === "u" ? (
            <>
              {printer.usbDeviceList.map((device: USBDevice) => {
                return (
                  <IonItem
                    key={device.serialNumber}
                    onClick={() => {
                      printer.selectUSBPrinter(device);
                    }}
                  >
                    <IonAvatar slot="start">
                      {device === printer.selectedPrinter ? (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="success"
                        />
                      ) : (
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="light"
                        />
                      )}
                    </IonAvatar>
                    <IonLabel>
                      <h2>{device.productName}</h2>
                      <p>{device.manufacturerName}</p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </>
          ) : null}
        </IonList>
        <IonList>
          {/* get actions for the ticket
          show for each action an input value number
          save the input in a temp array
          when print then get that array and print according to the input values */}
          {tickets.ticket.Actions?.map((a) => {
            return (
              <IonRow>
                <IonCol sizeLg="6" sizeXs="12">
                  <TextField
                    // value={customer.Discount}
                    type="number"
                    label={a}
                    onChange={(v: string) => {
                      tickets.addActionAmount(a, parseInt(v));
                    }}
                  />
                </IonCol>
              </IonRow>
            );
          })}
        </IonList>
      </IonContent>
    </>
  );
};

export default inject("store")(observer(MiniTicketPrinter));
