import { RootStore } from "../../stores/RootStore";
import React from "react";
import { inject, observer } from "mobx-react";
import {
  IonGrid,
  IonText,
  IonRow,
  IonCol,
  IonCheckbox,
  IonLabel,
  IonItem,
} from "@ionic/react";
import { TextField, SelectField } from "..";

import "./EmployeePermissions.css";
import { IOption } from "../SelectField/SelectField";

interface IEmployeePermissions {
  store?: RootStore;
}

const EmployeePermissions: React.FC<IEmployeePermissions> = ({ store }) => {
  const { employee } = store!.stores.employees;

  const _roles: IOption[] = [
    { value: "admin", label: "Administrator" },
    { value: "manager", label: "Manager" },
    { value: "employee", label: "Employee" },
    { value: "driver", label: "Driver" },
  ];

  return (
    <IonGrid>
      <IonText color="medium">
        <h5 style={{ padding: 15 }}>Permissions</h5>
      </IonText>
      <IonRow>
        <IonCol sizeLg="4" sizeXs="12">
          <TextField
            label="Pin"
            type="number"
            value={employee.Pin}
            onChange={(p: string) => {
              employee.Pin = parseInt(p);
            }}
          />
        </IonCol>
        <IonCol sizeLg="4" sizeXs="12">
          <SelectField
            label="Role"
            options={_roles}
            value={employee.Role}
            onChange={(role: string) => {
              employee.Role = role;
            }}
          />
        </IonCol>
        <IonCol sizeLg="4" sizeXs="12">
          <IonItem>
            <IonLabel>Enabled Login</IonLabel>
            <IonCheckbox
              checked={employee.LoginEnabled}
              onIonChange={(e) => {
                employee.LoginEnabled = e.detail.checked;
              }}
            />
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(EmployeePermissions));
