import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Modifier")
class Modifier {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("parent_id", Number, true)
  ParentID: number | null = null;

  @JsonProperty("type", String)
  Type: string = "";

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("image", String, true)
  Image: string | null = null;

  @JsonProperty("discount", String)
  Discount:string = "Percentage 0"

  @JsonProperty("visible", Boolean)
  Visible: boolean = true;

  @JsonProperty("up_charge", Number)
  UpCharge: number = 0.0;

  @JsonProperty("order", Number)
  Order: number = 0;

  @JsonProperty("is_percentage", Boolean)
  IsPercentage: boolean = true;

  public get isColor() {
    return this.Image!.startsWith("color:");
  }
}

export default Modifier;
