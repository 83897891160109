import { JsonObject, JsonProperty } from "json2typescript";
import moment from "moment";
import { TicketSubCategory } from ".";

@JsonObject("WorkLoadStatistic")
export class WorkLoadStatistic {
  @JsonProperty("day_of_week", Number)
  DayOfWeek: number = 0;

  @JsonProperty("capacity", Number)
  Capacity: number = 0;

  @JsonProperty("assigned", Number)
  Assigned: number = 0;

  @JsonProperty("ticket_sub_categories", [TicketSubCategory], true)
  TicketSubCategories: TicketSubCategory[] = [];

  @JsonProperty("date", String)
  Date: string = moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");
}

@JsonObject("ActionWorkLoadStatistics")
export class ActionWorkLoadStatistics {
  @JsonProperty("action", String)
  Action: string = "Dry Cleaning";

  @JsonProperty("work_load_statistic", [WorkLoadStatistic], true)
  WorkLoadStatistics: WorkLoadStatistic[] = [];

  @JsonProperty("date", String)
  Date: string = moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");

  @JsonProperty("day_of_week", Number)
  DayOfWeek: number = 0;
}
