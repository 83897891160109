import { Customer } from "../../stores/models";
import {
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonAvatar,
  IonIcon,
} from "@ionic/react";
import React from "react";
import "./CustomerListItem.css";
import { personCircleOutline } from "ionicons/icons";

interface CustomerListItemProps {
  customer: Customer;
}

const CustomerListItem: React.FC<CustomerListItemProps> = ({ customer }) => (
  <IonItem routerLink={"/customers/" + customer.ID} routerDirection='forward'>
    <IonAvatar slot='start' color='dark'>
      <IonIcon icon={personCircleOutline} size='large' color='medium' />
    </IonAvatar>
    <IonLabel>
      <h2 style={{ textTransform: "capitalize" }}>
        {customer.Title} {customer.FullName}
      </h2>
      <IonGrid style={{ margin: 0, padding: 0 }}>
        <IonRow>
          <IonCol size='auto'>
            <IonText color='medium'>
              <h2>{customer.Landline}</h2>
            </IonText>
          </IonCol>
          <IonCol size='auto'>
            <IonText color='medium'>
              <h2>{customer.Mobile}</h2>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonLabel>
  </IonItem>
);

export default CustomerListItem;
