import { observable, action, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import {
  Ticket,
  TicketSubCategory,
  TicketSubCategoryAction,
  TicketSubCategoryModifier,
  GarmentSubCategory,
} from "./models";
import { ReportingService, CustomerService } from "../services";
import EscPosEncoder from "esc-pos-encoder";
import Moment from "moment";
import moment from "moment";
import BulkLogState from "./models/BulkLogState";

class ReportingStore {
  private root: RootStore;
  private api: ReportingService;

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.reporting;
  }

  public fetchSalesReport(from: string, to: string, actions:number[]) {
    return this.api.fetchSalesReport(from, to, actions);
  }

  public fetchClientReport(from: string, to: string) {
    return this.api.fetchClientReport(from, to);
  }

  public fetchItemReport(from: string, to: string, agents:number[], states:string[], actions:number[], commission:number) {
    return this.api.fetchItemReport(from, to, agents, states, actions, commission);
  }

  public fetchItemStateReport(from: string, to: string, states:string[], actions:number[]) {
    return this.api.fetchItemStateReport(from, to, states, actions);
  }
}

export default ReportingStore;
