import React from "react";

import { observer, inject } from "mobx-react";
import { IonLabel } from "@ionic/react";
import { useEffect } from "react";
import moment from "moment";
import "./DatePicker.css";

interface IDatePickerProps {
  date: string;
  select: Function;
  label?: string;
  showDayOfWeek?: boolean;
  labelClass?: string;
  mobile?:boolean;
}

export const DatePicker: React.FC<IDatePickerProps> = (
  props: IDatePickerProps
) => {
  const [date, setDate] = React.useState(props.date);
  useEffect(() => {
    setDate(props.date);
  }, [props.date]);
  return (
    <>
      {props.label ? (
        <IonLabel className={props.labelClass} position="stacked">
          {props.label}
        </IonLabel>
      ) : null}
      {props.showDayOfWeek && props.date !== "Invalid date" ? (
        <IonLabel
          position="stacked"
          style={{
            color: "black",
            height: "100%",
            backgroundColor: "white",
            padding: "4px",
            margin: "5px",
          }}
        >
          {moment(date).format("ddd").toUpperCase()}
        </IonLabel>
      ) : (
        <></>
      )}
      <input
        type="date"
        value={date}
        onChange={(e) => {
          setDate(e.target.value);
          props.select(e.target.value);
        }}
      ></input>
    </>
  );
};

export default inject("store")(observer(DatePicker));
