import React from "react";
import { observer, inject } from "mobx-react";
import { RootStore } from "../stores/RootStore";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonIcon,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonItemDivider,
  IonItem,
  IonCheckbox,
  withIonLifeCycle,
  isPlatform,
} from "@ionic/react";

import {
  trashOutline,
  create,
  cashOutline,
  carOutline,
  closeCircle,
  copyOutline,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";

import {
  Garments,
  Header,
  Modifiers,
  Actions,
  TicketItems,
  PrinterSelect,
  TextField,
} from "../components";

import { action, observable, runInAction } from "mobx";
import { withRouter, RouteComponentProps } from "react-router-dom";
import TextArea from "../components/TextArea/TextArea";
import { TicketSubCategory } from "../stores/models";
import moment from "moment";
import PiecesSelect from "../components/PiecesSelect/PiecesSelect";
import { DatePicker } from "../components/DatePicker/DatePicker";
import PickupDeliveryOptions from "../components/PickupDeliveryOptions/PickupDeliveryOptions";
import MiniTicketPrinter from "../components/MiniTicketPrinter/MiniTicketPrinter";

interface ITicket {
  store: RootStore;
}

interface IRoute {
  id: string;
  ticketID: string;
}

@inject("store")
@observer
class TicketDetail extends React.Component<
  ITicket & RouteComponentProps<IRoute>
> {
  @observable lastGarmentId: number = 0;
  @observable garmentType: string | undefined = undefined;
  @observable isNoteOpen: boolean = false;
  @observable pickupDate: string = moment(new Date()).format("YYYY-MM-DD");
  @observable copyNumber: number = 1;
  @observable showGarments: boolean = true;

  @action
  private SetShowGarments(show: boolean) {
    this.showGarments = show;
  }

  async componentDidMount() {
    console.log("component mounting");
    const {
      tickets,
      ui,
      actionGarmentSubCategories,
      garments,
    } = this.props.store.stores;
    await garments.getGarmentGategories();
    await garments.getGarmentSubCategoriesAsync();
    await actionGarmentSubCategories.getActionGarmentSubCategories();
    ui.setResizeListener();
    const ticketID = parseInt(this.props.match.params.ticketID);
    if (ticketID !== 0) {
      const customerID = parseInt(this.props.match.params.id);
      await tickets.loadTicket(ticketID, customerID);
    } else {
      // const customerID = parseInt(this.props.match.params.id);
      // await tickets.initNewTicket(customerID);
    }
    this.setPickupDate(tickets.ticket.PickupDate);
  }

  ionViewWillEnter() {
    console.log("ionViewWillEnter event fired");
  }

  ionViewDidEnter() {
    const { tickets } = this.props.store.stores;
    // tickets.getAllTickets();
    const customerID = parseInt(this.props.match.params.id);
    const ticketID = parseInt(this.props.match.params.ticketID);
    if (ticketID !== 0) {
      runInAction(() => {
        console.log("getting ticket");
        // tickets.ticket = tickets.allTickets.find((t) => t.ID === ticketID)!;
        tickets.getTicketOnly(ticketID);
      });
    } else {
      console.log("init ticket");
      tickets.initNewTicket(customerID);
    }
    console.log("ionViewDidEnter event fired");
  }

  // UNSAFE_componentWillReceiveProps(np: any, ns: any) {
  //   const {
  //     tickets,
  //     ui,
  //     actionGarmentSubCategories,
  //     garments,
  //   } = this.props.store.stores;
  //   garments.getGarmentGategories();
  //   garments.getGarmentSubCategoriesAsync();
  //   actionGarmentSubCategories.getActionGarmentSubCategories();
  //   ui.setResizeListener();
  //   const ticketID = parseInt(this.props.match.params.ticketID);
  //   if (ticketID !== 0) {
  //     const customerID = parseInt(this.props.match.params.id);
  //     tickets.loadTicket(ticketID, customerID);
  //   }
  // }

  handleGarmentSubCategorySelect = (id: number, copies: number) => {
    const { tickets, ui, garments } = this.props.store.stores;
    const garment = garments.garmentSubCategories.find((g) => g.ID === id);
    const pieces = garment?.getPiecesArray;
    pieces?.forEach((p) => {
      tickets.updateSelectedPieces(p);
    });
    if (pieces !== undefined && garment?.ShowChoosePieces) {
      ui.showDialog({
        title: "Select Pieces.",
        content: <PiecesSelect checked={true} pieces={pieces} />,
        handler: () => {
          if (tickets.selectedPieces.length <= 0) {
            ui.showToast({
              color: "danger",
              message: "At least one piece must be selected!",
            });
          } else {
            tickets.addGarmentSubCategoryWithPieces(id, copies);
            runInAction(() => {
              this.copyNumber = 1;
            });
            ui.closeDialog();
          }
        },
      });
      return;
    }
    if (!garment?.ShowChoosePieces) {
      tickets.addGarmentSubCategoryWithPieces(id, copies);
      runInAction(() => {
        this.copyNumber = 1;
      });
      return;
    }
    tickets.addGarmentSubCategory(id);
  };

  handleActionSelect = (id: number, image?: string | undefined) => {
    const { tickets } = this.props.store.stores;
    tickets.addActionToSubcategory(id, image);
  };

  handleModifierSelect = (id: number) => {
    const { tickets } = this.props.store.stores;
    tickets.addModifierToSubcategory(id);
  };

  handleDeleteSelected = () => {
    const { tickets } = this.props.store.stores;
    tickets.deleteSubCategory();
  };

  handleDuplicateSelected = (quantity: number, id?: number) => {
    const { tickets } = this.props.store.stores;
    tickets.duplicateSelected(quantity, id);
    runInAction(() => {
      this.copyNumber = 1;
    });
  };

  handleUpdatePrice = () => {
    const { ui, tickets } = this.props.store.stores;
    const selectedItem = tickets.getSelectedGarment();
    if (selectedItem === undefined) {
      return;
    }
    ui.showDialog({
      title: selectedItem.Description,
      content: (
        <TextField
          type="text"
          label="Price"
          onChange={(v: string) => {
            selectedItem.TotalOverride = parseInt(v);
          }}
          value={this.getTotal(selectedItem)}
        />
      ),
      handler: this.handleSave,
    });
  };

  setDeliveryDate = (date: string) => {
    const { tickets } = this.props.store.stores;
    tickets.setDeliveryDate(date);
  };

  @action
  setPickupDate = (date: string) => {
    const { tickets } = this.props.store.stores;
    this.pickupDate = date;
    runInAction(() => {
      this.pickupDate = date;
      tickets.ticket.PickupDate = date;
    });
  };

  printTicket = () => {
    const { ui, tickets, auth, tracking } = this.props.store.stores;
    if (tickets.ticket.ID === 0 || tickets.ticket.SubCategories.length === 0) {
      ui.showToast({ color: "warning", message: "Ticket is empty" });
      return false;
    }
    ui.showDialog({
      title: "Select Printer",
      content: <PrinterSelect />,
      OKText: "PRINT",
      CancelText: "CLOSE",
      handler: () => {
        const ok = tickets.print();
        // if (tickets.ticket.State === "CREATED" && ok) {
          this.handleConfirmTicket().then(() => {});
        // }
        this.printMultiTicket();
      },
    });
  };

  // printTicket2 = () => {
  //   const { ui, tickets, auth, tracking } = this.props.store.stores;
  //   if (tickets.ticket.ID === 0 || tickets.ticket.SubCategories.length === 0) {
  //     ui.showToast({ color: "warning", message: "Ticket is empty" });
  //     return false;
  //   }
  //   ui.showDialog({
  //     title: "Select Printer",
  //     content: <PrinterSelect />,
  //     OKText: "PRINT",
  //     CancelText: "CLOSE",
  //     handler: () => {
  //       const ok = tickets.print();
  //       if (tickets.ticket.State === "CREATED" && ok) {
  //         this.handleConfirmTicket().then(() => {});
  //       }
  //       this.printMultiTicket2();
  //     },
  //   });
  // };

  printMultiTicket = () => {
    const { ui, tickets } = this.props.store.stores;
    if (tickets.ticket.ID === 0 || tickets.ticket.SubCategories.length === 0) {
      ui.showToast({ color: "warning", message: "Ticket is empty" });
      return false;
    }
    ui.showDialog({
      title: "Mini Tickets",
      content: <MiniTicketPrinter />,
      OKText: "PRINT",
      CancelText: "CLOSE",
      handler: () => {
        runInAction(() => {
          tickets.mini = true;
        });
        const ok = tickets.print();
        //this.props.history.replace(`/customers/${tickets.ticket.CustomerID}`);
        runInAction(() => {
          tickets.mini = false;
        });
        ui.closeDialog();
      },
    });
  };

  // printMultiTicket2 = () => {
  //   const { ui, tickets } = this.props.store.stores;
  //   if (tickets.ticket.ID === 0 || tickets.ticket.SubCategories.length === 0) {
  //     ui.showToast({ color: "warning", message: "Ticket is empty" });
  //     return false;
  //   }
  //   runInAction(() => {
  //     tickets.mini = true;
  //   });
  //   ui.showDialog({
  //     title: "Mini Tickets",
  //     content: <MiniTicketPrinter />,
  //     OKText: "PRINT",
  //     CancelText: "CLOSE",
  //     handler: () => {
  //       tickets.test = 1;
  //       const ok = tickets.print();
  //       this.props.history.replace(`/customers/${tickets.ticket.CustomerID}`);
  //       runInAction(() => {
  //         tickets.mini = false;
  //         tickets.test = 0;
  //       });
  //       ui.closeDialog();
  //     },
  //   });
  // };

  handleUpdateDeliveryDate = () => {
    const { ui, tickets } = this.props.store.stores;
    // const ticket = tickets.ticket;
    ui.showDialog({
      title: "Delivery/Pickup Options",
      content: (
        <PickupDeliveryOptions
          store={this.props.store}
          ticket={tickets.ticket}
          saveAll={this.handlePickupDateAndSetDelivery}
        />
      ),
      OKText: "OK",
      CancelText: "CLOSE",

      handler: () => {
        this.handlePickupDateAndSetDelivery(true);
      },
      // () => {
      // this.handlePickupDateAndSetDelivery(true);
      // },
    });
  };
  renderDeliveryCheckbox = (): JSX.Element => {
    const { tickets } = this.props.store.stores;
    const ticket = tickets.ticket;
    return (
      <IonItem>
        <IonCheckbox checked={ticket.DeliveryToClient} onChange={(e) => {}} />
      </IonItem>
    );
  };

  handlePickupDateAndSetDelivery = async (closeDialog: boolean) => {
    const { ui, tickets } = this.props.store.stores;
    await tickets.updateDeliveryPickupOptions(
      tickets.ticket.ID,
      tickets.ticket.PickupDate,
      tickets.ticket.DeliveryToClient,
      tickets.ticket.PickupFromClient,
      tickets.ticket.PickupComment,
      tickets.ticket.DeliveryComment
    );
    if (closeDialog === true) {
      ui.closeDialog();
    }
  };

  handleConfirmTicket = async () => {
    const { tickets, customers } = this.props.store.stores;
    if (await tickets.confirmTicket()) {
      await customers.getSimpleCustomerWithSimpleTicketsDetails(customers.customer.ID)
      // await tickets.getAllForCustomer(customers.customer.ID);
      // await customers.getCustomerWithDetails(customers.customer.ID)
      this.props.history.replace(`/customers/${customers.customer.ID}`);
    }
  };

  renderGarmentTypes(): React.ReactElement {
    const { types } = this.props.store.stores.garments;
    if (types.length === 0) {
      return <></>;
    }
    runInAction(() => {
      if (this.garmentType === undefined) {
        this.garmentType = types[0];
      }
    });
    return (
      <IonSegment
        value={this.garmentType}
        onIonChange={(e) =>
          runInAction(() => {
            this.garmentType = e.detail.value;
          })
        }
      >
        {types.map((t) => {
          return (
            <IonSegmentButton value={t} key={t}>
              <IonLabel>{t}</IonLabel>
            </IonSegmentButton>
          );
        })}
      </IonSegment>
    );
  }

  renderGarments(): React.ReactFragment {
    const { garments, tickets } = this.props.store.stores;
    const { garmentCategories } = garments;
    return (
      <div>
        <div style={{ paddingLeft: 15, paddingTop: 5 }}>
          <small>
            <strong>GARMENTS</strong>
          </small>
        </div>
        <Garments
          garments={garmentCategories}
          onSelect={(id: any) => {
            tickets.clearSelectedPieces();
            this.handleGarmentSubCategorySelect(id, this.copyNumber);
          }}
        />
        <IonItemDivider />
        <br />
      </div>
    );
  }

  renderMobileGarments(): React.ReactFragment {
    const { garments, tickets } = this.props.store.stores;
    const { garmentCategories } = garments;
    if (this.showGarments === true) {
      return (
        <div>
          <div
            onClick={() => {
              this.SetShowGarments(false);
            }}
          >
            <IonCol>
              <IonIcon
                icon={chevronDownOutline}
                size="large"
                style={{ float: "right" }}
                // slot="end"
              />
            </IonCol>
          </div>
          <div style={{ paddingLeft: 15, paddingTop: 5 }}>
            <small>
              <strong>GARMENTS</strong>
            </small>
          </div>
          <Garments
            garments={garmentCategories}
            onSelect={(id: any) => {
              tickets.clearSelectedPieces();
              this.handleGarmentSubCategorySelect(id, this.copyNumber);
            }}
          />
          <IonItemDivider />
          <br />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            this.SetShowGarments(true);
          }}
        >
          <IonCol>
            <IonIcon
              icon={chevronUpOutline}
              size="large"
              style={{ float: "right" }}
              // slot="end"
            />
          </IonCol>
        </div>
      );
    }
  }

  renderModifiers(): React.ReactFragment {
    const { modifiers } = this.props.store.stores;
    const { types } = modifiers;
    return (
      <>
        {types.map((t) => {
          let m = modifiers.getRootModifiers(t);
          if (m.length <= 0) {
            return;
          }
          return (
            <div key={t}>
              <div style={{ paddingLeft: 15 }}>
                <small>
                  <strong>{t.toUpperCase()}</strong>
                </small>
              </div>
              <Modifiers modifiers={m} onSelect={this.handleModifierSelect} />
            </div>
          );
        })}
      </>
    );
  }

  handleImageUpload = (image: string, actionID: number) => {
    this.handleActionSelect(actionID, image);
  };

  renderActions(): React.ReactFragment {
    const { tickets } = this.props.store.stores;
    const { actions } = this.props.store.stores.actions;
    let showImageUploader: number[] = [];
    tickets.ticket.SubCategories.forEach((tsc) => {
      if (tsc.ID == tickets.selectedGarment) {
        tsc.Actions.forEach((tsca) => {
          showImageUploader.push(tsca.ActionID);
        });
      }
    });
    return (
      <Actions
        actions={actions}
        onSelect={this.handleActionSelect}
        imageUpload={this.handleImageUpload}
        store={this.props.store}
        showImageUploader={showImageUploader}
      />
    );
  }

  renderTotal(): React.ReactFragment {
    const { ticket } = this.props.store.stores.tickets;
    return (
      <IonButtons slot="end">
        <h4 style={{ paddingRight: 20, paddingBottom: 10 }}>
          Total: &euro; {ticket.Total}
        </h4>
      </IonButtons>
    );
  }

  getTotal(ticketSubCategory: TicketSubCategory): number {
    if (ticketSubCategory.TotalOverride !== null) {
      return ticketSubCategory.TotalOverride;
    } else {
      return ticketSubCategory.Total;
    }
  }

  renderMobileTotal(): React.ReactFragment {
    const { ticket } = this.props.store.stores.tickets;
    return (
      <div className="ticket-totals-mobile">
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="6" className="label">
              Total
            </IonCol>
            <IonCol sizeXs="6" className="price">
              &euro; {ticket.Total}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  handleSave = async () => {
    const { tickets, ui } = this.props.store!.stores;
    const selectedItem = tickets.getSelectedGarment();
    if (selectedItem === undefined) return;
    await tickets.saveTicketSubCategory(selectedItem!.ID, selectedItem!);
    ui.closeDialog();
  };

  renderTicketItemsHeader = () => (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton onClick={this.handleDeleteSelected}>
            <IonIcon icon={trashOutline} size="medium" />
          </IonButton>
          <IonButton onClick={this.handleUpdateDeliveryDate}>
            <IonIcon icon={carOutline} size="medium" />
          </IonButton>
          <IonButton
            onClick={() => {
              console.log("copy the item that is selected", this.copyNumber);
              this.handleDuplicateSelected(this.copyNumber);
            }}
          >
            <IonIcon icon={copyOutline} size="medium" />
          </IonButton>
          <TextField
            inputStyles={{ width: "50px" }}
            type="number"
            value={this.copyNumber}
            onChange={(v: string) => {
              //update state numberCopy to whatever the value entered is
              runInAction(() => {
                this.copyNumber = parseInt(v);
              });
            }}
          />
        </IonButtons>
        <IonButtons slot="end">{this.renderTotal()}</IonButtons>
      </IonToolbar>
    </IonHeader>
  );

  renderMobileTicketItemsHeader = () =>
    isPlatform("mobile") ? (
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={this.handleDeleteSelected}>
              <IonIcon icon={trashOutline} size="medium" />
            </IonButton>
            <IonButton onClick={this.handleUpdateDeliveryDate}>
              <IonIcon icon={carOutline} size="medium" />
            </IonButton>
            <IonButton
              onClick={() => {
                console.log("copy the item that is selected", this.copyNumber);
                this.handleDuplicateSelected(this.copyNumber);
              }}
            >
              <IonIcon icon={copyOutline} size="medium" />
            </IonButton>
            <TextField
              inputStyles={{ width: "50px" }}
              type="number"
              value={this.copyNumber}
              onChange={(v: string) => {
                //update state numberCopy to whatever the value entered is
                runInAction(() => {
                  this.copyNumber = parseInt(v);
                });
              }}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    ) : (
      <></>
    );

  reschedulePickupDate = async (e: string, ticketID: number) => {
    const { tickets, statistics } = this.props.store!.stores;
    await tickets.reschedulePickupDate(ticketID, e);
    await statistics.getScheduleStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
  };

  rescheduleDeliveryDate = async (e: string, ticketID: number) => {
    const { tickets, statistics } = this.props.store!.stores;
    await tickets.rescheduleDeliveryDate(ticketID, e);
    await statistics.getScheduleStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
  };

  rendeDateHeader() {
    const { tickets } = this.props.store.stores;
    this.setPickupDate(tickets.ticket.PickupDate);
    return tickets.ticket.ID != -0 ? (
      <IonHeader>
        <IonRow>
          <IonCol sizeLg="4" sizeXs="12">
            <DatePicker
              showDayOfWeek={true}
              label="Pickup Date: "
              date={moment(tickets.ticket.PickupDate).format("YYYY-MM-DD")}
              select={(e: string) => {
                this.reschedulePickupDate(e, tickets.ticket.ID);
              }}
            />{" "}
          </IonCol>
          <IonCol sizeLg="4" sizeXs="12">
            <DatePicker
              showDayOfWeek={true}
              label=" Delivery Date: "
              date={moment(tickets.ticket.DeliveryDate).format("YYYY-MM-DD")}
              select={async (e: string) => {
                await this.rescheduleDeliveryDate(e, tickets.ticket.ID);
              }}
            />
            <IonIcon
              style={{ cursor: "pointer" }}
              icon={closeCircle}
              color={"danger"}
              onClick={() => {
                console.log("remove delivery date");
                this.rescheduleDeliveryDate("", tickets.ticket.ID);
              }}
            />
          </IonCol>
        </IonRow>
      </IonHeader>
    ) : null;
  }

  getCustomerDetails(): string {
    const { tickets } = this.props.store.stores;
    const { ticket } = tickets;
    return `${ticket.Customer.FullName}: ${ticket.Customer.Mobile}, ${
      ticket.Customer.Address.Name
    }${
      ticket.Customer.Address.Number ? ` ${ticket.Customer.Address.Number}` : ``
    }, ${ticket.Customer.Address.Street.Name}, ${
      ticket.Customer.Address.Locality.Name
    }`;
  }

  render() {
    const { tickets } = this.props.store.stores;
    const { ticket } = tickets;
    const { customer } = this.props.store.stores.customers;
    // ticket.CustomerID = customer.ID;
    const items = ticket.SubCategories.slice().reverse();

    return (
      <IonPage>
        <Header
          store={this.props.store}
          title={`#${ticket.ID}: ${this.getCustomerDetails()}`}
          backURL="/home"
          onConfirm={
            ticket.ConfirmedAt
              ? () => {
                  this.printMultiTicket();
                }
              : () => {
                  this.handleConfirmTicket().then(() => {
                    this.printMultiTicket();
                  });
                }
          }
          // onConfirm2={
          //   ticket.ConfirmedAt
          //     ? () => {
          //         this.printMultiTicket2();
          //       }
          //     : () => {
          //         this.handleConfirmTicket().then(() => {
          //           this.printMultiTicket2();
          //         });
          //       }
          // }
          onPrint={this.printTicket}
          //onPrint2={this.printTicket2}
        />
        {this.rendeDateHeader()}
        {this.renderMobileTicketItemsHeader()}
        {this.renderMobileTotal()}
        <TicketItems items={items} className="show-xs" />
        <IonContent
          style={this.showGarments ? { flex: 2 } : { flex: "0 0 5%" }}
        >
          <IonGrid style={{ width: "100%", height: "100%" }}>
            <IonRow style={{ height: "calc(100% - 60px)" }}>
              <IonCol sizeLg="4" sizeXs="0" className="hide-xs">
                {this.renderTicketItemsHeader()}
                <TicketItems items={items} />
              </IonCol>
              <IonCol sizeLg="8" sizeSm="12" style={{ paddingTop: 0 }}>
                <IonContent style={{ height: "calc(100% + 65px)" }}>
                  {isPlatform("mobile")
                    ? this.renderMobileGarments()
                    : this.renderGarments()}
                  {this.showGarments ? this.renderModifiers() : null}
                </IonContent>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>{this.renderActions()}</IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(TicketDetail));
