import {
  IonItem,
  IonCol,
  IonSelectOption,
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import React from "react";
import "./ActionGarmentSubCategoryListItem.css";
import ActionGarmentSubCategory from "../../stores/models/ActionGarmentSubCategory";
import { inject } from "mobx-react";
import { RootStore } from "../../stores/RootStore";
import TextField from "../TextField/TextField";
import { runInAction } from "mobx";
import CustomSelect from "../CustomSelect/CustomSelect";

interface ActionGarmentSubCategoryListItemProps {
  actionGarmentSubCategory: ActionGarmentSubCategory;
  deleteFunc?: Function;
  store: RootStore;
}

const ActionGarmentSubCategoryListItem: React.FC<ActionGarmentSubCategoryListItemProps> = ({
  actionGarmentSubCategory,
  store,
}) => {
  const [isPercentage, SetPercentage] = React.useState(
    actionGarmentSubCategory.IsPercentageOf ? true : false
  );
  const { actions, actionGarmentSubCategories } = store!.stores;
  const action = actions.getActionById(actionGarmentSubCategory.ActionID);
  const handleSave = (actionGarmentSubCategory: ActionGarmentSubCategory) => {
    if (actionGarmentSubCategory.ID === 0) {
      //create
      runInAction(() => {
        actionGarmentSubCategories.actionGarmentSubCategory = actionGarmentSubCategory;
        actionGarmentSubCategories.createActionGarmentSubCategory();
      });
    } else {
      // update
      runInAction(() => {
        actionGarmentSubCategories.actionGarmentSubCategory = actionGarmentSubCategory;
        actionGarmentSubCategories.updateActionGarmentSubCategory();
      });
    }
  };
  return (
    <IonItem>
      {/* <IonRow> */}
      <IonCol size="4">
        <h2 style={{ textTransform: "capitalize" }}>{action?.Name}</h2>
      </IonCol>

      <IonCol size="2" style={{ float: "right" }}>
        <TextField
          prefix={isPercentage ? <>&#37;&nbsp;</> : <>&euro;&nbsp;</>}
          value={
            actionGarmentSubCategory.Price
              ? actionGarmentSubCategory.Price
              : 0.0
          }
          type={"number"}
          onChange={(v: string) => {
            runInAction(async () => {
              const price = parseFloat(parseFloat(v).toFixed(2));
              actionGarmentSubCategory.Price = price;
              handleSave(actionGarmentSubCategory);
            });
          }}
        />
      </IonCol>
      <IonCol sizeLg="2" sizeXs="12">
        <IonItem>
          <IonLabel>Is Percentage</IonLabel>
          <IonCheckbox
            checked={isPercentage}
            onIonChange={(e) => {
              SetPercentage(e.detail.checked);
              if (e.detail.checked === false) {
                actionGarmentSubCategory.IsPercentageOf = null;
                handleSave(actionGarmentSubCategory);
              }
            }}
          />
        </IonItem>
      </IonCol>
              {/* <IonCol sizeLg="2" sizeXs="12">
                <TextField
                  type="number"
                  label="Discount"
                  onChange={(v: any) => {
                    parseFloat(v)<=0?v=0:v=v
                    parseFloat(v)>=100?v=100:v=v;
                    actionGarmentSubCategory.Discount ="PERCENTAGE "+ parseFloat(v);
                    handleSave(actionGarmentSubCategory);
                  }}
                  value={actionGarmentSubCategory.Discount?actionGarmentSubCategory.Discount.replace("PERCENTAGE ",""):0}
                />
              </IonCol> */}
      {isPercentage ? (
        <IonCol>
          <CustomSelect
            interface="popover"
            onSelect={(e: string) => {
              runInAction(() => {
                actionGarmentSubCategory.IsPercentageOf = parseInt(e);
                handleSave(actionGarmentSubCategory);
              });
            }}
            title="Action"
            elements={actionGarmentSubCategories.actionGarmentSubCategories
              .filter(
                (agsc) =>
                  agsc.GarmentSubCategoryID ===
                    actionGarmentSubCategory.GarmentSubCategoryID &&
                  agsc.ID !== actionGarmentSubCategory.ID
              )
              .map((agsc) => {
                return (
                  <IonSelectOption value={agsc.ID} key={agsc.ID}>
                    {actions.actions.find((a) => a.ID === agsc.ActionID)?.Name}
                  </IonSelectOption>
                );
              })}
            value={actionGarmentSubCategory.IsPercentageOf!}
          ></CustomSelect>
        </IonCol>
      ) : null}
    </IonItem>
  );
};

export default inject("store")(ActionGarmentSubCategoryListItem);
