import {
  IonContent,
  IonLabel,
  IonButtons,
  IonInput,
  IonPage,
  IonHeader,
  IonSelect,
  IonItemDivider,
  IonSelectOption,
  IonTitle,
  IonBackButton,
  IonButton,
  IonToolbar,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
} from "@ionic/react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import moment from "moment";
import { Virtuoso } from 'react-virtuoso';

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

interface PageState {
  ticketID: number;
  employeeID: number;
  dateFrom: string;
  dateTo: string;
  state: string;
  isLoading: boolean;
  data: any[];
  states: string[];
  employees: any[];
}

@inject("store")
@observer
class StateLogs extends React.Component<
PageProps & RouteComponentProps<RouteProps>, PageState
> {

  constructor(props: any) {
    super(props)
    this.state = {
      ticketID: 0,
      employeeID: 0,
      dateFrom: moment().subtract(1, 'w').format("YYYY-MM-DD"),
      dateTo: "",
      state: "",
      isLoading: true,
      data: [],
      employees: [],
      states: [],
    }
  }

  componentDidMount() {
    this.getData();
    this.query();
  }

  async getData() {
    const { employees, tracking } = this.props.store.stores;
    await employees.getEmployees();
    this.setState({
      employees: employees.employees,
      states: tracking.states
    })
  }

  async query() {
    const { statistics } = this.props.store.services;
    const resp = await statistics.getLogStateJourney(
      this.state.ticketID,
      this.state.employeeID,
      this.state.dateFrom,
      this.state.dateTo,
      this.state.state
    );

    this.setState({
      data: resp.data.data || []
    })
  }

  reset() {
    this.setState({
      ticketID: 0,
      employeeID: 0,
      dateFrom: moment().subtract(1, 'w').format("YYYY-MM-DD"),
      dateTo: "",
      state: "",
    }, this.query);
  }

  renderItem = (index: number) => {
    const item = this.state.data[index];
    return (
      <IonItem key={item.garment_id + '' + index} style={{ height: '50px' }}>
        <IonGrid style={{ margin: 0, padding: 0, fontSize: 13, letterSpacing: 1 }}>
          <IonRow style={{ display: "flex", alignItems: "center" }}>
            <IonCol size="1">
              <IonBadge color="warning">#{item.ticket_id}</IonBadge>
              &nbsp;
              <IonBadge color="danger">{item.garment_id}</IonBadge>
            </IonCol>
            <IonCol size="2">
              <strong>{item.employee}</strong><br />
              <small style={{ textTransform: "uppercase" }}>{item.agent}</small>
            </IonCol>
            <IonCol size="2" style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
              <IonBadge color="primary" style={{ fontSize: 11 }}>{item.state}</IonBadge>
            </IonCol>
            <IonCol size="2">
              {moment(item.date).format("DD/MM/YYYY HH:mm:ss")}
            </IonCol>
            <IonCol size="4">
              <span style={{ opacity: 0.6 }}>{item.description}</span>
            </IonCol>
            <IonCol size="1" style={{ textAlign: 'right' }}>
              <IonLabel color="success">{item.total.toFixed(2)}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    );
  };

  renderHeader() {
    return (
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonButtons slot="end">
            <IonItem>
              <IonLabel>Ticket ID</IonLabel>
              <IonInput placeholder="1234.." onIonChange={(e) => {
                this.setState({ ticketID: parseInt(e.detail.value!) })
              }} />
            </IonItem>
            <IonItem>
              <IonLabel>DateFrom</IonLabel>
              <IonInput placeholder="YYYY-MM-DD" onIonChange={(e) => {
                const d = moment(e.detail.value!)
                if (moment(d).isValid()) {
                  this.setState({ dateFrom: moment(d).format("YYYY-MM-DD") })
                }
              }} />
            </IonItem>
            <IonItem>
              <IonLabel>Date To</IonLabel>
              <IonInput placeholder="YYYY-MM-DD" onIonChange={(e) => {
                const d = moment(e.detail.value!)
                if (moment(d).isValid()) {
                  this.setState({ dateTo: moment(d).format("YYYY-MM-DD") })
                }
              }} />
            </IonItem>
            <IonSelect interface="popover" placeholder="Employee" onIonChange={(e) => {
              this.setState({
                employeeID: e.detail.value!
              })
            }}>
              <IonSelectOption value={0}>Any</IonSelectOption>
              {this.state.employees.map((s) => {
                return <IonSelectOption value={s.ID} key={s.ID}>{s.FullName}</IonSelectOption>
              })}
            </IonSelect>
            &nbsp;
            <IonSelect interface="popover" placeholder="State" onIonChange={(e) => {
              this.setState({
                state: e.detail.value!
              })
            }}>
              <IonSelectOption value="">Any</IonSelectOption>
              {this.state.states.map((s) => {
                return <IonSelectOption value={s} key={s}>{s}</IonSelectOption>
              })}
            </IonSelect>
            &nbsp;
            <IonButton color="primary" onClick={(e) => { e.preventDefault(); this.query() }}>Search</IonButton>
            <IonButton color="warning" onClick={() => { window.location.reload() }}>Reset</IonButton>
          </IonButtons>
          <IonTitle>State Logs</IonTitle>
        </IonToolbar>
      </IonHeader>
    )
  }

  renderTotal() {
    let total = 0;
    let garmentIDS: any = {}
    for (var i = 0; i < this.state.data.length; i++) {
      const item = this.state.data[i];
      if (garmentIDS[item.garment_id] === undefined) {
        total += this.state.data[i].total;
        garmentIDS[item.garment_id] = true;
      }
    }
    return (
      <IonHeader>
        <IonToolbar style={{
          paddingRight: 15, textAlign: 'right'
        }}>
          <IonLabel color="success"
            style={{ fontWeight: 'bold', letterSpacing: 1.1 }}>
            {Intl.NumberFormat('en-MT', { style: 'currency', currency: 'EUR' }).format(total)}
          </IonLabel>
        </IonToolbar>
      </IonHeader>
    )
  }

  render() {
    return (
      <IonPage>
        {this.renderHeader()}
        <IonContent>
          <Virtuoso style={{ height: 'calc(100% - 60px)' }} totalCount={this.state.data.length} itemContent={(index) => {
            return (
              <div style={{ height: '50px' }}>{this.renderItem(index)}</div>
            )
          }} />
          {this.renderTotal()}
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(StateLogs);
