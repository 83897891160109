import React from "react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import {
  IonContent,
} from "@ionic/react";
import TextField from "../TextField/TextField";
import { runInAction } from "mobx";

interface IPrintSelection {
  store?: RootStore;
}

const PrinterSelect: React.FC<IPrintSelection> = ({ store }) => {
  const { tickets } = store!.stores;
  const [percentageDiscount, setPercentageDiscount] = React.useState<number>(0)
  const updatePercentageDiscountValue =(v:number) =>{
    if (v<0){
      setPercentageDiscount(0)
    }else if (v>100){
      setPercentageDiscount(100)
    }else{
      setPercentageDiscount(v)
    }
  }
  // const discounts = [10,20,30,40,50,60,70,80,90,100]
  return (
    <>
      <IonContent>
        <TextField
        label={"Discount %"}
          // prefix={isPercentage ? <>&#37;&nbsp;</> : <>&euro;&nbsp;</>}
          value={
            tickets.ticket.Discount
          }
          type={"number"}
          onBlur={(v: any) => {
            tickets.ticket.Discount = "PERCENTAGE "+ v.target.value
          }}
        />
      </IonContent>
    </>
  );
};

export default inject("store")(observer(PrinterSelect));
