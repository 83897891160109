import { observable, runInAction, computed } from "mobx";
import { IResponse } from "../services/BaseService";
import { RootStore } from "./RootStore";

class AuthStore {
  public AdminRole: string = "admin";
  public ManagerRole: string = "manager";
  public EmployeeRole: string = "employee";
  public DriverRole: string = "driver";
  public Roles: string[] = [
    this.AdminRole,
    this.ManagerRole,
    this.EmployeeRole,
    this.DriverRole,
  ];
  @observable public aToken: string = "";
  @observable public eToken: string = "";

  @observable public employeeID: number = 0;
  @observable public role: string = "";
  @observable public isHq: boolean = false;
  @observable public agentID: number = 0;
  @observable public agentName: string = "";
  @observable public employeeName: string = "";

  private root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    this.init();
  }

  init() {
    const { storage } = this.root.services;
    runInAction(async () => {
      this.aToken = storage.get("aToken");
      this.eToken = storage.get("eToken");
      if (this.aToken && this.aToken.trim() !== "") {
        this.root.services.auth.setAuthToken("agent", this.aToken);
        const t = this.parseToken(this.aToken);
        this.agentID = t.agent_id;
      }
      if (this.eToken && this.eToken.trim() !== "") {
        this.root.services.auth.setAuthToken("employee", this.eToken);
        const t = this.parseToken(this.eToken);
        // console.log(t.hq)
        this.employeeID = t.employee_id;
        this.role = t.role;
        this.isHq = t.hq;
        this.employeeName = t.employee_name;
        this.agentName = t.agent_name;
      }
    });
  }

  public isAdmin(): boolean {
    return this.AdminRole === this.role;
  }

  private parseToken(token: string) {
    if (!token || token.trim() === "") {
      return null;
    }
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  @computed
  get isLoggedIn(): boolean {
    const t = this.parseToken(this.aToken);
    if (t === null) return false;
    const expiry: number = t.exp;
    const todayUnix: number = Date.now() / 1000;
    return this.aToken !== "" && this.aToken !== null && todayUnix < expiry;
  }

  @computed
  get isEmployeeLoggedIn(): boolean {
    return this.eToken !== "" && this.eToken !== null;
  }

  async agentLogin(username: string, password: string): Promise<Boolean> {
    const { auth, storage } = this.root.services;
    try {
      const resp: IResponse = await auth.agentLogin(username, password);
      if (resp.success === false) {
        return false;
      }
      runInAction(() => {
        this.aToken = resp.data;
      });
      storage.set("aToken", resp.data);

      return true;
    } catch (error) {
      return false;
    }
  }

  async agentLogout(): Promise<Boolean> {
    const { storage } = this.root.services;
    runInAction(() => {
      this.aToken = "";
    });
    storage.rem("aToken");
    return this.employeeLogout();
  }

  async employeeLogin(pin: string): Promise<Boolean> {
    const { auth, storage } = this.root.services;
    try {
      const resp: IResponse = await auth.employeeLogin(pin);
      if (resp.success === false) {
        return false;
      }
      runInAction(() => {
        this.eToken = resp.data;
        const t = this.parseToken(this.eToken);
        this.employeeID = t.employee_id;
        this.role = t.role;
        this.isHq = t.hq;
      });
      storage.set("eToken", resp.data);

      return true;
    } catch (error) {
      return false;
    }
  }

  async employeeLogout(): Promise<Boolean> {
    const { storage } = this.root.services;
    // TODO: implement
    runInAction(() => {
      this.eToken = "";
    });
    storage.rem("eToken");
    return true;
  }
}

export default AuthStore;
