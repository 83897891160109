import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonSelectOption,
  IonReorderGroup,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";
import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";

import { RootStore } from "../stores/RootStore";

import { Header, TextField } from "../components";
import { Modifier } from "../stores/models";
import { ColorPicker } from "../components/ColorPicker/ColorPicker";
import ModifierListItem from "../components/ModifierListItem/ModifierListItem";
import CustomSelect from "../components/CustomSelect/CustomSelect";
import { runInAction } from "mobx";

interface ModifierDetailsProps {
  store: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class ModifierDetails extends React.Component<
  ModifierDetailsProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    this.init();
    // https://stackoverflow.com/questions/59951414/ionic-4-ion-select-does-not-scroll-to-selected-item-in-alert
    window.addEventListener("ionAlertDidPresent", (e) => {
      const selected = (e.target as HTMLElement).querySelector(
        '[aria-checked="true"]'
      );
      selected && selected.scrollIntoView();
    });
  }

  UNSAFE_componentWillReceiveProps(np: any, ns: any) {
    const id = parseInt(np.match.params.id);
    this.getModifier(id);
  }

  init() {
    const id = parseInt(this.props.match.params.id);
    this.getModifier(id);
    // const { modifiers } = this.props.store.stores;
    // modifiers.getModifiers();
  }

  getModifier(id: number) {
    const { modifiers } = this.props.store.stores;
    modifiers.getModifiers();
    if (modifiers.modifier.ID === id) return;
    modifiers.getModifierByIdAsync(id);
    if (id === 0) {
      this.reset();
      return;
    }
    runInAction(() => {
      modifiers.subModifiers = modifiers.allModifiers.filter((m) => {
        return m.ParentID === id;
      });
    });
  }

  reset() {
    const { modifiers } = this.props.store.stores;
    modifiers.resetModifier();
  }

  componentWillUnmount() {
    this.reset();
  }

  createModifier = async () => {
    const { modifiers, ui } = this.props.store.stores;
    try {
      const m = await modifiers.createModifier();
      ui.showToast({ message: "Information Saved", color: "success" });
      runInAction(() => {
        modifiers.setModifier(m);
        modifiers.modifiers.push(m);
        modifiers.allModifiers.push(m);
      });
      this.props.history.replace(`/modifiers/${m.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  updateModifier = async () => {
    const { modifiers, ui } = this.props.store.stores;
    try {
      await modifiers.saveModifier();
      ui.showToast({ message: "Information Saved", color: "success" });
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { modifiers } = this.props.store.stores;
    if (modifiers.modifier.ID === 0) {
      await this.createModifier();
    } else {
      await this.updateModifier();
    }
  };

  handleImageUpload = (e: any) => {
    const { modifiers } = this.props.store.stores;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64Image: string | undefined = reader.result?.toString();
      modifiers.setImageModifier(base64Image ? base64Image : "");
    };
    reader.onerror = function () {};
  };

  // @action
  handleDelete = async (confirmed: boolean) => {
    const { modifiers, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    if (modifiers.modifier.ID === 0) return;
    try {
      const s = await modifiers.deleteModifier(modifiers.modifier.ID);
      if (s) {
        ui.showToast({ message: "Modifier Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/modifiers");
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleDeleteSubCategory = async (confirmed: boolean, id: number) => {
    const { modifiers, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDeleteSubCategory(true, id);
        },
      });
      return;
    }
    const categoryID = parseInt(this.props.match.params.id);
    this.getModifier(categoryID);
    if (id === 0) return;
    try {
      const s = await modifiers.deleteModifier(id);
      if (s) {
        ui.showToast({
          message: "Modifier Sub Category Removed",
          color: "success",
        });
      }
      this.props.history.replace(`/modifiers/${categoryID}`);
    } catch (error) {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  reOrder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { modifiers } = this.props.store.stores;
    const subModifiers = modifiers.reOrderSubModifiers(
      event.detail.from,
      event.detail.to
    );
    modifiers.setSubModifiers(subModifiers);
    event.detail.complete();
  };

  listOfSubCategories() {
    const { modifiers } = this.props.store.stores;
    const ms = modifiers.subModifiers;
    // const ms = modifiers.allModifiers;
    const id = parseInt(this.props.match.params.id);
    return (
      <IonReorderGroup
        disabled={modifiers.subCategoryReOrderDisabled}
        onIonItemReorder={this.reOrder}
      >
        {ms.slice(0, 50).map((m: Modifier) => {
          if (m.ParentID === id) {
            return (
              <ModifierListItem
                key={m.ID}
                modifier={m}
                reOrder={"start"}
                deleteFunc={this.handleDeleteSubCategory}
              ></ModifierListItem>
            );
          }
        })}
      </IonReorderGroup>
    );
  }

  private setColor(color: string) {
    const { modifiers } = this.props.store.stores;
    modifiers.setModifierColor(color);
  }

  //Refactor into component
  getModifierImage(): JSX.Element {
    const { modifiers } = this.props.store.stores;
    // if (modifiers.modifier.Image !== "") {
    if (modifiers.modifier.Type === "COLOR") {
      //SHOW THE COLOR
      const imageColor = modifiers.modifier.Image?.replace(/^color:/, "");
      return (
        <ColorPicker
          color={imageColor ? imageColor : ""}
          select={this.setColor.bind(this)}
        />
      );
    }
    // }
    return (
      <IonThumbnail slot="start">
        <label htmlFor={"imageUploader"} style={{ cursor: `pointer` }}>
          <img
            alt={"image"}
            src={
              modifiers.modifier.Image ? modifiers.modifier.Image : "no image"
            }
          />
        </label>
        <input
          type="file"
          accept="image/*"
          capture="camera"
          id="imageUploader"
          style={{ display: `none` }}
          onChange={this.handleImageUpload}
        />
      </IonThumbnail>
    );
  }

  body() {
    const { modifiers } = this.props.store.stores;
    const types = modifiers.types;
    return (
      <>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonItem>{this.getModifierImage()}</IonItem>
              <IonCol sizeLg="2" sizeXs="12">
                <TextField
                  type="text"
                  label="Name"
                  onChange={(v: any) => {
                    modifiers.modifier.Name = v;
                  }}
                  value={modifiers.modifier.Name}
                />
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Visible</IonLabel>
                  <IonCheckbox
                    checked={modifiers.modifier.Visible}
                    onIonChange={(e) => {
                      modifiers.modifier.Visible = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="1" sizeXs="12">
                <TextField
                  type="number"
                  label="Up Charge"
                  onChange={(v: any) => {
                    modifiers.modifier.UpCharge = parseFloat(v);
                  }}
                  value={modifiers.modifier.UpCharge}
                />
              </IonCol>
              <IonCol sizeLg="1" sizeXs="12">
                <TextField
                  type="number"
                  label="Discount"
                  onChange={(v: any) => {
                    parseFloat(v)<=0?v=0:v=v
                    parseFloat(v)>=100?v=100:v=v;
                    modifiers.modifier.Discount ="PERCENTAGE "+ parseFloat(v);
                  }}
                  value={modifiers.modifier.Discount.replace("PERCENTAGE ","")}
                />
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <IonItem>
                  <IonLabel>Is Percentage</IonLabel>
                  <IonCheckbox
                    checked={modifiers.modifier.IsPercentage}
                    onIonChange={(e) => {
                      modifiers.modifier.IsPercentage = e.detail.checked;
                    }}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="2" sizeXs="12">
                <CustomSelect
                  interface="popover"
                  onSelect={(e: string) => {
                    // if (e === "COLOR") {
                    //   runInAction(() => {
                    //     modifiers.modifier.Image = "color:white";
                    //   });
                    // }
                    modifiers.setModifierType(e);
                  }}
                  title="Type"
                  elements={types.map((t) => {
                    return <IonSelectOption value={t}>{t}</IonSelectOption>;
                  })}
                  value={modifiers.modifier.Type}
                ></CustomSelect>
              </IonCol>
              <IonCol>
                <CustomSelect
                  interface="popover"
                  onSelect={(e: string) => {
                    runInAction(() => {
                      modifiers.modifier.ParentID = parseInt(e);
                    });
                  }}
                  title="Parent"
                  elements={modifiers.modifiers
                    .slice()
                    .filter(
                      (m) => m.Type === modifiers.modifier.Type && m.Type !== ""
                    )
                    .map((m) => {
                      return (
                        <IonSelectOption value={m.ID} key={m.ID}>
                          {m.Name}
                        </IonSelectOption>
                      );
                    })}
                  value={modifiers.modifier.ParentID!}
                ></CustomSelect>
              </IonCol>
            </IonRow>
            <IonRow>{this.listOfSubCategories()}</IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                expand="full"
                size="large"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
              {modifiers.modifier.ID !== 0 ? (
                <IonButton
                  color="danger"
                  expand="full"
                  size="large"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }

  toggleReOrder = () => {
    const { modifiers } = this.props.store.stores;
    modifiers.toggleModifierSubCategoryReOrderDisabled();
    if (modifiers.subCategoryReOrderDisabled) {
      modifiers.reOrderSubModifiersAsyc();
    }
  };

  render() {
    const { modifiers } = this.props.store.stores;
    const title =
      modifiers.modifier.ID === 0 ? "New Modifier" : "Modifier Details";
    return (
      <IonPage>
        <Header
          title={title}
          backURL="/modifiers"
          toggleReOrder={this.toggleReOrder}
        />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(ModifierDetails);
