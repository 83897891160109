import { BaseService, IResponse } from "./BaseService";
import { PickupDelivery } from "../stores/models";

class PickupDeliveryService extends BaseService {
  public async fetchAll(): Promise<PickupDelivery[]> {
    try {
      const resp = await this.http.get("/api/v1/pickupDeliveries/");
      return this.decodeArray<PickupDelivery>(resp, PickupDelivery);
    } catch (error) {
      throw error;
    }
  }

  public async save(
    pickupDeliveries: PickupDelivery[]
  ): Promise<PickupDelivery[]> {
    try {
      const payload = this.encodeArray(pickupDeliveries, PickupDelivery);
      const resp = await this.http.post("/api/v1/pickupDeliveries/", payload);
      return this.decodeArray<PickupDelivery>(resp, PickupDelivery);
    } catch (error) {
      throw error;
    }
  }
}

export default PickupDeliveryService;
