import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Action")
class Action {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("image", String, true)
  Image: string | null = null;

  @JsonProperty("visible", Boolean)
  Visible: boolean = false;

  @JsonProperty("is_percentage_of", Number, true)
  IsPercentageOf: number | null = null;

  @JsonProperty("up_charge", Number)
  UpCharge: number = 0.0;

  @JsonProperty("images_required", Boolean)
  ImagesRequired: boolean = false;

  @JsonProperty("discount", String)
  Discount:string = "Percentage 0"

  findPrice(actions: Action[]): number {
    try {
      const percentage = this.UpCharge / 100;
      const newAction = actions.find((agsc) => agsc.ID === this.IsPercentageOf);
      // console.log(this);
      if (this?.IsPercentageOf !== null && newAction !== undefined) {
        const price = newAction.findPrice(actions);
        const percentagePrice = percentage * price;
        // console.log(percentagePrice);
        return percentagePrice;
      } else {
        // console.log(this!.UpCharge);
        return this!.UpCharge;
      }
    } catch (e) {
      return 0;
    }
  }
}

export default Action;
