import React from "react";
import { TextField, SelectField } from "..";
import "mobx-react-lite/batchingForReactDom";
import { IonGrid, IonCol, IonRow, IonText } from "@ionic/react";

import "./CustomerContactDetails.css";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/RootStore";
import { useHistory } from "react-router";
import { runInAction } from "mobx";

interface ICustomerContactDetails {
  handleSubmit: Function;
  store?: RootStore;
}

const CustomerContactDetails: React.FC<ICustomerContactDetails> = ({
  store,
  handleSubmit,
}) => {
  const { customers } = store!.stores;
  const { simpleCustomer } = customers;
  const history = useHistory();
  return (
    <IonGrid>
      <IonText color="medium">
        <h6 style={{ paddingLeft: 15 }}>Contact Details</h6>
      </IonText>
      <IonRow>
        <IonCol sizeLg="2" sizeXs="12">
          <SelectField
            interface={"popover"}
            value={simpleCustomer.Title}
            label="Title"
            onChange={(v: any) => {
              // if (simpleCustomer.ID !== 0 && simpleCustomer.Title != v) {
                simpleCustomer.Title = v;
                // handleSubmit();
              // }
            }}
            options={[
              { value: "Mr.", label: "Mr." },
              { value: "Mrs.", label: "Mrs." },
              { value: "Profs.", label: "Profs." },
              { value: "Ing.", label: "Ing." },
              { value: "Dr.", label: "Dr." },
            ]}
          />
        </IonCol>
        <IonCol sizeLg="4" sizeXs="12">
          <TextField
            type="text"
            label="Full name"
            onChange={(v: any) => {
              simpleCustomer.FullName = v;
            }}
            value={simpleCustomer.FullName}
          />
        </IonCol>
        <IonCol sizeLg="3" sizeXs="12">
          <TextField
            type="number"
            label="Mobile"
            onBlur={() => {
              customers.customerExists("mobile", simpleCustomer.Mobile, history);
            }}
            onChange={(v: any) => {
              simpleCustomer.Mobile = v;
            }}
            value={simpleCustomer.ID === 0 ? customers.mobile : simpleCustomer.Mobile}
          />
        </IonCol>
        <IonCol sizeLg="3" sizeXs="12">
          <TextField
            type="number"
            label="Landline"
            onBlur={() => {
              customers.customerExists("landline", simpleCustomer.Landline, history);
            }}
            onChange={(v: any) => {
              simpleCustomer.Landline = v;
            }}
            value={simpleCustomer.Landline}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(CustomerContactDetails));
