import { JsonObject, JsonProperty } from "json2typescript";
import moment from "moment";
import Ticket from "./Ticket";

@JsonObject("ScheduleStatistic")
class ScheduleStatistic {
  @JsonProperty("day_of_week", Number)
  DayOfWeek: number = 0;

  @JsonProperty("capacity", Number)
  Capacity: number = 0;

  @JsonProperty("assigned_deliveries", Number)
  AssignedDeliveries: number = 0;

  @JsonProperty("assigned_pickups", Number)
  AssignedPickups: number = 0;

  @JsonProperty("date", String)
  Date: string = moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");

  @JsonProperty("localities", String)
  Localities: string = "";

  @JsonProperty("tickets", [Ticket], true)
  Tickets: Ticket[] = [];
}

export default ScheduleStatistic;
