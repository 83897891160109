import { JsonObject, JsonProperty } from "json2typescript";
import { Address } from "./Address";
import SimpleTicket from "./SimpleTicket";

@JsonObject("Customer")
class SimpleCustomer {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("title", String)
  Title: string = "";

  @JsonProperty("full_name", String)
  FullName: string = "";

  @JsonProperty("address_id", Number)
  AddressID: number = 0;

  @JsonProperty("address_name", String, true)
  AddressName: string|null = "";

  @JsonProperty("address_number", String, true)
  AddressNumber: string|null = "";

  @JsonProperty("address_locality_id", Number,  true)
  AddressLocalityID: number|null = 0;

  @JsonProperty("address_street_id", Number, true)
  AddressStreetID: number|null = 0;

  // @JsonProperty("address", Address, true)
  // Address: Address = new Address();

  @JsonProperty("mobile", String)
  Mobile: string = "";

  @JsonProperty("landline", String)
  Landline: string = "";

  @JsonProperty("email_address", String)
  EmailAddress: string = "";

  @JsonProperty("date_of_birth", String, true)
  DateOfBirth: string | null = null;

  @JsonProperty("id_card", String)
  IDCard: string = "";

  @JsonProperty("discount", Number, true)
  Discount: number = 0;

  @JsonProperty("occupation", String)
  Occupation: string = "";

  @JsonProperty("comments", String)
  Comments: string = "";

  @JsonProperty("number_of_visits", Number, true)
  NumberOfVisits: number = 0;

  @JsonProperty("total_spent", Number, true)
  TotalSpent: number = 0;

  @JsonProperty("last_visit", String, true)
  LastVisit: string | null = null;

  @JsonProperty("ticket_total", Number, true)
  TicketTotal: number = 0;

  @JsonProperty("outstanding_balance", Number, true)
  OutstandingBalance: number = 0.0;

  @JsonProperty("turnover_rank", Number, true)
  TurnoverRank: number = 0;

  @JsonProperty("visit_rank", Number, true)
  VisitRank: number = 0;

  @JsonProperty("simple_tickets", [SimpleTicket], true)
  SimpleTickets: SimpleTicket[] = [];

  get firstName(): string {
    try {
      return this.FullName.split(" ")[0];
    } catch (e) {
      return "";
    }
  }

  get lastName(): string {
    try {
      return this.FullName.split(" ")[1];
    } catch (e) {
      return "";
    }
  }
}

export default SimpleCustomer;
