import React from "react";
import { configure, observable, runInAction } from "mobx";
import { Provider, inject, observer } from "mobx-react";

import { rootStore, RootStore } from "./stores/RootStore";

import { IonApp, setupConfig } from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";

import {
  EmployeeLogin,
  Routes,
  Toast,
  Loader,
  Alert,
  Dialog,
} from "./components";
import Login from "./pages/Login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import IdleTimer from 'react-idle-timer'

/* Theme variables */
import "./theme/variables.css";

setupConfig({
  animated: true,
  mode: "md",
});

configure({ enforceActions: "observed" });

const App: React.FC = () => {
  return (
    <Provider store={rootStore}>
      <Main store={rootStore} />
    </Provider>
  );
};

@inject("store")
@observer
class Main extends React.Component<{ store: RootStore }> {
 
  @observable idleTime = 6;
  idleTimer:any = null;
  // handleOnAction = this.handleOnAction.bind(this)
  // handleOnActive = this.handleOnActive.bind(this)
  // handleOnIdle = this.handleOnIdle.bind(this)
  
  handleOnAction (event:any) {
    // console.log('user did something')
  }
 
  handleOnActive (event:any) {
    // console.log('user is active')
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  handleOnIdle (event:any, store:RootStore) {
    this.setState({hi:"hi"})
    const {auth} = store.stores
    // auth.agentLogout()
    auth.employeeLogout()
  }

  buildMain() {
    const { auth } = this.props.store.stores;
    if (auth.role === "driver"){
      runInAction(()=>{
        this.idleTime = 60 * 8
      })
    }
    // console.log(this.idleTime)
    return (
      <div>
      {/* <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        // timeout={1000 * 60 * this.idleTime}//6 minutes
        timeout={this.idleTime * 1000}//12 seconds
        onActive={this.handleOnActive}
        onIdle={(e:any)=>{this.handleOnIdle(e, this.props.store)}}
        onAction={this.handleOnAction}
        debounce={250}
      /> */}
      <IonReactHashRouter>
        <EmployeeLogin />
        {auth.isEmployeeLoggedIn ? <Routes /> : null}
      </IonReactHashRouter></div>
    );
  }

  render() {
    const { auth } = this.props.store.stores;
    return (
      <IonApp>
        {auth.isLoggedIn ? (
          this.buildMain()
        ) : (
          <Login store={this.props.store} />
        )}
        <Toast />
        <Alert />
        <Dialog />
        <Loader />
      </IonApp>
    );
  }
}

export default App;
