import React from "react";
import {
  IonContent,
  IonPage,
  IonFooter,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { Menu } from "../components";
import FeedbackBar from "../components/FeedbackBar/FeedbackBar";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { runInAction } from "mobx";

interface PageProps {
  store: RootStore;
}

@inject("store")
@observer
class Home extends React.Component<PageProps> {
  componentDidMount() {
    const { ui, auth, loading } = this.props.store.stores;
    if (auth.role !== "admin") {
      return;
    }
    runInAction(() => {
      ui.setLoading(true);
    });
    this.getDataFromApi().then(() => {
      ui.setLoading(false);
    });
  }

  async getDataFromApi() {
    const { statistics, tickets, customers } = this.props.store.stores;
    await customers.getCustomersLimited(20, "");
    statistics.getActions();
    statistics.getConfigurations();
    // statistics.fetchStatsThisWeek();
    // await tickets.getAllTickets();
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <Menu />
          {isPlatform("mobile") === true ? null : (
            <div
              onScroll={() => {
                console.log("scroll");
              }}
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                zIndex: 99999,
              }}
            >
              v1.0.6
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default Home;
