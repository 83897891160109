import React, { useEffect } from "react";
import { isPlatform } from "@ionic/react";
const Instascan = require("instascan-new");

interface IQRCodeScanner {
  onScan: Function;
}

const QRCodeScanner: React.FC<IQRCodeScanner> = ({ onScan }) => {
  let scanner: any = null;
  useEffect(() => {
    init();
    return () => {
      scanner.stop();
    };
  }, []);

  const init = (cam?: number) => {
    scanner = new Instascan.Scanner({
      video: document.getElementById("__instascan"),
      mirror: !isPlatform("mobileweb"),
    });
    scanner.addListener("scan", onScan);
    Instascan.Camera.getCameras()
      .then((cams: any[]) => {
        if (cams.length > 0) {
          scanner.start(cams[cams.length - 1]);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <video
        id="__instascan"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        playsInline
        autoPlay
        muted
      ></video>
    </div>
  );
};

export default QRCodeScanner;
