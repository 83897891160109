import React, { useState } from "react";

import "./Tiles.css";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

interface ITile {
  title: string;
  selected: boolean;
  image: string | null;
  icon?: string;
  onClick?: any;
  onSelect?: any;
  className?: string;
}

interface ITiles {
  store?: RootStore;
  children: any[];
}

const TileImage: React.FC<{ image: string | null }> = ({ image }) => {
  if (image === null || image === undefined) return null;
  try {
    // i = image;
  } catch (e) {}
  const isColor = image!.startsWith("color:");
  if (isColor) {
    const color = image!.split(":")[1];
    return (
      <div
        className="t-grid-item-image"
        style={{
          backgroundColor: color,
        }}
      />
    );
  }
  return (
    <div
      className="t-grid-item-image"
      style={{
        backgroundImage: `url("${image}")`,
      }}
    />
  );
};

const Tile: React.FC<ITile> = ({
  title,
  selected,
  onClick,
  image,
  className,
}) => (
  <div
    onClick={onClick!}
    className={"t-grid-item " + (selected ? "selected" : "")}
  >
    <TileImage image={image} />
    <div className={`${className!}`}>{title}</div>
  </div>
);

const ShowMoreButton = (showMore: boolean, setShowMore: any) => {
  return !showMore ? (
    <Tile
      title={"+"}
      selected={false}
      onClick={() => {
        setShowMore(!showMore);
      }}
      image={""}
      className={"t-grid-item-text-v2"}
    ></Tile>
  ) : (
    <Tile
      title={"-"}
      selected={false}
      onClick={() => {
        setShowMore(!showMore);
      }}
      image={""}
      className={"t-grid-item-text-v2"}
    ></Tile>
  );
};

const Tiles: React.FC<ITiles> = ({ children, store }) => {
  const [showMore, setShowMore] = useState(false);
  const screenWidth = store!.stores.ui.screenWidth;
  let tilesToShow = children[0].length;
  if (!showMore) {
    tilesToShow = Math.trunc(screenWidth / 120);
    if (screenWidth <= 991) {
      tilesToShow = Math.trunc(screenWidth / 80);
    }
  }
  const childrenNotNulls = children[0].filter(function (el: any) {
    return el != null;
  });
  const needTheShowMoreButton: boolean =
    tilesToShow < childrenNotNulls.length ||
    (showMore && childrenNotNulls.length !== 1);

  children = [children[0].slice(0, tilesToShow + 1), children[1]];
  return (
    <div className="t-grid">
      <div className="t-grid-items">
        {children}
        {needTheShowMoreButton ? ShowMoreButton(showMore, setShowMore) : null}
      </div>
    </div>
  );
};

export { Tile };
export default inject("store")(observer(Tiles));
