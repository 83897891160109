import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TicketSubCategoryLogState")
class TicketSubCategoryLogState {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("ticket_sub_category_id", Number)
  TicketSubCategoryID: number = 0;

  @JsonProperty("state", String)
  State: string = "";

  @JsonProperty("logged_at", String)
  LoggedAt: string = "";

  @JsonProperty("log", String)
  Log: string = "";
}

export default TicketSubCategoryLogState;
