import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Configuration")
class Configuration {
  @JsonProperty("id", Number)
  ID: number = 0;

  @JsonProperty("month", String)
  Month: string = "";

  @JsonProperty("action_id", Number)
  ActionID: number = 0;

  @JsonProperty("amounts", String)
  Amounts: string = "";
}

export default Configuration;
