import {
  IonContent,
  IonPage,
  IonReorderGroup,
  IonItemDivider,
  IonLabel,
  IonItemGroup,
  IonItem,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";

import React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { Header } from "../components";
import { Modifier } from "../stores/models";
import ModifierListItem from "../components/ModifierListItem/ModifierListItem";
import Accordion from "../components/Accordion/Accordion";

interface PageProps {
  store: RootStore;
}

interface RouteProps {
  name: string;
}

@inject("store")
@observer
class Modifiers extends React.Component<
  PageProps & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      return;
    }
    this.getModifiersList();
  }

  getModifiersList = () => {
    const { modifiers } = this.props.store.stores;
    modifiers.getModifiers();
  };

  search = (q: string) => {
    const { modifiers } = this.props.store.stores;
    modifiers.filterModifiers(q);
  };

  add = () => {
    this.props.history.push("/modifiers/0");
  };


  Reorder = (event: CustomEvent<ItemReorderEventDetail>, type:string) => {
    const { modifiers } = this.props.store.stores;
    const reorderedModifiers = modifiers.reOrderModifiers(event.detail.from, event.detail.to, type);
    event.detail.complete();
  };

  getModifiersAccordions = (): JSX.Element[] => {
    const { modifiers } = this.props.store.stores;
    let ms = modifiers.modifiers;
    let types = modifiers.types;
    return types.map((type) => {
      const elements = ms
        .filter((m) => m.Type === type)
        .map((modifier) => {
          if (!modifier.ParentID) {
            return (
              <ModifierListItem
                modifier={modifier}
                reOrder={"start"}
                key={modifier.ID}
              />
            );
          }
        });
      return (
        <Accordion
          reOrderItems={() => {
            // modifiers.reOrderModifiersAsyc();
          }}
          reOrderEvent={this.Reorder}
          title={type}
          elements={elements}
        />
      );
    });
  };

  getReorderableModifiers = (): JSX.Element => {
    return <>{this.getModifiersAccordions()}</>;
  };

  render() {
    const { auth } = this.props.store.stores;
    if (auth.role !== "admin") {
      //TODO display message of un-authorization
      return <Redirect to="/home" exact />;
    }
    return (
      <IonPage>
        <Header
          backURL="/home"
          title="Modifiers"
          onSearch={this.search}
          onAdd={this.add}
        />
        <IonContent>{this.getReorderableModifiers()}</IonContent>
      </IonPage>
    );
  }
}

export default withRouter(Modifiers);
