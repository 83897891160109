import { BaseService, IResponse } from "./BaseService";
import { Agent } from "../stores/models";

class AgentService extends BaseService {
  public async fetchAll(): Promise<Agent[]> {
    try {
      const resp = await this.http.get("/api/v1/agents/");
      return this.decodeArray<Agent>(resp, Agent);
    } catch (error) {
      throw error;
    }
  }

  public async fetchById(id: number): Promise<Agent> {
    try {
      const resp = await this.http.get(`/api/v1/agents/${id}`);
      return this.decodeObject<Agent>(resp, Agent);
    } catch (error) {
      throw error;
    }
  }

  public async create(agent: Agent): Promise<Agent> {
    try {
      const payload = this.encodeObject<Agent>(agent, Agent);
      delete payload["address"];
      const resp = await this.http.post("/api/v1/agents/", payload);
      return this.decodeObject<Agent>(resp, Agent);
    } catch (error) {
      throw error;
    }
  }

  public async update(id: number, agent: Agent): Promise<Agent> {
    try {
      const payload = this.encodeObject<Agent>(agent, Agent);
      delete payload["address"];
      const resp = await this.http.put(`/api/v1/agents/${id}`, payload);
      return this.decodeObject<Agent>(resp, Agent);
    } catch (error) {
      throw error;
    }
  }

  public async delete(id: number): Promise<IResponse> {
    try {
      const resp = await this.http.delete(`/api/v1/agents/${id}`);
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async setPassword(
    id: number,
    emailAddress: string,
    password: string
  ): Promise<IResponse> {
    try {
      const resp = await this.http.put(`/api/v1/agents/password/${id}`, {
        email_address: emailAddress,
        password: password,
      });
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default AgentService;
