import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import { Agent } from "./models";
import { AgentService } from "../services";
import Fuse from "fuse.js";

class AgentStore {
  private root: RootStore;
  private api: AgentService;

  @observable public agents: Agent[] = [];
  @observable private _agents: Agent[] = [];
  @observable public agent: Agent = new Agent();

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.agents;
  }

  public async getAgents() {
    const { ui } = this.root.stores;
    try {
      const agents = await this.api.fetchAll();
      this.setAgents(agents, true);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getAgentById(id: number): Promise<Agent> {
    const { ui } = this.root.stores;
    this.setAgent(new Agent());
    try {
      const agent = await this.api.fetchById(id);
      this.setAgent(agent);
      return agent;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async filterAgents(q: string) {
    if (q.trim() === "") {
      this.setAgents(this._agents);
      return;
    }
    const options: any = {
      threshold: 0,
      distance: 10,
      keys: ["Name", "Landline", "Mobile", "EmailAddress"],
    };
    const fuse = new Fuse(this._agents, options);
    const agents = fuse.search(q).map((a) => a.item);
    this.setAgents(agents);
  }

  public async createAgent(): Promise<Agent> {
    const { ui } = this.root.stores;
    try {
      const agent = await this.api.create(this.agent);
      runInAction(() => {
        this.agents.push(agent);
        this._agents.push(agent);
      });
      return agent;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async updateAgent(id: number, agent: Agent) {
    try {
      await this.api.update(id, agent);
      return true;
    } catch (error) {
      throw error;
    }
  }

  public async saveAgent(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      // TODO: parse response or agent ?
      const updatedAgent = await this.api.update(this.agent.ID, this.agent);
      this.replaceAgent(updatedAgent);
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async deleteAgent(id: number): Promise<boolean> {
    if (this.agent.ID === 0) {
      return false;
    }
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.delete(id);
      if (resp.success) {
        runInAction(() => {
          const customersToFilter = this.agents;
          this.agents = customersToFilter.filter((a) => a.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async setAgentPassword(
    user_name: string,
    password: string
  ): Promise<Boolean> {
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.setPassword(
        this.agent.ID,
        user_name,
        password
      );
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  @action
  public resetAgent() {
    this.agent = new Agent();
  }

  @action
  public resetAgents() {
    this.agents = [];
    this._agents = [];
  }

  @action
  private replaceAgent(agent: Agent) {
    this.agent = agent;
    if (this.agents.length > 0) {
      var index = this.agents.findIndex((item) => item.ID === agent.ID);
      this.agents.splice(index, 1, agent);
      this._agents.splice(index, 1, agent);
    }
  }

  @action
  public setAgent(agent: Agent) {
    this.agent = agent;
  }

  @action
  public setAgents(agents: Agent[], reload: boolean = false) {
    if (reload) {
      this._agents = agents;
    }
    this.agents = agents;
  }
}

export default AgentStore;
