import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Statistics")
class Statistics {
  @JsonProperty("month", String)
  Month: string = "";

  @JsonProperty("action", String)
  Action: string = "";

  @JsonProperty("day_of_week", String)
  DayOfWeek: string = "";

  @JsonProperty("amount_left", Number)
  AmountLeft: number = 0;

  @JsonProperty("amount_accepted", Number)
  AmountAccepted: number = 0;

  @JsonProperty("total_amount", Number)
  TotalAmount: number = 0;
}
export default Statistics;
