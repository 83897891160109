import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Message")
class Message {
  @JsonProperty("customer_ids", [Number])
  CustomerIDS: Number[] = [];

  @JsonProperty("text", String)
  Text: string = "";
}

export default Message;
