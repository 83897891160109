import { withRouter, RouteComponentProps } from "react-router";
import React from "react";
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonList,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  isPlatform,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonRadio,
  withIonLifeCycle,
} from "@ionic/react";
// import logoposMini from "./../assets/logo68x28.jpg";

// import beep from "./../assets/beep.wav""
import { Header, QRCodeScanner, TextField } from "../components";
import { inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import TrackingItem from "../components/TrackingItem/TrackingItem";
import {
  qrCode,
  playForwardCircleSharp,
  brushSharp,
  appsOutline,
} from "ionicons/icons";
import { observable, runInAction, action } from "mobx";
import SignatureCanvas from "react-signature-canvas";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";

interface ITracking {
  store?: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class Tracking extends React.Component<
  ITracking & RouteComponentProps<RouteProps>
> {
  @observable private isManualInput = true;
  @observable private isScanning = false;
  @observable private isSigning = false;
  @observable private startedSigning = false;
  @observable private scannedCode = "";
  @observable private id: number = 0; //ticket or garment.
  private signatureString = "";
  private signatureData: any[] = [];
  private sigCanvas: any = null;
  private logState: string = "";
  private _qrCodeInput: any = null;


  ionViewWillLeave() {
    const { tracking } = this.props.store!.stores;
    runInAction(() => {
      tracking.items = [];
      // tracking.clearCache(); 
    });
    console.log("ionViewWillLeave event fired for tracking");
  }

  ionViewWillEnter() {
    const { tracking } = this.props.store!.stores;
    runInAction(() => {
      tracking.items = [];
      tracking.clearCache(); 
    });
    console.log("ionViewWillLeave event fired for tracking");
  }

  componentDidMount() {
    const { tracking } = this.props.store!.stores;
    this.getDataFromApi().then(() => {
      if (this.props.match.params.id !== undefined) {
        tracking.reset();
        const params = new URLSearchParams(this.props.location.search);
        const isTicket = params.get("type") === "ticket";
        const id = parseInt(this.props.match.params.id);
        this.setId(id);
        if (isTicket === true) {
          tracking.parseTrackingCode(`!T!T:${id}`, false);
        } else {
          const description = params.get("desc");
          tracking.parseTrackingCode(`!I!I:${id},${description}`, false);
        }
      }
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this._qrCodeInput !== null) {
      this._qrCodeInput.focus();
    }
  }

  async getDataFromApi() {
    const { tickets } = this.props.store!.stores;
    // await tickets.getAllTickets();
  }

  @action
  private setId(id: number) {
    this.id = id;
  }

  private scanNative() {
    const { ui } = this.props.store!.stores;
    BarcodeScanner.scan({
      showTorchButton: true,
      showFlipCameraButton: true,
      formats: "QR_CODE",
      disableAnimations: true,
      disableSuccessBeep: false,
      resultDisplayDuration: 0,
    })
      .then((barcodeData) => {
        this.handleScan(barcodeData.text);
      })
      .catch((err) => {
        ui.showToast({ color: "danger", message: err, position: "top" });
      });
  }

  handleScan = (data: string | null) => {
    const { ui, tracking } = this.props.store!.stores;
    try {
      // const audio = new Audio("http://www.soundjay.com/button/beep-07.wav")
      // audio.play()
      console.log(data);
      tracking.parseTrackingCode(data, true);
    } catch (err) {
      ui.showToast({ color: "danger", message: err, position: "top" });
    }
  };

  handleReset = () => {
    const { ui, tracking } = this.props.store!.stores;
    ui.showAlert({
      title: "Confirm",
      message: "Are you sure you want to clear the list?",
      handler: () => tracking.reset(),
    });
  };

  handleConfirm = async () => {
    const { tracking, tickets, statistics } = this.props.store!.stores;
    tracking.setLogState(this.logState);
    tracking.setSignature(this.signatureString);
    if (await tracking.bulkSetStates()) {
      runInAction(() => {
        statistics.getScheduleStatisticsByWeek(
          statistics.yearNumber,
          statistics.weekNumber
        );
        this.logState = "";
        this.signatureData = [];
        this.signatureString = "";
      });
      if (this.id !== 0) {
        tickets.getTicket(tickets.ticket.ID);
        this.props.history.goBack();
      }
    }
  };

  @action
  showSignature = () => {
    if (this.isSigning) {
      this.signatureData = this.sigCanvas.toData();
      this.signatureString = this.sigCanvas
        .getTrimmedCanvas()
        .toDataURL("image/png");
    } else {
      setTimeout(() => {
        this.sigCanvas.fromData(this.signatureData);
      }, 100);
    }
    this.isManualInput = false;
    this.isScanning = false;
    this.isSigning = !this.isSigning;
  };

  @action
  showManual = () => {
    this.isManualInput = !this.isManualInput;
    // this.isScanning = false;
  };

  showStateSelection = () => {
    const { ui } = this.props.store!.stores;
    runInAction(() => {
      this.isScanning = false;
    });
    ui.showDialog({
      title: "Select State",
      content: this.renderStateOptions(),
      handler: () => {
        ui.closeDialog();
      },
    });
  };

  startScanner = () => {
    if (
      !isPlatform("mobileweb") &&
      !isPlatform("pwa") &&
      !isPlatform("desktop")
    ) {
      this.scanNative();
    } else {
      runInAction(() => {
        this.isScanning = !this.isScanning;
        // this.isManualInput = false;
      });
    }
  };

  renderTotal = () => {
    const { tracking } = this.props.store!.stores;
    let total = 0;
    tracking.items.forEach((i) => {
      if (i.Type === "ticket") {
        total += i.Price ? i.Price : 0;
      }
    });
    return <>&euro;{total}</>;
  };

  renderFooter = () => {
    return (
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color={this.isScanning ? "danger" : ""}
              onClick={this.startScanner}
            >
              <IonIcon icon={qrCode} size="large" />
            </IonButton>
            <IonButton onClick={this.showManual}>
              <IonIcon icon={appsOutline} size="large" />
            </IonButton>
          </IonButtons>
          {/* {tracking.items.length > 0 ? ( */}
          <IonButtons slot="end">
            {this.renderTotal()}
            <IonButton onClick={this.showStateSelection}>
              <IonIcon icon={playForwardCircleSharp} size="large" />
            </IonButton>
            <IonButton onClick={this.showSignature}>
              <IonIcon icon={brushSharp} size="large" />
            </IonButton>
          </IonButtons>
          {/* ) : null} */}
        </IonToolbar>
      </IonFooter>
    );
  };

  @action
  setStartedSigning(value: boolean): void {
    this.startedSigning = value;
  }

  renderSignature = () => (
    <div style={{ flex: 1, position: "relative" }}>
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton
            color="warning"
            onClick={() => {
              this.sigCanvas.clear();
              this.setStartedSigning(false);
            }}
          >
            Clear
          </IonButton>
          <IonButton fill="solid" color="success" onClick={this.showSignature}>
            Confirm
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {this.startedSigning ? (
        <div></div>
      ) : (
        <div
          style={{
            pointerEvents: "none",
            position: "absolute",
            zIndex: 2,
            backgroundColor: "#ffffff",
            width: "80%",
            bottom: "29px",
            marginLeft: "10%",
            fontSize: "7vw",
            textAlign: "center",
            color: "black",
          }}
          onTouchStart={() => {
            console.log(this.setStartedSigning(true));
          }}
        >
          Sign Here
        </div>
      )}
      <SignatureCanvas
        canvasProps={{
          style: {
            width: "100%",
            height: "calc(100% - 60px)",
            backgroundColor: "#fff",
            position: "absolute",
            zIndex: 1,
            border: "2px dotted #000000",
          },
        }}
        onBegin={() => {
          console.log(this.setStartedSigning(true));
        }}
        ref={(ref: any) => {
          this.sigCanvas = ref;
        }}
      />
    </div>
  );

  renderStateOptions = () => {
    const { tracking } = this.props.store!.stores;
    return (
      <IonContent>
        <IonList>
          <IonRadioGroup value={tracking.logState}>
            {tracking.states.map((s, i) => (
              <IonItem
                key={s + "" + i}
                onClick={() => {
                  this.logState = s;
                }}
              >
                <IonLabel key={"l" + i}>{s}</IonLabel>
                <IonRadio key={"r" + i} value={s} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    );
  };

  renderManual = () => {
    const { tracking, tickets, garments, ui } = this.props.store!.stores;
    return (
      <>
        <IonRow>
          <IonCol sizeLg="3" sizeMd="4" sizeSm="6" sizeXs="12">
            <TextField
              type="number"
              label="Ticket Number"
              onChange={(v: number) => {
                tracking.setTicketID(v);
              }}
              done={async () => {
                if (tracking.ticketID) {
                  await tickets.getTicket(tracking.ticketID);
                  console.log(tickets.ticket.ID)
                  if (tickets.ticket.ID!== 0){
                  const qrCode: string = tickets.ticket.getQRString;
                  console.log(qrCode);
                  tracking.parseTrackingCode(qrCode, true);
                  }
                } else {
                  ui.showToast({
                    color: "danger",
                    message: "Ticket Number UNDEFINED!",
                  });
                }
              }}
            ></TextField>
          </IonCol>
          <IonCol>
            <input
              type="password"
              value={this.scannedCode}
              ref={(c) => (this._qrCodeInput = c)}
              onChange={(e: any) => {
                runInAction(() => {
                  this.scannedCode = e.target.value;
                });
              }}
              style={{ width: "100%", padding: 10, color: "#000" }}
              placeholder="QR Code"
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  tracking.parseTrackingCode(this.scannedCode, false);
                  runInAction(() => {
                    this.scannedCode = "";
                  });
                }
              }}
            />
          </IonCol>
        </IonRow>
      </>
    );
  };

  renderCamera = () => (
    <div>
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ padding: 0 }}>
          <IonCol
            sizeLg="4"
            offsetLg="4"
            sizeXs="12"
            offsetXs="0"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
            }}
          >
            <div style={{ width: "100%", maxHeight: "60vh" }}>
              <QRCodeScanner onScan={this.handleScan} />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );

  renderItems = () => {
    const { tracking } = this.props.store!.stores;
    return (
      <IonContent>
        <IonList>
          {tracking.items
            .slice()
            .reverse()
            .map((t) => {
              return (
                <TrackingItem store={this.props.store!} key={t.ID} item={t} />
              );
            })}
        </IonList>
      </IonContent>
    );
  };

  render() {
    return (
      <IonPage>
        {this.id === 0 ? (
          <Header
            title="Tracking"
            backURL="/home"
            onConfirm={this.handleConfirm}
            onCancel={this.handleReset}
          />
        ) : (
          <Header
            title="Tracking"
            backURL="/home"
            onConfirm={this.handleConfirm}
          />
        )}

        {this.isManualInput ? this.renderManual() : null}
        {this.isScanning ? this.renderCamera() : null}
        {this.renderItems()}
        {this.isSigning ? this.renderSignature() : null}
        {this.renderFooter()}
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Tracking));


