import React from "react";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/RootStore";
import { IOption } from "../SelectField/SelectField";
import { IonGrid, IonText, IonRow, IonCol } from "@ionic/react";
import { TextField, SelectField } from "..";

import "./EmployeeContactDetails.css";

interface IEmployeeContactDetails {
  store?: RootStore;
}

const EmployeeContactDetails: React.FC<IEmployeeContactDetails> = ({
  store,
}) => {
  const { agents } = store!.stores.agents;
  const { employee } = store!.stores.employees;

  const _agents: IOption[] = agents.map((a) => {
    return { value: a.ID, label: a.Name };
  });

  return (
    <IonGrid>
      <IonText color='medium'>
        <h5 style={{ padding: 15 }}>Contact Details</h5>
      </IonText>
      <IonRow>
        <IonCol sizeLg='4' sizeXs='12'>
          <TextField
            label='Full name'
            type='text'
            value={employee.FullName}
            onChange={(n: string) => {
              employee.FullName = n;
            }}
          />
        </IonCol>
        <IonCol sizeLg='4' sizeXs='12'>
          <TextField
            label='Mobile'
            type='text'
            value={employee.Mobile}
            onChange={(m: string) => {
              employee.Mobile = m;
            }}
          />
        </IonCol>
        <IonCol sizeLg='4' sizeXs='12'>
          <SelectField
            label='Agent'
            value={employee.AgentID}
            onChange={(agentID: number) => {
              employee.AgentID = agentID;
            }}
            options={_agents}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(EmployeeContactDetails));
