import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import { TrackingService } from "../services";
import BulkLogState from "./models/BulkLogState";
import { sign } from "crypto";
import Tickets from "../pages/Tickets";

export interface ITrackingItem {
  ID?: string;
  TicketID?: number;
  ItemID?: number;
  Type: "ticket" | "item" | "multipleItems";
  Date?: string;
  Piece?: string;
  Desc?: string;
  Agent?: string;
  Customer?: string;
  Price?: number;
  ItemIDS?: string[];
}

class TrackingStore {
  private root: RootStore;
  private api: TrackingService;
  private _cache: string[] = [];

  @observable
  public items: ITrackingItem[] = [];
  @observable
  public signature: string = "";
  @observable
  public logState: string = "";
  @observable
  public ticketSubCategoryID: number = 0;
  @observable
  public ticketID: number = 0;

  public states = [
    "AWAITING PICKUP",
    "PICKED UP",
    "MARKED",
    "TECNOWASH",
    "AWAITING DELIVERY",
    "OUT FOR DELIVERY",
    "B Kara",
    "Mosta",
    "San Gwann",
    // "DELIVERED",
    "DELIVERED AND PAID",
    "DELIVERED NOT PAID",
    "CLOSED",
  ];

  constructor(root: RootStore) {
    this.root = root;
    this.api = root.services.tracking;
  }

  public clearCache() {
    runInAction(() => {
      this._cache = [];
    });
  }

  public parseTrackingCode(code: string | null, prompts: boolean) {
    const { ui } = this.root.stores;
    if (code === null || code === undefined) {
      return;
    }
    if (!code.startsWith("!")) {
      code = code.substring(1, code.length);
      console.log(code);
    }
    if (this._cache.includes(code)) {
      if (prompts === true) {
        ui.showToast({
          color: "warning",
          message: "Already in List",
          position: "top",
        });
      }
      return;
    }
    let type: "ticket" | "item" | "multipleItems" = "ticket";
    if (code.substring(0, 3) === "!T!") {
      type = "ticket";
    } else if (code.substring(0, 3) === "!M!") {
      type = "multipleItems";
    } else if (code.substring(0, 3)) {
      type = "item";
    } else {
      return;
    }

    const data = code.substring(3, code.length + 1);

    let item: ITrackingItem = { Type: type };
    data.split(",").forEach((d) => {
      const fields = d.split(":");
      // ticket T:43,IA:Queen's HQ,DI:2020-07-30,CN:Roland Cachia
      // item T:46,I:252,D:SUIT 2PC,P:jacket
      if (fields.length === 2) {
        switch (fields[0]) {
          case "T":
            item.TicketID = parseInt(fields[1]);
            break;
          case "I":
            item.ItemID = parseInt(fields[1]);
            break;
          case "IA":
            item.Agent = fields[1];
            break;
          case "DI":
            item.Date = fields[1];
            break;
          case "CN":
            item.Customer = fields[1];
            break;
          case "D":
            item.Desc = fields[1];
            break;
          case "P":
            item.Piece = fields[1];
            break;
          case "ITEMS":
            item.ItemIDS = fields[1].split("-").concat();
            break;
          case "TOTAL":
            item.Price = parseFloat(fields[1]);
            break;
        }
      }
    });

    item.ID =
      (item.TicketID || 0) + "-" + (item.ItemID || 0) + "-" + Math.random();

    const { tickets } = this.root.stores;
    const ticket = tickets.allTickets.find((t) => t.ID === item.TicketID);
    if (item.Type === "ticket") {
      if (ticket !== undefined) {
        item.Price = ticket.Total;
      }
    } else if (item.Type === "multipleItems") {
      console.log("this is a multiple item qr code scan");
    } else {
      const ticketSubCategory = ticket?.SubCategories.find(
        (tsc) => tsc.ID === item.ItemID
      );
      if (ticketSubCategory !== undefined) {
        item.Price = ticketSubCategory.getTotal();
      }
    }

    runInAction(() => {
      this.items.push(item);
    });
    if (prompts === true) {
      ui.showToast({
        color: "success",
        position: "top",
        message: "Scan Successful",
      });
    }
    this._cache.push(code);
  }

  private filterByType(type: string) {
    return this.items.slice().filter((i) => i.Type === type);
  }

  public async bulkSetStates(): Promise<boolean> {
    const { ui } = this.root.stores;
    const ticketStore = this.root.stores.tickets;
    if (this.items.length === 0) {
      ui.showToast({ color: "warning", message: "No items to submit" });
      return false;
    }
    if (this.logState.trim() === "") {
      ui.showToast({ color: "warning", message: "Select a state first" });
      return false;
    }
    const tickets = this.filterByType("ticket").map((t) => t.TicketID);
    let items = this.filterByType("item").map((i) => i.ItemID);
    this.filterByType("multipleItems").map((i) =>
      i.ItemIDS?.map((item) => {
        items.push(parseInt(item));
      })
    );
    console.log(items);
    let errors: boolean = false;
    if (tickets !== undefined && tickets.length > 0) {
      const bls: BulkLogState = {
        Ids: tickets as number[],
        Signature: this.signature,
        State: this.logState,
      };
      const ok = await this.api.setTicketsStates(bls);
      if (!ok) {
        errors = true;
      } else {
        //refresh state for tickets in the all tickets also.
        bls.Ids.forEach((id) => {
          ticketStore.setTicketState(id, bls.State);
        });
      }
    }
    if (items !== undefined && items.length > 0) {
      const bls: BulkLogState = {
        Ids: items as number[],
        Signature: this.signature,
        State: this.logState,
      };
      const ok = await this.api.setTicketSubCategoryStates(bls);
      if (!ok) {
        errors = true;
      } else {
        bls.Ids.forEach((id) => {
          ticketStore.setTicketSubCategoryState(id, bls.State);
        });
      }
    }
    if (!errors) {
      ui.showToast({
        color: "success",
        message: `Saved ${this.items.length} items successfully`,
      });
      this.reset();
    } else {
      ui.showToast({
        color: "danger",
        message: "Something went wrong.",
      });
    }
    return !errors;
  }

  public async setTicketSubCategoriesState(
    ticketSubCategoryIDS: number[],
    state: string,
    signature: string
  ): Promise<Boolean> {
    const bls: BulkLogState = {
      Ids: ticketSubCategoryIDS,
      Signature: signature,
      State: state,
    };
    return this.api.setTicketSubCategoryStates(bls);
  }

  public async setTicketsState(
    ticketSubCategoryIDS: number[],
    state: string,
    signature: string
  ): Promise<Boolean> {
    const bls: BulkLogState = {
      Ids: ticketSubCategoryIDS,
      Signature: signature,
      State: state,
    };
    const ok = await this.api.setTicketsStates(bls);
    if (ok) {
      //for each ticket that the state has been updated find the ticket in the store and update accordingly.
      bls.Ids.forEach((tid) => {
        this.root.stores.tickets.setTicketState(tid, bls.State);
        this.root.stores.tickets.getTicket(tid as number);
        this.root.stores.customers.getCustomerById(
          this.root.stores.customers.customer.ID
        );
      });
      return true;
    } else {
      return false;
    }
  }

  @action
  public removeItem(item: ITrackingItem) {
    this.items = this.items.filter((i) => i !== item);
  }

  @action
  removeItemFromCache(code: string) {
    this._cache = this._cache.filter((c) => c !== code);
  }

  @action
  public setSignature(signature: string) {
    this.signature = signature;
  }

  @action
  public setLogState(logState: string) {
    this.logState = logState;
  }

  @action
  public setGarmentID(id: number) {
    this.ticketSubCategoryID = id;
  }

  @action
  public setTicketID(id: number) {
    this.ticketID = id;
  }

  @action
  public reset() {
    this._cache = [];
    this.items = [];
    this.signature = "";
    this.logState = "";
  }
}

export default TrackingStore;
