import React from "react";

import { IonGrid, IonText, IonRow, IonCol } from "@ionic/react";
import { TextField, SelectField } from "..";

import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import { IOption } from "../SelectField/SelectField";
import moment from "moment";
import "./CustomerStatistics.css";

interface ICustomerStatistics {
  store?: RootStore;
}

const CustomerStatistics: React.FC<ICustomerStatistics> = ({ store }) => {
  const {  simpleCustomer } = store!.stores.customers;

  return (
    <IonGrid>
      <IonText color="medium">
        <h6 style={{ paddingLeft: 15 }}>Statistics</h6>
      </IonText>
      <IonRow>
        <IonCol sizeLg="1" sizeXs="12">
          <TextField
            disabled={true}
            // labelStyle={"opacity"}
            label="Visit Rank"
            value={simpleCustomer.VisitRank.toString() === "-1"?'N/A':simpleCustomer.VisitRank.toString()}
            type="text"
          />
        </IonCol>
        <IonCol sizeLg="1" sizeXs="12" style={{ display: "table" }}>
          <TextField
            disabled={true}
            labelClassNames={"fullLabel"}
            label="Turnover Rank"
            // value={customer.OutstandingBalance!}
            value={simpleCustomer.TurnoverRank.toString() === "-1"?'N/A':simpleCustomer.TurnoverRank.toString()}
            type="text"
          />
        </IonCol>
        <IonCol sizeLg="1" sizeXs="12">
          <TextField
            disabled={true}
            // labelStyle={"opacity"}
            label="Total Spent"
            value={"€ "+simpleCustomer.TotalSpent!.toFixed(2)}
            type="text"
          />
        </IonCol>
        <IonCol sizeLg="2" sizeXs="12">
          <TextField
            disabled={true}
            // labelStyle={"opacity"}
            label="Outstanding Balance"
            // value={customer.OutstandingBalance!}
            value={
              "€ " +
              simpleCustomer.OutstandingBalance.toFixed(2).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }
            type="text"
          />
        </IonCol>
        <IonCol sizeLg="1" sizeXs="12">
          <TextField
            disabled={true}
            // labelStyle={"opacity"}
            label="Total Tickets"
            value={simpleCustomer.TicketTotal!}
            type="text"
          />
        </IonCol>
        <IonCol sizeLg="3" sizeXs="12">
          <TextField
            disabled={true}
            // labelStyle={"opacity"}
            label="Last Visit"
            value={
              simpleCustomer.LastVisit
                ? moment(simpleCustomer.LastVisit!).format(
                    `dddd MMMM YYYY HH:MM`
                  )
                : "N/A"
            }
            type="text"
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(CustomerStatistics));
