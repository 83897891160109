import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import PickupDelivery from "./models/PickupDelivery";
import { PickupDeliveryService } from "../services";
import moment from "moment";

class PickupDeliveryStore {
  private root: RootStore;
  private api: PickupDeliveryService;

  public months: string[] = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  public daysOfTheWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // @observable public pickupDelivery: PickupDelivery[] = [];
  @observable public pickupDeliveries: PickupDelivery[] = [];

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.pickupDelivery;
  }

  @action
  resetPickupDeliveries() {
    this.pickupDeliveries = [];
  }

  public getPickupDeliveryLocalities(): string[] {
    let localities: string[] = [];
    this.pickupDeliveries.forEach((pd) => {
      const locs: string[] = pd.Localities.split(";");
      localities = locs.concat(localities);
    });
    return localities;
  }

  public getPickupDeliveryLocalitiesThisSeason(): string[] {
    let localities: string[] = [];
    this.pickupDeliveries.forEach((pd) => {
      const from = moment(pd.From).format("MM-DD");
      const to = moment(pd.To).format("MM-DD");
      const today = moment(new Date()).format("MM-DD");
      if (to > from) {
        if (today > from && today < to) {
          const locs: string[] = pd.Localities.split(";");
          localities = locs.concat(localities);
        }
      } else {
        const locs: string[] = pd.Localities.split(";");
        localities = locs.concat(localities);
      }
    });
    return localities;
  }

  public async getPickupDeliveries() {
    const { ui } = this.root.stores;
    try {
      const pickupDeliveries = await this.api.fetchAll();
      this.setPickupDeliveries(pickupDeliveries);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async save() {
    const { ui } = this.root.stores;
    try {
      const pickupDeliveries = await this.api.save(this.pickupDeliveries);
      runInAction(() => {
        this.pickupDeliveries = pickupDeliveries;
        // this.setPickupDeliveries(pickupDeliveries);
        ui.showToast({
          color: "success",
          message: `Settings saved.`,
        });
      });
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  @action
  public setPickupDeliveries(pickupDeliveries: PickupDelivery[]) {
    this.pickupDeliveries = pickupDeliveries;
  }
}

export default PickupDeliveryStore;
