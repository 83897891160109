import {
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";

import { inject, observer } from "mobx-react";

import React from "react";

import {
  people,
  peopleCircleOutline,
  business,
  logOut,
  cogOutline,
  shirtOutline,
  colorPaletteOutline,
  settingsOutline,
  qrCode,
  hammerOutline,
  clipboardOutline,
  calendar,
  carSharp,
  alert,
} from "ionicons/icons";

import "./Menu.css";
import { RootStore } from "../../stores/RootStore";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  blackList?: string[];
  MustBeHQ?: boolean;
}

const appPages: AppPage[] = [
  {
    title: "Agents",
    url: "/agents",
    iosIcon: business,
    mdIcon: business,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Employees",
    url: "/employees",
    iosIcon: peopleCircleOutline,
    mdIcon: peopleCircleOutline,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Customers",
    url: "/customers",
    iosIcon: people,
    mdIcon: people,
  },
  {
    title: "Tickets",
    url: "/tickets",
    iosIcon: clipboardOutline,
    mdIcon: clipboardOutline,
  },
  {
    title: "Actions",
    url: "/actions",
    iosIcon: hammerOutline,
    mdIcon: hammerOutline,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Garments",
    url: "/garments",
    iosIcon: shirtOutline,
    mdIcon: shirtOutline,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Modifiers",
    url: "/modifiers",
    iosIcon: colorPaletteOutline,
    mdIcon: colorPaletteOutline,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Configuration",
    url: "/configuration",
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Tracking",
    url: "/tracking",
    iosIcon: qrCode,
    mdIcon: qrCode,
    blackList: [],
    // MustBeHQ: true,
  },
  {
    title: "Scheduler",
    url: "/scheduling",
    iosIcon: calendar,
    mdIcon: calendar,
    blackList: ["employees", "manager"],
    MustBeHQ: true,
  },
  {
    title: "Reporting",
    url: "/reporting",
    iosIcon: calendar,
    mdIcon: calendar,
    blackList: ["employee", "manager", "driver"],
  },
  {
    title: "Express",
    url: "/express",
    iosIcon: alert,
    mdIcon: alert,
    blackList: ["driver"],
  },
  {
    title: "State Logs",
    url: "/state-logs",
    iosIcon: carSharp,
    mdIcon: carSharp,
    blackList: ["driver"],
  }
];

interface IMenu {
  store?: RootStore;
}
const Menu: React.FC<IMenu> = ({ store }) => {
  const { auth } = store!.stores;
  const handleLogout = async (e: any) => {
    e.preventDefault();
    // const { auth } = props.store!.stores;
    await auth.employeeLogout();
  };

  return (
    <IonGrid>
      <IonRow>
        {appPages.map((appPage) => {
          let blackList = appPage.blackList?.includes(auth.role);
          const mustBeHQ = appPage.MustBeHQ;
          if (mustBeHQ && !auth.isHq) {
            return
          }
          if (!blackList) {
            return (
              <IonCol key={appPage.url} sizeXs="12" sizeLg="3">
                <IonItem
                  className="main-menu-item"
                  routerLink={appPage.url}
                  routerDirection="root"
                >
                  <IonIcon slot="start" size="large" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonCol>
            );
          }
        })}

        {/*static*/}

        <IonCol key="logout" sizeXs="12" sizeLg="3">
          <IonItem
            routerLink="/"
            className="main-menu-item"
            onClick={handleLogout}
            routerDirection="root"
          >
            <IonIcon
              slot="start"
              icon={logOut}
              size="large"
              className="main-menu-item-icon"
            />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default inject("store")(observer(Menu));
