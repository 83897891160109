import React from "react";
import { observer, inject } from "mobx-react";
import { RootStore } from "../stores/RootStore";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Header,
  AgentContactDetails,
  AgentPasswordReset,
  ContactPersonDetails,
} from "../components";
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonContent,
} from "@ionic/react";
import Address from "../components/Address/Address";

interface IAgentDetails {
  store: RootStore;
}

interface IRoute {
  id: string;
}

@inject("store")
@observer
class AgentDetails extends React.Component<
  IAgentDetails & RouteComponentProps<IRoute>
> {
  UNSAFE_componentWillReceiveProps(np: any, ns: any) {
    const { agents, addresses } = this.props.store.stores;
    const id = parseInt(this.props.match.params.id);
    if (id === np.match.params.id) {
      return;
    }
    addresses.getLocalities();
    if (id === 0) {
      this.reset();
      return;
    }
    // TODO refactor to store?

    agents.getAgentById(id).then((a) => {
      try {
        if (a.AddressID !== 0) {
          addresses.getAddressById(a.AddressID);
        }
      } catch (error) {
        a.AddressID = 0;
      }
    });
    const { actions } = this.props.store.stores;
    actions.getActions()
  }

  componentWillUnmount() {
    this.reset();
  }

  createAgent = async () => {
    const { addresses, agents, ui } = this.props.store.stores;
    const { agent } = agents;

    try {
      if (!addresses.isValid()) {
        ui.showToast({ message: "Invalid Address", color: "danger" });
        return;
      }
      const addr = await addresses.createAddress();
      agent.AddressID = addr.ID;
      const a = await agents.createAgent();
      ui.showToast({ message: "Agent Created", color: "success" });
      agents.getAgentById(a.ID);
      this.props.history.replace(`/agents/${a.ID}`);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
    }
  };

  updateAgent = async () => {
    const { addresses, agents, ui } = this.props.store.stores;
    const [addressSaved, agentSaved] = await Promise.all([
      addresses.updateAddress(),
      agents.saveAgent(),
    ]);

    if (addressSaved && agentSaved) {
      ui.showToast({ message: "Agent Updated", color: "success" });
    } else {
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  handleSubmit = async () => {
    const { agents } = this.props.store.stores;
    const { agent } = agents;
    if (agent.ID === 0) {
      this.createAgent();
    } else {
      this.updateAgent();
    }
  };

  handleDelete = async (confirmed: boolean) => {
    const { agents, ui } = this.props.store.stores;
    if (!confirmed) {
      ui.showAlert({
        handler: () => {
          this.handleDelete(true);
        },
      });
      return;
    }
    const { agent } = agents;
    if (agent.ID === 0) return;
    try {
      const s = await agents.deleteAgent(agent.ID);
      if (s) {
        ui.showToast({ message: "Agent Removed", color: "success" });
        this.reset();
      }
      this.props.history.replace("/agents");
    } catch (error) {
      console.log(error);
      ui.showToast({ message: "Something went wrong", color: "danger" });
    }
  };

  reset() {
    const { agents, addresses } = this.props.store.stores;
    addresses.resetAddress();
    agents.resetAgent();
  }

  body = () => {
    const { agent } = this.props.store.stores.agents;
    return (
      <IonContent>
        <AgentContactDetails />
        <hr />

        <Address title={"Business Address"} />
        <hr />

        <ContactPersonDetails />
        <hr />

        {agent.ID !== 0 ? <AgentPasswordReset /> : null}
        <hr />
        <hr />
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="6" sizeLg="2">
              <IonButton
                color="primary"
                expand="full"
                onClick={this.handleSubmit}
              >
                Save
              </IonButton>
            </IonCol>
            {agent.ID !== 0 ? (
              <IonCol sizeXs="6" sizeLg="2">
                <IonButton
                  color="danger"
                  expand="full"
                  onClick={() => this.handleDelete(false)}
                >
                  Delete
                </IonButton>
              </IonCol>
            ) : null}
          </IonRow>
        </IonGrid>
        <hr />
        <hr />
      </IonContent>
    );
  };

  render() {
    const { agent } = this.props.store.stores.agents;
    const title = agent.ID === 0 ? "New Agent" : "Agent Details";
    return (
      <IonPage>
        <Header title={title} backURL="/agents" />
        {this.body()}
      </IonPage>
    );
  }
}

export default withRouter(AgentDetails);
