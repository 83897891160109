import React, { useState } from "react";
import { Modifier } from "../../stores/models";
import { Tile, Tiles } from "..";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

interface IModifier {
  store?: RootStore;
  onSelect: Function;
  modifiers: Modifier[];
}

const Modifiers: React.FC<IModifier> = ({ modifiers, onSelect, store }) => {
  const [selected, setSelected] = useState(0);
  const [children, setChildren] = useState<Modifier[]>([]);

  const handleClick = (i: Modifier) => {
    if (selected !== i.ID && (i.ParentID === 0 || i.ParentID === null)) {
      if (!getChildren(i.ID)) {
        onSelect(i.ID);
      } else {
        setSelected(i.ID);
      }
    } else {
      setChildren([]);
      setSelected(0);
      if (i.ParentID !== null && i.ParentID > 0) {
        onSelect(i.ID);
      }
    }
  };

  const getChildren = (id: number): boolean => {
    const { modifiers } = store!.stores;
    const children = modifiers.getModifiersByParent(id);
    setChildren(children);
    return children.length > 0;
  };

  return (
    <Tiles>
      {modifiers.map((m) => {
        return selected === 0 || selected === m.ID ? (
          <Tile
            key={m.ID}
            image={m.Image}
            title={m.Name}
            selected={false}
            onClick={() => {
              handleClick(m);
            }}
            className={"t-grid-item-text "}
          />
        ) : null;
      })}
      <>
        {children.map((c) => {
          return (
            <Tile
              key={c.ID}
              title={c.Name}
              image={c.Image}
              selected={false}
              onClick={() => handleClick(c)}
              className={"t-grid-item-text "}
            />
          );
        })}
      </>
    </Tiles>
  );
};

export default inject("store")(observer(Modifiers));
