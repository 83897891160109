import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { IonItem, IonLabel, IonCheckbox } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";

interface IPiecesSelection {
  checked?: boolean;
  store?: RootStore;
  pieces: string[];
}

const PiecesSelect: React.FC<IPiecesSelection> = (props: IPiecesSelection) => {
  const { tickets } = props.store!.stores;

  return (
    <div>
      {props.pieces.map((val) => {
        return (
          <IonItem>
            <IonLabel>{val}</IonLabel>
            <IonCheckbox
              slot="end"
              value={val}
              checked={props.checked}
              onIonChange={(e) => {
                let value: string = e.detail.value + "";
                tickets.updateSelectedPieces(value.trim());
              }}
            />
          </IonItem>
        );
      })}
    </div>
  );
};

export default inject("store")(observer(PiecesSelect));
