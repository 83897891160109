import React from "react";
import { inject, observer } from "mobx-react";
import "./MultipleSelect.css";
import { IonSelect, IonSelectOption } from "@ionic/react";

interface IMultipleSelect {
  selected: string[];
  options: string[];
  label: string;
  onSelect: Function;
  placeholder?: string;
}

const MultipleSelect: React.FC<IMultipleSelect> = (props: IMultipleSelect) => {
  return (
    <>
      <IonSelect
        style={{ width: "100%;" }}
        placeholder={props.placeholder ? props.placeholder : ""}
        interface={"popover"}
        value={props.selected}
        multiple={true}
        cancelText="Cancel"
        okText="Ok!"
        onIonChange={(e) => {
          props.onSelect(e.detail.value);
        }}
      >
        {props.options.map((o) => {
          return <IonSelectOption value={o}>{o}</IonSelectOption>;
        })}
      </IonSelect>
    </>
  );
};

export default inject("store")(observer(MultipleSelect));
