import React from "react";
import { RootStore } from "../stores/RootStore";
import { RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonFooter,
  IonButtons,
  IonList,
  IonIcon,
  IonButton,
  withIonLifeCycle,
} from "@ionic/react";
import { Header, TicketItems, PrinterSelect } from "../components";
import {
  navigateSharp,
  personSharp,
  callSharp,
  business,
  cutOutline,
} from "ionicons/icons";
import ProcessFeedbackBar from "../components/ProcessFeedbackBar/ProcessFeedbackBar";
import { runInAction } from "mobx";
import { Customer, Ticket } from "../stores/models";
import StateDropDown from "../components/StateDropDown/StateDropDown";
import moment from "moment";
import DiscountSelect from "../components/DiscountSelect/DiscountSelect";
import MessageDropDown from "../components/MessageDropDown/MessageDropDown";

interface ITicketViewer {
  store: RootStore;
}

interface RouteProps {
  id: string;
}

@inject("store")
@observer
class TicketViewer extends React.Component<
  ITicketViewer & RouteComponentProps<RouteProps>
> {
  componentDidMount() {
    // this.getDataFromApi();
  }

  ionViewWillLeave() {
    const { tickets } = this.props.store.stores;
    runInAction(() => {
      tickets.ticket = new Ticket();
      tickets.ticketSubCategoriesToPrint = [];
    });
    console.log("ionViewWillLeave event fired");
  }


  ionViewDidEnter() {
    console.log("entered again");
    this.getDataFromApi();
  }

  async getDataFromApi() {
    const { tickets, statistics } = this.props.store.stores;
    await tickets.getTicket(parseInt(this.props.match.params.id));
    statistics.getActionWorkLoadStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
  }
  componentWillUnmount() {
    const { tickets } = this.props.store.stores;
    tickets.reset();
  }

  printTicket = () => {
    const { ui, tickets } = this.props.store.stores;
    if (tickets.ticket.ID === 0 || tickets.ticket.SubCategories.length === 0) {
      ui.showToast({ color: "warning", message: "Ticket is empty" });
      return false;
    }
    ui.showDialog({
      title: "Select Printer",
      content: <PrinterSelect />,
      // content: <div />,
      OKText: "PRINT",
      CancelText: "CLOSE",
      handler: () => {
        tickets.print();
      },
    });
  };

  printLabels = () => {
    const { ui, tickets } = this.props.store.stores;
    console.log(`Print Labels Ticket is : ${tickets.ticket}`);
    if (tickets.ticket.ID === 0 || tickets.ticket.SubCategories.length === 0) {
      ui.showToast({ color: "warning", message: "Ticket is empty" });
      return false;
    }
    ui.showDialog({
      title: "Select Printer",
      // content: <>print all</>,
      content: <PrinterSelect />,
      OKText: "PRINT 0",
      CancelText: "CLOSE",
      handler: async () => {
        await tickets.printAllItemLabels(tickets.ticket, 0);
        tickets.ticket.SubCategories.forEach((tsc) => {
          let tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          this.selectProcessDate(tsc.ID, moment(tomorrow).format("YYYY-MM-DD"));
        });
      },
    });
  };

  applyDiscount = () => {
    const { ui, tickets } = this.props.store.stores;
    ui.showDialog({
      title: "Apply Discount",
      content: <DiscountSelect />,
      OKText: "Apply",
      CancelText: "CANCEL",
      handler: async () => {
        //from ticket store get the discount and type and send it to the backend to handle stuff...
        //with the response being ticket with children set the ticket in store so that the screen refreshes with the discount
        console.log(tickets.ticket.Discount);
        await tickets.updateDiscount();
        ui.closeDialog();
      },
    });
  };

  selectProcessDate = async (id: number, date: string) => {
    const { tickets, statistics } = this.props.store.stores;
    await tickets.updateTicketSubCategoryPorcessDate(id, date);
    await statistics.getActionWorkLoadStatisticsByWeek(
      statistics.yearNumber,
      statistics.weekNumber
    );
  };

  renderItems(ticket: Ticket, customer: Customer) {
    const { tickets } = this.props.store.stores;
    let balance: number = 0;
    ticket.SubCategories.forEach(
      (tsc) => (balance += tsc.getTotal() - tsc.TotalPaid!)
    );
    return (
      <IonList>
        <IonItem>
          <IonGrid style={{ padding: 0 }}>
            <IonRow>
              {ticket.Agent.ID > 0 ? (
                <IonCol sizeXs="12" sizeLg="3">
                  <IonIcon icon={business} style={{ fontSize: 13 }} />
                  &nbsp;&nbsp;{ticket.Agent.Name} / {ticket.Employee.FullName}
                </IonCol>
              ) : null}
              <IonCol sizeXs="12" sizeLg="2">
                <IonLabel>
                  <IonIcon icon={personSharp} style={{ fontSize: 13 }} />
                  &nbsp;&nbsp;
                  <strong>{customer.FullName}</strong>
                </IonLabel>
              </IonCol>
              {customer.AddressID > 0 ? (
                <IonCol sizeXs="12" sizeLg="4">
                  <IonLabel style={{ fontSize: 14 }}>
                    <IonIcon icon={navigateSharp} style={{ fontSize: 13 }} />
                    &nbsp;&nbsp;
                    {customer.Address.Number} {customer.Address.Name}{" "}
                    {customer.Address.Street.Name}{" "}
                    {customer.Address.Locality.Name}
                  </IonLabel>
                </IonCol>
              ) : null}
              {customer.Landline.trim() !== "" ||
              customer.Mobile.trim() !== "" ? (
                <IonCol sizeXs="12" sizeLg="2">
                  <IonLabel style={{ fontSize: 14 }}>
                    <IonIcon icon={callSharp} style={{ fontSize: 13 }} />
                    &nbsp;&nbsp;
                    <a href={"tel:" + customer.Mobile}>{customer.Mobile}</a>
                    &nbsp;&nbsp;
                    <a href={"tel:" + customer.Landline}>{customer.Landline}</a>
                  </IonLabel>
                </IonCol>
              ) : null}
              <IonCol>
                <IonButton onClick={this.applyDiscount}>
                  <IonIcon icon={cutOutline} />
                </IonButton>
              </IonCol>
              <IonCol><MessageDropDown store={this.props.store} ticketID={ticket.ID}/></IonCol>
              <IonCol>
                <IonButton
                  style={{ float: "right" }}
                  color={balance === 0 ? "success" : "danger"}
                  onClick={async () => {
                    await ticket.SubCategories.forEach((tsc) => {
                      tickets.updatePayment(tsc.ID, tsc.getTotal());
                    });
                    tickets.getAllForCustomer(
                      this.props.store.stores.customers.customer.ID
                    );
                    this.props.store.stores.customers.reloadCustomer();
                  }}
                >
                  {balance === 0 ? "PAID" : balance.toFixed(2)}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
    );
  }

  render() {
    const { statistics } = this.props.store.stores;
    const { ticket } = this.props.store.stores.tickets;
    const customer = ticket.Customer;

    return (
      <IonPage>
        <Header
          title={"View Ticket #" + ticket.ID}
          backURL={"/customers/" + ticket.CustomerID}
          onPrintAll={this.printLabels}
          showPriceTag={true}
          onPrint={this.printTicket}
        />
        {this.renderItems(ticket, customer)}

        <IonContent style={{ flex: 3 }}>
          <TicketItems items={ticket.SubCategories} view={true} />
        </IonContent>
        <IonFooter>
          <ProcessFeedbackBar
            store={this.props.store}
            title={"Process Feedback"}
            data={statistics.actionWorkLoadStatistics}
            forwardWeekFunction={() => {
              const { statistics } = this.props.store!.stores;
              runInAction(() => {
                statistics.weekNumber++;
                if (statistics.weekNumber === 53) {
                  statistics.weekNumber = 1;
                  statistics.yearNumber++;
                }
                statistics.getActionWorkLoadStatisticsByWeek(
                  statistics.yearNumber,
                  statistics.weekNumber
                );
              });
            }}
            backWeekFunction={() => {
              runInAction(() => {
                const { statistics } = this.props.store!.stores;
                statistics.weekNumber--;
                if (statistics.weekNumber === -1) {
                  statistics.weekNumber = 52;
                  statistics.yearNumber--;
                }
                statistics.getActionWorkLoadStatisticsByWeek(
                  statistics.yearNumber,
                  statistics.weekNumber
                );
              });
            }}
          />
          <IonToolbar>
            <IonButtons slot="start" style={{ paddingLeft: 10 }}>
              <StateDropDown
                store={this.props.store!}
                elements={this.props.store!.stores.tracking.states.map(
                  (s) => s
                )}
                selected={ticket.State}
                onSelect={async (s: string, sig: string) => {
                  console.log(`item selected ${s}`);
                  await this.props.store?.stores.tracking.setTicketsState(
                    [ticket.ID],
                    s,
                    sig
                  );
                  this.props.store?.stores.tickets.reloadTicket();
                }}
              />
            </IonButtons>
            <IonButtons slot="end">
              <h1
                style={{ marginRight: 10, padding: 0, marginTop: 5 }}
                color="success"
              >
                Total: &euro; {ticket.Total}
              </h1>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(TicketViewer);
