import { RootStore } from "./RootStore";
import { observable, action, runInAction } from "mobx";
import { Action } from "./models";
import { ActionService } from "../services";
import Fuse from "fuse.js";

class ActionStore {
  private root: RootStore;
  private api: ActionService;

  @observable public actions: Action[] = [];
  @observable private _actions: Action[] = [];
  @observable public action: Action = new Action();

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.actions;
  }

  public async getActions() {
    const { ui } = this.root.stores;
    if (this.actions.length > 0) {
      return;
    }
    try {
      const actions = await this.api.fetchAll();
      this.setActions(actions, true);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async createAction(): Promise<Action> {
    const { ui } = this.root.stores;
    try {
      const action = await this.api.create(this.action);
      runInAction(() => {
        this.actions.push(action);
        this._actions.push(action);
      });
      return action;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async updateAction() {
    try {
      await this.api.update(this.action.ID, this.action);
      const actions = await this.api.fetchAll();
      this.setActions(actions, true);
      return true;
    } catch (error) {
      throw error;
    }
  }

  public async saveAction(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      // TODO: parse response or action ?
      await this.api.update(this.action.ID, this.action);
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getActionByIdAsync(id: number) {
    const { ui } = this.root.stores;
    this.setAction(new Action());
    ui.setLoading(true);
    try {
      const ACTION = await this.api.fetchOne(id);
      this.setAction(ACTION);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public getActionById(id: number): Action | undefined {
    return this.actions.find((a) => a.ID === id);
  }

  public async deleteAction(id: number): Promise<boolean> {
    if (this.action.ID === 0) {
      return false;
    }
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.delete(id);
      if (resp.success) {
        runInAction(() => {
          const actionsToFilter = this.actions;
          this.actions = actionsToFilter.filter((a) => a.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  // TODO: refactor to service ?
  @action("filter action")
  public filterActions(q: string) {
    if (q.trim() === "") {
      this.actions = this._actions;
      return;
    }

    const filterOptions: any = {
      threshold: 0,
      distance: 10,
      keys: ["Name"],
    };
    const fuse = new Fuse(this._actions, filterOptions);
    this.actions = fuse.search(q).map((a) => a.item);
  }

  @action
  public resetAction() {
    this.action = new Action();
  }

  @action
  public resetActions() {
    this.actions = [];
    this._actions = [];
  }

  @action
  public setAction(action: Action) {
    this.action = action;
  }

  @action
  public setActions(actions: Action[], reload: boolean = false) {
    if (reload) {
      this._actions = actions;
    }
    this.actions = actions;
  }
}

export default ActionStore;
