import axios from "axios";
import API_CONFIG from "../services/api.config";
import {
  UIStore,
  AuthStore,
  CustomersStore,
  AddressStore,
  PreferencesStore,
  AgentStore,
  EmployeeStore,
  GarmentStore,
  ActionStore,
  ModifierStore,
  TicketsStore,
  PrintStore,
  StatisticsStore,
  ConfigurationStore,
  TrackingStore,
  ActionGarmentSubCategoryStore,
  PickupDeliveryStore,
  ReportingStore,
  MessagingStore,
} from "../stores";
import {
  CustomerService,
  AuthService,
  StorageService,
  AddressService,
  PreferencesService,
  AgentService,
  EmployeeService,
  TicketService,
  ActionService,
  GarmentService,
  ModifierService,
  PrintService,
  ConfigurationService,
  ActionGarmentSubCategoryService,
  StatisticsService,
  TrackingService,
  PickupDeliveryService,
  ReportingService,
  MessagingService,
} from "../services";
import { IResponse } from "../services/BaseService";
import LoadingStore from "./LoadingStore";
import { observable } from "mobx";

interface IServices {
  auth: AuthService;
  customers: CustomerService;
  addresses: AddressService;
  preferences: PreferencesService;
  agents: AgentService;
  storage: StorageService;
  employees: EmployeeService;
  modifiers: ModifierService;
  actions: ActionService;
  statistics: StatisticsService;
  configuration: ConfigurationService;
  actionGarmentSubCategoryService: ActionGarmentSubCategoryService;
  tickets: TicketService;
  garments: GarmentService;
  printer: PrintService;
  tracking: TrackingService;
  pickupDelivery: PickupDeliveryService;
  reporting: ReportingService;
  messagingService:MessagingService;
}

interface IStores {
  ui: UIStore;
  auth: AuthStore;
  loading: LoadingStore;
  customers: CustomersStore;
  addresses: AddressStore;
  preferences: PreferencesStore;
  employees: EmployeeStore;
  agents: AgentStore;
  tickets: TicketsStore;
  actions: ActionStore;
  configuration: ConfigurationStore;
  pickupDelivery: PickupDeliveryStore;
  actionGarmentSubCategories: ActionGarmentSubCategoryStore;
  garments: GarmentStore;
  modifiers: ModifierStore;
  printer: PrintStore;
  statistics: StatisticsStore;
  tracking: TrackingStore;
  reporting: ReportingStore;
  messagingStore:MessagingStore;
}

class RootStore {
  services: IServices;
  stores: IStores;
  @observable interval: any;

  constructor() {
    const http = axios.create(API_CONFIG);
    http.interceptors.response.use(this._handleResponse, this._handleError);

    this.services = {
      auth: new AuthService(http),
      customers: new CustomerService(http),
      addresses: new AddressService(http),
      preferences: new PreferencesService(http),
      agents: new AgentService(http),
      employees: new EmployeeService(http),
      tickets: new TicketService(http),
      garments: new GarmentService(http),
      actions: new ActionService(http),
      statistics: new StatisticsService(http),
      configuration: new ConfigurationService(http),
      pickupDelivery: new PickupDeliveryService(http),
      modifiers: new ModifierService(http),
      actionGarmentSubCategoryService: new ActionGarmentSubCategoryService(
        http
      ),
      storage: new StorageService(),
      printer: new PrintService(),
      tracking: new TrackingService(http),
      reporting: new ReportingService(http),
      messagingService:new MessagingService(http),
    };
    this.stores = {
      loading: new LoadingStore(this),
      ui: new UIStore(this),
      auth: new AuthStore(this),
      customers: new CustomersStore(this),
      addresses: new AddressStore(this),
      preferences: new PreferencesStore(this),
      agents: new AgentStore(this),
      employees: new EmployeeStore(this),
      tickets: new TicketsStore(this),
      actions: new ActionStore(this),
      configuration: new ConfigurationStore(this),
      pickupDelivery: new PickupDeliveryStore(this),
      modifiers: new ModifierStore(this),
      actionGarmentSubCategories: new ActionGarmentSubCategoryStore(this),
      garments: new GarmentStore(this),
      printer: new PrintStore(this),
      statistics: new StatisticsStore(this),
      tracking: new TrackingStore(this),
      reporting: new ReportingStore(this),
      messagingStore:new MessagingStore(this),
    };

    // this.interval = setInterval(async () => {
    //   const {
    //     customers,
    //     addresses,
    //     preferences,
    //     agents,
    //     employees,
    //     tickets,
    //     actions,
    //     configuration,
    //     pickupDelivery,
    //     modifiers,
    //     actionGarmentSubCategories,
    //     garments,
    //     statistics,
    //   } = this.stores;
    //   console.log("hi");
    //   customers.resetCustomers();
    //   addresses.resetAddress();
    //   garments.resetGarmentCategories();
    //   statistics.resetStatistics();
    //   statistics.getThisMonth();
    //   preferences.resetPreferences();
    //   agents.resetAgents();
    //   employees.resetEmployee();
    //   tickets.resetTickets();
    //   actions.resetActions();
    //   configuration.resetConfig();
    //   pickupDelivery.resetPickupDeliveries();
    //   modifiers.resetModifier();
    //   actionGarmentSubCategories.resetActionGarmentSubCategories();
    //   await addresses.getAddresses();
    //   await addresses.getLocalities();
    //   addresses.localities.forEach((l) => {
    //     addresses.getStreets(l.ID);
    //   });
    //   await preferences.getPreferences();
    //   await customers.getCustomersLimited(20,"");
    //   await agents.getAgents();
    //   await employees.getEmployees();
    //   await tickets.getAllTickets();
    //   await tickets.getAllTicketsWithUnconfirmed();
    //   await actions.getActions();
    //   await configuration.getConfigurations();
    //   await pickupDelivery.getPickupDeliveries();
    //   await modifiers.getModifiers();
    //   await actionGarmentSubCategories.getActionGarmentSubCategories();
    //   await garments.getGarmentGategories();
    //   await garments.getGarmentSubCategoriesAsync();
    //   await statistics.fetchStatsThisWeek();
    //   await statistics.getScheduleStatisticsByWeek(
    //     statistics.yearNumber,
    //     statistics.weekNumber
    //   );
    //   await statistics.getActionWorkLoadStatisticsByWeek(
    //     statistics.yearNumber,
    //     statistics.weekNumber
    //   );
    //   if (customers.customer.ID !== 0) {
    //     await tickets.getAllForCustomer(customers.customer.ID);
    //     customers.getCustomerWithDetails(customers.customer.ID);
    //   }
    //   // printer
    //   // tracking
    // }, 30000);
  }

  private _handleResponse = (resp: any) => resp;

  private _handleError = (error: any) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    const resp: IResponse = error.response.data;
    const { auth } = this.stores;
    if (!resp.data && !resp.success && resp.message.startsWith("Agent")) {
      auth.agentLogout();
    }
    if (!resp.data && !resp.success && resp.message.startsWith("Employee")) {
      auth.employeeLogout();
    }
    if (!resp.data && !resp.success && resp.message.startsWith("Token")) {
      auth.agentLogout();
      auth.employeeLogout();
    }
  };
}

const rootStore = new RootStore();
export { rootStore, RootStore };
