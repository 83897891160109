import { RootStore } from "./RootStore";
import { observable, action, runInAction, computed } from "mobx";
import { Modifier } from "./models";
import { ModifierService } from "../services";
import Fuse from "fuse.js";

class ModifierStore {
  private root: RootStore;
  private api: ModifierService;

  @observable public modifiers: Modifier[] = [];
  @observable public allModifiers: Modifier[] = [];
  @observable public subModifiers: Modifier[] = [];
  @observable public _modifiers: Modifier[] = [];
  @observable public modifier: Modifier = new Modifier();
  @observable public subModifier: Modifier = new Modifier();
  @observable
  public showMoreMaterials: boolean = false;
  @observable
  public showMoreStains: boolean = false;
  @observable
  public showMoreBrands: boolean = false;
  @observable
  public reOrderDisabled: boolean = true;
  @observable
  public subCategoryReOrderDisabled: boolean = true;

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.modifiers;
  }

  public async getModifiers(force?:boolean) {
    const { ui } = this.root.stores;
    if (!force){
      if (this.modifiers.length > 0 && this._modifiers.length > 0) {
        return;
      }
    }
    try {
      const modifiers = await this.api.fetchAll();
      this.setAllModifiers(this.sortModifierList(modifiers));
      const sortedModifiers = this.sortModifierList(modifiers).filter(
        (m) => m.ParentID === 0
      );
      this.setModifiers(sortedModifiers, true);
      if (this.modifier.ID !== 0) {
        const subModifiers = this.sortModifierList(modifiers).filter((m) => {
          return m.ParentID === this.modifier.ID;
        });
        this.setSubModifiers(subModifiers);
      }
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getModifierByIdAsync(id: number) {
    const { ui } = this.root.stores;
    this.setModifier(new Modifier());
    ui.setLoading(false);
    try {
      const MODIFIER = await this.api.fetchOne(id);
      this.setModifier(MODIFIER);
      const subModifiers = this.sortModifierList(this.allModifiers).filter(
        (m) => {
          return m.ParentID === id;
        }
      );
      this.setSubModifiers(subModifiers);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async getSubModifierByIdAsync(id: number) {
    const { ui } = this.root.stores;
    this.setSubModifier(new Modifier());
    ui.setLoading(false);
    try {
      const MODIFIER = await this.api.fetchOne(id);
      this.setSubModifier(MODIFIER);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      ui.setLoading(false);
    }
  }

  public async reOrderSubModifiersAsyc(): Promise<void> {
    const { ui } = this.root.stores;
    try {
      await this.api.reOrderModifiers(this.subModifiers);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public sortModifierList(modifiers: Modifier[]): Modifier[] {
    return modifiers.slice().sort(function (a, b) {
      return a.Order - b.Order;
    });
  }

  public getRootModifiers(type: string): Modifier[] {
    return this.modifiers.filter((m) => m.ParentID === 0 && m.Type === type);
  }

  public getModifierById(id: number): Modifier | undefined {
    return this.allModifiers.find((m) => m.ID === id);
  }

  @action("filter modifier")
  public filterModifiers(q: string) {
    if (q.trim() === "") {
      this.modifiers = this._modifiers;
      return;
    }

    const filterOptions: any = {
      threshold: 0,
      distance: 10,
      keys: ["Name"],
    };
    const fuse = new Fuse(this._modifiers, filterOptions);
    this.modifiers = fuse.search(q).map((a) => a.item);
  }

  public getModifiersByParent(parentId: number): Modifier[] {
    return this.allModifiers.filter((m) => m.ParentID === parentId);
  }

  public async createModifier(): Promise<Modifier> {
    const { ui } = this.root.stores;
    try {
      const modifier = await this.api.create(this.modifier);
      runInAction(() => {
        if (modifier.ParentID !== 0) {
          // this.modifiers.push(modifier);
          // this.allModifiers.push(modifier);
        } else {
          // this.subModifiers.push(modifier);
        }
      });
      return modifier;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async updateModifier(id: number, modifier: Modifier) {
    try {
      const m = await this.api.update(id, modifier);
      runInAction(() => {
        this.replaceModifier(m);
      });
      return true;
    } catch (error) {
      throw error;
    }
  }

  public async saveModifier(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      const m = await this.api.update(this.modifier.ID, this.modifier);
      runInAction(() => {
        console.log(m);
        this.replaceModifier(m);
      });
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async saveSubModifier(): Promise<boolean> {
    const { ui } = this.root.stores;
    try {
      // TODO: parse response or action ?
      await this.api.update(this.subModifier.ID, this.subModifier);
      return true;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async deleteModifier(id: number): Promise<boolean> {
    if (this.modifier.ID === 0) {
      return false;
    }
    const { ui } = this.root.stores;
    try {
      const resp = await this.api.delete(id);
      if (resp.success) {
        runInAction(() => {
          const modifiersToFilter = this.modifiers;
          this.modifiers = modifiersToFilter.filter((a) => a.ID !== id);
          const allModifiersToFilter = this.allModifiers;
          this.allModifiers = allModifiersToFilter.filter((a) => a.ID !== id);

          const subModifiersToFilter = this.subModifiers;
          this.subModifiers = subModifiersToFilter.filter((a) => a.ID !== id);
        });
      }
      return resp.success;
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async reOrderModifiersAsyc(): Promise<void> {
    const { ui } = this.root.stores;
    try {
      console.log('reorder async')
      await this.api.reOrderModifiers(this.modifiers);
    } catch (error) {
      ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public typeIsLess(key: string): boolean {
    if (key === "MATERIAL") {
      return this.showMoreMaterials;
    }
    if (key === "STAIN") {
      return this.showMoreStains;
    }
    if (key === "BRAND") {
      return this.showMoreBrands;
    }
    return false;
  }

  public sortModifiers(): Modifier[] {
    return this.modifiers.slice().sort(function (a, b) {
      return a.Order - b.Order;
    });
  }

  @action
  public replaceModifier(modifier: Modifier) {
    console.log("replace modifier started");
    console.log(modifier);
    this.modifier = modifier;
    if (modifier.ParentID !== 0) {
      if (this.allModifiers.length > 0) {
        var index = this.allModifiers.findIndex(
          (item) => item.ID === modifier.ID
        );
        console.log("allmodifiers");
        this.allModifiers.splice(index, 1, modifier);
      }
      if (this.subModifiers.length > 0) {
        var index = this.subModifiers.findIndex(
          (item) => item.ID === modifier.ID
        );
        console.log("sub modifiers");
        this.subModifiers.splice(index, 1, modifier);
      }
    } else {
      if (this.modifiers.length > 0) {
        var index = this.modifiers.findIndex((item) => item.ID === modifier.ID);
        console.log("modifiers and _modifiers");
        this.modifiers.splice(index, 1, modifier);
        this._modifiers.splice(index, 1, modifier);
      }
      if (this.allModifiers.length > 0) {
        var index = this.allModifiers.findIndex(
          (item) => item.ID === modifier.ID
        );
        console.log("allmodifiers");
        this.allModifiers.splice(index, 1, modifier);
      }
    }
  }

  // public sortTypes(): string[] {
  // }

  @computed
  public get types(): string[] {
    var types = [
      "COLOR",
      "DESIGN",
      "MATERIAL",
      "STAIN",
      "BRAND",
      "TAILOR",
      "EXPRESS",
    ];
    return types;
  }

  @action
  public resetModifier() {
    this.modifier = new Modifier();
  }

  @action
  public resetModifiers() {
    this.modifiers = [];
    this.allModifiers = [];
    this.subModifiers = [];
    this._modifiers = [];
  }

  @action
  public resetSubModifier() {
    this.subModifier = new Modifier();
  }

  @action
  public setModifier(modifier: Modifier) {
    this.modifier = modifier;
  }

  @action
  public setSubModifier(modifier: Modifier) {
    this.subModifier = modifier;
  }

  @action
  public setModifiers(modifiers: Modifier[], reload: boolean = false) {
    if (reload) {
      this._modifiers = modifiers;
    }
    this.modifiers = modifiers;
  }

  @action
  public setAllModifiers(modifiers: Modifier[]) {
    this.allModifiers = modifiers;
  }

  @action
  public setSubModifiers(subModifiers: Modifier[]) {
    this.subModifiers = subModifiers;
  }

  @action
  public setShowMaterialStainBrand(key: string) {
    if (key === "MATERIAL") {
      this.showMoreMaterials = !this.showMoreMaterials;
    }
    if (key === "STAIN") {
      this.showMoreStains = !this.showMoreStains;
    }
    if (key === "BRAND") {
      this.showMoreBrands = !this.showMoreBrands;
    }
  }

  @action
  public toggleReOrderDisabled() {
    this.reOrderDisabled = !this.reOrderDisabled;
  }

  @action
  public setImageModifier(image: string) {
    this.modifier.Image = image;
    const m = this.modifier;
    this.resetModifier();
    this.setModifier(m);
  }

  @action
  public  reOrderModifiers(old_index: number, new_index: number, type:string): Modifier[] {
    console.log(type)
    console.log(old_index, " ", new_index)
    //given the type get the modifiers of this type in order
    const typeModifiers = this.modifiers.filter(m => m.Type === type).sort(function(a:Modifier,b:Modifier){
      if (a.Order < b.Order) {
      return -1;
    }
    if (a.Order > b.Order) {
      return 1;
    }
    // names must be equal
    return 0;})

    const reOrderedModifiers = this.moveModifier(typeModifiers, old_index, new_index)
    //now save them in their order
   this.api.reOrderModifiers(reOrderedModifiers).then(()=>{

    this.getModifiers(true)
   })
    // then get the old index item and put it in the new index position
    return this.modifiers;
  }

  // https://www.w3resource.com/javascript-exercises/javascript-array-exercise-38.php
  private moveModifier(arr:Modifier[], old_index:number, new_index:number) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    // if (new_index >= arr.length) {
    //     var k = new_index - arr.length;
    //     while ((k--) + 1) {
    //         arr.push(undefined);
    //     }
    // }
     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
   return arr;
}

  @action
  public reOrderSubModifiers(old_index: number, new_index: number): Modifier[] {
    this.subModifiers.splice(
      new_index,
      0,
      this.subModifiers.splice(old_index, 1)[0]
    );
    return this.subModifiers;
  }

  @action
  public toggleModifierSubCategoryReOrderDisabled() {
    this.subCategoryReOrderDisabled = !this.subCategoryReOrderDisabled;
  }

  @action
  setModifierColor(color: string) {
    this.modifier.Image = `color:${color}`;
    const tmp = this.modifier;
    this.modifier = new Modifier();
    this.modifier = tmp;
  }

  @action
  setModifierType(type: string) {
    this.modifier.Type = type;
    const tmp = this.modifier;
    this.modifier = new Modifier();
    this.modifier = tmp;
  }

  @action
  setModifierParent(parentID: number) {
    this.modifier.ParentID = parentID;
    const tmp = this.modifier;
    this.modifier = new Modifier();
    this.modifier = tmp;
  }
}

export default ModifierStore;
